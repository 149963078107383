.portal-modal {
    overflow-y: scroll !important;
    margin-right: -17px;

    @translate: 20px;
    @animation-duration: 0.4s;

    .modal-dialog {
        .absolute();
        .fadeIn(@animation-duration);
        .animation-delay(0);
        margin: 0;
        width: auto;

        .modal-content {
            width: 100%;
            height: 100%;
        }
    }

    .modal-header {
        .flex();
        .flex-center();
        border: 0;
        height: 140px;
        padding-top: 50px;

        .modal-title {
            font-size: 30px;
            .delayed-show-bt(0.20s, @animation-duration, @translate);
        }
    }

    .modal-body {

        .portal-container {
            .flex();
            .flex-center();

            &.item-selected {
                .fadeOut(@animation-duration, @animation-duration);
            }
        }

        .portal-item {
            text-align: center;
            margin: 0 40px;
            will-change: transform;
            width: 350px;

            &:nth-of-type(1) {
                .delayed-show-bt(0.25s, @animation-duration, @translate);
            }

            &:nth-of-type(2) {
                .delayed-show-bt(0.40s, @animation-duration, @translate);
            }

            &:nth-of-type(3) {
                .delayed-show-bt(0.55s, @animation-duration, @translate);
            }

            &.selected {
                pointer-events: none;
                .fadeOut(@animation-duration, 0.8s);
            }

            &.unselected {
                pointer-events: none;
                .toggle-hide-tb(@animation-duration);
            }

            div.inner {
                padding: 20px 20px;
                .transition(box-shadow, 0.3s);

                &:hover {
                    .box-shadow(0 10px 30px 0 rgba(0, 0, 0, .1));
                    cursor: pointer;
                }
            }

            img {
                width: 100%;
            }

            p.item-title {
                .bold();
                font-size: 14px;
                text-transform: uppercase;
            }

            p.item-subtitle {
                .opacity(80);
                margin-top: 5px;
                font-size: 13px;
            }
        }
    }

    .modal-back,
    .modal-close {
        .flex();
        .flex-center();
        .flex-column();
        .z-index(100);
        .opacity(80);
        .rounded(50%);
        .transition(all, ease, 0.3s);
        position: absolute;
        top: 20px;
        right: 30px;
        text-align: center;
        font-size: 22px;
        cursor: pointer;
        height: 64px;
        width: 64px;

        &:hover {
            .opacity(100);
        }

        span {
            .bold();
            display: block;
            font-size: 10px;
            position: relative;
        }
    }

    .modal-back {
        left: 30px;
    }
}


/* Manage connections */
#manage-connections-modal{

    div.modal-header {
        div.service-square {
            top:-4px;
            margin-right:10px;
        }
    }
}

#edit-form {
  hr {
    opacity: .3;
  }
}

/* Assign Clients */
#assign-client-modal {

    div.modal-header {
        div.service-square {
            top:-4px;
            margin-right:10px;
        }
    }

    div.modal-body {

        h3 {
            margin-top:0;
            font-size: 20px;

            small {
                margin-left:10px;
            }
        }

        div.step-1,
        div.step-2 {
            //.transition();
        }

        div#account-list-header {
            visibility: hidden;
            height:50px;

            div.panel-body {
                padding-bottom:0;
            }
        }

        div.form-group.search {

            input {
                padding-left:30px;
                font-size:13px;
                margin-right:40px;
            }
        }

        div.form-group.search:before {
            top:6px;
            left:21px;
            font-family: 'icomoon';
            content: "\ea7e";
            position:absolute;
            font-size:20px;
            z-index:100;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        div#account-list-container {
            height:405px;
            padding-bottom:10px;
        }


        div.account-list-loading {
            text-align:center;
            width:100%;
            height:360px;
            padding-top:120px;

            span.text {
                .fontSmoothing();
                font-size:21px;
                white-space: nowrap;
            }

            div.delay-text {
                display:none;
                margin-top:25px;

                div.delay-icon {
                    .animate(1.5s);
                    display:inline-block;
                    font-size:38px;
                    position:relative;
                    top:3px;
                    left:5px;
                }

                span.text {
                    font-size:16px;
                }
            }
        }

        //Optional design @gavin TODO: discuss with krisitin
        /*
        div#edit-form {
          height:332px;
          overflow-x: hidden;
          overflow-y: auto;
          padding-right:10px;
        }
        */

        div#account-list {
            height:335px;
            overflow-x: hidden;
            overflow-y: auto;
            padding-right:10px;

            div.no-result {

                span.icon {
                    font-size: 20px;
                    float: left;
                    margin-right: 10px;
                    position: relative;
                    top: -4px;
                }
            }

            a.list-group-item {

                div.toggler {
                    cursor:pointer;
                    position:relative;
                    top:-3px;
                    left:-6px;
                    margin-right:4px;
                    border:1px solid;
                    .rounded(3px);
                    text-align:center;
                    height:22px;
                    width:22px;
                    line-height:20px;
                    font-size:11px;
                    float:left;
                }

                div.toggler.pre-disabled,
                div.toggler.disabled {
                    cursor:default;
                    .opacity(75);
                }

                div.pre-selected-info {
                    float:right;
                    position:relative;
                }

                span.pre-selected {
                    visibility:visible !important;
                }

                span.selected {
                    visibility:visible !important;
                }

                span.badge {
                    margin-left:4px;
                    float:right;
                }

                span.check {
                    visibility:hidden;
                    float:right;
                    font-size:18px;
                    margin-top: -1px;
                }

                span.account-first-date-seen {
                    margin-left: 8px;
                    padding: 3px;
                    border-radius: 5px;
                    background-color: grey;
                    color: white;
                }

                span.account-first-date-seen-new {
                    margin-left: 8px;
                    padding: 3px;
                    border-radius: 5px;
                    background-color: orange;
                    color: white;
                }

                div.unassign-client {
                    float:right;
                    margin-left:12px;
                    margin-right:-4px;
                    text-align:center;
                    width:24px;
                    height:24px;
                    line-height:26px;
                    .rounded(12px);
                    cursor:pointer;
                    font-size:12px;
                    margin-top: -4px;
                    color:#fff;
                    text-indent:3px;
                }
            }

            a.list-group-item.pre-disabled,
            a.list-group-item.disabled {
                cursor:default;
                .opacity(75);

                span.check {
                    visibility:hidden;
                }
            }

            a.list-group-item.pre-disabled:hover,
            a.list-group-item.disabled:hover {
                background: none;
            }

            a.list-group-item-success {

                label {
                    margin-left:5px;
                }
            }

            div.already-assigned-child-warning {
                display:none;
            }

            div.list-group.sub-item-list {
                display:none;
                padding:15px 20px 15px 20px;
                margin-bottom: -1px;
                border-left:1px solid;
                border-right:1px solid;
                border-bottom:1px solid;
                .innershadow(1px, 1px, 8px, 0.1);


                div.alert {
                    margin-bottom:10px;
                }

                div.alert-info {
                    margin-bottom:0;

                    i.icon {
                        margin-right:10px;
                    }
                }
            }
        }

        div#assign-client-form-container {
            min-height:90px;
            padding-right: 15px;

            hr {
              margin: 0;
            }

            form#individual-client-form {
                max-height: 245px;
                overflow-y: auto;
                overflow-x: hidden;
            }

            h4 {
                margin-top:14px;
            }

            h5 {
                margin-top:0;
                margin-bottom:14px;
            }

            form#assign-existing-client {

                div.select2-container {
                    font-size:14px;
                    width: 100% !important;

                    a.select2-choice {
                        height:34px;
                        line-height:32px;

                        div {
                            top:3px;
                        }

                        abbr {
                            top:9px;
                        }
                    }
                }

                div.select2-container:before {
                    top: 6px;
                }
            }

            #email-options {
                margin-top:5px;

                span.help-block {
                    margin-bottom:18px;
                }
            }
        }

        div.cant-find-account {
            margin-bottom:0;
        }

        div.no-result {
            display:none;
        }

        div.service-fetching-error {
            margin-top:35px;
        }

        div#selected-campaigns {
            hr {
                margin-top: 20px;
                margin-bottom: 20px;
            }

            .title {
              font-size:16px;
            }

            .toggler {
              color: inherit;
            }

            div#selected-list {
              display: block;
              margin-left: 10px;
              margin-right: 10px;
              margin-top: 10px;
            }

            .selected-item {
              font-size:12px;
              padding: 8px;
              border: 1px solid #ddd;
              margin-bottom: -1px;
            }

            .button-remove {
              float: right;
              position: relative;
              text-align:center;
              width:24px;
              height:24px;
              line-height:26px;
              .rounded(12px);
              cursor:pointer;
              font-size:12px;
              margin-top: -4px;
              color:#fff;
              background-color: #dd4c3c;
            }
        }
    }

    #load-more-items {

        div.list-group-item:hover {
            .transition();
            cursor: pointer;

        }
    }

    #refresh-account-list-container {
        visibility:hidden;
        height:45px;
        width:100%;
        text-align:center;
        border-top:1px solid;
        padding-top:10px;
        position:relative;
        top:5px;

        span.placeholder {
            margin-right:15px;
            line-height:30px;
        }
    }

    div.modal-footer {
        display:none;
        margin-bottom:0;
        margin-right: 15px;


        div#add-url {
            margin-left:-15px;
        }
    }
}


#image-upload-modal {

    div#image-upload-container {
        max-width: 100%;
       
        .justify-content(center);
        text-align: center;

        div#image-upload-preview {
            position: absolute;

            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
        }

        div.image-upload-placeholder {
            i.icon {
                font-size: 200px;
            }
        }

        div.crop-area {
            clear: both;

            img-crop {
                // Needed in order to fix bug for ng-img-crop inside a modal
                height: 300px;
                width: 100%;
                display: block;
                margin: 10px;
                text-align: center;

                canvas {
                    margin: 0 !important;
                }
            }
        }

        span.file-input {
            width: 100%;
            div.file-caption {
                height: 31px;
            }
        }
    }
}

@media screen and (max-width: 1365px) {
    a.list-group-item div.pre-selected-info {
        display:inline;
        float:none !important;
    }
}

/* Create new client */
#create-client-modal {

    div.modal-body {

        h3 {
            margin-top:30px;
            font-size: 18px;

            small {
                margin-left:10px;
            }
        }
    }
}

/* Add account modal */
div#add-account-modal {
    div.modal-body {
        span.form-control-feedback {
            line-height: 34px;
        }
    }
}

div#manage-dashboard-views-modal {
    div.modal-body {
        table {
            > tbody > tr > td {
                padding: 3px 12px;
            }
        }
    }
}

div#edit-dashboard-modal {

    div.layout-help {
        float:left;
        width:32px;
        height:32px;
        line-height:32px;
        text-align:center;
    }

    span.help-block {
        font-size:12px;
        margin-top:7px;
    }

    ul.widget-sort-container {
        margin-top:15px;

        li {
            cursor:move;

            span.label {
                position:absolute;
                top:12px;
                left:-26px;
            }

            span.label.active {
                .animation(rubberBand 1s ease);
            }

            span.icon-reorder {
                float:left;
                margin-right: 10px;
                margin-left:-5px;
            }

            span.service-square {
                margin-top:-3px;
            }

            b {
                margin-left:2px;
                text-transform: uppercase;
            }

            span.badge {
                float:right;
                cursor:pointer;
                font-size:11px;
                padding-bottom:5px;
                margin-top:-1px;
            }
        }
    }

}

/* Add/edit widget modal */
div#edit-widget-modal {
    div.modal-header {
        a.action {
            margin-left:0;
            margin-right:10px;
        }

    }

    div.modal-body {


        div.widget-type {
            text-align: center;
            height: 120px;
            padding-top: 25px;
            .transition(all, 0.2s);

            div.icon {
                .transition(top, 0.4s);
                top:0;
                position:relative;
                font-size: 40px;
            }

            span.title {
                display: block;
                margin-top: 10px;
                font-size: 14px;
            }
        }


        div.widget-type:hover {
            cursor: pointer;

            div.icon {
                top: -8px;
            }
        }


        div.widget-type.selected {
            div.icon {
                top: 0;
            }
        }
    }
}


/* Resize widget modal */
div#resize-widget-modal {

    @size-box-dimension:80px;
    @preview-box-dimension:85px;
    @heading-size:50px;

    .widget-preview-width(@multiplier: 1) {
        width: (@preview-box-dimension * @multiplier);
    }

    .widget-preview-height(@multiplier: 1) {
        height: (@preview-box-dimension * @multiplier);
    }

    div.modal-body {

        p.text {
            font-size:12px;
            margin-bottom:4px;
        }

        div.widget-pane-preview.panel {
            position:relative;
            margin-bottom:0;
            border:2px dashed;

            div.panel-heading {
                padding:6px 10px;
                overflow:hidden;
                white-space: nowrap;

                div.service-square {
                    margin-right:10px;
                    margin-left:-2px;
                }

                .panel-title {
                    font-size:12px;
                    position:relative;
                    top:3px;
                    display:inline-block;
                }
            }
        }


        div.widget-pane-preview.width1 {
            .widget-preview-width();
        }

        div.widget-pane-preview.height1 {
            .widget-preview-height();
        }

        div.widget-pane-preview.width2 {
            .widget-preview-width(2);
        }

        div.widget-pane-preview.height2 {
            .widget-preview-height(2);
        }

        div.widget-pane-preview.width3 {
            .widget-preview-width(3);
        }

        div.widget-pane-preview.height3 {
            .widget-preview-height(3);
        }

        div.widget-pane-preview.width4 {
            .widget-preview-width(4);
        }

        div.widget-pane-preview.height4 {
            .widget-preview-height(4);
        }

        span.height-info {
            float:left;
            margin-top:75px;
            margin-right:10px;
            margin-left:36px;

            span {
                margin:2px 0;
                display:block;
                margin-left:13px;
            }
        }

        span.width-info {
            display:block;
            margin-bottom:8px;

            span {
                margin:0 5px;
                position:relative;
                top:-1px;
            }
        }

        div.size-box-container {
            width:@size-box-dimension * 4;
            height:@size-box-dimension * 2;
            text-align:center;
            float:left;
            margin-bottom:30px;

            div.size-box {
                float:left;
                width:@size-box-dimension;
                height:@size-box-dimension;
                line-height:@size-box-dimension;
                text-align:center;
                font-size:12px;
                cursor:pointer;
            }
        }
    }
}