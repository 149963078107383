//
// DATAGRID WIDGET
//
.datagrid-display {
    .flex-full;
    padding: 0;
    overflow-y: auto;

    // If datagrid is not responsive
    &.show-overflow {
        overflow-x: auto;

        div.dataTables_wrapper {
            position: static;
            overflow-x: auto;

            // Make page control follow horizontal scrolling
            div.dataTables_header {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                background: transparent !important;
                .z-index();
            }
        }
    }

    &.show-overflow-reportstudio {
        overflow-x: auto;

        div.dataTables_wrapper {
            position: static;

            // Make page control follow horizontal scrolling
            div.dataTables_header {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                background: transparent !important;
                .z-index();
            }
        }
    }

    // If fix header is enabled for datagrid
    &.has-fixed-header {
        overflow-y: hidden;

        div.dataTables_wrapper {
            div.dataTables_scroll {
                // No need to set margin top
                div.dataTables_scrollHead,
                div.dataTables_scrollBody {
                    table.dataTable {
                        margin-top: 0px !important;
                    }
                    table.show-gridlines {
                        border-collapse: collapse;
                        border: 1px solid #e5e5e5;
                        th, td {
                            border: 1px solid #e5e5e5 !important;
                        }
                    }
                    table.show-gridlines-dark {
                        border-collapse: collapse;
                        border: 1px solid #333333;
                        th, td {
                            border: 1px solid #333333 !important;
                        }
                    }
                }

                div.dataTables_scrollHead {
                    // Make 'Show More' column visible if responsive is enabled
                    table.responsive {
                        th.control {
                            visibility: visible;
                        }
                    }
                }
            }

            // Fix search bar/page button cannot be clicked if datagrid is not responsive
            div.dataTables_header {
                display: none;
            }
        }
    }

    div.dataTables_wrapper {
        // Solve the issue that user cannot click because of no data overlay
        div.dataTables_filter,
        div.dataTables_length,
        div.dataTables_paginate {
            position: relative;
        }

        &.has-header {
            table.dataTable {
                margin-top: 60px !important;
            }
        }

        td.dataTables_empty {
            display: none;
        }

        div.dataTables_processing {
            .rounded(0);
            top: 0;
            bottom: 0;
            .shadow(0, 0, 0, 0);
        }

        table {

            // Responsive
            &.collapsed {
            }

            th {
                white-space: normal;
                word-wrap: break-word;
                min-width: 60px;

                svg.delta-icon {
                    width: 12px;
                    height: 14px;
                    padding-top: 2px;
                }

                &.total-column {
                    .bold();
                }
                &.total-column.datagrid_old {
                    font-size: 13px;
                }
            }

            td {
                word-wrap: break-word;
                overflow: hidden;
                text-overflow: ellipsis;

                p.value-container {
                    white-space: nowrap;
                }

                svg.delta-icon {
                    width: 12px;
                    height: 14px;
                    padding-top: 2px;
                }

                &.image-preview {
                    &.full {
                        img {
                            max-width: initial;
                            max-height: initial;
                            width: auto;
                            height: auto;
                        }
                    }

                    &.image-preview--aspect-fit {
                        img {
                            width: 100%;
                            height: auto;
                            object-fit: contain;
                        }
                    }

                    &.image-preview--flip {
                        img {
                            height: auto;
                            object-fit: contain;
                        }
                    }

                    .generate-max-height-classes(@i) when (@i > 0) {
                        &.max-height-image-@{i} {
                            img {
                                max-height: ~"@{i}px !important";
                            }
                        }
                        .generate-max-height-classes(@i - 1);
                    }

                    // Call the mixin starting from 500
                    .generate-max-height-classes(500);
                }

                img {
                    max-width: 160px;
                    max-height: 160px;
                }
                img.square {
                    width: 80px;
                    height: 80px;
                }
                img.portrait {
                    width: auto;
                    height: 100%;
                }
                img.landscape {
                    width: 100%;
                    height: auto;
                }

                &.linked-cell {
                    padding: 0;
                    .transition(background-color);

                    > span.button-group a,
                    > a {
                        cursor: pointer;
                        display: block;
                        padding: 8px 16px;
                    }
                }

                a.action-link {
                    i.icon {
                        font-size: 16px;
                    }
                }

                div.image-placeholder {
                    i.icon {
                        font-size: 24px;
                    }

                    span {
                        position: relative;
                        top: -4px;
                        margin-left: 5px;
                        display: inline-block;
                    }
                }

                audio {
                    margin: -7px 0;
                    position: relative;
                    top: 3px;
                    height: 32px !important;
                }

                .badge {
                    font-size: 11px;
                }

                &.total-column {
                    .bold();
                }

                &.total-column.datagrid_old {
                    font-size: 14px;
                }

                span.tag {
                    display: inline-block;
                }
                span.tag-container {
                    display: flex;
                    align-items: center;
                }
                span.tag-child {
                    display: inline-block;
                }
            }

            tr.total-row {
                visibility: visible !important;

                th {
                    white-space: normal;
                    b {
                        float: left;

                        span.icomoon-help {
                            font-size: 11px;
                            margin-left: 6px;
                        }
                    }
                    overflow: hidden;
                    padding: 10px 16px;
                    border-top: 0;
                    vertical-align: middle;
                    text-align: right;
                    .bold();
                }
            }

            tr.total-row.datagrid-old {
                font-size: 14px;
            }
            tr.total-row.fontsize-12 {
                font-size: 12px;
            }
            tr.total-row.fontsize-16 {
                font-size: 16px;
            }
            tr.total-row.fontsize-20 {
                font-size: 20px;
            }
        }
    }

    .white-br-patch {
        border-radius: 5px;
        background: whitesmoke;
        padding: 4px 2px;
        opacity: 0.8;
    }

    .generic-comparison {
        color: #000000;
    }
}

div.layout-freeflow {
    .datagrid-display {
        table.dataTable {
            th {
                white-space: nowrap;
            }
        }
    }
}

div.datagrid-widget-container {
    width: 100%;
    position: relative;

    div.dataTables_wrapper {
        p.value-container {
            margin-bottom: 0;

            span.delta {
                display: inline-block;
                position: relative;
                top: -8px;
                margin-right: 8px;
            }

            span.value {
                display: block;
            }
        }
    }

    div.clipped-notify-container {
        position: relative;
        text-align: right;
        right: 20px;
        .z-index(1);
        white-space: nowrap;
    }

    //Max width of widget + left frame
    @media screen and (max-width: 1025px) {

        div.dataTables_wrapper {
            div.dataTables_filter,
            div.dataTables_length {
                display: none;
            }

            div.dataTables_paginate {
                margin-top: 15px !important;

                .pagination > li > a {
                    padding: 2px 6px;
                }
            }
        }
    }
}