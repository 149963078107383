div.alert-item {
    position: relative;
    margin: 8px 12px;

    span.optional-text {
        text-transform: initial;
        font-weight: 550;
    }

    div.alert-item-header {
        .flex;
        margin-top: 5px;
        margin-bottom: -12px;

        div.data-source-display {
            display: block;
            font-size: 13px;

            span {
                display: block;
                line-height: 36px;
                margin-left: 8px;
            }
        }

        div.alert-name-container {
            width: 200px;

            input {
                height: 30px;
                margin-top: 4px;
            }
        }
    }

    div.alert-option {
        position: absolute;
        top: 10px;
        right: 15px;
    }

    div.alert-line {
        .flex-display();
        margin-bottom: 15px;

        .alert-datasource-select {
            div.datasource-select-container {
                display: inline-block;
            }
        }

        div.remove-groupby-button {
            margin: 0 15px 0 25px;
        }

        > div {
            margin: 4px 12px;

            select,
            input {
                display: inline-block;
                min-width: 100px;
            }

            > label {
                display: inline-block;
                margin-top: 8px;
                font-size: 12px;
            }
        }

        div.metric-select-container {
            width: 220px;
        }

        .select2-chosen {
            label.label {
                margin-right: 4px;
                position: relative;
                top: -2px;
                .rounded(0);
            }
        }

        &.optional {
            height: 40px;

            div.cluster-select-container {
                display: block;
                margin: 4px 12px;
            }

            div.client-and-client-group-container {
                display: flex;
                margin-top: 10px;

                div.client-and-client-group-select-container {
                    margin-left: 15px;
                }
            }
            div.alert-button-group {
                height: 36px;
            }

            select {
                width: 220px;
            }
        }

        &.rules {
            margin-bottom: 0;
            display: block;

            div.rule-item {
                div.rule-condition-text {
                    font-size: 10px;
                    margin: 0 10px;
                }

                div.rule-item-container {
                    > div {
                        margin: 4px 10px;
                        display: inline-block;
                    }

                    div.rule-and-text {
                        width: 50px;
                        margin-left: 0;
                    }

                    div.remove-rule-button {
                        margin: 6px 8px;
                    }
                }
            }
        }

        &.data-configuration-container {
            flex-direction: column;

            div.data-source-container {
                label.data-view-text {
                    margin-left: 162px;
                }
            }

            div.groupby-container {
                div.groupby-item {
                    display: inline-block;
                    margin-bottom: 20px;
                }

                div.groupby-text {
                    display: inline-block;
                    font-size: 10px;
                    margin-right: 8px;
                }

                div.add-groupby-button {
                    cursor: pointer;
                    margin: 4px;
                    text-align: center;
                }
            }
        }

        .cluster-selector {
            display:inline-block;
            margin:4px 12px;
        }
    }

    div.add-rule-button {
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        margin: 8px 10px 20px 10px !important;
        font-size: 24px;
        text-align: center;
        .transition(background-color);
    }

    div.alert-recipients {
        display: flex;
        margin: 10px 12px 15px 12px;

        > div {
            display: flex;
        }

        div.send-alert-to-client-container {
            margin: 3px 10px;
            display: flex;

            input {
                min-width: auto;
            }

            div.send-alert-to-client-tooltip {
                margin: 4px 12px;

                span.send-alert-to-client-icon {
                    font-size: 13px;
                }
            }
        }
    }
}