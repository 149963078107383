@import "quickexport/exportbuilder.admin.quickexport.less";

.export-builder-template-container {

    .design-page {
        position: relative;
        .z-index();

        .new-report {
            top: 10px;
            position: relative;
            float: right;
            margin-right:20px;
            width: 120px;
        }
    }

    .option-container {
        .flex();
        position: relative;

        div.search-container {
            position: relative;
            left: 25px;
            cursor: pointer;
            width: 21px;
            overflow: hidden;
            border-bottom: 2px solid transparent;
            .transition(all, .2s, ease);

            &.active {
                width: 204px;
            }

            i.icon {
                cursor: pointer;
                position: absolute;
                top: 10px;
                font-size: 20px;
                .z-index();
            }

            i.icomoon-cross {
                .bold();
                font-size: 14px;
                right: -1px;
                top: 13px;
            }

            input {
                box-sizing: border-box;
                display: block;
                position: relative;
                width: 100%;
                height: 40px;
                padding: 0 18px 0 30px;
                margin-bottom: 0;
                outline: 0;
                border: 0;
                border-radius: 0;
                background: 0 0;
                font-size: 14px;
                line-height: 40px;
                .no-shadow();
                .fontSmoothing();
                .transition-group(width .3s ease, border-color .3s ease);
            }
        }

        ul {
            margin-left: 20px;

            li.sort-option {
                text-transform: uppercase;
                font-size: 11px;
                .bold();
                line-height: 17px;
                display: inline-block;
                padding: 11px 4px 12px;
                border-bottom: 2px solid transparent;
                margin-right: 25px;
                cursor: pointer;
                .transition-group(color .2s ease, border-color .2s ease);
            }
        }

        select.form-control {
            .bold();
            .no-shadow();
            text-transform: uppercase;
            font-size: 11px;
            border: 0 !important;
            background-color: transparent !important;
            width: auto;
            margin-left: 30px;
        }

        .exportbuilder-admin-paginator {
            display: flex;
            flex-direction: row;
            margin-left: 30px;
            height: 30px;

            .preview-button,
            .next-button {
                &[disabled="disabled"] {
                    opacity: 0.6;
                }
            }

            .page-number {
                margin: 0 20px;
                padding-top: 7px;

                font-weight: 800;
                text-transform: uppercase;
                font-size: 11px;
            }
        }

        .page-results-label {
            font-weight: 800;
            text-transform: uppercase;
            font-size: 11px;

            &.page-results-label-results {
                margin-left: 30px;
                width: 20px;
                padding-top: 10px;
            }

            &.page-results-label-page {
                padding-top: 9px;
                margin-left: 15px;
            }
        }
    }

    .exportbuilder-list {
        overflow: hidden;
        margin-top: 20px;

        &-title {
            .delayed-show-lr(0.5s);
            text-align: center;
            font-size: 20px;
            position: relative;
            top: 5px;
        }

        &__list {
            .delayed-show-lr(0.5s);
            margin: 40px auto 0;

            .grid-item {
                width: 370px;
                height: 334px;
                margin-bottom: 40px;
            }

            .report-item {
                position: relative;
                .transition(all, 0.2s, ease-out);
                width: 370px;
                height: 334px;
                border-radius: 5px;
                overflow: hidden;
                margin-bottom: 50px;
                cursor: pointer;

                .report-item-inactive-container {
                    display: none;
                }

                &.is-inactive {
                    cursor: not-allowed;

                    .report-item-title-container {
                        pointer-events: none;
                    }

                    .report-item-overflow-content {
                        pointer-events: none;
                        button {
                            display: none;

                            &.export-builder-admin-export-btn {
                                display: initial;
                                pointer-events: initial;
                            }
                        }
                    }

                    .report-item-inactive-container {
                        display: initial;
                        height: 100%;
                        width: 100%;
                        .flex();
                        .flex-center();
                        position: absolute;
                        top: 0;
                        pointer-events: none;

                        .report-item-inactive-content {
                            width: 75%;
                            padding: 10px;
                            font-size: 14px;
                            text-align: center;
                            color: white;
                            .flex-align-start();
                            margin-top: 45px;
                        }
                    }
                }

                &.delete-item {
                    transition: transform 0.8s ease, opacity 0.4s ease;
                    transform: scale(0.0);
                    opacity: 0;
                }

                &:hover {
                    transform: translateY(-6px);

                    .report-item-overflow {
                        opacity: 1;
                    }
                    .report-item-overflow-header {
                        transform: translateY(0);
                    }
                    .report-item-overflow-content {
                        transform: translateY(0);
                    }

                    .report-item-detail-container {
                        .report-item-detail {
                            &__hover {
                                top: 12px;
                                opacity: 0;
                            }
                            &__hidden {
                                top: 0;
                                opacity: 1;
                            }
                        }
                    }
                }

                &-thumbnail-container {
                    position: relative;
                }

                &-overflow {
                    width: 100%;
                    height: 260px;
                    .z-index();
                    .transition(all, 0.2s, ease-out);
                    position: absolute;
                    opacity: 0;

                    &-header {
                        width: 100%;
                        height: 50px;
                        position: absolute;
                        transition: all 0.2s ease-out;
                        transform: translateY(-10px);
                        .z-index();

                        i[data-toggle="dropdown"] {
                            position: absolute;
                            top: 15px;
                            right: 8px;
                            font-size: 18px;
                            cursor: pointer;
                        }

                        ul.dropdown-menu {
                            .toggle-show-tb(0.25s);
                            cursor: pointer;
                            padding: 6px 0;
                            top: 20px;
                            left: 200px;

                            i.icon {
                                position: relative;
                                top: 2px;
                            }
                        }
                    }

                    &-content {
                        transition: all 0.2s ease-out;
                        transform: translateY(-12px);
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        button {
                            cursor: pointer;

                            &.font-large {
                                font-size: 12px;
                            }

                            &.font-small {
                                font-size: 10px;
                            }

                            &.link-fade-hover-inverse {
                                transition: all 0.3s ease;
                                i {
                                }
                            }
                        }
                    }
                }

                &-thumbnail {
                    position: relative;
                    width: 100%;
                    height: 260px;
                    overflow: hidden;
                    text-align: center;

                    img {
                        width: 100%;
                        height: auto;
                    }

                    i.icon {
                        .fontSmoothing();
                        width: 200px;
                        height: 200px;
                        font-size: 75px;
                        margin: 0 auto;
                        display: block;
                        margin-top: 85px;
                    }

                    &-overlay {
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        position: absolute;
                    }
                }

                &-title-container {
                    width: 100%;
                    height: 91px;
                    padding: 10px 28px;
                    border-top: 1px solid;
                    margin-bottom: 30px;

                    .report-item-detail-container {
                        position: relative;
                        font-size: 10px;
                        text-transform: uppercase;

                        .report-item-detail {
                            .transition(all, 0.2s, ease-out);
                            position: absolute;

                            &__hover {
                                top: 0;
                                opacity: 1;
                            }
                            &__hidden {
                                top: 12px;
                                opacity: 0;
                            }
                        }
                    }
                }

                &__title {
                    padding: 0;
                    margin: 5px 0 3px 0;
                    .ellipsis(300px);
                    text-align: left;
                    font-size: 16px;
                    height: 18px;
                }

                &-data-source-info {
                    position: absolute;
                    bottom: 12px;
                    left: 20px;
                    height: 30px;
                    cursor: default;

                    span {
                        .rounded(50%);
                        text-align: center;
                        float: left;
                        margin-left: -8px;
                        height: 26px;
                        width: 26px;
                        border: none;
                        font-size: 12px;
                        line-height: 22px;

                        &[class*="serviceicon-"] {
                            font-size: 16px;
                            line-height: 26px;
                        }
                    }
                }

                &-user-info {
                    display: flex;
                    position: absolute;
                    z-index: 1001;
                    top: 12px;
                    left: 12px;
                    width: 300px;
                    height: 30px;
                }
            }

        }

    }
}

export-builder-admin-list {
    height: 100%;
    element-placeholder {
        z-index: 0;
        div.element-placeholder {
            top: -250px !important;
            img {
                width: 600px !important;
            }
        }
    }
}

#new-report-modal {
    div.modal-body {
        position: relative;
    }

    .export-builder-template-container {
        .absolute();
        top: -30px;
    }

    .search-container {
        left: 53px;
    }
}