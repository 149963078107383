div.login-body {
    .flex;
    .flex-center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    .divider {
        display: block;
        height: 10px;
    }

    .divider-lg {
        height: 30px;
    }

    .form-control,
    .form-control:focus {
        border-width: 1px;
        box-shadow: none;
        font-size: 18px;
    }

    div.content-container {
        height: auto;
        width: 100%;
        height: auto;
    }

    .page-signin,
    .page-signup,
    .page-forgot {

        .signin-header {
            margin-top: -100px;

            .brand {
                img {
                    max-height: 80px;
                }
            }
        }

        @media screen and (max-width: 768px) {
            .signin-header {
                margin-top: -30px;
            }
        }

        .form-group {
            position: relative;

            .btn-icon-lined {
                position: absolute;
                top: 9px;
                left: 15px;
                font-size: 22px;
            }

            .input-lg {
                padding: 10px 30px;
            }

            .input-round {
                .rounded(25px);
            }

            .btn-round {
                .rounded(2em);
            }

            .btn:active {
                outline: 0;
                background-image: none;
            }
        }

        div.alert {
            span.icon {
                margin-right: 8px;
            }
        }

        .signin-body {
            padding: 20px 10px;
        }

        .form-container {
            max-width: 450px;
            margin: 10px auto;

            .fields {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                height: 0;
                width: 0;
                z-index: -1;
            }
        }
    }

    div.footer-text {
        position: fixed;
        bottom: 15px;
        right: 15px;
        padding-left: 10px;
        font-size: 11px;

        a {
            margin-right: 10px;
        }
    }
}
.new-customer{
    width: 100%;
}
.customer-info{
    display: flex;
}
.new-user{
    float: left;
}
.custom-login-link {
    float: left;
    font-weight: bold;
}