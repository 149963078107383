.connectionstatus-success {
  font-size:16px;
  color: yellowgreen;
  margin-left: 10px;
}

.connectionstatus-in-progress {
  font-size:16px;
  color: dimgrey;
  margin-left: 10px;
}

.connectionstatus-warning {
  font-size:16px;
  color: orange;
  margin-left: 10px;
}

.connectionstatus-error {
    font-size:16px;
    color: red;
    margin-left: 10px;
}
