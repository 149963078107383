//
// GOAL WIDGET
//
.goal-widget {
    .flex-column;
    .flex-full;
    overflow: hidden;
    max-width: 100%;
    max-height: 100%;
    padding: 0 5px;

    div.goal-display {
        .flex(2);
        margin-top: 5px;
        min-height: 80px;

        div.progress-title-container {
            margin-top: 10px;
            width: 40%;
            text-align: left;
            max-height: 20px;
        }

        div.chart-widget-container {
            .flex();
            width: 100%;
            height: 120px;
            overflow: unset;

            div.amcharts-chart-div {

                text.amcharts-graph-label {
                    font-weight: bold;
                }

                g {
                    &.success {
                        rect {
                            stroke: @success-color;
                            fill: @success-color;
                        }
                        path {
                            stroke: @success-color;
                            fill: @success-color;
                        }
                    }

                    &.warning {
                        rect {
                            stroke: @warning-color;
                            fill: @warning-color;
                        }
                        path {
                            stroke: @warning-color;
                            fill: @warning-color;
                        }
                    }

                    &.danger {
                        rect {
                            stroke: @error-color;
                            fill: @error-color;
                        }
                        path {
                            stroke: @error-color;
                            fill: @error-color;
                        }
                    }
                }

                div.amcharts-balloon-div {
                    border: none;

                    span {
                        &.success {
                            background-color: @success-color;
                        }

                        &.warning {
                            background-color: @warning-color;
                        }

                        &.danger {
                            background-color: @error-color;
                        }
                    }
                }
            }
        }
    }

    div.details-display {
        .flex(5) !important;
        .flex-full;
        .flex-column;
        height: 100%;
        margin-top: 30px;

        div.form-details {
            align-items: center;
        }

        span.goal-history-title {
            text-align: center;
            font-weight: bold;
        }

        serialchart-widget.widget-display {
            .flex(3);
        }
    }

    div.detail-toggle {
        .flex-full;
        .flex-center;
        align-items: flex-end;
        cursor: pointer;
        max-height: 50px;
    //     TODO: Fix hover color width
    }
}