/******************* Plugin Overrides ******************/

/* Angular loading bar */
#loading-bar .bar {
    height: 2px;
}

/* Select 2 */
ui-select2 {
    position: relative;

    &:after {
        .rotate(1.25s);
        font-family: 'icomoon';
        content: "\eacd";
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 14px;
        line-height: initial;
        .opacity(70);
    }

    &.ui-select2-loaded:after {
        content: none;
    }

    .select2-loading {
        padding-left: 30px;
    }
    &.disable-animation:after {
        display: none !important;
    }
}

div.select2-container,
div.select2-container-multi {
    min-width: 220px;
    max-height: 110px;
    overflow-y: auto;

    a.select2-choice {
        line-height: 30px;
    }

    .select2-locked {
        padding: 4px 5px 6px 5px !important;
    }

    a.select2-choice,
    ul.select2-choices {
        height: 34px;
        font-size: 13px;
        .shadow(0, 0, 0, 0);
        background-image: none;
        padding-left: 1px;
        .mainfont();

        span.select2-chosen {
            padding: 2px 9px;

            .badge {
                position: relative;
                top: -1px;
            }
        }

        span.select2-arrow {
            background: none;
            border: 0;
            right: 3px;
            top: 1px;
        }

        &.ui-sortable {
            li:hover {
                cursor: move;
            }
        }

        .select2-search-field {
            input {
                font-family: inherit;
                padding-left: 5px;
                cursor: pointer;
            }
        }

        .badge {
            margin-bottom: 0;
            font-size: 11px;
        }
    }

    ul.select2-choices {
        .rounded(4px);
    }

    div.service-square-24 {
        margin-top: 2px;
        margin-right: 8px;
        margin-left: -2px;
    }
}

div.select2-drop {

    // Add magnifying glass to search box
    div.select2-search::after {
        font-family: 'icomoon';
        content: '\ea7e';
        position: absolute;
        top: 3px;
        right: 10px;
        font-size: 15px;
    }

    div.select2-search input[type=text] {
        .rounded(4px);
        .mainfont();
        background-image: none !important;
    }

    ul.select2-results {
        font-size: 12px;
        overflow-wrap: break-word;

        li {
            padding: 4px 4px;
        }
    }

    div.service-square {
        margin-top: -3px;
        margin-right: 7px;
        margin-left: -2px;
    }
}

div.select2-drop-active {
}

/* Multi select tag styling */
.select2-container-multi .select2-choices .select2-search-choice {
    .shadow(0, 0, 0, 0);
    padding: 4px 20px 2px 5px;
    overflow-wrap: break-word;
    margin-left: 2px;
}

.select2-container-multi .select2-search-choice-close {
    top: 13px;
    right: 5px;
    left: auto;
}

/* Disabled select styling */
.select2-container.select2-container-disabled .select2-choice .select2-arrow {
    background: none;
}

.select2-container.select2-container-disabled .select2-choice {
    cursor: not-allowed;
}

div.select2-container.select2-container-disabled ul.select2-choices li:hover, div.select2-container-multi.select2-container-disabled ul.select2-choices li:hover {
    cursor: not-allowed;
}

/* ui-select (angular select2) */
.ui-select-container.ui-select-multiple {
    input.ui-select-search {
        width: auto !important;
    }

    .ui-select-match .close {
        line-height: 0.9;
    }
}

/* Owl Carousel */

.owl-carousel.top-pagination .owl-controls {
    margin-top: 0;
    text-align: center;
}

//To move navigation buttons outside use these settings:

.owl-carousel .owl-controls .owl-buttons div {
    position: absolute;
    z-index: 10;
    height: auto;
    .rounded(0px);
    .opacity(25);
    bottom: 80px;
    top: 5px;
    width: 25px;

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -2px;
        margin-top: -6px;
    }
}

.owl-carousel .owl-controls .owl-buttons .owl-prev {
    left: -20px;

}

.owl-carousel .owl-controls .owl-buttons .owl-next {
    right: -20px;
}

/************** Bootstrap ***********/

a {
    cursor: pointer;
}

a:hover,
a:focus {
    text-decoration: none;
}

ul {
    list-style-type: none;
    margin-bottom: 0;

    li.disabled a {
        .opacity(40);
        cursor: not-allowed;
    }
}

div.disabled {
    .opacity(40);
}

div.translucent {
    .opacity(15);
}

.note-editing-area {
    ul {
        list-style-type: disc;
    }
}

.paragraph-container {
    ul {
        list-style-type: disc;
    }
}

th:focus {
    outline: none !important;
}

input[type="radio"],
input[type="checkbox"] {
    margin: 3px 0 0;
}

textarea {
    resize: none;
}

.btn,
.btn-group > .btn,
.btn-group-sm > .btn,
.btn-group-xs > .btn,
.btn-group-xxs > .btn {
    .rounded(20px);
    font-size: 11px;
    text-transform: uppercase;
}

.btn,
.btn-group > .btn {
    padding: 7px 14px;
}

.btn-lg,
.btn-group-lg > .btn {
    padding: 10px 16px;
    font-size: 14px;
}

.btn-sm,
.btn-group-sm > .btn {
    padding: 6px 9px;
}

.btn-xs,
.btn-group-xs > .btn {
    padding: 5px 7px;
    font-size: 10px;
}

.btn-xxs,
.btn-group-xxs > .btn {
    padding: 3px 4px;
    font-size: 9px;
}

.btn-circle {
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 26px;
    font-size: 13px;
    padding: 0;
    .rounded(50%);

    &.btn-primary,
    &.btn-success,
    &.btn-warning,
    &.btn-danger {
        border: 0;
    }

    &.circle-small {
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 10px;
    }

    &.circle-large {
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 22px;
    }

    &.circle-xlarge {
        width: 100px;
        height: 100px;
        line-height: 100px;
        font-size: 40px;
    }

    &.btn-default.circle-small {
        line-height: 18px;
    }

    &.btn-default.circle-large {
        line-height: 48px;
    }

    i.icon {
        margin: 0;
    }
}

.label,
.badge {
    .mainfont();
}

/* Row buffers */

div.row.top-buffer-sm {
    margin-top: 10px;
}

div.row.top-buffer-md {
    margin-top: 20px;
}

div.row.top-buffer-lg {
    margin-top: 40px;
}

/* Forms */
.form-control {
    font-size: 13px;
    padding: 6px 10px;
}

.form-control[disabled],
fieldset[disabled] .form-control {
    .opacity(60);
}

div.form-inline div.form-group,
form.form-inline div.form-group {
    margin-right: 8px;
}

div.form-horizontal .control-label {
    padding-top: 4px;
}

div.form-horizontal .form-group {
    span {
        line-height: 26px;
    }
}

label.required:after {
    position: absolute;
    content: '*';
    right: 5px;
    top: 6px;
}

div.form-group-search {
    input {
        padding-left: 36px;
    }
}

div.form-group-search:before {
    font-family: 'icomoon';
    content: "\ea7e";
    position: absolute;
    left: 23px;
    font-size: 20px;
    z-index: 100;
    line-height: 1;
    top: 18px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.form-horizontal {
    div.form-group input[type="color"].form-control,
    div.form-group input[type="password"].form-control,
    div.form-group input[type="email"].form-control,
    div.form-group input[type="text"].form-control,
    div.form-group select.form-control {
        max-width: 50%;
    }

    .has-feedback .form-control {
        padding-right: 12px;
    }
}

form.form-horizontal {
    div.form-group.full input[type="color"].form-control,
    div.form-group.full input[type="password"].form-control,
    div.form-group.full input[type="email"].form-control,
    div.form-group.full input[type="text"].form-control,
    div.form-group.full select.form-control {
        max-width: 100%;
    }
}

form {
    div.form-group-inline input[type=text],
    div.form-group-inline select {
        display: inline-block;
        width: auto;
        margin-left: 8px;
    }
}

/* Panels */
.panel-default > .panel-heading {
    border-bottom: 1px dotted;
    padding: 15px 15px 12px 15px;
 
    .panel-title {
        font-size: 14px;
        text-transform: uppercase;
        .bold();
    }
}

.panel-heading {
    i.icon {
        margin-right: 6px;
    }
}

.panel-heading.note-toolbar .dropdown-menu {
    min-width: 210px;
}

/* Pagination */
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
    .opacity(50);
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    z-index: auto;
}

/* Modals */
.modal {
    &.in {
        z-index: 1080 !important;
    }
}

.modal-backdrop.in {
    .opacity(70);
    z-index: 1070 !important;
}

.modal.smooth {

    div.modal-dialog {
        .magictime;
        .zoomInSmooth();
        .animation-duration(0.4s);
    }
}

.modal-sm {
    width: 45%;
}

.modal-md {
    width: 65%;
}

.modal-lg {
    width: 95%;
}

.modal-full {
    .absolute();
    margin: 0;
    width: auto;

    .modal-content {
        width: 100%;
        height: 100%;
    }
}

.modal-full-close {
    position: absolute;
    top: 20px;
    right: 20px;
    text-align: center;
    font-size: 26px;
    line-height: 52px;
    height: 50px;
    width: 50px;
    cursor: pointer;
    .rounded(50%);
    .z-index(100);
    .opacity(80);

    &:hover {
        .opacity(100);
    }
}

@media screen and (max-width: 768px) {
    .modal-sm,
    .modal-md,
    .modal-lg {
        width: 98%;
    }
}

.modal-content {
    .shadow(0, 3px, 9px, 0.4);

    .note-resizebar {
        display: none;
    }
}

.modal-header .close {
    margin-top: 0;
}

.modal-title .icon {
    margin-right: 8px;
}

/* Dropdown */
.dropdown-menu {
    .shadow(0, 1px, 8px, .175);
    border: 0;

    > li > a {
        padding: 5px 12px;

        &.disabled {
            opacity: .4;
            pointer-events: none;
            cursor: default;
        }

        i.icon {
            margin-right: 10px;
        }
    }

    .dropdown-menu {
        .shadow(0, 1px, 8px, .175);
        border: 0;
        position: relative;
        padding: 0;
        margin: 0;
        display: block;
        width: 100%;
    }
}

/* Popovers */
.popover {
    max-width: none !important;
    .z-index(81);
}

/* Tooltips */
.tooltip-inner {
    overflow-wrap: break-word;
    white-space: normal;
}

.tooltip {
    z-index: 1090 !important;
}

/* Jumbotron */
.jumbotron {
    margin-top: 15px;
}

.jumbotron h2, .jumbotron .h2 {
    font-size: 40px;
}

@media screen and (min-width: 768px) {
    .jumbotron h1, .jumbotron .h1 {
        font-size: 50px;
    }
}

/* Tabs */

.tab-content .tab-pane {
    padding-top: 20px;

    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;

    .clearfix();
}

/* Help block */
.help-block {
    margin-bottom: 5px;
    font-size: 11px;
}

/* Warning block */
.notice-block {
    display: block;
    font-size: 11px;
    margin: 5px 0;
    border-left: 2px solid;
    padding-left: 6px;
    margin-left: 6px;

    &.text-md {
        font-size: 13px;

        &:before,
        &:after {
            top: 2px;
        }
    }

    &.text-lg {
        font-size: 16px;

        &:before,
        &:after {
            top: 0;
        }
    }

    &.disabled {
        &:before {
            content: "";
        }
        margin-left: 15px;
    }
}

/* New Report Language Selection */
.margin-report {
    margin-left: 38% !important;
}

/* Alert */
.alert {
    overflow: hidden;
}

.alert-small {
    padding: 5px 15px;
    margin-bottom: 0;
    margin-top: 10px;
}

.alert-info {
    background-color: #d9edf74d;
}

/* Upload File */

.file-caption {
    .file-caption-ellipsis {
        display: none;
    }
}

.file-caption .icon {
    display: inline-block;
    min-width: 18px;
    margin-top: 2px;
}

.file-preview {
    border: 0;
    padding: 0;
    width: auto;

    div.close {
        display: none;
    }
}

.file-preview-frame {
    height: auto;
    .shadow(1px, 1px, 6px, 0.1);

    img {
        max-height: 60px;
        max-width: 200px;
    }
}

.file-preview-frame:hover {
    .shadow(3px, 3px, 10px, 0.3);
}

.file-caption-name {
    background-color: transparent;
    border: transparent;
}

.file-caption-name .glyphicon-file {
    font-family: 'icomoon';
}

.file-caption-name .glyphicon-file:before {
    content: "\e631";
}

/************** Bootstrap Daterange Picker ***********/
.daterangepicker {

    &.opensright {
        .ranges {
            .range_inputs {
                left: 196px;
            }
        }
    }

    .ranges {
        overflow-y: auto;
        max-height: 400px;

        li {
            font-size: 11px;
            .rounded(4px);
            margin-bottom: 5px;
            padding: 2px 7px;
        }
    }
    .calendar {

        td.off {
            .rounded(0px);
        }
    }

    &.no-arrow:after,
    &.no-arrow:before {
        content: none;
    }
}

/************** Notify ***********/
.notifyjs-container {
    .animate();
    line-height: 14px;
    .rounded(6px);

    span.icon {
        position: relative;
        top: 2px;
        font-size: 16px;
        margin-right: 6px;
        .bold();
    }

    span.success {
        color: #8cc63e;
    }

    span.warning {
        color: #ffa902;
    }

    span.info {
        color: #73B9E7;
    }

    span.error {
        color: #dd4c3c;
    }

}

.notifyjs-corner {
    z-index: 10000 !important;
}

/************** jQuery UI ***********/

.ui-widget {
    .mainfont();
}

.ui-widget-header {
    border: 0;
    background: none;
}

/*********** Summernote *************/
div.note-editor {
    .mainfont();
    table.table-bordered tbody th,
    table.table-bordered tbody td {
        border-left-width: 1px;
        border-bottom-width: 1px;
    }
}

/*********** angularjs-slider *************/

rzslider {
    margin: 28px 0 10px 0;

    .rz-bar-wrapper {
        height: 24px;
        padding-top: 12px;
        margin-top: -12px;
    }

    .rz-pointer {
        top: -10.5px;
        width: 24px;
        height: 24px;
        .rounded(12px);
    }

    .rz-pointer:after {
        top: 8px;
        left: 8px;
    }

    .rz-bubble {
        bottom: 14px;
    }

    &.rz-active{

        &:after {
            background-color: #fff;
        }
    }
}

/*********** sweet-alert *************/

.sweet-overlay {
    .z-index(10000); /* to make sure it comes before any modal dialog */
}

.sweet-alert {
    .z-index(10001); /* to make sure it comes before any modal dialog */

    &:focus {
        outline: none;
    }

    h2 {
        font-size: 24px;
        margin-top: 30px;
    }

    p {
        font-size: 16px;
        padding: 10px;
    }

    .sa-button-container {
        .btn {
            margin: 0 5px;

            &:focus {
                outline: none;
            }
        }
    }
}

.file-preview .krajee-default .file-drag-handle, .krajee-default .file-upload-indicator {
    display: none;
}

.widget-header .widget-title-container {
    width: 100%;

    .widget-title {
        width: 100%;
    }
}
