#page-library-modal {

    div.section-info {
        margin-top: 10px;

        span {
            position: relative;
            top: 1px;
            left: 2px;
        }
    }

    page-library-detailed-item {
    }
}

div.manage-library-page {
    table.dataTable {
        td {
            div.dash-thumbnail-container {
                text-align: center;
                overflow: hidden;

                div.inner {
                    width: 100px;
                    height: 125px;
                    display: inline-block;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}