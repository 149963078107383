div.design-page.services-overview-page {

    widget-loader.dash-loader {
        i.icon {
            margin-top: -100px !important;
        }
    }

    div.design-widget {

        div.widget-inner {
            min-height: 149px;
        }
    }

    div.page-inner {
        margin-top: 6px !important;
    }

    div.campaign-performance-container {
        margin-top: 5px;

        div.panel {
            margin: 10px 5px 5px 5px;

            div.panel-body {

                div.bignumber-widget {

                    p.value {
                        font-size: 27px;
                    }
                }
            }
        }

        div.progress {
            margin-bottom: 0;
            position: relative;
            height: 30px;
            top: 30px;
            .rounded(6px);

            div.progress-bar {
                line-height: 30px;
            }
        }

        div.data-panel {
            flex: 1;

            div.overall-campaigns {
                min-width: 180px;
            }
            div.overall-score {
                flex: 1;
            }

            div.average-score {
                width: 100px;

                //Score circle
                div.circle {
                    float: right;
                    width: 75px;
                    height: 75px;
                    .rounded(50%);
                    text-align: center;
                    color: #fff;
                    margin-top: 9px;

                    span.value {
                        display: block;
                        margin-top: 12px;
                        font-size: 20px;
                    }

                    span.text {
                        display: block;
                        font-size: 10px;
                        text-transform: uppercase;
                    }
                }
            }
        }

        div.legend-panel {
            width: 240px;

            div.panel-body {
                padding: 9px 15px 14px 15px;
            }

            div.progress {
                position: static;
                height: 20px;
                margin-top: 6px;

                div.progress-bar {
                    line-height: 20px;
                }
            }
        }
    }

    //
    // DATA FRESHNESS
    //
    div.data-freshness-container {
        font-size: 10px;
        position: absolute;
        top: 6px;
        right: 14px;
        text-align: right;

        b {
            display: block;
            margin-bottom: 3px;
        }
    }

    //
    // SERVICE/CATEGORY DATASOURCED WIDGETS
    //
    div.service-entity-display {
        .flex-full;
        .flex-column;
        text-align: right;
        position: relative;
        .z-index(0);
        padding: 5px;
        margin-right: 6px;

        &.has-score-display {
            min-width: 240px;
        }

        &:not(.has-score-display) {
            > div {
                .flex-column;
            }
        }

        a {
            p.title {
                display: block;
                font-size: 11px;
                border: 0;
            }

            span {
                position: relative;
                top: 1px;
                font-size: 13px;
                .fontSmoothing();
                .bold();
            }
        }

        div.service-score-display {
            height: 55px;
            width: 100%;
            padding-top: 5px;

            div.progress {
                .flex;
                margin-bottom: 0;
                position: relative;
                height: 30px;
                .rounded(6px);
                margin-top: 7px;
                margin-right: 8px;

                .progress-bar {
                    line-height: 30px;

                    &.hover {
                        cursor: pointer;
                    }
                }
            }

            div.circle {
                width: 45px;
                height: 45px;
                .rounded(50%);
                text-align: center;
                color: #fff;
                padding-top: 4px;

                span.value {
                    font-size: 14px;
                }

                span.text {
                    display: block;
                    font-size: 10px;
                    text-transform: uppercase;
                }
            }

            div.empty-progress {
                div.progress-bar {
                    text-align: center;
                    min-width: 100%;
                }

            }
        }
    }
}