/*fonts for lato*/
@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400,600");

@font-face {
  font-family: "Arial";
  src: url("Arial.ttf") format("truetype");
}

@font-face {
  font-family: "Arial";
  font-weight: bold;
  src: url("ArialBold.ttf") format("truetype");
}

@font-face {
  font-family: "Lato";
  src: url("Lato.ttf") format("truetype");
}

@font-face {
  font-family: "Lato";
  font-weight: bold;
  src: url("LatoBold.ttf") format("truetype");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  src: url("LatoItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Lato";
  font-weight: bold;
  font-style: italic;
  src: url("LatoBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Arial Black";
  src: url("ArialBlack.ttf") format("truetype");
}

@font-face {
  font-family: "Comic Sans MS";
  src: url("ComicSansMS.ttf") format("truetype");
}

@font-face {
  font-family: "Comic Sans MS";
  font-weight: bold;
  src: url("ComicSansMSBold.ttf") format("truetype");
}

@font-face {
  font-family: "Courier New";
  src: url("CourierNew.ttf") format("truetype");
}

@font-face {
  font-family: "Courier New";
  font-weight: bold;
  src: url("CourierNewBold.ttf") format("truetype");
}

@font-face {
  font-family: "Century Gothic";
  src: url("CenturyGothic.ttf") format("truetype");
}

@font-face {
  font-family: "Century Gothic";
  font-weight: bold;
  src: url("CenturyGothicBold.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica";
  src: url("Helvetica.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica";
  font-weight: bold;
  src: url("HelveticaBold.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("HelveticaNeue.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica Neue";
  font-weight: bold;
  src: url("HelveticaNeueBold.ttf") format("truetype");
}

@font-face {
  font-family: "Impact";
  src: url("Impact.ttf") format("truetype");
}

@font-face {
  font-family: "Impact";
  font-weight: bold;
  src: url("Impact.ttf") format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("IBMPlexSans.ttf") format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-weight: bold;
  src: url("IBMPlexSansBold.ttf") format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  src: url("IBMPlexSansItalic.ttf") format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: bold;
  src: url("IBMPlexSansBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "IBM Plex Serif";
  src: url("IBMPlexSerif.ttf") format("truetype");
}

@font-face {
  font-family: "IBM Plex Serif";
  font-weight: bold;
  src: url("IBMPlexSerifBold.ttf") format("truetype");
}

@font-face {
  font-family: "IBM Plex Serif";
  font-style: italic;
  src: url("IBMPlexSerifItalic.ttf") format("truetype");
}

@font-face {
  font-family: "IBM Plex Serif";
  font-style: italic;
  font-weight: bold;
  src: url("IBMPlexSerifBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Lucida Grande";
  src: url("LucidaGrande.ttf") format("truetype");
}

@font-face {
  font-family: "Lucida Grande";
  font-weight: bold;
  src: url("LucidaGrandeBold.ttf") format("truetype");
}

@font-face {
  font-family: "Tahoma";
  src: url("Tahoma.ttf") format("truetype");
}

@font-face {
  font-family: "Tahoma";
  font-weight: bold;
  src: url("TahomaBold.ttf") format("truetype");
}

@font-face {
  font-family: "Tahoma";
  font-style: italic;
  src: url("TahomaItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Tahoma";
  font-weight: bold;
  font-style: italic;
  src: url("TahomaBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Tahoma";
  font-weight: bold;
  src: url("TahomaBold.ttf") format("truetype");
}

@font-face {
  font-family: "Times New Roman";
  src: url("TimesNewRoman.ttf") format("truetype");
}

@font-face {
  font-family: "Times New Roman";
  font-weight: bold;
  src: url("TimesNewRomanBold.ttf") format("truetype");
}

@font-face {
  font-family: "Verdana";
  src: url("Verdana.ttf") format("truetype");
}

@font-face {
  font-family: "Verdana";
  font-weight: bold;
  src: url("VerdanaBold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Light";
  src: url("Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Regular";
  src: url("Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Black";
  src: url("Roboto-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Light";
  font-weight: bold;
  src: url("RobotoBold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Regular";
  font-weight: bold;
  src: url("RobotoBold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Black";
  font-weight: bold;
  src: url("RobotoBold.ttf") format("truetype");
}

@font-face {
  font-family: "Playfair Display Black";
  src: url("PlayfairDisplay-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Playfair Display Black";
  font-weight: bold;
  src: url("PlayfairDisplayBold.ttf") format("truetype");
}

@font-face {
  font-family: "Hypatia Sans Pro";
  src: url("CenturyGothic.ttf") format("truetype");
}

@font-face {
  font-family: "Hypatia Sans Pro";
  font-weight: bold;
  src: url("CenturyGothicBold.ttf") format("truetype");
}

@font-face {
  font-family: "Cronos Pro";
  src: url("Lato.ttf") format("truetype");
}

@font-face {
  font-family: "Cronos Pro";
  font-weight: bold;
  src: url("Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Myraid Pro";
  src: url("LucidaGrande.ttf") format("truetype");
}

@font-face {
  font-family: "Myraid Pro";
  font-weight: bold;
  src: url("LucidaGrandeBold.ttf") format("truetype");
}
