/**
 * BootstrapValidator (http://bootstrapvalidator.com)
 */
.bv-form .help-block {
    margin-bottom: 0;
}
.bv-form .tooltip-inner {
    text-align: left;
}
.nav-tabs li.bv-tab-success > a {
    color: #3c763d;
}
.nav-tabs li.bv-tab-error > a {
    color: #a94442;
}

.bv-form .bv-icon-no-label {
    top: 0;
}

.bv-form .bv-icon-input-group {
    top: 0;
    z-index: 100;
}