//
// CHART WIDGET
//
div.chart-widget-container {
    .flex-full;

    select {
        float: right;
        width: 20%;
        margin-right: 14px;
    }

    &.drilldown {
        .amcharts-category-axis,
        .amcharts-pie-item,
        .amcharts-graph-column,
        .amcharts-graph-stroke,
        .amcharts-cursor-fill {
            cursor: pointer !important;
        }
    }

    div.amcharts-balloon-div {
        position: absolute !important;
        border: 1px solid;
        padding: 5px;

        strong {
            display: block;
            margin-bottom: 10px;
            font-size: 13px;
        }

        span.label {
            font-size: 12px;
            margin: 0 4px 4px 0;
            display: inline-block;
        }

        i {
            margin-top: 10px;
            display: block;
        }
    }

    &.upright {
        div.amcharts-balloon-div {
            top: 0 !important;
        }
    }

    &.rotated {
        div.amcharts-balloon-div {
            right: 0 !important;
            left: auto !important;
        }
    }

    div.amcharts-legend-div {
        max-height: 25%; // 3 rows of legends max
        overflow-y: auto !important;
    }

    // Back button
    text.amcharts-label-back {

    }
}

div.amcharts-export-menu-top-right {
    right: -7px;

    > ul > li.export-main {
        > a {
            width: 26px !important;
            height: 26px !important;
            margin-top: 2px !important;
        }

        > ul {
            //border: 0 !important;
            .rounded(4px);

            li:first-child > a {
                &:hover {
                    .rounded(4px, 0);
                }
            }

            li:last-child > a {
                &:hover {
                    .rounded(0, 4px);
                }
            }
        }
    }
}