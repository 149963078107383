div.design-widget {

    div.date-range-filter-toggler {
        display: inline-block;
        vertical-align: top;
        margin-top: 4px;
        margin-right: 10px;
        margin-left: 4px;
        font-size: 18px;
        width: 20px;
    }
}

div#date-range-filter-modal {

    div.element-overlay-container {
        overflow: visible;
        z-index: 10;

        div.element-overlay {
            border: 2px dashed;
        }

        label {
            font-size: 11px;
        }
    }

    input.switch {
        position: relative;
        top: 8px;
    }

    select,
    input.date-picker {
        width: 225px;
    }

    input.date-picker {
        margin-top: 12px;
    }

    div.help-block {
        margin-top: 10px;
        padding: 0 18px;
    }

    label.label-wrap {
        white-space: normal !important;
        line-height: normal;
    }

    .control-label-black {
        font-size: rem(11px);
        color: black;
    }
}