history-panel {
    messenger {
        messenger-thread {

            div.messenger-thread-container {
                margin-bottom: 15px;
            }

            div.content {
                margin-top: 5px;
                line-height: 20px;
            }
        }
    }
}

