//
// BIGNUMBER WIDGET
//
big-number-widget, new-big-number-widget {
    display: table;
    width: 100%;
    height: 100%;
    // Fix the issue that big number is rendered out of border in wkhtmltopdf
    page-break-inside: avoid;

    div.bignumber-widget {
        display: inline-block;
        margin: 8px 20px;
        text-align: center;

        p.value-container {
            margin-bottom: 0;

            span.value {
                .fontSmoothing();

                &.comparison {
                    margin-right: 10px;
                }
            }
        }

        span.title {
            text-transform: uppercase;
        }

        svg.delta-icon {
            width: 12px;
            height: 14px;
            padding-top: 2px;
        }
    }

    div.center-align {
        .flex-display();
        .flex-wrap(wrap);
        .justify-content(center);
        .flex-direction(row);
        margin: auto;
        text-align: center;

        .classic-bignumber {
            position: relative;
            top: -10px;
        }
    }


    div.right-align {
        .flex-display();
        .flex-wrap(wrap);
        .justify-content(flex-end);
    }

    div.right-align {
        .flex-display();
        .flex-wrap(wrap);
        .justify-content(flex-end);
    }

    div.circle-wrap {

        div.bignumber-widget {
            display: inline-block;
            top: 0;
            white-space: nowrap;
        }

        p.value-container {
            .rounded(50%);
            margin: auto auto 8px auto;
            display: flex !important;
            flex-direction: column !important;
            align-items: center !important;
            justify-content: center !important;

            span.value {
                .fontSmoothing();
                font-weight: 400;

                &.comparison {
                    margin-right: 0;
                    display: block;
                    margin-top: 20px;
                }
            }
        }

        span.title {
            font-size: 12px;
            text-transform: none;
            display: block;
        }
    }

    p.title {
        border: none !important;
        margin-left: 0px !important;
    }
}
