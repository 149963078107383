//
//------------------------- ExportBuilder ----------------------------->
//

//
//  Vars
//

@page-ratio-standard: 110/85; //Letter page ratio
@page-ratio-widescreen: 177/100;
@page-width: 1360px;
@page-height-standard: (@page-width / @page-ratio-standard);
@page-height-widescreen: (@page-width / @page-ratio-widescreen);

@page-margin: 30px;

@default-page-color: #fff;
@widget-icon-color: #10ac84;
@text-icon-color: #f368e0;
@shape-icon-color: #2e86de;
@icon-icon-color: #5f27cd;
@image-icon-color: #ee5253;
@macro-icon-color: #ff9f43;

.element-icon {
    &.item-type-widget {
        color: @widget-icon-color;
    }
    &.item-type-image {
        color: @image-icon-color;
    }
    &.item-type-shape {
        color: @shape-icon-color;
    }
    &.item-type-icon {
        color: @icon-icon-color;
    }
    &.item-type-text {
        color: @text-icon-color;
    }
    &.item-type-macro {
        color: @macro-icon-color;
    }
}

//
//  General
//

#export-builder {
    .absolute();
    overflow: hidden;

    @import "../dashboard/presentation/exportbuilder.dashboard.presentation.less";
    @import "../dashboard/base/exportbuilder.dashboard.less";
    @import "../dashboard/item/exportbuilder.item.less";
    @import "../dashboard/header/exportbuilder.header.tools.less";
    @import "../dashboard/header/exportbuilder.reportheader.less";
    @import "../dashboard/panel/exportbuilder.panel.less";
    @import "../dashboard/layers/exportbuilder.layers.less";
    @import "../dashboard/item/macros/item.macros.less";

    &.is-resizing-item {
        .exportbuilder-item.focused {
            opacity: 0.6;
        }
    }

    &.cannot-edit {
        div.exportbuilder-dashboard,
        div.header-title-display {
            pointer-events: none;
            user-select: none;
        }

        div.header-title-display {
            i {
                display: none;
            }
        }

        export-builder-layers-page-panel {
            .page-drag-handle {
                display: none !important;
            }
        }
    }

    &.is-widget-builder-active {
        export-builder-report-header,
        export-builder-layers-page-panel {
            * {
                pointer-events: none !important;
                user-select: none !important;
                opacity: 0.8 !important;
            }
        }
    }

    .background-image-water-mark {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .widget-header .widget-title-container {
        width: 100%;

        .widget-title {
            width: 95%;
        }

        .filter-or-date-toggle {
            width: 89%;
        }

        .filter-date-toggles {
            width: 85%;
        }
    }
}
export-builder {

    #widget-builder-panel {
        .delayed-show-rl();
        box-shadow: 0 0 10px rgba(0,0,0,0.15);
    }
}

// Right click
@import "../dashboard/menu/exportbuilder.menu.less";

//Modals
@import "../dashboard/export/exportbuilder.dashboard.export.less";
@import "../dashboard/format/exportbuilder.format.modal.less";
@import "../dashboard/shortcuts/exportbuilder.shortcuts.modal.less";
@import "../dashboard/item/iconmodal/exportbuilder.item.itemmodal.less";
@import "../dashboard/pageassignmentmodal/exportbuilder.pageassignment.modal.less";

//Export
@import "../standalone/exportbuilder.standalone.less";

//Admin page
@import "../admin/exportbuilder.admin.less";
@import "../admin/managelibrary/exportbuilder.managelibrary.less";

//Edit title
@import "../dashboard/header/exportbuilder.reportheader.overlay.less";