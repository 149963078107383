//
//------------------------- ExportBuilder Standalone  -----------------------------
//
@import "../base/exportbuilder.less";
//
//  General (only for exporting)
//
body.exporting-builder {
    font-variant-ligatures: none;
    pointer-events: none;

    &.landscape-standard {
        height: floor(@page-height-standard);
        width: @page-width;
    }

    &.landscape-widescreen {
        height: floor(@page-height-widescreen);
        width: @page-width;
    }

    &.portrait-standard {
        height: @page-width;
        width: @page-height-standard;
    }

    &.portrait-widescreen {
        height: @page-width;
        width: @page-height-widescreen;
    }


    &.is-thumbnail {
    }

    &.export_ppt {
        div, p {
            box-shadow: none !important;
            text-shadow: none !important;
        }

        .exportbuilder-item-inner {
            &[class*="has-borders-8"] {
                .design-widget {
                    padding: 8px !important;
                }
            }

            &[class*="has-borders-4"] {
                .design-widget {
                    padding: 4px !important;
                }
            }
        }
    }

    &.export_pdf {
    }

    overflow: auto;

    .exportbuilder-item-inner {
        height: 100%;

        .report-element-overlay {
            border: none !important;
        }
    }

    #export-builder.export-builder-export {
        overflow: initial !important;

        .page {
            @bg-border-width: 0px;
            @bg-page-width: @page-width + 2 * @bg-border-width;

            page-break-after: always;
            overflow: hidden;
            background: none;
            height: 100% !important;

            .page-background {
                width: 100%;
                height: 100% !important;
                position: absolute;
                background-color: @default-page-color;
            }

            &.landscape,
            &.portrait {
                height: 100% !important;
                width: 100%;
            }

            .page-content {

                position: relative;
                width: 100%;
                height: 100% !important;
                margin-left: @bg-border-width;

                // Fix the issue that wkhtmltopdf doesn't support flex syntax
                .widget-box-horizontal {
                    .old-flex(horizontal);
                }

                .widget-box-vertical {
                    .old-flex(vertical);
                }

                div.chart-widget-container {
                    .old-flex-size(1);
                    width: 100%;
                    height: 100%;
                }
                div.design-widget {
                    div.widget-inner {
                        div.widget-header {
                            span.widget-title {
                                color: black !important;
                            }
                        }

                        div.widget-content {
                            .datagrid-display {
                                p.value-container {
                                    span.value.comparison {
                                        color: rgba(115, 124, 132, 0.7);
                                    }

                                    span.value {
                                        color: black;
                                    }
                                }
                            }

                            .classic-bignumber {
                                p.value-container {
                                    span.value.comparison {
                                        color: rgba(115, 124, 132, 0.7);
                                    }

                                }
                            }

                        }
                    }

                }

                media-widget {
                    padding: 0px;
                    div.media-widget {
                        padding: 0px;
                    }
                }

                // ACCOUNT MANAGER
                account-manager-widget {
                    div.column-display {
                        div.accountmanager-text {
                            .old-flex(horizontal);
                            .old-flex-size(1);
                        }
                    }
                }

                geochart-widget {
                    .amcharts-zoom-control {
                        display: none;
                    }
                }

                .summernote-wrapper {
                    position: absolute;
                    left: (@bg-border-width / 2);
                    right: (@bg-border-width / 2);
                    height: 50px;

                    .panel.note-editor.note-frame {

                        border: none;
                        background: transparent;
                        box-shadow: none;

                        .note-editing-area .note-editable {
                            background: none;
                        }

                        .note-toolbar.panel-heading {
                            position: absolute;
                            padding: 0;
                            opacity: 0;
                            background: none;
                        }

                        .note-statusbar .note-resizebar {
                            display: none;
                        }
                    }

                    &.summernote-header {
                        top: @bg-border-width;

                        .panel.note-editor.note-frame .note-toolbar.panel-heading {
                            display: none;
                        }
                    }

                    &.summernote-footer {
                        bottom: @bg-border-width;

                        .panel.note-editor.note-frame .note-toolbar.panel-heading {
                            display: none;
                        }
                    }

                }

            }

        }

        // AMCHARTS
        div.amcharts-chart-div {

            path.amcharts-bg,
            path.amcharts-axis-line,
            path.amcharts-axis-band,
            path.amcharts-axis-tick-minor,
            path.amcharts-axis-tick,
            path.amcharts-pie-slice,
            path.amcharts-funnel-slice,
            text.amcharts-funnel-label,
            circle.amcharts-graph-bullet,
            path.amcharts-plot-area,
            path.amcharts-plot-area-left,
            path.amcharts-plot-area-bottom,
            path.amcharts-plot-area-right {
                //stroke: none;
            }
        }

        div.amcharts-legend-div {
            //max-height: 50%;
            //overflow-y: hidden !important;

            path.amcharts-legend-bg,
            circle.amcharts-graph-bullet {
                //stroke: none;
            }
        }
    }
}
