//
// DESIGN CREATE EXECUTIVE SUMMARY
//
create-executive-summary {

    div.create-executive-summary-inner {

        div.create-executive-summary-header {
            margin: 15px;
            text-align: left;

            span.executive-summary-title-display {
                font-size: 14px;
            }
        }

        div.executive-summary-content-container {
            max-height: 220px;
            min-height: 50px;
            position: relative;
            border-radius: 4px;
            border: 1px solid;
            padding: 2px 6px 4px 6px;
            margin: 15px 15px 8px 15px;
            overflow: hidden;

            &:hover {
                cursor: pointer;

                div.hidden-label-container {
                    display: block;

                    label.label {
                        cursor: pointer;
                        display: block;
                        .opacity(100);
                    }
                }
            }

            div.executive-content-display {
                position: relative;
                .scale(0.5);
                .transform-origin(top left);
                min-width: 700px;
                max-height: 450px;
                white-space: pre-line;
            }

            div.hidden-label-container {
                border-radius: 4px;
                position: absolute;
                display: none;
                top: 0;
                right: 0;
                left: 0;
                bottom: -1px;
                .z-index();

                label.label {
                    display: none;
                    .center-element();
                    .opacity(0);
                    .z-index();
                    font-size: 13px;
                }
            }
        }

        div.summary-info {
            .justify-content(space-between);
            margin: 0 15px 0 10px;
            font-size: 10px;

            span.label-default {
                max-height: 15px;
                margin: 2px;
            }
        }
    }
}

#create-executivesummary-modal {
    div.exec-modal-header {
        .flex();

        div#executive-summary-title-container {
            margin: 10px;
            input.form-control {
                margin-left: 12px;
            }
        }

        div#executive-summary-date-assignment-container {
            margin: 10px 0;

            date-range-picker {
                .flex();
                max-width: 240px;
                margin-right: -15px;

                input {
                    height: 34px;
                }

                i.clear-button {
                    top: 13px;
                    right: 20px;
                }
            }
        }

        div#executive-summary-assignment-container {
            .flex();
            .flex-row();
            .justify-content(flex-start);
            margin: 10px 15px;

            div.entity-select-container {
                .flex();
                max-width: 400px;
                margin-left: 5px;

                div.entity-select {
                    .flex();

                    div.or-text {
                        max-width: 20%;
                        display: inline-block;
                        text-align: center;
                        margin: 10px;
                    }
                    div.executive-summary-select {
                        max-width: 80%;
                    }
                }
            }

        }

        span.icomoon-help {
            margin: 11px;
        }

    }

    div.text-edit-container {
        div.note-editor {
            display: flex;
            flex-flow: column;
            height: 100%;
            width: 100%;
            border: 0;
            margin-bottom: 0;
            .shadow(0, 0, 0);

            div.panel-heading.note-toolbar {
                padding: 0 15px;
                line-height: 54px;
            }

            div.note-editing-area {
                flex: 1;
                overflow-y: auto;
                white-space: pre-line;

                div.panel-body.note-editable {
                    padding: 15px;
                    height: 100% !important;
                    min-height: 200px !important;
                }
            }

            div.note-statusbar {
                display: none;
            }
        }
    }
}