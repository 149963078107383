@font-face {
  font-family: 'icomoon-new';
  src: url('../new_fonts/icomoon.eot');
  src: url('../new_fonts/icomoon.eot') format('embedded-opentype'),
  url('../new_fonts/icomoon.ttf') format('truetype'),
  url('../new_fonts/icomoon.woff') format('woff'),
  url('../new_fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^='icomoon-new-'],
[class*=' icomoon-new-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-new' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icomoon-new-chatgpt-colour::before {
  content: '\e900';
  color: rgb(116, 170, 156);
}
.icomoon-new-chatgpt-black::before {
  content: '\e900';
}
.icomoon-new-bubbles:before, .icomoon-new-bubbles-bold:before {
  content: "\e904";
}
.icomoon-new-audit-logs:before {
  content: '\e905';
}