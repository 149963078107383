//
// MEDIA WIDGET
//
media-widget {
    max-width: 100%;
    max-height: 100%;
    padding: 0px 5px;

    &.hide-scroll {
        overflow-y: hidden;
    }

    &.show-scroll {
        overflow-y: auto;
    }

    div.media-widget {
        padding: 5px;
        iframe.note-video-clip {
            width: 100% !important;
        }
    }
}