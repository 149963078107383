export-builder-layers-page-panel {

    div.export-builder-layers-page-panel {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 250px;
        .z-index(5);
        overflow: hidden;

        div.panel-tab-container {
            .flex-display();
            div.panel-tab {
                .flex(1);
            }
        }

        div.inner {
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        div.layers-pages,
        div.layers-items {
            position: relative;
            height: 100%;

            .pages,
            .elements {
                position: absolute;
                height: calc(~'100% - 37px');
                width: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                padding-right: 17px;
                box-sizing: border-box;
            }
        }

        div.layers-pages {
            .delayed-show-rl(0s);
        }

        div.layers-items {
            .delayed-show-lr(0s);
        }

        .section-more-options {
            position: absolute !important;
            right: 0;
        }

        .hidden-content-panel {
            background: #B7BFC633;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
        }

        .pages {
            margin-top: 7px;
            padding-left: 5px;

            .sortable-placeholder {
                width: 230px;
                height: 155px;
                margin: 5px;
            }

            .page {
                position: relative;
                margin: 5px 0 34px 0;

                &.selected {
                    div.page-content-banner-top-side,
                    div.page-content-banner-right-side {
                        display: none;
                    }
                }

                &-inner {
                    .flex();
                    margin-left: 15px;
                }

                &-number {
                    .bold();
                    position: absolute;
                    left: 3px;
                    top: -2px;

                    .page-drag-handle {
                        display: none;
                        position: absolute;
                        top: 20px;
                        cursor: move;
                    }
                }
                &-title {
                    position: absolute;
                    top: -2px;
                    left: 22px;
                    span {
                        .bold();
                        .ellipsis(180px);
                        font-size: 11px;
                    }
                }
                &-content {
                    position: relative;
                    top: 22px;
                    left: 6px;
                    height: 125px;
                    width: 200px;
                    cursor: pointer;
                }

                &-hidden-panel {
                    position: relative;
                    top: 22px;
                    left: 6px;
                    height: 30px;
                    width: 200px;
                    cursor: pointer;
                }

                &:hover {
                    .page-drag-handle {
                        display: initial;
                    }
                }
            }

            .page-add-page {
                opacity: 0;
                height: 20px;
                width: 100%;
                text-align: center;

                &-last {
                    margin-top: 10px;
                    width: 50%;
                }

                &:hover {
                    opacity: 1;
                    cursor: pointer;
                }
            }
        }

        div.header-container {
            .flex();
            .justify-content(space-between);

            div.unhide-all {
                cursor: pointer;
                position: relative;
                top: 6px;
                right: 14px;
                font-size: 18px;
                .opacity(70);

                &:hover {
                    .opacity(100);
                }
            }
        }

        div.export-builder-panel-element-list {
            overflow-x: hidden;
        }
    }

    div.export-builder-layers-page-content {
        position: relative;
        .clearfix();
        width: 100%;
        height: 100%;
        overflow: hidden;
        text-align: center;

        div.page-content-banner {
            position: absolute;
            transform: rotate(45deg);
            width: 100px;
            height: 15px;
            right: -34px;
            top: 9px;

            &-title {
                .bold();
                position: relative;
                top: -2px;
                font-size: 10px;
                width: 100%;
                height: 100%;
                text-align: center;
            }
        }
        div.page-content-banner-top-side {
            position: absolute;
            width: 18px;
            height: 1px;
            right: 23px;
        }
        div.page-content-banner-right-side {
            position: absolute;
            right: 0px;
            top: 22px;
            width: 1px;
            height: 18px;
        }

            div.item-content {
            .flex();
            width: 90px;
            margin: 4px;
            padding: 4px 3px;
            float: left;
            .rounded(3px);

            i.icon {
                font-size: 12px;
                width: 18px;
                margin-right: 4px;
                margin-left: 1px;
                line-height: 14px;
            }

            span {
                .ellipsis(60px);
                .bold();
                font-size: 10px;
            }
        }

        i.icomoon-dashboard {
            font-size: 60px;
            .opacity(30);
        }

        canvas {

        }
    }
}

export-builder-layers-element-item {

    > :first-child {
        height: 35px;
        .flex();
        margin: 5px 8px;
        cursor: move;
        overflow: hidden;
        font-size: 11px;
        .rounded(3px);

        .item-hoverable {
            .transition(opacity, 0.5s);
            .opacity(0);
        }
        &:hover {
            .item-hoverable {
                .opacity(100);
            }
            .item-actions {
                .icon-label {
                    .opacity(50);
                }
            }
        }
    }

    .item-descriptor {
        flex: 1;
        display: flex;
        align-items: center;
        margin-left: 10px;

        .preview {
            display: inline-block;
            height: 20px;
            width: 15px;
            margin-right: 7px;
            font-size: 12px;
            line-height: 18px;
            text-align: center;

            &.color-preview {
                height: 18px;
                width: 18px;
                border-width: 1px;
                border-style: solid;
            }
        }

        label {
            margin-bottom: 2px;
            .ellipsis(160px);
        }
    }

    .item-actions {
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon-label {
            height: 100%;
            font-size: 15px;
            .opacity(0);
            position: relative;
            top: 8px;
            right: 8px;

            &:hover {
                .opacity(90);
            }

            &.icon-infinity {
                opacity: 1;
                cursor: default;
            }

            &.is-hidden {
                .opacity(25);

                i.icon:before {
                    font-family: 'icomoon';
                    content: '\ea8b';
                }
            }
        }
    }
}