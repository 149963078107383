leads-notes-panel {
    div.slide-panel {
        &.active{
            .z-index(1000);
        }
        div.slide-panel-header {

            div.service-square {
                position: absolute;
                left: 12px;
                top: 12px;
            }

            span.title-inner {
                display: inline-block;
                margin-left: 10px;
            }
        }
    }
}

