#details-modal {

    .date-range-picker {
        input.daterange {
            margin-left: -16px;
            width: 100%;
            height: 34px;
        }
    }

    .goals-details-modal {

        .placeholder {
            height: 400px;
        }

        .datasource-selects-container {
            margin: 20px 0 10px 0;
        }

        .goal-threshold-select {
            input {
                .flex();
                width: 20%
            }

            rzslider {
                .flex();
                width: 50%;
                margin-top: 35px;
            }
        }

        .datasource-selects {
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;

            .datasource-select {
                position: relative;
                flex: 1;
                width: 250px;
                margin: 10px;
                justify-content: space-between;

                .select2-container {
                    width: 100%;
                }

                .notice-block {
                    margin-left: 0;
                }
            }
        }

        .customer-select-container {
            margin: 10px 30px 10px 10px;
        }

        .disabled {
            opacity: 0.4;
            cursor: not-allowed;
            .select2-container {
                width: 100%;
                pointer-events: none;
            }
            pointer-events: none;
        }

        .configuration-container {
            @widthPadding: 10px;
            margin-top: -20px;

            border: none;

            div.design-page {
                border: none;
                padding: 0;
            }

            .tab-content {
                height: 400px;
                padding: 0;
            }

            .search-value {
                > div {
                    float: left;
                }

                height: 80px;
                margin-top: 30px;

                input[type="text"] {
                    width: 250px;
                    margin: 5px;
                    display: inline-block;
                }
                .btn {
                    position: relative;
                    top: -3px;
                    margin: 5px;
                }

                button.configuration-btn {
                    position: relative;
                    top: 26px;
                    float: right;
                }

                span.icomoon-help {
                    margin-left: 20px
                }
            }

            .preview {
                position: relative;
                min-height: 200px;
                margin-top: 20px;
            }
        }
    }

    .goal-preview-list {
        .delayed-show-lr();

        .campaigns {
            border: 1px solid rgba(0,0,0,0.1);
            border-radius: 3px;

            .campaign {
                display: flex;
                flex-direction: row;
                align-items: center;
                border-bottom: 1px solid rgba(0,0,0,0.1);
                padding-left: 10px;

                &:first-child {
                    height: 35px;
                }
                &:not(:first-child) {
                    cursor: pointer;
                }

                .label-container-title {
                    font-weight: bold;
                }

                &:last-child {
                    border-bottom: none;
                }

                height: 50px;
                .checkbox {
                    cursor: pointer;
                }
                label {
                    font-weight: lighter;
                    font-size: 20px;
                    margin-left: 10px;
                    margin-bottom: 0;
                }
            }
        }

        datagrid-widget {
            border-radius: 10px;
            table {
                tr {
                    transition: background-color 0.3s ease;
                    cursor: pointer;
                }
            }
        }

        .checkbox-container,
        .label-container {

        }
    }
}
