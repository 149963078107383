div[export-builder-dashboard-grid] {
    div.export-builder-dashboard-grid {
        position: absolute;
        .z-index(1000);
        height: 100%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        pointer-events: none;
        opacity: 0.0;
        .transition(all, 0.3s);

        .grid-unit {
            border: 1px solid rgba(0,0,0,0.1);
        }
    }
}