messenger-panel {
    div.slide-panel {
        .shadow(0, 0, 10px, 0.1);
        right: 0;

        div.slide-panel-header {
            text-indent: 0;
            padding-left: 12px;
        }

        div.slide-panel-body {
            margin-top: 25px;
            div.panel-content {
                padding: 12px 6px;
            }
        }
    }

    div#messenger-title {
        .ellipsis(250px);
    }

    div.alert-info {
        margin: 5px 10px 10px 10px;
    }
}

messenger {
    messenger-thread {

        div.messenger-thread-container {
            margin-bottom: 20px;
            .clearfix();
        }

        &:hover {
            nav.dropdown {
                .opacity(100);
            }
        }

        div.title-container {
            div.created-by-container {
                font-size: 11px;

                b {
                    margin-right: 5px;
                }
                span {
                    .opacity(65);
                }
            }
        }

        div.content {
            clear: both;
            font-size: 13px;
            overflow-wrap: break-word;
        }

        nav.dropdown {
            .z-index(4);
            .opacity(0);
            float: right;
            right: -4px;
            top: 2px;

            > i.icon {
                font-size: 20px;
                display: block;
                width: 20px;
                height: 20px;
                cursor: pointer;
            }

            ul {
                right: 0;
                left: auto;

                a {
                    padding: 5px 12px;
                }
            }
        }

        div.button-container {
            margin-right: 2px;
            margin-top: 7px;
            margin-bottom: 15px;
        }
    }

    div.user-image {
        height: 30px;
        width: 30px;
        display: inline-block;
        float: left;
        margin-right: 10px;

        &.main {
            .opacity(60);
        }

        &.main.active {
            .opacity(100);
        }

        img {
            .rounded(50%);
            height: 30px;
            width: 30px;
        }

        i {
            font-size: 32px;
        }
    }

    div.interaction-container {
        float: left;
        width: 225px;
        position: relative;
    }

    date-range-picker {
        div.date-range-picker {
            padding-left: 3px;

            input.daterange {
                width: 100%;
            }
        }
    }

    textarea.form-control {
        resize: none;
        overflow: hidden;
        height: 32px;
        min-height: 64px;
        max-height: 200px;
    }

    div#add-thread-container {

        div.info-container {
            float: right;
        }

        button.save-button {
            float: right;
            margin-right: 2px;
            margin-top: 8px;
            margin-bottom: 15px;
            .opacity(0);

            &.zoomIn {
                .opacity(100);
            }
        }
    }
}