div#edit-alert-modal {

    div.alert-creation-canvas {
        padding: 0 15px;
        overflow-y: auto;
    }

    div.add-alert-button {
        cursor: pointer;
        height: 60px;
        line-height: 60px;
        margin: 4px 12px;
        font-size: 28px;
        text-align: center;
        .transition(background-color);
    }

    div.select2-container {
        // always set select2 above overlay
        .z-index(1001);
    }

    @import "../item/alert.item.less";
}

@import "../widget/alert.widget.less";