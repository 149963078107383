edit-layout-panel {

    div.orderable-container {
        overflow-y: auto;
        position: absolute;
        margin-top: 40px;
        top: 10px;
        left: 12px;
        right: 12px;
        bottom: 58px;
        .z-index(1);
    }

    div.orderable-widget {
        .flex();
        position: relative;
        height: 42px;
        line-height: 42px;

        &:hover {
            div.handle-container {
                div.widget-handle {
                    .opacity(100);
                }
            }
        }

        label {
            height: 14px;
            margin-right: 8px;
            margin-top: 7px;
        }

        div.tiny-widget {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 32px;
            line-height: 30px;
            margin-bottom: 10px;
            cursor: move;

            > div.inner {
                .flex();

                i.icon {
                    width: auto;
                    margin: 0 4px;
                    font-size: 14px;

                    &[class*=" serviceicon-"] {
                        font-size: 20px;
                        margin: 0 2px;
                    }
                }
            }
        }

        p.title {
            font-size: 10px;
            border: 0;
            text-transform: none;
            .ellipsis(160px);
        }

        div.handle-container {
            position: absolute;
            right: 5px;
            top: 6px;

            div.widget-handle {
                text-align: center;
                float: left;
                .z-index(10);
                margin: 0 3px;
                width: 18px;
                height: 18px;
                line-height: 18px;
                .opacity(20);
                .rounded(50%);

                &.widget-drag-handle {
                    cursor: ns-resize;
                }

                i.icon {
                    display: block;
                    position: relative;
                    font-size: 13px;
                }
            }
        }
    }
}