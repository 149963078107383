div#dashboard-filter-header {
    .fontSmoothing();
    min-height: 20px;
    padding: 2px 90px 10px 18px;
    margin: 12px -14px 0px -10px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-height: 90px;
    overflow-y: auto;

    div.filter-header-item {
        margin-top: 8px;
        margin-right: 8px;
        white-space: wrap;
        float: none;
    }

    &.fixed {
        position: fixed;
        top: 50px;
        left: 225px;
        right: 15px;
        margin: 0;
        .z-index(10000);

        .close-header {
            right: 13px;
        }
    }

    &.fixed.panel-padded-pushed {
        .close-header {
            right: 300px;
        }
    }

    .close-header {
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 5px;
        font-size: 20px;
        .opacity(80);

        &:hover {
            .opacity(100);
        }
    }

    .show-filter-values {
        cursor: pointer;
        position: absolute;
        right: 40px;
        display: flex;
        top: -2px;
        font-size: 35px;
        .opacity(40);

        &:hover {
            .opacity(80);
            color: #2d72af;
        }
    }

    .badge {
        .codefont();
    }

    span {
        .codefont();
        text-transform: uppercase;
        font-size: 12px;
        cursor: default;
    }

    label {
        margin: 0 2px;
        position: relative;
        top: -1px;
    }

    span.value {
        .bold();
        margin-right: 3px;
    }

    span.filter-values {
        font-weight: normal;
        text-transform: inherit;
    }

    span.token {
        margin-right: 3px;

        &.and-token {
            margin-left: 16px;
        }
    }
}

.show-filter-content {
    flex-direction: column;
    flex-wrap: nowrap !important;
    .filter-header-item {
        flex: 1;
    }
}

.impersonation-mode div#dashboard-filter-header.fixed {
    top: 90px;
}