/* General Rules */
.pointer {cursor: pointer;}
.underline {text-decoration: underline;}
.center {text-align: center;}
.bold {.bold();}
.clear {clear: both;}
.clear-left {clear: left;}
.clear-right {clear: right;}
.left {float: left;}
.right {float: right;}
.fixed {position: fixed;}
.relative {position: relative;}
.position-absolute {position: absolute;}
.visible {visibility: visible !important;}
.invisible {visibility: hidden !important;}
.block {display: block !important;}
.inline-block {display: inline-block !important;}
.disable-pointer-events {pointer-events: none !important;}
.disable-element {pointer-events: none !important; .opacity(40); cursor: not-allowed;}
.transparent {background: transparent !important;}
.no-shadow {.no-shadow();}
.no-border {border: 0 !important;}
.transparent-border {border-color: transparent !important;}
.nowrap {white-space: nowrap;}
.full-width {width: 100% !important;}
.full-height {height: 100% !important;}

.margin-auto {margin: auto}
.margin-left-auto {margin-left: auto}

.mt0 {margin-top: 0 !important}
.mt2 {margin-top: 2px !important}
.mt5 {margin-top: 5px !important}
.mt10 {margin-top: 10px !important}
.mt15 {margin-top: 15px !important}
.mt20 {margin-top: 20px !important}
.mt30 {margin-top: 30px !important}
.mt40 {margin-top: 40px !important}
.mt50 {margin-top: 50px !important}
.mt60 {margin-top: 60px !important}

.mmt1 {margin-top: -1px}
.mmt2 {margin-top: -2px}
.mmt5 {margin-top: -5px}
.mmt10 {margin-top: -10px}
.mmt15 {margin-top: -15px}
.mmt20 {margin-top: -20px}

.mb0 {margin-bottom: 0 !important}
.mb2 {margin-bottom: 2px !important}
.mb5 {margin-bottom: 5px !important}
.mb10 {margin-bottom: 10px !important}
.mb15 {margin-bottom: 15px !important}
.mb20 {margin-bottom: 20px !important}
.mb30 {margin-bottom: 30px !important}
.mb40 {margin-bottom: 40px !important}
.mb50 {margin-bottom: 50px !important}
.mb60 {margin-bottom: 60px !important}
.mb70 {margin-bottom: 70px !important}
.mb80 {margin-bottom: 80px !important}
.mb90 {margin-bottom: 90px !important}
.mb100 {margin-bottom: 100px !important}
.mb120 {margin-bottom: 120px !important}
.mb150 {margin-bottom: 150px !important}

.mr0 {margin-right: 0 !important}
.mr2 {margin-right: 2px !important}
.mr5 {margin-right: 5px !important}
.mr10 {margin-right: 10px !important}
.mr12 {margin-right: 12px !important}
.mr15 {margin-right: 15px !important}
.mr20 {margin-right: 20px !important}
.mr30 {margin-right: 30px !important}
.mr40 {margin-right: 40px !important}
.mr50 {margin-right: 50px !important}
.mr60 {margin-right: 60px !important}
.mr70 {margin-right: 70px !important}
.mr80 {margin-right: 80px !important}
.mr90 {margin-right: 90px !important}


.ml0 {margin-left: 0 !important}
.ml2 {margin-left: 2px !important}
.ml5 {margin-left: 5px !important}
.ml10 {margin-left: 10px !important}
.ml12 {margin-left: 12px !important}
.ml15 {margin-left: 15px !important}
.ml20 {margin-left: 20px !important}
.ml25 {margin-left: 25px !important}
.ml30 {margin-left: 30px !important}
.ml35 {margin-left: 35px !important}
.ml40 {margin-left: 40px !important}
.ml45 {margin-left: 45px !important}
.ml50 {margin-left: 50px !important}
.ml60 {margin-left: 60px !important}
.ml70 {margin-left: 70px !important}
.ml80 {margin-left: 80px !important}
.ml90 {margin-left: 90px !important}

.mml1 {margin-left: -1px}
.mml2 {margin-left: -2px}
.mml5 {margin-left: -5px}

// Bootstrap class imports (can be sued in other less files using &:extend(.classname)
.badge-success {background-color: #5cb85c;}
.badge-info {background-color: #5bc0de;}
.badge-label {background-color: #bbb;}
.badge-warning {background-color: #f0ad4e;}
.badge-danger {background-color: #d9534f;}