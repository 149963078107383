//
// EXECUTIVE SUMMARY PANEL
//
dashboard-executive-summary-panel {
    .z-index(30);
    div#executivesummary-panel {
        .z-index(40);
        .flex();
        .flex-column();

        div.executive-summary-loading {
            margin: auto;
        }

        div.slide-panel-header {
            line-height: 20px;
            text-indent: 0;
            width: 350px;
            margin-top: 10px;
            .close-panel {
                margin-top: 10px;
            }
        }

        div.slide-panel-body {
            overflow-y: auto;
            overflow-x: hidden;
            margin-bottom: 50px;

            div.option-panel-item {
                margin: 15px 15px 0 15px;
            }

            input.switch {
                float: right;
            }

            hr {
                margin: 20px 15px 10px 15px;
            }

            div.add-summary-button {
                cursor: pointer;
                height: 60px;
                line-height: 60px;
                margin: 15px 5px;
                font-size: 28px;
                .transition(background-color);

                i.icon {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                }
            }
        }

        div.date-display {
            font-size: 12px;
        }
    }

  div.daterangepicker {
    &.dropdown-menu {
      z-index: 2147483628; // Fix overlapping issue with WalkMe icon
      width: fit-content;
      margin-right: 50px;
    }
  }

    @media screen {
        @media (max-width: 1600px) {
            div.daterangepicker {
                &.dropdown-menu {
                    left: 600px !important;
                }
            }
        }
        @media (max-width: 1380px) {
            div.daterangepicker {
                &.dropdown-menu {
                    left: 500px !important;
                }
            }
        }
        @media (max-width: 1280px) {
            div.daterangepicker {
                &.dropdown-menu {
                    left: 400px !important;
                }
            }
        }
    }
}
