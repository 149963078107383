.upcoming-schedule-container {
	height: 275px;
	overflow-y: auto;

	div.dataTables_header {
		display: none;
	}
	
	#upcoming-scheduled-table, #upcoming-scheduled-warning {
		display: none;
	}
}

#custom_data_date_range {
	.radio-fixed {
		height: 45px;
	}
}

#upcoming-scheduled-same-day-warning {
	display: none;
}
