div#filter-widget-modal {

    div.select2-container {
        margin-top: 3px;
    }

    div.select-columns-container {

        div.loading-inner {
            bottom: 50px;
        }

        p.user-toggle {
            text-align: left;
            margin-bottom: 10px;
            line-height: 24px;

            span.icomoon-help {
                position: relative;
                top: 1px;
            }

            input.switch {
                float: right;
                margin-right: 5px;
            }
        }
    }

    div.widget-preview {
        padding: 0 15px;

        div.loading-inner {
            left: 301px;
        }

        > div.flex-column {
            overflow-y: auto;
            margin-bottom: 10px;
        }

        span.total-message-icon {
            font-size: 16px;
        }
        
        span.total-message-text {
            text-transform: initial;
            font-weight: bold;
            font-size: 12px;
        }
    }

    @import "../item/widget.filter.item.less";
}

div.filter-description {
    .fontSmoothing();
    .codefont();
    .bold();
    .rounded(4px);
    padding: 15px 15px 10px 15px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 14px;

    .badge {
        .codefont();
    }

    span.token {
        text-transform: uppercase;
        &.and-token {
            margin-left: 16px;
        }

        &.or-token {
            margin-left: 10px;
        }
    }

    span.value {
        text-transform: uppercase;
        cursor: default;
    }

    div.token-container {
        width: 50px;
        text-align: right;
    }
}

div.design-widget {

    div.filter-toggler {
        display: inline-block;
        vertical-align: top;
        margin-top: 3px;
        margin-right: 10px;
        margin-left: 1px;
        font-size: 20px;
    }

    div.filter-option-container {
        display: inline-block;
        vertical-align: top;
        margin-top: 4px;
        .z-index();

        div.filter-option {
            display: inline-block;
            margin: 0 5px 0 3px;
            .z-index(100);
            .animation(zoomIn 0.3s);
        }

        div.filter-dragger {

            div.btn {
                cursor: move;
                .transition(transform);
            }

            &.over-drop-zone {
                .z-index(100);

                div.btn {
                    color: #fff;
                    line-height: 27px;
                    .scale(3);
                }
            }

            &.ui-draggable-reverting {

                div.btn {
                    .scale(1);
                }
            }

            &.disable-reverting {

                div.btn {
                    .scale(0);
                }
            }
        }
    }

    div.filter-panel {
        .absolute();
        background: rgba(0,0,0, 0.75);
        top: 45px;
        .z-index(2);
        padding: 15px 15px 10px 15px;
        overflow-y: scroll;

        p.title {
            font-size: 11px;
        }

        > div {
            .rounded(2px);

            label {
                display: inline-block;
            }
        }
    }

    div.filter-drop-zone {
        .absolute();
        top: 0;
        .z-index(-1);
        .rounded(2px);
        .flex();
        .flex-center();

        div.drop-zone-text {
            color: #fff;
            text-align: center;
            position: relative;
            top: -20px;

            i.icon {
                font-size: 70px;
            }

            p {
                position: relative;
                top: -10px;
                font-size: 12px;
                margin-bottom: 5px;
                .opacity(70);

                &.small {
                    font-size: 10px;
                }
            }

            h4 {
                margin: 0;
                padding: 0;
            }
        }
    }
}