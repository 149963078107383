//
// DESIGN EXECUTIVE SUMMARY
//
executive-summary-display {

    element-placeholder {
        i.icon {
            font-size: 50px !important;
        }

        p {
            margin: 0;
            font-size: 14px;
            line-height: 16px;
        }

        div.btn {
            margin-top: 15px !important;
            font-size: 12px !important;
        }
    }

    div.executive-summary-inner {
        width: 100%;

        div.executive-summary-header {
            .justify-content(flex-end);
        }

        div.info-container {
            .justify-content(space-between);
            font-size: 11px;
        }

        owl-carousel {
            ul {
                list-style-type: initial;
            }

            div.owl-carousel-content {
                white-space: pre-line;
            }

            div.info-container {
                margin-right: 24px;
                margin-bottom: 35px;
            }

            div.owl-item {
                &:hover {
                    cursor: default;
                }
            }

            div.owl-carousel {
                padding: 15px;
            }

            div.owl-item,
            div.item {
                margin: 0 auto;
                width: 100%;
                display: block;
                height: auto;
                overflow: hidden;
            }
        }
    }

    &:hover {
        #edit-executive-summary-btn {
            display: block;
        }
    }

    #edit-executive-summary-btn {
        margin: 5px;
        z-index: 2;
        position: absolute;
        right: 5px;
        display: none;
    }
}

div.executive-summary-loader {
    .flex;
    .flex-column;
    .flex-center;
    margin-top: 15px;
    position: relative;
    height: 100%;

    div.spin-loader {
        width: 50px;
        height: 50px;
        border-width: 5px;
    }

    i.icon {
        position: relative;
        margin-top: -30px;
        margin-bottom: 20px;
        .bigPulse(1.3, 2s, infinite);

        &[class*="icon-"] {
            line-height: 1;
        }
    }

    span {
        display: block;
        margin-top: 5px;
        .fontSmoothing();
    }
}


//
// Executive Summary Admin Page
//
div.executive-summary-dash {

    div.datagrid-widget-container {
        td {
            div.content-wrapper {
                max-height: 100px;
                max-width: 300px;
                overflow: hidden;

                div.inner {
                    position: relative;
                    .scale(0.5);
                    .transform-origin(top left);
                }
            }
        }
    }
}