#tag-picker-background-mask {
	height: 100vh;
	width: 100vw;
	position: fixed;
	.z-index(1000);
}

datagrid-tag-picker {
	.tag-picker-container {
		position: fixed;
		.z-index(1001);
		opacity: 0;
		padding: 10px;
		max-width: 282px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
		border: 2px solid;

		max-height: 86px;
		min-height: 66px;
		transition: top 0.2s cubic-bezier(0.00, 0.00, 0.06, 1.24), left 0.2s cubic-bezier(0.00, 0.00, 0.06, 1.24), max-width 0.2s cubic-bezier(0.00, 0.00, 0.06, 1.24), max-height 0.2s cubic-bezier(0.00, 0.00, 0.06, 1.24), opacity 1.0s ease;
		overflow: hidden;

		&.isLoading {
			opacity: 0.8;
			overflow: hidden;
		}

		&.done-loading {
			opacity: 1;
		}

		&.color-picker-open {
			overflow: initial;
		}

		.isHidden {
			display: none;
		}

		.tag-picker-inner {

		}

		.tag-picker-content {
			display: flex;
			flex-direction: row;

			tap-colors {
				border: 1px solid;
				border-radius: 50px;
				margin-right: 8px;
			}

			.accept-button {
				width: 27px;
				height: 24px;
				padding: 5px;
				margin-top: 5px;
				margin-left: 5px;

				border-style: solid;
				border-width: 1px;
				border-radius: 15px;
				cursor: pointer;

				transition: all 0.3s ease;
				box-shadow: 0 0 0 rgba(0,0,0,0);

				&:hover {
					box-shadow: 0 2px 10px rgba(0,0,0,0.6);
					transform: scale(1.05);
				}
			}
		}

		.select2-container {
			overflow: hidden;
			max-width: 223px;
		}
	}
}