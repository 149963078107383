div.background-overlay {
    .z-index(9);
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    right: 0;
    top: 0;
}

div.export-builder-header-display-overlay {
    pointer-events: none;

    .overlay-content-shadow {
        .z-index(10);
        position: fixed;
        width: 600px;
        height: 230;

        .rounded(5px);
        margin: 0 auto;
        left: 0;
        right: 30px;
        top: 28px;
        .shadow(0, 1px, 8px, 0.2);
        transition: transform 0.3s ease, opacity 0.3s linear;
        transform: perspective(400px) rotateX(-15deg);
        opacity: 0;
        transform-origin: 50% -50px;

        .overlay-back-arrow {
            z-index: -1;
            position: absolute;
            width: 45px;
            height: 45px;
            margin: 0 auto;
            left: 0;
            right: 0;
            top: 0;
            transform: rotate(45deg);
            .rounded(5px);
            .shadow(0, 1px, 8px, 0.2);
        }

        .overlay-content-container {
            width: 100%;
            height: 100%;
            .rounded(5px);

            .overlay-content {
                height: 100%;
                width: 100%;
                padding: 15px;

                div.label-container {
                    width: 90px;
                    text-align: right;
                    margin-right: 12px;

                    label {
                        position: relative;
                        top: 6px;
                        font-size: 11px;
                    }
                }

                div.filter-mode {
                    margin-top: 8px;
                    input {
                        margin: 3px;
                    }
                    #widget-filters-mode-label {
                        margin-right: 10px;
                    }
                }

                input[type="checkbox"].switch {
                    position: relative;
                    top: 3px;
                    display: inline-block;
                }

                span.icon {
                    font-size: 16px;
                    position: relative;
                    top: 2px;
                    left: 7px;
                }

                .user-avatar-bubble {
                    display: inline-block;
                }

                span.user-name {
                    position: relative;
                    top: 2px;
                    left: 5px;
                }
            }
        }

        .reportheader-overlay-cluster-select {
            &.disabled {
                pointer-events: none;
            }
        }
    }

    .design-template-tooltip {
        position: relative;
        top: -3px;
        left: 5px;
    }

    .design-template-notice {
        ul {
            padding-left: 16px;
            list-style: disc;
        }
    }

    &.is-active {
        pointer-events: initial;
        .overlay-content-shadow {
            transform: none;
            opacity: 1;
        }
    }
}