table.dataTable {

    td.action-cell {
        a.execute-action {
            margin-right: 10px;
        }
    }
}

div.goals-page {
    div.goal-legend-container {

        .option-container {
            .flex();
            .flex-align-end();
            position: relative;

            ul {
                padding-left: 15px;
                margin-bottom: 12px;

                li.filter-option {
                    text-transform: uppercase;
                    font-size: 11px;
                    .bold();
                    line-height: 17px;
                    display: inline-block;
                    padding: 11px 4px 12px;
                    border-bottom: 2px solid transparent;
                    margin-right: 25px;
                    cursor: pointer;
                    .transition-group(color .2s ease, border-color .2s ease);

                    &.success {
                        color: @success-color;
                    }

                    &.warning {
                        color: @warning-color;
                    }

                    &.danger {
                        color: @error-color;
                    }

                    i.glyphicon {
                        font-size: 20px;
                    }
                }
            }

            .detail-toggle {
                .flex();
                .flex-align-end();
                justify-content: flex-end;
                position: relative;
                margin: 10px;
                height: 30px;

                i.glyphicon {
                    top: 0px;
                }
            }
        }

        .search-container {
            position: relative;
            left: 25px;
            cursor: pointer;
            width: 21px;
            overflow: hidden;
            border-bottom: 2px solid transparent;
            .transition(all, .2s, ease);

            &.active {
                width: 204px;
            }

            i.icon {
                cursor: pointer;
                position: absolute;
                top: 10px;
                font-size: 20px;
                .z-index();
            }

            i.icomoon-cross {
                .bold();
                font-size: 14px;
                right: -1px;
                top: 13px;
            }

            input {
                box-sizing: border-box;
                display: block;
                position: relative;
                width: 100%;
                height: 40px;
                padding: 0 18px 0 30px;
                margin-bottom: 0;
                outline: 0;
                border: 0;
                border-radius: 0;
                background: 0 0;
                font-size: 14px;
                line-height: 40px;
                .no-shadow();
                .fontSmoothing();
                .transition-group(width .3s ease, border-color .3s ease);
            }
            span.icomoon-help {
                margin: 5px;
            }

            input#goals-search {
                width: 100%;
            }
        }
    }


    div.widget-title-container {
        .widget-title {
            margin: 0 !important;
        }
    }

    form.form-details {
        .flex();
        .flex-space-between();
        margin: 0 15px;
        width: auto;
    }

    .progress-bar {
        .bold();
        font-size: 11px;
    }
}

.mr-bottom-30{
    margin-bottom: 30px;
}

.mr-top-10{
    margin-top: 10px;
}