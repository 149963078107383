.connectservices {
    form.form-horizontal div.form-group input.form-control {
        max-width: 100%;
    }
    form.form-horizontal div.form-group select.form-control {
        max-width: 100%;
    }

    .checkbox label {
       cursor: default;
    }
}
//TODO:MPD
//.connect-container{
//  display: flex;
//  height:100vh !important;
//}
//
//
//.marketplace{
//
//    &.panel {
//        margin:0;
//        &.marketplace-inactive {
//            border:0;
//            margin-bottom:0;
//        }
//
//        &.marketplace-active {
//            border:1;
//            margin-bottom:72px;
//        }
//    }
//
//}
//
//
//.service-square-container {
//
//  .row {
//    margin: 0;
//  }
//
//  .marketplace-active {
//    left: 16px;
//    width: 100%;
//
//    div.market-body {
//       margin-top: 12px;
//       opacity: 1;
//    }
//
//
//    div.header {
//      display: flex;
//      flex-direction: row;
//      flex-wrap: nowrap;
//      justify-content: flex-start;
//      align-items: stretch;
//      align-content: stretch;
//      font-size: 16px;
//
//      div.service-square {
//        width: 16.666%;
//      }
//
//      div.about {
//        padding: 16px;
//        flex: 10;
//      }
//
//      div.keywords {
//        padding: 16px;
//        flex: 7;
//      }
//
//      div.preferred {
//        h3 {
//            float: right;
//        }
//        padding: 16px;
//        flex: 1;
//      }
//
//    }
//
//    div.databody {
//      height: 100%;
//      display: flex;
//      flex-direction: row;
//      flex-wrap: nowrap;
//      justify-content: flex-start;
//      align-items: stretch;
//      align-content: stretch;
//      font-size: 16px;
//      opacity: 1;
//
//      div.links{
//        flex:1;
//        padding: 16px;
//      }
//
//      div.services{
//        text-align: left;
//        flex:1;
//        padding: 16px;
//      }
//
//      div.preferred-services{
//        text-align: center;
//        flex:1;
//        padding: 16px;
//
//        div.element-overlay{
//          z-index: 98;
//          p {
//            z-index: 99;
//          }
//        }
//      }
//    }
//
//  }
//  .marketplace-inactive {
//    height:250px;
//    width:16.666%;
//
//    div.row {
//        div.about {
//            height:0;
//            opacity: 0;
//            h3{
//                height:0;
//                opacity: 0;
//            }
//            p{
//                height:0;
//                opacity: 0;
//            }
//        }
//        div.keywords {
//            height:0;
//            opacity: 0;
//            h3{
//                height:0;
//                opacity: 0;
//            }
//            p{
//                height:0;
//                opacity: 0;
//            }
//        }
//        div.preferred {
//            height:0;
//            opacity: 0;
//            h3{
//                height:0;
//                opacity: 0;
//            }
//            p{
//                height:0;
//                opacity: 0;
//            }
//        }
//    }
//  }
//}
