
draw-option-panel {

	div.slide-panel.draw-option-panel {
		right: 0;
		.shadow(0, 0, 10px, 0.1);
	}

	&.embedded {
		div.slide-panel.draw-option-panel {
			display: initial;
			float: right;
			right: 14px;
			box-shadow: none;

			p.title.help-block {
				text-transform: none !important;
			}
			@import "../item/drawoptionpanel.item.less";
		}
	}

	&.export-panel {
		div.slide-panel.draw-option-panel {
			right: 18px;
			.shadow(0, 0, 10px, 0.1);
		}
	}
}

.disabled {
	pointer-events: none;
	cursor: not-allowed;
	.opacity(40);
}
.disabled-export {
	cursor: pointer;
	.opacity(40);
}
