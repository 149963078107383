#export-custom-report-modal {

    div.modal-full {
        overflow: hidden;
    }

    div.slide-panel-body.has-tabs {
        .panel-tab-container {
            max-height: 40px;
        }
    }

    div.slide-panel {
        position: absolute;
        right: auto;
        left: 0;
        .animation-duration(0.5s);

        hr {
            margin: 4px 6px 12px 6px;
        }

        div.option-panel-item {
            span.icomoon-help.help-tooltip {
                left: -2px;
                top: 12px;
            }
        }
    }

    div.pdf-preview-container {
        margin-left: 300px;
    }

    #export-preview-frame {
        visibility: hidden;
    }

    rzslider {
        width: 100%;

        .rz-pointer:after {
            display: none;
        }
    }
}