div#branding {
    .tools {
        margin-top: 30px;
        margin-left: 43px;
    }
    .palettes-container {
        min-width: 775px;
        height: 114px;
    }

    .palette-selection {
        .palette-selection-inner {
            display: flex;
            & > div {

            }
        }

        .smart-image-container {
            align-self: center;
            position: relative;
            left: 15px;
        }
    }

    .palette-image {
        margin-right: 30px;
        padding: 10px;
        border: solid 1px rgba(1, 1, 1, 0.1);
        border-radius: 3px;
    }

    .palette-seperator {
        height: 68px;
        width: 1px;
        border-width: 1px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .palettes {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        .palette {
            margin: 5px;
            width: 100px;
            height: 100px;
            cursor: pointer;

            &__inner {
                position: relative;
            }

            &__fill {
                border-color: white;
                border-radius: 5px;
                width: 100px;
                height: 100px;
            }

            .selection-overlay {
                .z-index();
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                & > span {
                    width: 100%;
                    font-size: 15px;
                }
                & > i {
                    position: absolute;
                    top: 91px
                }

                &-selected {
                    border: solid 3px;
                    border-bottom: solid 12px;
                    transition: all 0.3s ease;
                }

                &:hover {
                    .selection-overlay-selected {
                        opacity: 0.6;
                    }
                    .selection-overlay-delete {
                        pointer-events: initial;
                        opacity: 1;
                    }
                }

                &-delete {
                    pointer-events: none;
                    opacity: 0;
                    position: absolute;
                    top: 59px;
                    transition: all 0.3s ease;
                }

            }
        }
    }

    .grayscale {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
    }

    .pop-anim() {
        .small-pop();
    }
}

.anim-loop(@n) when (@n > 0) {
    .palette-animate-@{n} {
        opacity: 0;
        transform: scale(0);
        animation-fill-mode: forwards;
        animation-duration: 1s;
        animation-name: palette-appear-animation;
        animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);;
        animation-delay: (0.1s * @n);
    }
    .anim-loop(@n - 1);
}
@iterations: 7;
.anim-loop(@iterations);

@keyframes palette-appear-animation {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}