@item-width: 8.3333333333%;

.ui-resizable-helper {
    outline: 2px dashed;
    outline-offset: -4px;
}

.ui-state-highlight {
    outline: 2px dashed;
    outline-offset: -4px;
    background: none;
    border: 0;
}

div.widget-grid-sizer {
    width: @item-width;

    &.export-grid-sizer {
        // wkhtmltopdf uses an older webkit version that will round down to 131, which causes isotope miscalculations
        width : 132px !important;
    }
}

//Max width of widget + left frame
@media screen and (max-width: 1170px) {

    body:not(.exporting) {
        div:not(.widget-preview) {
            div.widget-grid-sizer,
            div.widget-grid-item {
                width: 100% !important;

                div.widget-content {
                    max-height: 500px;
                }
            }
        }
    }
}