//
// Custom bootstrap .btn
//
div.btn-sonar.btn {
    .sonar-button();
}

.sonar-button(@size: 30px) {
    width: @size !important;
    height: @size !important;
    line-height: @size;
    font-size: @size/2;

    &[class*="icon-"] {
        line-height: @size - (@size/8);
    }
}

//
// SONAR BUTTON
//
.sonar-button {
    text-align: center;
    cursor: pointer;
    display: inline-block;

    i.sonar-sm {
        .sonar-button(35px);
    }

    i.sonar-md {
        .sonar-button(50px);
    }

    i.sonar-lg {
        .sonar-button(75px);
    }

    i.sonar-xl {
        .sonar-button(100px);
    }

    i.icon {
        .rounded(50%);
        display: block;
        position: relative;
        border: 1px solid transparent;
        -webkit-transition: -webkit-transform ease-out 0.1s, background 0.2s;
        -moz-transition: -moz-transform ease-out 0.1s, background 0.2s;
        transition: transform ease-out 0.1s, background 0.2s;
    }

    i.icon:after {
        pointer-events: none;
        position: absolute;
        width: 100%;
        height: 100%;
        .rounded(50%);
        content: '';
        .box-sizing();
        top: 0;
        left: 0;
        padding: 0;
        z-index: -1;
        box-shadow: 0 0 0 2px rgba(255,255,255,0.1);
        .opacity(0);
        .transform(scale(0.9));
    }

    i.icon:hover {
        .transform(scale(0.93));
    }
}

//
// HOVER BUTTON
//
div.btn-hover {
    .hover-button();
}

.hover-button(@size: 30px) {
    width: @size !important;
    height: @size !important;
    line-height: @size;
    font-size: @size/2;

    > i.icon {
        line-height: @size;
    }
}

.hover-button {
    position: relative;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    .transition(background);
    .rounded(50%);

    &:hover {
        > i.icon {
            color: #fff;
            .opacity(100);
            .transform(scale(0.8));
        }
    }

    > i.icon {
        display: block;
        position: relative;
        margin: 0 !important;
        .transition(transform);
    }

    > a {
        display: block;
        width: 100%;
        height: 100%;
    }

    &.hover-sm {
        .hover-button(28px);
    }

    &.hover-md {
        .hover-button(35px);
    }

    &.hover-lg {
        .hover-button(50px);
    }

    &.hover-xl {
        .hover-button(100px);
    }
}