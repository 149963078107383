@white-color: #fff;
@gray-color: #333;
@active-icon-color: #3897d8;
@light-border-color: #ededed;

#widget-builder-panel {

    > div.inner {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    div.slide-panel-body {
        height: 100%;

        @import '../tabs/widget.builder.data.tab.less';
        @import '../tabs/widget.builder.styles.tab.less';
        @import '../tabs/widget.builder.library.tab.less';

        div.inner {
            height: 100%;
        }

        div.panel-content {
            padding: 0;
            .calcHeight(192px);
            overflow-y: auto;
            overflow-x: hidden;
        }

        div.tab-content {
            > div.inner {
                .delayed-show-lr(0);
            }

            .options-panel {
                padding: 0 15px;

                div.option-panel-item {
                    .flex();
                    margin: 16px 0 16px 22px;
                }
            }
        }
    }

    div.widget-type-container {
        .flex();
        .flex-space-around();
        .align-items(stretch);
        line-height: 10px;
        border-bottom: 1px solid @light-border-color;

        div.widget-type {
            .opacity(50);
            padding: 15px 12px;
            min-width: 32px;

            &:hover {
                .opacity(100);
                cursor: pointer;
            }

            &.active {
                .opacity(100);
                color: @active-icon-color;
            }

            i.icon {
                font-size: 20px;
            }
        }
    }

    div.tab-content {
        height: 100%;
        min-height: 100%;

        div.tab-section {
            position: relative;
            border-bottom: 1px solid @light-border-color;
            padding: 15px;

            p.text {
                .bold();

                &.with-tooltip {
                    position: relative;

                    > i.icon {
                        position: absolute;
                        top: 4px;
                        right: 10px;
                    }
                }
            }

            div.btn-hover {
                position: absolute;
                top: 8px;
                right: 10px;
            }
        }
    }

    .icon.approximation {
        position: relative;
        width: 12px;
        height: 28px;
        content: url('../../../../../images/approximation.svg');
    }
}

// Select2 Overrides
div.select2-columns-dropdown {
    .select2-result-label {
        padding-left: 0;
    }

    .select2-result-sub {
        .select2-result-label {
            padding-left: 22px;
            padding-right: 0;
            position: relative;

            label {
                position: absolute;
                top: 0;
                left: 0;
                padding: 0;

                margin-bottom: 0;
                margin-right: 8px;
                width: 19px;
                height: 23px;
                line-height: 23px;
                font-size: 13px;
                .rounded(3px);
                color: @white-color;
                background-color: @gray-color;
            }
        }
    }
}