@import 'library.publish.less';

div.library-modal {

    /**
     * OVERRIDES
     */
    .select2-container-multi .select2-search-choice-close {
        top: 18px;
    }
    /**
     * END OF OVERRIDES
     */

    &.modal {
        overflow-y: hidden;
    }

    &.search-active {
        div.default-row {
            .zoomOutSmall();
        }
    }

    p.title {
        .bold();
        .fontSmoothing();
        font-size: 11px;
        margin-bottom: 4px;
        text-transform: uppercase;
        margin-left: 6px;
        border: 0;
        text-indent: 0;
    }

    div.library-header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 0 20px;
        height: 68px;
        .z-index();
        .flex();
        .align-items(center);

        h4 {
            display: inline-block;
            position: relative;
            top: -2px;
            margin-right: 5px;
        }

        div.sonar-button {
            margin-left: 8px;

            i.icon {
                .opacity(80);
            }

            &:hover {
                i.icon {
                    .opacity(100);
                }
            }
        }

        div.divider {
            display: inline-block;
            height: 40px;
            width: 1px;
            border-left: 1px dashed;
            margin: 0 10px 0 15px;
        }

        div.search-container {
            .flex();
            .align-items(center);
            position: relative;
            cursor: pointer;
            overflow: hidden;

            i.icon {
                font-size: 20px;
                .z-index();

                &.icomoon-cross {
                    cursor: pointer;
                }
            }

            input {
                box-sizing: border-box;
                display: block;
                position: relative;
                width: 200px;
                height: 40px;
                padding: 0 18px 0 10px;
                margin-bottom: 0;
                outline: 0;
                border: 0;
                border-radius: 0;
                background: 0 0;
                font-size: 14px;
                .no-shadow();
                .fontSmoothing();
            }

            div.search-box-container {
                .flex();
                .align-items(center);
                position: relative;

                &:hover {
                    div.select2-container {
                        max-height: 500px;
                    }
                }

                span.search-select2-container {
                    width: 100% !important;

                    div.select2-container {
                        max-height: 45px;
                        overflow: hidden;
                        .no-shadow();

                        ul.select2-choices {
                            border:0;
                        }

                        input {
                            font-size: 14px;
                            height: 40px;
                        }

                        li.select2-search-field {
                            min-width: 275px;
                        }

                        li.select2-search-choice {
                            margin-left: 5px;
                            line-height: 25px;
                            padding-left: 8px;
                            padding-right: 24px;
                            font-size: 12px;

                            .icon {
                                margin-right: 7px;

                                &.icon-tag {
                                    margin-right: 5px;
                                }

                                &.custom-icon {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }

        button.close {
            float: right;
            font-size: 24px;
        }
    }

    div.library-rows {
        overflow-y: auto;
        position: absolute;
        top: 74px;
        right: 0;
        left: 0;
        bottom: 0;
    }

    div.library-row {
        min-height: 400px;
        margin: 20px 0;
        .magictime;
        .transition(all, 0.5s, ease-in-out);

        .owl-stage-outer.owl-height {
            height: 435px;
        }

        .owl-carousel {
            .delayed-show-lr(0.45s);
        }

        h3 {
            .bold();
            font-size: 18px;
            text-indent: 50px;
            margin-bottom: 20px;
        }

        div.carousel-item {
            overflow: hidden;
            .transition(all, 0.3s);
            border: 3px solid transparent;

            div.inner {
                padding: 8px;
            }

            &.selected,
            &:hover {
                .opacity(100);
                cursor: pointer;
                .z-index(1);
            }

            p.text {
                .bold();
                margin-bottom: 3px;
                font-size: 13px;
            }

            small {
                display: block;
                font-size: 10px;
                margin-bottom: 12px;
                .opacity(70);
            }

            label.badge {
                position: absolute;
                top: 10px;
                right: 14px;
                font-size: 11px;
                padding: 2px 6px;
                .z-index(1000);
                .opacity(80);
            }

            div.content {
                margin-top: 10px;
                height: 380px;
                overflow: hidden;

                &.empty-thumbnail {
                    text-align: center;

                    img {
                        width: 80%;
                        display: inline-block;
                        margin-top: 20px;
                        .opacity(75);
                    }

                    b {
                        display: block;
                        font-size: 10px;
                        text-transform: uppercase;
                        .opacity(50);
                    }
                }
            }

            div.footer {
                max-height: 38px;
                border-top: 1px dashed;
                padding: 8px 6px;
                white-space: nowrap;
                overflow: hidden;

                i.icon {
                    font-size: 24px;
                    float: left;
                }

                span.text,
                label {
                    font-size: 11px;
                    float: left;
                    margin-top: 4px;
                    margin-left: 7px;
                }
            }
        }
    }

    div.library-detailed-item {
        display: block;
        .absolute();
        top: 0;
        .z-index();

        > div {
            height: 100%;
        }

        div.library-detailed-left {
            width: 130px;

            div.tag-container {
                .flex;
                .flex-column;
                .align-items(flex-start);
                width: 100%;
                text-align: left;
                margin-left: 15px;

                small {
                    margin-left: 12px;
                    margin-top: -5px;
                }

                div.tag-header {
                    margin-bottom: 10px;

                    i.icon {
                        position: relative;
                        top: 3px;
                        font-size: 18px;
                        margin-right: 4px;
                    }

                    span.title {
                        text-transform: uppercase;
                        font-size: 12px;
                    }
                }

                div.tag-item {
                    position: relative;
                    left: -110px;
                    width: 110px;
                    .ellipsis(110px);
                    .stagger(20);
                    .stagger(@n, @i: 1) when (@i =< @n) {
                        &:nth-child(@{i}) {
                            .animation-delay(200ms + (@i * 60));
                        }
                        .stagger(@n, (@i + 1));
                    }
                    label {
                        display: inline-block;
                        font-size: 11px;
                        margin: 7px 0;
                    }
                }
            }
        }

        div.library-detailed-center {
            width: 280px;
            min-width: 280px;
            overflow-y: scroll;

            div.description {
                white-space: pre-wrap;
                line-height: 22px;
                margin-right: 15px;
            }

            div.data-source-info {
                .flex;

                .data-source {
                    height: 24px;
                    width: 24px;
                    line-height: 24px;
                    margin-left: 4px;
                    .rounded(2px);

                    i.icon {
                        top: 2px;
                        font-size: 22px;
                    }

                    i.custom-icon {
                        top: 0;
                        font-size: 18px;
                    }
                }

                div.data-source-display {
                    display: block;
                    margin-left: 0;
                    font-size: 13px;

                    span {
                        display: block;
                        line-height: 24px;
                        margin-left: 7px;
                        .opacity(60);
                    }
                }
            }

            div.select-columns-container {
                margin-top: 20px;
                background: none !important;
                border: 0;
                width: auto;

                div.selected-column-container {
                    overflow-y: hidden;
                }

                div.column-item {
                    cursor: default;
                }
            }

            div.tiny-widget-container {
                .flex();
                position: relative;

                div.tiny-widget {
                    margin: 0;
                    padding: 0;
                    width: 260px;
                    height: 32px;
                    line-height: 30px;
                    margin-bottom: 10px;
                    cursor: default;

                    > div.inner {
                        .flex();
                        .align-items(center);

                        p.title {
                            .ellipsis(200px);
                            text-transform: none;
                        }

                        i.icon {
                            width: 24px;
                            font-size: 14px;

                            &[class*=" serviceicon-"] {
                                font-size: 20px;
                                margin-left: 4px;
                            }
                        }
                    }
                }
            }
        }

        div.library-detailed-right {
            position: relative;
            width: 100%;

            div.item-preview {
                padding: 10px 15px;
                width: 100%;

                p.header-title {
                    font-size: 16px;
                    margin-left: 12px;
                    margin-bottom: 10px;
                }

                library-connected-badge {
                    margin-top: -2px;
                }

                hr {
                    margin-top: 0;
                }

                p.title {
                    margin-top: 5px;
                    margin-left: 14px;
                }

                div.inner {
                    .absolute();
                    top: 68px;
                    left: 0;
                    bottom: 60px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    padding-top: 8px;

                    &.tab-pane {
                        top: 114px;
                    }
                }
            }
        }

        footer {
            bottom: 60px;
            left: 0;
            padding: 0;
            height: 60px;
            cursor: pointer;

            i.icon {
                position: relative;
                top: -7px;
                font-size: 32px;

                &.icomoon-download-cloud {
                    .verticalFloating();
                }
                &.icon-bolt {
                    .bigPulse(1.15, 1.2s, infinite);
                }
            }

            p {
                font-size: 14px;
                margin-left: 15px;
            }
        }
    }
}

.select2-template-search-dropdown {
    &.grouped-2-columns {
        .select2-results {
            > li.select2-result {
                width: 50%;
                float: left;
            }
        }
    }
    &.grouped-3-columns {
        .select2-results {
            > li.select2-result {
                width: 33%;
                float: left;
            }
        }
    }

    div.select2-result-label {

        .icon {
            margin-right: 7px;

            &.icon-tag {
                margin-right: 5px;
            }

            &.custom-icon {
                font-size: 12px;
            }
        }
    }
    .select2-results-dept-0.select2-result.select2-result-unselectable.select2-result-with-children .select2-result-label .icon {
        margin-right: 0;
    }
}