div#campaign-stats {
    margin-top:-8px;

    div.service-data-container {
        .rounded(4px);
    }

    div.panel-heading {

        .panel-title {
            text-indent: 0;

            span.icon {
                font-size:20px;
                float:left;
                margin-right: 8px;
                margin-top: -3px;
            }
        }
    }

    div.panel-body {
        padding:0;
    }

    div.service-square {
        .rounded(4px, 0, 0, 4px);

        div.icon {
            margin-top:0;
        }

        div.serviceicon-custom-icon {
            font-size:58px;
            margin:20px 0;
        }

        span.title {
            font-size:14px;
            white-space: normal;
            margin-top:-2px;
            display:block;
            padding:0 4px;
        }
    }

    h4 {
        margin-bottom:20px;
    }

    div.stat-detail {
        .clearfix();
    }

    hr.selection-line {
        position: relative;
        top: -35px;
    }

    div.period-legend-holder {
        margin-left:10px;
    }

    div.chart-container {
        margin-left:6px;
    }
}

div#service-metrics {
   min-height:131px;
}

div.stat-item {
    display:inline-block;
    min-width: 150px;

    span.value {
        font-size:32px;
    }

    span.changes {
        font-size:11px;
        position:relative;
        top:-7px;
        margin-left:3px;
    }

    div.stat-diff {
        margin-bottom:5px;
        margin-top:-3px;

        span.zero-color {
            //visibility: hidden;
        }
    }

    span.title {
        display:block;
        font-size:12px;
        margin-bottom:6px;
        text-transform: uppercase;
    }

}

div.chart-container {

    div.chart-filters {
        visibility: hidden;
        margin:8px 0 10px 0;
    }

    .dxc-legend {
        cursor:pointer;
    }
}

form#breakdown-form {
    padding:8px 0;

    span.title {
        margin-left:20px;
        display:inline-block;
        font-size:14px;
        text-align:right;
    }

    input {
        margin: 0 6px 0 18px;
    }
}

/* Custom steprep classes */
div#steprep-dashboard-container {
    display:none;

    div.panel {
        position:absolute;
        top:200px;
        bottom:0;
        left:30px;
        right:30px;

        div.panel-body {
            padding:0;
            position:absolute;
            top:50px;
            bottom:10px;
            left:10px;
            right:10px;
        }

        iframe {
            height:100%;
            border:0;
        }
    }
}

div.impressions-info {
    margin-left: 15px;
    margin-top: 5px;
    display: block;
    span {
        font-weight: bold;
    }
}