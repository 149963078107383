/* Effect Lay Down for design modals */
div.app-view {

    &.laydown {
        position: fixed;
        width: 100%;
        height: 100%;
        outline: 1px solid transparent;

        > div {
            width: 100%;
            height: 100%;
            min-height: 100%;
            position: absolute;
            overflow: hidden;
            .transition(transform, 0.6s);
            .transform-origin(50% 50%);
            .transform(scale(1) translateZ(0));
            .z-index();
        }
    }

    &.laydown.animated {
        > div {
            .transform(scale(0.75));
        }

        #right-frame {
            overflow: hidden;
        }
    }
}

div.design-modal {

    p.title {
        text-indent: 6px;
        border-left: 4px solid;
        .bold();
        font-size: 11px;
        margin-bottom: 4px;
        text-transform: uppercase;
        margin-left: 6px;
        .fontSmoothing();
    }

    div.modal-full {

        div.tooltip {
            overflow-y: visible;
        }
    }
    //
    // WIDGET PREVIEW PLACEHOLDER
    //
    div.creation-placeholder {
        position: absolute;
        // Can change widget type when one column selected and current instruction is 'Please select at least two columns'.
        // Since widgetType selection is disabled when no column is selected, this space does not need to be taken by creation-placeholder
        top: 60px;
        bottom: 1px;
        right: 1px;
        left: 300px;
        .opacity(50);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        div.text-container {

            i.icon {
                display: block;
                font-size: 200px;
            }

            p.large {
                font-size: 32px;
            }
        }
    }

    div.creation-placeholder:hover {

        div.text-container {

            p.large {
                //.animPulse();
            }
        }
    }

    // Footer (aligned bottom)
    div.footer-buttons {
        padding: 10px 0;
        text-align: center;
        align-self: flex-end;
        width: 100%;
        position: relative;

        div.btn {
            margin: 2px;
            padding: 6px 20px;
        }
    }
}
