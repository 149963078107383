div.data-tab-content {
    .not-allowed {
        cursor: not-allowed;
        opacity: 0.7;
        background: #dddddd;
    }
    .select2-container,
    .select2-container-multi {
        overflow: visible;
        max-height: none;

        .select2-choice,
        .select2-choices {
            border-color: fade(@gray-color, 10%) !important;

            .select2-search-choice {
                border: 0;
                background: transparent;
                color: @white-color;
                padding-right: 2px;
                margin: 0;

                div.select-data-column {
                    padding: 6px 20px 6px 8px;
                    width: 108px;
                    font-size: 11px;
                    line-height: 12px;
                    border: solid 1px;
                    .ellipsis(108px);
                    .rounded(12px);

                    span.icon {
                        margin-right: 6px;
                        position: relative;
                    }
                }
            }

            .select2-locked {
                padding: 4px 2px 2px 5px !important;
            }
        }

        .select2-search-choice-close {
            filter: brightness(0) invert(1);
            top: 17px;
            right: 8px;

            &:hover {
                filter: brightness(0) invert(0.8);
            }
        }
    }

    .user-conditional-columns-container {
        .condition-divider {
            margin-top: 10px;
        }
        .conditional-block {
            border: 2px solid #d3caca;
            padding: 2px;
            border-radius: 15px;
            margin-top: 3px;
        }

        .user-conditional-columns-heading {
            width: 100%;
            .add-new-condition-btn {
                padding-left: 10px;
                margin-bottom: 10px;
            }
        }

        .rule-error-message {
            margin-bottom: 0;
        }

        .user-conditional-columns-metric-block {
            width : 100%;
            .select-metric-block {
                width: 90%;
                .select2-container {
                    width: 100%;
                }
            }
            .remove-user-conditional-columns {
                width: 10%;
                margin-top: 5px;
                .btn-default {
                    color: #ffffff !important;
                    background-color: #585858 !important;
                }
            }
        }

        .user-conditional-columns-rules-block {
            width : 100%;
            margin-bottom :5px;
            .color-block {
                width: 10%;
                margin-top : -10px;
                .user-conditional-columns-color {
                    transform: translateY(25%);
                }
            }
            .rule-block {
                width: 80%;
                .rule-box {
                    width: 100%;
                    margin-bottom : 2px;
                    .condition-type {
                        width: 50%;
                        padding-right: 15px;
                    }

                    .condition-value {
                        width: 50%;
                    }

                    .condition-text {
                        width: 50%;
                        text-align: right;
                        padding-right: 15px;
                    }
                }
            }

            .add-rule-block {
                width:100%;
                cursor: pointer;
                line-height: 30px;
                border-radius: 5px;
                .btn-default {
                    width: 22px;
                    height: 22px;
                    line-height: 20px;
                    color: #ffffff !important;
                    background-color: #585858 !important;
                }
            }

            .remove-rule-block {
                width: 10%;
                .btn-default {
                    color: #ffffff !important;
                    background-color: #585858 !important;
                    width: 22px;
                    height: 22px;
                    line-height: 20px;
                }
            }
        }
    }

    .user-grouped-columns-container {
        .user-grouped-columns-input {
            width: 90%;
            .user-grouped-columns-color {
                transform: translateY(25%);
            }

            .user-grouped-columns {
                .select2-container {
                    width: 100%;
                }
            }
        }
        .user-grouped-columns-add,
        .user-grouped-columns-remove {
            width: 10%;
            margin-top: -50px;
            .btn-default {
                color: #ffffff !important;
                background-color: #585858 !important;
            }
        }


    }

    .user-grouped-columns-container,
    .user-conditional-columns-container, .embedded-sparkline-container {
        .select2-container-multi {
            margin-top: 10px;
            .select2-choices {
                border-color: #d9d9d9 !important;
                background-color: #fff;
                color: #555555;

                .select2-search-choice {
                    background: #f2f2f2;
                    color: #555555;
                    border: 1px solid #aaaaaa;
                    margin: 5px 0px 3px 2px !important;
                    padding: 4px 20px 2px 5px;
                }

                .select2-search-choice-close {
                    filter: none;
                    top: 13px;
                    right: 5px;
                }
            }
        }
    }

    .assignments-drop-down-single-select {
            .select2-search-choice-close {
                    filter: brightness(0) invert(0.5);
                    top: 17px;
                    right: 25px;
                }
        }

    widget-builder-columns-config {
        > div.inner {
            .delayed-show-lr(0);
            padding: 8px 5px;
        }

        div.select-columns-container,
        div.user-grouped-columns-container,
        div.user-conditional-columns-container {
            width: 100%;
            border: 0;
            overflow: hidden;

            div.selected-column-container {
                overflow: hidden;
            }

            div.selected-column-color,
            div.user-grouped-columns-color,
            div.user-conditional-columns-color {
                margin: 0 5px;
                .rounded(50%);
                border: 1px solid #fff;

                div.color-picker-input {
                    width: 18px;
                    height: 18px;
                }
            }

            div.btn-default {
                margin: 0 3px;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                text-align: center;
                text-indent: 0;
                padding: 0;
                line-height: 24px;

                .rotate {
                    transform: rotate(90deg);
                    display: inline-block;
                }

                i.icon {
                    margin: 0;
                }
            }

            div.btn-comparison-arrow {
                i.icon {
                    font-size: 20px;
                    width: 22px;
                    margin-top: 2px;
                }
            }

            div.btn-group {
                margin: 0 2px;
                float: right;

                span.btn {
                    height: 24px;
                    padding-top: 2px;

                    i.icon {
                        margin: -2px;
                        padding-top: 4px;
                        font-size: 14px;
                        position: relative;
                        top: 4px;
                    }
                }
            }

            div.column-item {
                padding-left: 34px;
                margin: 5px;
                display: inline-block;
                position: relative;
                border: 1px solid;
                .rounded(4px);
                height: 34px;
                line-height: 32px;
                cursor: move;
                width: 100%;

                label {
                    position: absolute;
                    padding: 0;
                    margin-bottom: 0;
                    width: 26px;
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                    top: 0;
                    left: 0;
                    .rounded(4px, 0, 0, 4px);
                }

                span.label-text {
                    display: inline-block;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                span.label {
                    margin: 0 2px;
                }

                span.sort-label {
                    font-size: 80%;
                }
            }
        }
    }
    .card-stack {
        position: relative;
        z-index: 1;
        .card {
            transition: all 100ms ease-in-out;
            border: 1px solid @light-border-color !important;
            border-radius: 2px;
            width: 330px;
            height: 160px;
            -webkit-box-shadow: 0 2px 15px 1px rgba(225, 225, 225, 0.5);
            box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.5);
        }
        .panel-body {
            padding: 10px;
            b {
                width: 260px;
                cursor: pointer;
            }
        }
    }
}

div.live-filters-container {

    div.select-columns-container {
        width: unset;
        border-right: unset;
        padding-top: 10px;

        div.column-item {

            div.form-horizontal {

                div.form-group {
                    display: flex;


                }
            }
        }
        div.filter-values-container {

        }
    }

    input.filter-input {
        width: 80px;
    }

    div.live-filter-button-right {
        justify-content: flex-end;
    }

    div.live-filter-footer-buttons {
       justify-content: space-between;
    }

    div.min-filter {
        margin-left: -15px;
    }

    div.filter-text-input {
        width: 185px;
    }

    div.btn-top-margin {
        margin-top: 13px !important;
    }

    div.btn-hover.inside {
        top: unset !important;
        right: 14px !important;
        bottom: 2px !important;
    }

    div.btn-hover.outside {
        top: unset !important;
        right: -20px !important;
        bottom: 2px !important;
    }


    div.input-remove {
        position: absolute;
        padding: 0;
        margin-bottom: 0;
        width: 26px;
        height: 36px;
        line-height: 32px;
        font-size: 14px;
        bottom: -1px;
        left: -5px;
        border: 1px solid;
        border-radius: 4px 0 0 4px;
        background-color: #dd4c3c;
        color: white;

    }

    label.filter-label {
    line-height: 3;
    }

    div.filter-connector {
        margin-top: 3px;
    }

    div.icomoon-cross.remove {
        color: red;
    }
}

.dimension-tooltip {
    float: right;
    margin-top: -25px;
}
