
/*
 *  Usage:
 *
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
 *
 */
.sk-three-bounce {
    margin: 40px auto;
    width: 80px;
    text-align: center;
}
.sk-three-bounce .sk-child {
    width: 20px;
    height: 20px;
    background-color: #333;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
    animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
    transform: translateZ(0);
}

.sk-three-bounce .sk-bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.sk-three-bounce .sk-bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-three-bounce {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-three-bounce {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

//
// SPIN LOADER (define width + height where used)
//
.spin-loader {
    border: solid;
    .transform(translateZ(0));
    .rotate(1.1s);
}

.spin-loader,
.spin-loader:after {
    .rounded(50%);
}

button > .spin-loader {
    margin-left:auto;
    margin-right:auto;
}

loader {
    position: relative;
    height: 100%;
    .loader-container {
        display: inline-block;
        height: 100%;
        .loader-inner {
            height: 100%;
        }
    }
}