#report-studio-icon-modal {

    div.modal-dialog {
    }

    div.modal-body {
        height:50vh;
        overflow-y:auto;
        padding: 10px;
    }

    div.icon-container {
        display: inline-block;
        width: 60px;
        height: 60px;
        line-height: 56px;
        font-size: 32px;
        text-align: center;
        border: 1px dashed;
        .rounded(5px);
        margin: 10px;
    }

    div.icon-container.selected {
        cursor:default;
    }

    div.icon-container:hover {
        cursor:pointer;
    }
}