#export-builder-format-modal {


  .modal-content {
    border: 0px !important;
    box-shadow: none !important;
  }

  /** Popup Styles **/

  .bg-transparent {
    background: rgba(0, 0, 0, 0.49);
  }
  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: none;
  }
  .display-block {
    display: block !important;
  }



  .form.border select {
    border: 1px solid #ddd;
    background: #fff;
    padding: 10px;
    font-size: 13px;
  }

  .mr-lr-10 {
    margin: 0px 10px;
  }
  .send-to-css-content.border {
    border: 1px solid #ddd;
    height: 250px;
    border-radius: 4px;
  }
  .modal-dialog.modal-md.modal-confirm {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .modal-confirm .edit-page-canvas.modal-content {
    max-width: 500px;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 180px;
  }
  .modal-confirm .edit-page-canvas.modal-content p{
    margin-bottom:25px;
    font-size: 13px;
  }
  .mr-right-20 {
    margin-right: 20px;
  }

  .btn-secondary {
    border: 1px solid #3498db;
    color: #3498db !important;
  }

  .btn-secondary:hover {
    opacity: .8;
  }

  a.btn {
    font-size: 13px;
    line-height: normal;
  }
}