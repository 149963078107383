/*--------------------------------

icomoon Web Font
Generated using nucleoapp.com

-------------------------------- */
@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot');
  src: url('../fonts/icomoon.eot') format('embedded-opentype'), url('../fonts/icomoon.woff2') format('woff2'), url('../fonts/icomoon.woff') format('woff'), url('../fonts/icomoon.ttf') format('truetype'), url('../fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*------------------------
	base class definition
-------------------------*/
[class^="icomoon-"], [class*=" icomoon-"] {
  display: inline-block;
  font: normal normal normal 1em/1 'icomoon';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
/* relative units */
.icomoon-sm {
  font-size: 0.8em;
}
.icomoon-lg {
  font-size: 1.2em;
}
/* absolute units */
.icomoon-16 {
  font-size: 16px;
}
.icomoon-32 {
  font-size: 32px;
}
/*----------------------------------
  add a square/circle background
-----------------------------------*/
.icomoon-bg-square,
.icomoon-bg-circle {
  padding: 0.35em;
  background-color: #eee;
}
.icomoon-bg-circle {
  border-radius: 50%;
}
/*------------------------------------
  use icons as list item markers
-------------------------------------*/
.icomoon-ul {
  padding-left: 0;
  list-style-type: none;
}
.icomoon-ul > li {
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
}
.icomoon-ul > li > .icomoon {
  margin-right: 0.4em;
  line-height: inherit;
}
/*------------------------
  spinning icons
-------------------------*/
.icomoon-is-spinning {
  -webkit-animation: icomoon-spin 2s infinite linear;
  -moz-animation: icomoon-spin 2s infinite linear;
  animation: icomoon-spin 2s infinite linear;
}
@-webkit-keyframes icomoon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes icomoon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes icomoon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.icomoon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.icomoon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.icomoon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.icomoon-flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.icomoon-flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}
/*------------------------
	icons
-------------------------*/

.icomoon-dashboard::before {
  content: "\ea02";
}

.icomoon-navigation-right::before {
  content: "\ea03";
}

.icomoon-navigation-left::before {
  content: "\ea04";
}

.icomoon-navigation-right-bold::before {
  content: "\ea05";
}

.icomoon-navigation-left-bold::before {
  content: "\ea06";
}

.icomoon-zoom-out-1::before {
  content: "\ea07";
}

.icomoon-zoom-in-1::before {
  content: "\ea08";
}

.icomoon-rotate-forward::before {
  content: "\ea09";
}

.icomoon-synchronize-arrows-1::before {
  content: "\ea0a";
}

.icomoon-delete-2::before {
  content: "\ea0b";
}

.icomoon-move-back::before {
  content: "\ea0d";
}

.icomoon-close-quote::before {
  content: "\ea0e";
}

.icomoon-scale-vertical::before {
  content: "\ea0f";
}

.icomoon-text-style::before {
  content: "\ea10";
}

.icomoon-scale-horizontal::before {
  content: "\ea11";
}

.icomoon-small-caps::before {
  content: "\ea12";
}

.icomoon-fullscreen::before {
  content: "\ea13";
}

.icomoon-rotate-vertical::before {
  content: "\ea14";
}

.icomoon-layout-1::before {
  content: "\ea15";
}

.icomoon-grid-artboard::before {
  content: "\ea16";
}

.icomoon-add-section::before {
  content: "\ea17";
}

.icomoon-admin-widget::before {
  content: "\ea18";
}

.icomoon-arrow-down::before {
  content: "\ea19";
}

.icomoon-arrow-left::before {
  content: "\ea1a";
}

.icomoon-arrow-right::before {
  content: "\ea1b";
}

.icomoon-arrow-up::before {
  content: "\ea1c";
}

.icomoon-arrows::before {
  content: "\ea1d";
}

.icomoon-back::before {
  content: "\ea1e";
}

.icomoon-bar-chart::before {
  content: "\ea1f";
}

.icomoon-barchart::before {
  content: "\ea20";
}

.icomoon-big-number::before {
  content: "\ea21";
}

.icomoon-blank-dashboard::before {
  content: "\ea22";
}

.icomoon-branch::before {
  content: "\ea23";
}

.icomoon-bring-forward::before {
  content: "\ea24";
}

.icomoon-add-widget::before {
  content: "\ea25";
}

.icomoon-caret-down::before {
  content: "\ea26";
}

.icomoon-caret-left::before {
  content: "\ea27";
}

.icomoon-add-widget2::before {
  content: "\ea28";
}

.icomoon-caret-right::before {
  content: "\ea29";
}

.icomoon-check::before {
  content: "\ea2a";
}

.icomoon-clock::before {
  content: "\ea2b";
}

.icomoon-close-full::before {
  content: "\ea2c";
}

.icomoon-coffee::before {
  content: "\ea2d";
}

.icomoon-collapse-menu::before {
  content: "\ea2e";
}

.icomoon-combinationchart::before {
  content: "\ea2f";
}

.icomoon-combo::before {
  content: "\ea30";
}

.icomoon-caret-up::before {
  content: "\ea31";
}

.icomoon-copy-widget::before {
  content: "\ea32";
}

.icomoon-copy::before {
  content: "\ea33";
}

.icomoon-cross::before {
  content: "\ea34";
}

.icomoon-dashboard-settings::before {
  content: "\ea35";
}

.icomoon-data-widget::before {
  content: "\ea36";
}

.icomoon-datagrid::before {
  content: "\ea37";
}

.icomoon-download-cloud::before {
  content: "\ea38";
}

.icomoon-download::before {
  content: "\ea39";
}

.icomoon-edit::before {
  content: "\ea3a";
}

.icomoon-ellipsis-h::before {
  content: "\ea3b";
}

.icomoon-ellipsis-v::before {
  content: "\ea3c";
}

.icomoon-bring-to-front::before {
  content: "\ea3d";
}

.icomoon-excel::before {
  content: "\ea3e";
}

.icomoon-connect::before {
  content: "\ea3f";
}

.icomoon-export::before {
  content: "\ea40";
}

.icomoon-files::before {
  content: "\ea41";
}

.icomoon-filter-list::before {
  content: "\ea42";
}

.icomoon-filter::before {
  content: "\ea43";
}

.icomoon-forward::before {
  content: "\ea44";
}

.icomoon-funnelchart::before {
  content: "\ea45";
}

.icomoon-gauge::before {
  content: "\ea46";
}

.icomoon-gaugechart::before {
  content: "\ea47";
}

.icomoon-globe::before {
  content: "\ea48";
}

.icomoon-group::before {
  content: "\ea49";
}

.icomoon-groupby::before {
  content: "\ea4a";
}

.icomoon-happy-page::before {
  content: "\ea4b";
}

.icomoon-happy-window::before {
  content: "\ea4c";
}

.icomoon-hash::before {
  content: "\ea4d";
}

.icomoon-help::before {
  content: "\ea4e";
}

.icomoon-image::before {
  content: "\ea4f";
}

.icomoon-jira::before {
  content: "\ea50";
}

.icomoon-key::before {
  content: "\ea51";
}

.icomoon-layout-2::before {
  content: "\ea52";
}

.icomoon-line-chart::before {
  content: "\ea53";
}

.icomoon-linechart::before {
  content: "\ea54";
}

.icomoon-lock::before {
  content: "\ea55";
}

.icomoon-logout::before {
  content: "\ea56";
}

.icomoon-manage-sections::before {
  content: "\ea57";
}

.icomoon-media-widget::before {
  content: "\ea58";
}

.icomoon-minus::before {
  content: "\ea59";
}

.icomoon-numbered-list::before {
  content: "\ea5a";
}

.icomoon-page-settings::before {
  content: "\ea5b";
}

.icomoon-paper-check::before {
  content: "\ea5c";
}

.icomoon-paragraph::before {
  content: "\ea5d";
}

.icomoon-pdf::before {
  content: "\ea5e";
}

.icomoon-pen-paper::before {
  content: "\ea5f";
}

.icomoon-pen-ruler::before {
  content: "\ea60";
}

.icomoon-pie-chart::before {
  content: "\ea61";
}

.icomoon-piechart::before {
  content: "\ea62";
}

.icomoon-plug::before {
  content: "\ea63";
}

.icomoon-plus-full::before {
  content: "\ea64";
}

.icomoon-plus::before {
  content: "\ea65";
}

.icomoon-powerpoint::before {
  content: "\ea66";
}

.icomoon-preview-file::before {
  content: "\ea67";
}

.icomoon-profile::before {
  content: "\ea68";
}

.icomoon-refresh::before {
  content: "\ea69";
}

.icomoon-report-full::before {
  content: "\ea6a";
}

.icomoon-report::before {
  content: "\ea6b";
}

.icomoon-resize-widget::before {
  content: "\ea6c";
}

.icomoon-resize::before {
  content: "\ea6d";
}

.icomoon-role::before {
  content: "\ea6e";
}

.icomoon-sad-page::before {
  content: "\ea6f";
}

.icomoon-sad-window::before {
  content: "\ea70";
}

.icomoon-send-to-back::before {
  content: "\ea72";
}

.icomoon-settings::before {
  content: "\ea73";
}

.icomoon-share::before {
  content: "\ea74";
}

.icomoon-sliders::before {
  content: "\ea75";
}

.icomoon-sort::before {
  content: "\ea76";
}

.icomoon-email::before {
  content: "\ea77";
}

.icomoon-table::before {
  content: "\ea78";
}

.icomoon-scheduled-report::before {
  content: "\ea79";
}

.icomoon-trash::before {
  content: "\ea7a";
}

.icomoon-unplug::before {
  content: "\ea7b";
}

.icomoon-section::before {
  content: "\ea7c";
}

.icomoon-tag::before {
  content: "\ea7d";
}

.icomoon-search::before {
  content: "\ea7e";
}

.icomoon-upload-cloud::before {
  content: "\ea7f";
}

.icomoon-upload::before {
  content: "\ea80";
}

.icomoon-user-logo::before {
  content: "\ea81";
}

.icomoon-user-settings::before {
  content: "\ea82";
}

.icomoon-exchange::before {
  content: "\ea83";
}

.icomoon-send-backwards::before {
  content: "\ea84";
}

.icomoon-swap::before {
  content: "\ea85";
}

.icomoon-user-widget::before {
  content: "\ea86";
}

.icomoon-warning::before {
  content: "\ea87";
}

.icomoon-widget-library::before {
  content: "\ea88";
}

.icomoon-word-doc::before {
  content: "\ea89";
}

.icomoon-view-1::before {
  content: "\ea8a";
}

.icomoon-view-off::before {
  content: "\ea8b";
}

.icomoon-hyperlink::before {
  content: "\ea8c";
}

.icomoon-piechart-bold::before {
  content: "\ea8d";
}

.icomoon-funnelchart-bold::before {
  content: "\ea8e";
}

.icomoon-combinationchart-bold::before {
  content: "\ea8f";
}

.icomoon-barchart-bold::before {
  content: "\ea90";
}

.icomoon-image-bold::before {
  content: "\ea91";
}

.icomoon-big-number-bold::before {
  content: "\ea93";
}

.icomoon-linechart-bold::before {
  content: "\ea94";
}

.icomoon-datagrid-bold::before {
  content: "\ea95";
}

.icomoon-page-format::before {
  content: "\ea97";
}

.icomoon-layers-4::before {
  content: "\ea98";
}

.icomoon-remove-circle-bold::before {
  content: "\ea99";
}

.icomoon-layers-5::before {
  content: "\ea9a";
}

.icomoon-layers-6::before {
  content: "\ea9b";
}

.icomoon-layers::before {
  content: "\ea9c";
}

.icomoon-left-2::before {
  content: "\ea9d";
}

.icomoon-right-2::before {
  content: "\ea9e";
}

.icomoon-keyboard::before {
  content: "\eaa1";
}

.icomoon-paragraph-center-align::before {
  content: "\eaa3";
}

.icomoon-paragraph-justified-align::before {
  content: "\eaa4";
}

.icomoon-paragraph-left-align::before {
  content: "\eaa5";
}

.icomoon-paragraph-right-align::before {
  content: "\eaa6";
}

.icomoon-shapes::before {
  content: "\eaa7";
}

.icomoon-cursor-select-1::before {
  content: "\eaa8";
}

.icomoon-flip-vertical::before {
  content: "\eaa9";
}

.icomoon-flip-horizontal::before {
  content: "\eaaa";
}

.icomoon-font-size::before {
  content: "\eaab";
}

.icomoon-font-expand-vertical::before {
  content: "\eaac";
}

.icomoon-align-bottom::before {
  content: "\eaad";
}

.icomoon-align-middle-move-vertical::before {
  content: "\eaae";
}

.icomoon-align-top::before {
  content: "\eaaf";
}

.icomoon-text-bold::before {
  content: "\eab0";
}

.icomoon-text-italic::before {
  content: "\eab1";
}

.icomoon-text-strike-through::before {
  content: "\eab2";
}

.icomoon-text-underline::before {
  content: "\eab3";
}

.icomoon-search-alternate::before {
  content: "\eab4";
}

.icomoon-brush-pen::before {
  content: "\eab5";
}

.icomoon-presentation-projector-screen-play::before {
  content: "\eab6";
}

.icomoon-share-1::before {
  content: "\eab7";
}

.icomoon-time-clock-circle-1-alternate::before {
  content: "\eaba";
}

.icomoon-time-clock-circle-alternate::before {
  content: "\eabb";
}

.icomoon-zoom-in-page::before {
  content: "\eabd";
}

.icomoon-save-disk::before {
  content: "\eabe";
}

.icomoon-add::before {
  content: "\eabf";
}

.icomoon-add-thin::before {
  content: "\eac0";
}

.icomoon-question-help::before {
  content: "\eac1";
}

.icomoon-location-target-hollow::before {
  content: "\eac2";
}

.icomoon-location-target::before {
  content: "\eac3";
}

.icomoon-help-hollow::before {
  content: "\eac4";
}

.icomoon-grid-monitor::before {
  content: "\eac5";
}

.icomoon-geochart::before {
  content: "\eac6";
}

.icomoon-geochart-bold::before {
  content: "\eac7";
}

.icomoon-next-page-icon::before {
  content: "\eac8";
}

.icomoon-previous-page-icon::before {
  content: "\eac9";
}

.icomoon-redo::before {
  content: "\eaca";
}

.icomoon-undo::before {
  content: "\eacb";
}

.icomoon-gaugechart-bold::before {
  content: "\eacc";
}

.icomoon-loading::before {
  content: "\eacd";
}

