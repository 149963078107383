export-builder-item-macro-factory {
    height: 100%;
    width: 100%;

    .display-inline {
        display: inline;
    }

    .display-inline-bold {
        display: inline;
        font-weight: bold;
    }

    .arial-bold {
        font-family: 'Arial Bold';
    }

    .century-gothic-bold {
        font-family: 'Century Gothic Bold';
    }

    .comic-sans-ms-bold {
        font-family: 'Comic Sans MS Bold';
    }

    .courier-new-bold {
        font-family: 'Courier New Bold';
    }

    .helvetica-bold {
        font-family: 'Helvetica Bold';
    }

    .helvetica-neue-bold {
        font-family: 'Helvetica Neue Bold';
    }

    .impact-bold {
        font-family: 'Impact Bold';
    }

    .lucida-grande-bold {
        font-family: 'Lucida Grande Bold';
    }

    .roboto-bold {
        font-family: 'Roboto Bold';
    }

    .tahoma-bold {
        font-family: 'Tahoma Bold';
    }

    .times-new-roman-bold {
        font-family: 'Times New Roman Bold';
    }

    .verdana-bold {
        font-family: 'Verdana Bold';
    }

    .playfair-display-bold {
        font-family: 'Playfair Display Bold';
    }
}

export-builder-item-macro-company-logo {
    width: 100%;
    height: 100%
}

export-builder-item-macro-date-range {
    div {
        display: inline;
    }
}
