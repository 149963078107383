
@import "../report/export.report.less";
@import "../options/export.options.less";

@import '../fonts/export.fonts.css';

//
// DESIGN EXPORT (Applies to ALL export formats)
//
body.exporting {
    //Letter page ratio
    @page-ratio: 110/85;
    // for wkhtmltopdf, makes 1584 pixels (1592 width - 8 padding), which is divisible by 12 (for isotope 12x14)
    @page-width: 1592px;
    @page-height: 1230px;

    overflow: auto;

    &.landscape {
        width: @page-width;
    }

    &.portrait {
        width: @page-height;
    }

    &.print {
        background-color: transparent !important;

        .pdf-page {
            background-color: #fff !important;
        }
    }

    #loading-bar,
    #loading-bar-spinner {
        display: none;
    }

    div.design-page {
        padding: 0;

        div#executive-summary-export {

            .executive-summary-inner {
                min-height: 150px;
                break-after: page;
            }
        }

        .es-break-before {
            break-before: page;
            padding-top: 20px;
        }

        .pdf-page-span-trick {
            color: white;
            background: white;
            opacity: 0;
        }

        div.page-inner {
            margin: 0;

            div.tab-content {
                div.tab-pane {
                    min-height: 0;
                    &:not(:last-child) {
                        break-after: page;
                    }
                    &.empty-pane {
                        break-after: avoid !important;
                    }
                }
            }
        }

        div.widget-inner {
            //border-width: 2px;
            overflow: hidden;

            div.widget-header {
                border: 0;
            }

            .widget-content {
                padding: 5px;
            }
        }

        div.widget-page-header {
            width: 100%;
            white-space: nowrap;
            height: 80px;
            margin-bottom: 5px;
            padding: 0 12px;
            position: relative;

            &.small {
                height: 50px;
                margin-top: 7px;
                margin-bottom: 7px;
            }

            img.logo {
                float: left;
                max-height: 70px;
            }

            p.title {
                .center-element();
                margin: 0;
                border-left-width: 3px;
                border-right: 3px solid;
                padding-right: 8px;
                font-size: 18px;
                text-indent: 8px;
                height: 18px;
                line-height: 16px;
            }
        }
    }

    .pdf-page {
        padding: 12px 8px 12px 0;
        //padding: 12px 4px;
        &.landscape {
            height: @page-height - 6;
        }
        &.summary-grids {
            height: auto !important;
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
        }

        &.portrait {
            height: @page-width - 16; // Make up for excess padding
        }
    }

    .widget-display {
        padding: 0;
        pointer-events: none;
        overflow-y: hidden;
    }

    // Fix the issue that wkhtmltopdf doesn't support flex syntax
    .widget-box-horizontal {
        .old-flex(horizontal);
    }

    .widget-box-vertical {
        .old-flex(vertical);
    }

    div.chart-widget-container {
        .old-flex-size(1);
        width: 100%;
        height: 100%;
    }

    media-widget {
        padding: 0px;

        div.media-widget {
            padding: 5px;
        }
    }

    executive-summary-display {
        div.owl-carousel-content {
            display: block;
            width: 100%;
        }

        div.executive-summary-inner {
            owl-carousel {
                div.info-container {
                    margin-bottom: 0 !important;
                }
            }
        }
    }

    owl-carousel {

        div.owl-carousel {
            padding: 0 30px !important;
        }
    }

    //
    // DATAGRIDS
    //
    div.freeflow-widget {
        div.design-widget {
            padding-bottom: 0 !important;
        }
        div.widget-content {
            height: auto !important;
        }
        .design-widget {
            &.multiple-page-span {
                break-after: page;
                .widget-inner {
                    border: none !important;
                }
            }
            &.final-widget {
                break-after: avoid !important;
            }
        }
    }

    div.design-widget {
        &.paginated-filler {
            div {
                display: flex;
                justify-content: center;
                align-content: center;
                flex-direction: column;
                border: 1px solid #ebebeb;
                border-radius: 10px;
                padding: 5px;
                width: 100%;

                p {
                    text-align: center;
                    color: rgb(115, 124, 132);
                }
            }
        }
    }

    div.clipped-notify-container {
        position: absolute;
        top: 17px;
        right: 20px;
        .z-index(1);
        white-space: nowrap;
    }

    .datagrid-display {
        table.dataTable {
            margin-top: 0 !important;
        }

        table.show-gridlines {
            border-collapse: collapse;
            border: 1px solid #e5e5e5;
            th, td, thead.total-row,
            tfoot.total-row {
                border: 1px solid #e5e5e5 !important;
            }
        }

        table.show-gridlines-dark {
            border-collapse: collapse;
            border: 1px solid #333333;
            th, td, thead.total-row,
            tfoot.total-row {
                border: 1px solid #333333 !important;
            }
        }

        table.datagrid-default-type {
            thead {
                tr, th {
                    color: revert;
                }
            }
        }

        div.datagrid-widget-container {
            overflow: hidden;

            div.dataTables_wrapper > div {
                display: none;
            }

            table {

                th, tr, td {
                    page-break-inside: avoid !important;
                }

                td {
                    white-space: normal;
                    word-wrap: break-word;

                    p.value-container {
                        white-space: nowrap;
                    }
                }

                thead.total-row,
                tfoot.total-row {
                    th {
                        b span.icon {
                            display: none;
                        }
                        border: none;
                    }
                }
            }
        }
    }

    table.dataTable thead .sorting_desc,
    table.dataTable thead .sorting {
        background: none;

        &:after {
            display: none;
        }
    }

    table.dataTable thead .sorting_desc:after,
    table.dataTable thead .sorting_asc:after {
        .opacity(100);
    }

    nav.widget-options,
    span.widget-interaction-container {
        display: none !important;
    }

    // AMCHARTS
    div.amcharts-chart-div {

        path.amcharts-bg,
        path.amcharts-axis-line,
        path.amcharts-axis-band,
        path.amcharts-axis-tick-minor,
        path.amcharts-axis-tick,
        path.amcharts-pie-slice,
        path.amcharts-funnel-slice,
        text.amcharts-funnel-label,
        circle.amcharts-graph-bullet,
        path.amcharts-plot-area,
        path.amcharts-plot-area-left,
        path.amcharts-plot-area-bottom,
        path.amcharts-plot-area-right {
            stroke: none;
        }

        path.amcharts-axis-grid {
            stroke-opacity: 1 !important;
        }

        // Geo chart control buttons
        .amcharts-zoom-control {
            display: none;
        }

        // Remove strokes between annotations
        g.amcharts-graph-annotation {
            path.amcharts-graph-stroke {
                stroke: none;
            }
        }
    }

    div.amcharts-legend-div {
        max-height: 50%;
        overflow-y: hidden !important;

        path.amcharts-legend-bg,
        circle.amcharts-graph-bullet {
            stroke: none;
        }
    }

    // ACCOUNT MANAGER
    account-manager-widget {
        div.column-display {
            div.accountmanager-text {
                .old-flex(horizontal);
                .old-flex-size(1);
            }
        }
    }

    // WIDGET LOADED
    div.widget-loaded-container {
        .old-flex(horizontal);
        .old-flex-size(1);

        div.loaded-panel {
            .old-flex(horizontal);
            .old-flex-size(1);
            position: absolute;
            left: 50%;
            top: 50%;
            .transform(translateX(-50%) translateY(-50%));
        }

        div.warning-panel {

            p.text {
                position: relative;
                top: 3px;
            }
        }
    }

    //
    // SINGLE WIDGET EXPORT
    //
    &.exporting-widget {

        background: none !important;
        width: 1600px;

        div.design-page {

            background: none !important;
            padding: 0 !important;
            margin: 0;

            div.tab-content {
                &,
                & div.tab-pane {
                    background: none !important;
                }

                div.design-widget {
                    padding: 0;
                }
            }
        }

        /* Hide page header when exporting single widget */
        div.widget-page-header {
            display: none !important;
        }

        // Remove padding for image/powerpoint
        div.pdf-page {
            padding: 0 !important;
        }

        // Remove padding for image/powerpoint
        div.design-widget {
            div.widget-inner {
                border: 0 !important;
            }
        }
    }

    //
    // HTML EXPORT  - DISPLAY MODE
    //
    &.exporting-html {
        overflow-x: hidden;
        margin: 0 10px;

        &.print {
            background-color: #fff !important;
        }

        div.design-page {
            .transform-origin(0 0);
            margin-bottom: 15px;

            div.tab-content div.tab-pane {
                background: transparent !important;

                div.element-placeholder {
                    top: -180px !important;
                }
            }
        }

        .pdf-page {
            .shadow(0px, 0px, 15px, 0.4);
            margin-top: 20px;
            margin-bottom: 15px;
        }

        div.page-break-notice {
            text-align: center;
            text-transform: uppercase;
            border-top: 1px solid;
            position: relative;
            height: 40px;
            margin-top: 60px;

            span {
                .center-element();
                top: auto;
                font-size: 14px;
                display: inline-block;
                padding: 0 20px;
                background: white;
            }
        }
    }

    export-dash-thumbnail {
        export-dash-header,
        div.page-break-notice {
            display: none;
        }

        .pdf-page {
            .no-shadow();
            margin: 0 !important;
            padding: 0 4px !important;
            height: auto !important;
        }
    }
    
    big-number-widget {
        .bignumber-margin {
            margin: auto;
        }
    }
}

body.wkhtmltopdf { // wkhtml and puppeteer render differently, hence this is necessary
    &.exporting {
        .pdf-page {
            padding: 12px 4px;

            &.landscape {
                height: 1230px;
            }

            &.summary-grids {
                height: auto !important;
            }
        }
    }
}
