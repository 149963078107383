//
// COLUMN DATA CONFIG
//
div.select-columns-container {
    .flex-display;
    .flex-column;
    position: relative;
    width: 301px;
    border-right: 1px solid;

    //
    // DATASOURCE DISPLAY
    //
    div.display-data-source-container {
        margin-bottom: 8px;

        > div {
            //+1 px for the border of widget-type-list-container
            width: 301px;
            height: 61px;
        }

        i.icon {
            font-size: 26px;
            position: absolute;
            top: 15px;
            left: 14px;

            &[class*="serviceicon-"] {
                font-size: 34px;
                top: 11px;
                left: 8px;
            }

            &.custom-icon {
                top: 9px;
                left: 14px;
            }

            &.generic {
                font-size: 36px;
                left: 7px;
            }
        }

        span {
            display: block;
            margin-left: 54px;
            padding-top: 9px;
            font-size: 14px;
            .fontSmoothing();
        }

        span.small {
            padding-top: 3px;
            font-size: 10px;
            text-transform: uppercase;
            .opacity(70);
        }

        span.single {
            line-height: 58px;
            font-size: 14px;
            padding: 0;
        }
    }

    span.axis-label {
        position: absolute;
        top: 0;
        right: 6px;
        font-size: 80%;
    }

    div.select2-container {
        min-width: 290px;
        margin: 5px;
    }

    div.selected-column-container {
        .flex-full;
        .flex-column;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0;
        padding-bottom: 5px;

        div.time-grouping-options-panel {
            margin-top: 4px;
            width: 122px;

            select {
                height: 34px;
            }
        }

        div.select-line {
            position: absolute;
            top: -15px;
            left: 0;
            right: 0;
            height: 4px;
            .transition(transform, 0.5s, ease-in-out);

            span.select-line-arrow {
                position: absolute;
                top: -8px;
                left: 50%;
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid;
            }

            &.is-drilling {
                .transform(translateX(100%));
            }
        }
    }

    div.column-item {
        padding-left: 34px;
        margin: 4px 5px;
        position: relative;
        border: 1px solid;
        .rounded(4px);
        height: 34px;
        line-height: 32px;
        cursor: move;

        &.toggle-combinationchart {
            margin-right: 85px;
            span.label-text {
                width: 100px;
            }

            &.hide-combo-buttons {
                margin-right: 35px;

                &.multi-sort {
                    margin-right: 55px;

                    .toggle-column-type {
                        right: -50px;
                    }

                    span.label-text {
                        width: 185px;
                    }
                }

                .toggle-column-type {
                    right: -30px;
                }

                span.label-text {
                    width: 205px;
                }
            }

            &.toggle-y-axis {
                margin-right: 105px;

                .toggle-column-type {
                    right: -100px;
                }

                span.label-text {
                    width: 130px;
                }
            }

            div.btn-group {
                span.btn, span.btn-default {
                    &:not(.active) {
                        padding-top: 10px;
                    }
                }
            }

            &.multi-sort {
                margin-right: 104px;

                &.toggle-y-axis {
                    margin-right: 124px;

                    .toggle-column-type {
                        right: -118px;
                    }

                    span.label-text {
                        width: 110px;
                    }
                }

                .toggle-column-type {
                    right: -98px;
                }

                span.label-text {
                    width: 130px;
                }
            }
        }

        &.toggle-datagrid,
        &.toggle-barchart,
        &.toggle-linechart {
            margin-right: 35px;

            &.multi-sort {
                margin-right: 58px;

                &.toggle-y-axis {
                    margin-right: 80px;

                    span.label-text {
                        width: 145px;
                    }
                }

                span.label-text {
                    width: 180px;
                }
            }

            &.toggle-y-axis {
                margin-right: 60px;

                span.label-text {
                    width: 180px;
                }
            }

            span.label-text {
                width: 100px;
            }
        }

        &.toggle-datagrid,
        &.toggle-bignumber {
            span.label-text {
                width: 175px;
            }
        }
        label,
        div.column-remove {
            position: absolute;
            padding: 0;
            margin-bottom: 0;
            width: 26px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            top: 0;
            left: 0;
            .rounded(4px, 0, 0, 4px);
        }

        div.column-remove {
            cursor: pointer;
            .opacity(0);
            .transition(opacity, 0.3s);
            font-size: 11px;
            text-indent: 4px;

            &.disabled {
                cursor: default;
            }
        }

        div.selected-column-color {
            position: absolute;
            top: 7px;
            right: 7px;
            width: 18px;
            height: 18px;
            .rounded(50%);
            border: 1px solid #fff;

            div.color-picker-input {
                width: 100%;
                height: 100%;
                border: 0;
            }
        }

        div.toggle-column {
            top: -1.5px;
            right: -53px;
            position: absolute;

            &.toggle-column-type {
                right: -80px;
            }

            &.toggle-column-sort {
                right: -30px;

                &.disabled {
                    cursor: not-allowed;
                    span {
                        pointer-events: none;
                        cursor: not-allowed;
                    }
                }

                .icon.icon-exchange {
                    position: relative;
                    top: 3px;
                }

                &.multi-sort {
                    right: -53px;

                    &.toggle-y-axis {
                        right: -75px;
                    }
                }

                &.toggle-y-axis {
                    right: -55px;
                }
            }



            span.btn, span.btn-default {
                padding: 7px 2px;
                height: 34px;

                .rotate {
                    transform: rotate(90deg);
                    display: block;
                }

                &#group-by-toggle {
                    border-radius: 0 4px 4px 0;
                }

                i.icon {
                    margin: 0 3px;
                }
            }
        }

        span.axis-label {
            position: absolute;
            top: 9px;
            right: 7px;
            font-size: 80%;
        }

        span.label-text {
            display: inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        span.sort-label {
            font-size: 80%;
        }

        &:hover {
            div.column-remove {
                .opacity(100);
            }
        }
    }

    div.grouped-column-container {
        min-height: 180px;
        margin-top: 5px;

        &.multi-select {
            height: 300px;
        }

        div.default-grouped-container {
            .flex-full;
            .flex-column;
        }

        div.grouped-selection-container {
            div.multiselect-grouped-container {
                overflow-y: auto;
                overflow-x: hidden;

                div.column-container {
                    max-height: 40px;
                }
            }
        }

        div.column-item {
            cursor: default;
        }

        div.column-toggle {
            margin-right: 80px;
        }

        div.toggle-column {
            right: -75px;
        }

        span.icomoon-help {
            margin-left: 4px;
        }
    }
}