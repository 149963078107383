export-builder-header-tools {

	div.tools {
		.flex();
		.justify-content(space-between);
		height: 60px;
		padding: 0 12px;

		.dropdown-menu {
			i:first-of-type.icon {
				position: relative;
				top: 1px;
				width: 1em;
				font-size: 13px;

				&.icomoon-barchart-bold {
					top: 2px;
					left: 2px;
				}
			}
		}

		div.tool-group {
			.flex-display();
			.justify-content(center);
			.align-items(center);
		}

		i.download-animator {
			transition: opacity 1.0s ease;
		}

		div.tool,
		div.tool-group {
			&.disabled {
				pointer-events: none;
				opacity: 0.5;
			}
		}

		div.tool {
			.flex();
			.flex-column();
			text-align: center;
			margin: 0 7px;
			cursor: pointer;

			i.icon {
				font-size: 20px;
			}

			i.icomoon-presentation-projector-screen-play {
				font-size: 18px;
				margin-top: 1px;
			}

			i.icomoon-save-disk {
				position: relative;
				top: 2px;
			}

			i.icomoon-rotate-vertical {
				.transition(transform);
				.transform(rotate(180deg));

				&.rotated {
					.transform(rotate(90deg));
				}
			}

			p {
				margin: 8px 0 0 0;
				font-size: 10px;
				white-space: nowrap;
			}
		}
		div.select2-drop {
				width: 150px !important;
			}
		div.tool.ratio-drop{
				text-align: left !important;

			 div.select2-container {
				min-width: 70px !important;
				height: 20px;
				margin-bottom: 5px;
			}

			 div.select2-container a.select2-choice {
				border: 0px !important;
				height: 20px !important;
			}



			 div.select2-container a.select2-choice span#select2-chosen-2, .select2-container .select2-choice > .select2-chosen {
				border: 1px solid rgba(7, 39, 93, 0.8);
				height: 20px;
				padding: 0px;
				line-height: 18px;
				width: 40px;
				text-align: center;
				color: rgba(7, 39, 93, 0.8);
			}

			 p {
				margin: 0px !important;
			}
			div.select2-container a.select2-choice span.select2-arrow {
				background: none;
				border: 0;
				right: 10px;
				top: -5px;
			}
			div.select2-container a.select2-choice, div.select2-container-multi a.select2-choice
			{
				line-height: 14px;
			}

		}
	}
}

div.select2-drop {
	width: 150px !important;
}
