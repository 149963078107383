//
// WIDGET PRODUCTS PANEL
//
product-widget-panel {
  .z-index(30);
  div#product-widget-panel {
    .z-index(40);
    .flex();
    .flex-column();

    div.widget-products-loading {
      margin: auto;
    }

    div.slide-panel-body {
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 50px;

      div.option-panel-item {
        margin: 15px 15px 0 15px;
      }

      input.switch {
        float: right;
      }
    }
  }
}
