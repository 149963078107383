.tapcolors__background_overlay {
  z-index: 1000000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
}

.tapcolors__container {
  z-index: 1000001;
  overflow: hidden;
  position: fixed;
}

.tapcolors__flipper {
  -webkit-transition: 0.35s;
  transition: 0.35s;
}
.tapcolors__flipper--flipped {
  position: relative;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.tapcolors__dismiss {
  display: none;
}

.tapcolors__pie {
  z-index: 10000000;
  backface-visibility: hidden;
  transform: rotateY(0deg);
  -webkit-transition: -webkit-opacity 0.3s;
}
.tapcolors__pie--hide {
  pointer-events: none;
  -webkit-opacity: 0;
}

.tapcolors__reverse-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: #eee;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.tapcolors__reverse-background__error-label {
  position: absolute;
  opacity: 0;
  text-align: center;
  top: 15%;
  width: 100%;
  height: 10%;
  font-size: 70%;
  animation: fade-in 0.2s forwards ease-in;
  font-family: sans-serif;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tapcolors__shake {
  animation: shake-anim 0.2s;
  animation-iteration-count: 1;
}

@keyframes shake-anim {
  0% {
    left: 4px;
  }
  25% {
    left: -8px;
  }
  50% {
    left: 4px;
  }
  75% {
    left: -8px;
  }
  100% {
    left: 4px;
  }
}

.tapcolors__reverse-input {
  box-sizing: border-box;
  height: 15%;
  width: 85%;
  border: none;
  border-bottom-style: solid;
  background: none;
  font-size: 100%;
  text-align: center;
  outline: none;
  margin: 42.5% 10% 42.5% 7%;
}

.tapcolors__reverse-input-button {
  position: absolute;
  border-radius: 50%;
  width: 10%;
  height: 10%;
  top: calc(50% - 10% + 5%);
  right: 8%;
  border: none;
  cursor: pointer;
  outline: none !important;
  transition: all 0.2s;
}
.tapcolors__reverse-input-button:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
  top: calc(50% - 10% + 4%);
}

.tapcolors__reverse-back-button {
  margin: 0 auto;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 65%;
  left: 50%;
  color: white;
  background-color: black;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.tapcolors__reverse-back-button:hover,
.tapcolors__reverse-button:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
  top: 64%;
}

.tapcolors__reverse-button {
  margin: 0 auto;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 20%;
  left: 50%;
  color: white;
  background-color: black;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  text-align: center;
  line-height: 25px;
  font-size: 25px;
}

.tapcolors__reverse-button:after {
  content: 'x';
}

.tapcolors__reverse-button:hover {
  top: 19%;
}

.tc-is-dark .tapcolors__reverse-button {
  color: black;
  background-color: white;
}

.tapcolors__preview {
  float: left;
  height: 34px;
  width: 34px;
  background-color: #aaaaaa;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.tapcolors__color {
  height: 70%;
  width: 70%;
  margin-top: 15%;
  margin-left: 15%;
  border-radius: 3px;
  cursor: pointer;
}

.tapcolors__textfield {
  height: 30px;
  width: 105px;
  border: 2px solid #aaaaaa;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 0px 4.375px;
  border-left: none;
  font-size: 24px;
  outline: none;
}

/*# sourceMappingURL=tapcolors.css.map*/
