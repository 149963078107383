/******************* Datatable Overrides ******************/

/* Datatables */
table.dataTable {
    margin: 0 !important;

    thead {

        th.datagrid-old {
            font-size: 11px;
        }
        th.fontsize-12 {
            font-size: 12px;
        }
        th.fontsize-16 {
            font-size: 16px;
        }
        th.fontsize-20 {
            font-size: 20px;
        }
        white-space: nowrap;
    }
}

.table > thead > tr > th {
    border-bottom: 1px dotted;
}
.table > tbody + tbody {
    border-top: 0;
}

table.dataTable tbody tr.selected td {
    font-style: italic;
}

table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_disabled,
table.dataTable thead .sorting {
    padding: 10px 16px;
    vertical-align: middle;

    &.control {
        visibility: hidden;
    }
}

table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc:after {
    .opacity(100);
}

.table > tbody > tr > td {
    padding: 8px 16px;
    border-top: 0;
    vertical-align: middle;

    &.control {
        height: 17px;
    }

    audio {
        margin: -6px 0;
    }
}

.table > tfoot > tr > td {
    overflow: hidden;
    padding: 10px 16px;
    border-top: 0;
    vertical-align: middle;
}

.table > thead > tr > th.numeric,
.table > tbody > tr > td.numeric,
.table > tfoot > tr > td.numeric {
    text-align: right;
}

div.dataTables_wrapper {
    position: relative;
    .clearfix();

    div.dataTables_header {
        height: 60px;
        overflow-y: hidden;
    }

    div.dataTables_filter {
        visibility: hidden;
        position: relative;
        float: left;
        margin-top: 14px;
        height: 42px;
        margin-left: 10px;

        input {
            padding-left: 30px;
            width: 200px;
        }
    }

    div.dataTables_length {
        visibility: hidden;
        float: right;
        margin-top: 12px;
        height: 42px;

        select {
            margin-left: 4px;
        }
    }

    div.dataTables_paginate {
        float: right;
        margin: 10px 15px 0 12px;
        height: 46px;
    }

    .total-items {
        float: left;
        margin: 18px 0 0 10px;
        font-size: 14px;

        .no-items {
            display: none;
        }
    }

    div.dataTables_processing {
        margin: 0;
        height: auto;
        width: auto;
        border: 0;
        top: 75px;
        right: 0;
        left: 0;
        bottom: 0;
        background: none;
        z-index: 101;
        min-height: 200px;

        div.dataTables_processing_backdrop {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 100;
            background: none;
        }

        span {
            .fontSmoothing();
            position: absolute;
            top: 75px;
            left: 0;
            right: 0;
            padding: 20px 0;
            font-size: 21px;
            z-index: 102;
            .shadow(0px, 1px, 8px, 0.1);
        }

        &.panel {
            background: none;
            border: none;
        }
    }

    div.popover-content {
        padding: 0;

        table {
            margin-bottom: 0;

            td {
                white-space: nowrap;
            }
        }
    }

}

div.dataTables_filter label:before {
    font-family: 'FontAwesome';
    content: "\f002";
    position: absolute;
    left: 12px;
    font-size: 16px;
    z-index: 100;
    line-height: 1;
    top: 5px;
    .fontSmoothing();
}

table.dataTable tbody.total-row {

    td {
        .bold();
        font-size: 14px;
        border-bottom: 1px dotted;
    }
}

table.dataTable tfoot {
    visibility: hidden;
}

table.dataTable.no-footer {
    border: 0;
}

div.datatable-footer {
    position: absolute;
    bottom: 10px;
    left: 20px;
    height: 32px;
    line-height: 32px;
    display: none;
}

/* Action elements */
table.dataTable {

    th.action-cell {
        text-align: center;
    }

    th {
        div.sort-label {
            display: inline-block;
            width: 16px;
            height: 16px;
            border-radius: 4px;
            border: 1px solid;
            font-size: 10px;
            text-align: center;
            margin: 0 5px;
        }

        div.tooltip {
            display: inline-block;
            margin-left: 5px;
        }
    }

    td.action-cell {
        text-align: center;
        padding: 4px 0;
        white-space: nowrap;

        a.action {
            display: inline-block;
            float: none;
            font-size: 16px;
            line-height: 26px;
            margin: 2px 4px 0 4px;
        }

        a.action.icomoon-line-chart {
            font-size: 14px;
        }

        a.btn-circle {
            display: inline-block;
            float: none;
            margin: 0 3px;
        }
    }

    td.status-cell {
        text-align: center;
    }

    tr td.link-cell {
        position: relative;
        text-align: center;
        .transition(background-color);

        a {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
        }
    }

    tr td.link-cell.numeric {
        text-align: right;
    }

    tr td.link-cell:hover {
        cursor: pointer;
    }

    tr.subrow td {
        height: 230px;
        padding-left: 15px;
        padding-top: 10px;
        border-left: 8px solid;
        .innershadow(0, 0, 8px, 0.15);
    }

    tr td div.service-square {
        margin-right: 5px;

    }
}

div.main-content-title.use-in-datatable {
    display: none;
    float: left;
    overflow-y: hidden;

    p.main-title {
        text-indent: 12px;
        font-size: 18px;
    }
}

/* Datatable filter button group */
div.datatable-filter-group {
    display: none;
    padding: 0 12px;
    height: 50px;
    min-height: 50px; // Fix for IE
    line-height: 50px;
    border-bottom: 1px solid;

    label {
        margin-right: 8px;
    }
}

/* Responsive table expander */
table.dataTable.dtr-column > thead .sorting_disabled.control {
    visibility: visible;
    padding-right: 10px;
}

table.dataTable.dtr-column > tbody > tr > td.control:before {
    font-family: 'icomoon';
    content: "\ea3b";
    .no-shadow();
    border: 1px solid;
    height: 20px;
    width: 20px;
    line-height: 20px;
    font-size: 14px;
    text-indent: 1px;
    .fontSmoothing();
}

table.dataTable.dtr-column > tbody > tr.parent td.control:before {
    font-family: 'icomoon';
    content: "\ea3c";
    text-indent: 0;
}

/* Fixed table header */

div.dataTables_fixedHeader {
    position: absolute;
    table {
        width: inherit;
    }
}

div.dataTables_fixedHeader.affix-top {
    left: 0 !important;
}

div.dataTables_fixedHeader.affix {
    position: fixed;
    top: 0;
    z-index: 99;
}

div.dataTables_fixedHeader.affix.dash-filter-fixed {
    top: 52px;
}

div.dataTables_fixedHeader.affix.client-fixed {
    top: 93px;
}

/* Full Row Details */

div.modal-content {
    table.table {
        &.show-more-table {
            td {
                text-align: right;
                word-break: break-all;

                img {
                    max-width: 250px;
                }

                p.value-container {
                    margin-bottom: 0;
                    white-space: nowrap;

                    span.delta {
                        display: inline-block;
                        position: relative;
                        top: -8px;
                        margin-right: 8px;
                    }

                    span.value {
                        display: block;
                    }
                }
            }
        }
    }
}