div.manage-library-reportstudio {
  table.dataTable {
    td,
    th {
      &.thumbnail {
        border: none;
        margin: 0;
      }
    }

    td {
      div.dash-thumbnail-container {
        text-align: center;
        overflow: hidden;

        div.inner {
          width: 100px;
          height: 125px;
          display: inline-block;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}