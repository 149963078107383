/** Feedbacks styling **/

div.feedback {
    display: block;
    position: absolute;
    bottom: 0px;
    right: 18px;
    z-index:100;

    width: 95px;
    height: 20px;

    .modal-title {
        font-size: 15px;
        text-align: center;
    }


    .modal-header{
        padding:4px;
        cursor:pointer;
        div.close {
            outline: none;
            position: relative;
            top: -3px;
        }
    }

    .modal-title {
        line-height: 0.7
    }

    .modal-body{

        .btn-group {
            display:flex;
            padding:0;
            margin-top:8px;
            margin-bottom:8px;
        }

        .btn {
            flex:1;
        }

        textarea {
            height: 200px;
        }

        .message {
            padding-left: 0px;
            p {
                text-align: center;
            }
            span.inactive {
                .opacity(50);
            }
        }
    }


    //
    // OPEN WIDGET STYLES
    //
    &.feedback-open {
        width: 400px;
        height: 425px;
        .modal-title {
            text-align : left;
        }
    }
}
