#export-builder-assigndatasource-modal {


  .modal-content {
    border: 0px !important;
    box-shadow: none !important;
  }
  .modal-body {
    padding-top: 0;
    color: #000;
  }

  .modal-title {
    font-size: 18px;
    color: #000;
  }

  .d-flex{
    display:flex;
  }
  .d-inline{
    display: inline-block;
  }
  .page-content-title{
    border-bottom:2px solid transparent;
    transition: all .3s;
    cursor: pointer;
  }

  .page-content-title:hover{
    border-color: #0D45A4;
  }

  .align-center {
    align-items: center;
  }

  .modal-footer {
    padding: 10px;
    text-align: left;
  }

  .clear-assignment-btn {
    background: none;
    color: #0D45A4;
    border: 0;
    border-bottom: 1px solid #0D45A4;
    padding: 0;
  }

  .hidden-icon {
    width: 12px;
  }

  .page-title-assign.hidden-page-title {
    color: #737C84;
  }
  .vertical-align-middle {
    vertical-align:middle;
  }

  .flex-1 {
    flex: 1;
  }

  .flex-2 {
    flex: 2;
  }

  .text-center {
    text-align: center;
  }

  .modal-header.borderless {
    border-bottom: none;
    padding-bottom: 5px;
  }

  .border-bottom {
    border-bottom: 1px solid #e6e6e6;
  }

  .padding-tb-5 {
    padding: 5px 0px;
  }

  .mr-bottom-5 {
    margin-bottom: 5px;
  }

  .mr-bottom-10 {
    margin-bottom: 10px;
  }

  .page-title {
    min-width: 20px;
  }

  .mr-right-5 {
    margin-right: 5px;
  }

  .headings > input {
    margin: 0 10%;
  }

  .borderstyle {
    border-bottom: 2px solid #0D45A4;
  }
  /** Popup Styles **/

  .body-page-content {
    max-height: 350px;
    overflow-y: auto;
  }

  .assignment-select {
    background: #B7BFC633;
    padding: 10px;
    border-radius: 4px;
    margin: 5px 5px  5px 0px;
    color: #737C84;
  }

  .bg-transparent {
    background: rgba(0, 0, 0, 0.49);
  }
  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: none;
  }
  .display-block {
    display: block !important;
  }
  .modal-dialog {
    position: absolute;
    background: #fff;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 600px;
    height: 530px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.42);
    border-radius: 4px;
    padding: 20px;
  }
  .send-proposal-popup .modal-dialog {
    height: 600px;
    padding: 30px;
  }


  .form.border select {
    border: 1px solid #ddd;
    background: #fff;
    padding: 10px;
    font-size: 13px;
  }

  .mr-lr-10 {
    margin: 0px 10px;
  }
  .send-to-css-content.border {
    border: 1px solid #ddd;
    height: 250px;
    border-radius: 4px;
  }

}