//
// WALKME specific styles
//

//@TEMP: can be removed once oct release goes out
// Support button
div[class*="custom-launcher-"].walkme-launcher-id-50250 {
    padding: 0 !important;
    height: 24px !important;
    width: 24px !important;
}