div.design-page {
    padding: 10px;

    div.main-content-title {
        display: inline-block;
        > i.icon {
            position: relative;
            top: 1px;
            margin-right: 6px;
        }
    }

    p.title {
        display: inline-block;
        text-indent: 6px;
        border-left: 4px solid;
        .bold();
        font-size: 14px;
        margin-bottom: 4px;
        text-transform: uppercase;
        margin-left: 10px;
        .fontSmoothing();
    }

    div.page-inner {
        position: relative;
        margin-top: 5px;

        ul.nav-tabs {
            margin: 10px -10px 2px -10px;
            padding: 0 20px;

            li {
                margin-top: 4px;

                &.active:hover,
                &.active {

                    a {
                        background: transparent;
                        border: 0;
                    }
                }
            }
        }
    }

    ul.nav-tabs {

        &:hover {
            li.action-tab {
                display: block;
            }
        }

        > li > a {
            margin: 0;
        }

        li {
            font-size: 13px;
            border: 0;
            border-bottom: 3px solid transparent;
            margin-bottom: 0;
            max-height: 41px;

            &.new {
                .magictime;
                .zoomInSmall;
            }

            a {
                .transition(color);
                border: 0;
                max-height: 41px;
            }
        }

        li.active a,
        li.active a:hover {
            background-color: transparent;
            border: 0 !important;
        }

        li.action-tab {
            margin-left: 5px;
            display: none;
            .rounded(4px, 0);
            border-color: transparent !important;

            &.icon-only {
                font-size: 18px;

                i.icon {
                    position: relative;
                    top: -1px;
                    left: 2px;
                }
            }

            a {
                border: 0;
                padding-bottom: 14px;
            }
        }
    }

    div.tab-content {
        div.tab-pane {
            padding-top: 0;
            border-left: 0;
            border-bottom: 0;
            border-right: 0;
            min-height: 51px;

            &.can-display-widgets {
                div.layout-inner:not(.layout-freeflow) {
                    .delayed-show-lr();
                }
            }
        }

        .layout-loader {
            i.icon {
                margin-top: -92px !important;
            }
        }
    }

    .data-source-section {
        i {
            position: relative;
            top: 2px;
            font-size: 15px
        }
    }
}

//
// FIX WIDGET DISPLAY ISSUE IN SAFARI
//
div.custom-design-page {
    .widget-display {
        position: absolute !important;
    }

    div#dash-header:not(.fixed) {
        div.dash-title-container {
            div.dash-button-container {
                clear: left;
                margin-top: -2px;
                margin-left: -3px;
            }
        }
    }
}

#edit-page-modal {

    div.btn-font-sizes {
        i.icon {
            position: relative;
            &.font-small {
                top: -2px;
                font-size: 8px;
            }
            &.font-medium {
                top: -1px;
                font-size: 11px;
            }
            &.font-large {
                font-size: 14px;
            }
        }
    }

    div.color-select {
        display: inline-block;
        width: 32px;
        height: 32px;
        .rounded(50%);
        margin-right: 10px;

        input[type="color"] {
            -webkit-appearance: none;
            border: none;
            padding: 0;
            width: 32px;
            height: 32px;
            .rounded(50%);
            .opacity(100);
            cursor: pointer;

            &:hover {
                .opacity(80);
            }

            &::-webkit-color-swatch-wrapper {
                padding: 1px;
                .rounded(50%);
            }

            &::-webkit-color-swatch {
                border: none;
                .rounded(50%);
            }
        }
    }
}

#copy-page-modal {
    div.modal-body {
        div.copy-option{
            margin: 0 15px
        }
    }
}

change-layout-footer {
    div.inner {
        .opacity(0);
        .transition(opacity);
        height: 40px;
        line-height: 40px;
        margin-top: 10px;
        padding: 0 8px;

        &.active {
            .opacity(100);
        }

        span.title {
            margin: 0 5px;
            position: relative;
            top: -2px;
            font-size: 14px;
            .opacity(60);
        }
    }
}

#view-dashboard-section-audit-logs-modal {

    div.modal-dialog {
        width: 450px;
        height: 170px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -150px;
        margin-top: -85px;
    }

    .modal-title {
        font-size: 15px;
    }

    .modal-header .close {
        font-size: 32px;
        margin-top: -8px;
    }

    div.modal-body {
        > div.inner {
            .flex();
            .justify-content(space-around);
        }

        input.switch {
            position: relative;
            left: 10px;
            top: -4px;
        }
    }

    div.option-container {
        width: 235px;
        height: 100px;
        text-align: center;
        border-right: 1px solid;
        cursor: pointer;
        .bold();

        &:last-child {
            border: 0;
        }

        i.icon {
            margin-top: 14px;
            display: block;
            font-size: 30px;
        }

        span {
            display: block;
            margin-top: 10px;
            text-transform: uppercase;
        }
    }
}