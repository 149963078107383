#export-builder-shortcuts-modal {

    .modal-body {
        padding-top: 0;
    }

    hr {
        margin: 0;
    }

    .shortcuts-container {
        width: 96%;
        margin: 10px 2%;
    }

    ul.shortcuts-list {
        padding: 0;
        margin-top: 10px;

        li.shortcuts-list-item {
            .flex();
            .flex-direction(row);
            .align-items(center);
            .justify-content(space-between);
            border-bottom: solid 1px;
            height: 44px;

            .item-title {
                height: 30px;
                padding: 5px;
            }

            .item-keys {
                //margin-bottom: 4px;
                div {
                    height: 30px;
                    min-width: 25px;
                    padding: 6px;
                    display: inline-block;
                    .rounded(4px);
                    background-color: #eeeeee;
                    border: solid 1px #919193;
                    border-bottom: solid 2px #919193;
                    margin-left: 5px;
                    text-align: center;
                    font-weight: 500;
                }
            }
        }
    }
}