/** If we make the parent level unselectable, then the children inherited the unselectable style.
The following is a hack to avoid that. To avoid this hack, we could have to revise all custom template .css **/
body div.smartconnector-select2 {
    ul.select2-results li.select2-result-with-children .select2-result-label {
        cursor: default !important;
    }
    ul.select2-results li.select2-disabled {
        cursor: default !important;
    }
    ul.select2-results li.select2-result-with-children {
      -ms-filter: formatstring("progid:DXImageTransform.Microsoft.Alpha(opacity='{0}')", 100) !important;
      filter: alpha(opacity=100) !important;
      -moz-opacity: 1 !important;
      -khtml-opacity: 1 !important;
      opacity: 1 !important;
    }

    .select2-results .select2-result-label label {
        background-color: #333333 !important;
    }
}

div.import-wizard-upload-container {
    .file-caption-main {
        width: 75%;
        .hidden-xs {
            line-height: 18px;
        }
    }
    .manualUploadWarningMessage {
        margin: 0 20px;
    }
}
span.nowrap {
    white-space: nowrap;
}
.pointer-hand {
    cursor: pointer;
}
div.import-wizard-container {
    input::placeholder, textarea::placeholder {
        opacity: 0.3;
    }
    .icon-sc-warning {
        font-size: 16px;
        color: orange;
        margin-top: 5px;
        margin-left: -20px;
    }
    .breadcrumb-container a {
        display: inline;
    }
    .mlim6 {
        margin-left: -6px !important;
    }
    .input-min-width {
        min-width: 220px;
    }
    .sampledata {
        max-height: 300px;
        overflow-x: scroll;
    }
    .samplemappingdata {
        max-height: 200px;
        max-width: 50%;
        overflow-x: scroll;
    }
    .leftinline {
        float: left;
        display: inline;
    }
    label.alert {
        display: block !important;
    }
    .bgconfigfieldstate1 {
        background-color: #e8ebec !important;
    }
    .bgconfigfieldstate2 {
        background-color: #e8ebec !important;
    }
    .badge {
        color: #fff;
        background-color: #8cc63e;
        border-color: #8cc63e;
    }
    .badge0 {
        color: #fff;
        background-color: #8cc63e;
        border-color: #8cc63e;
    }
    .badge1, .badge3 {
        color: #584f3e;
        background-color: #fcf8e3;
        border-color: #fcf8e3;
    }
    .badge2 {
        color: #b94a48;
        background-color: #f2dede;
        border-color: #f2dede;
    }
    .design-modal {
        font-size: smaller;
        p.title {
            cursor: pointer;
            a.action {
                margin-right: 30px;
                float: right;
                .icon {
                    margin-left: -17px;
                }
            }
        }
    }
    input[type="text"], input[type="password"], select {
        &.form-control.max-width-none {
            max-width: none;
        }
    }
    .loadsample {
        padding-left: 0;
    }
    table.upload-data-table td.data-mapping-cell div.input-group select.form-control {
        width: 43%;
    }
    table.upload-data-table td input[type='checkbox'] {
        width: auto !important;
    }
    textarea.extractdata {
        width: 100%;
        min-height: 60px;
        resize: vertical;
    }
    div.sample_div {
        max-height: 50px;
        overflow-y: auto;
    }
    div.data-fields-container {
        clear: both;
        overflow-x: auto;

        table.table {
            th.data-mapping-cell {
                span {
                    display: inline-block;
                    width:45%;
                }
                i {
                    width:10%;
                    margin-top: 3px;
                }
            }
            td.data-mapping-cell {
                min-width:300px;
            }
        }
    }
    .panel-heading {
        min-height: 43px;

        .panel-title {
            p {
                float: left;
            }
            a.action {
                margin-top: -8px;
                font-size: 12px;
            }
        }
    }
    .panel-preprocess_functions {
        .function-block, .notice-block {
            margin-bottom: 20px;
        }
        input {
            margin-bottom: 15px;
        }
        select {
            margin-left: 0 !important;
        }
        .button-row {
            margin-top: -10px;
        }
        div.loading-wheel {
            margin-left: 5px !important;
        }
        div.custom-fields {
            label {
                margin-top: 3px;
                margin-left: -15px;
            }
        }
        a.remove-function {
            float: right;
            margin-top: -8px;
        }
        .show-extra-info {
            padding: 5px 0 10px 0;
        }
        table {
            margin-top: 20px;
            th, td {
                padding-left: 5px;
            }
        }
        .code-samples {
            textarea.code-sample {
                resize: vertical;
                min-height: 350px;
            }
            .button-row {
                margin-top: 15px;
            }
            #preprocess-curl-sample {
                margin-top: 20px;
                textarea {
                    margin-top: 10px;
                    height: 34px;
                }
            }
        }
    }
    .form-suffix {
        min-height: 27px;
        padding-top: 7px;
        margin-left: -15px;
        font-weight: bold;
    }
    .with-loading {
        .full-width {
            max-width: 100% !important;
        }
        div.loading-wheel {
            margin-left: -20px !important;
        }
    }
    .loading-ml0 {
        .loading-wheel {
            margin-left: 0 !important;
        }
    }
    #sc_delete_details {
        margin: 0px 0 40px 20px;
        .loading-wheel {
            margin-left: 0px;
        }
    }
    .panel-title div.loading-wheel {
        margin-top: -5px;
        margin-left: 10px;
        width: 28px;
        height: 28px;
    }
    .full-width {
        max-width: 100% !important;
    }
    .no-max-width {
        max-width: none !important;
    }
    ul.disc {
        list-style-type: disc;
    }
    .section-border {
        border-style: none none ridge ridge;
        border-width: 0 0 1px 1px;
        margin-left: 0;
        padding-left: 4px;
    }
    input[type=checkbox].switch {
        margin-top: 5px;
    }
    input[type=checkbox].selector {
        margin-right: 5px;
    }
    .fluid-container.loading {
        font-size: 135%;
        font-weight: bold;
        color: #4ca939;
    }
    #liveconnector-panel {
        .attributes,
        .metrics {
            label {
                margin-top: -7px;
                cursor: pointer;
            }
            label:hover {
                background-color: #4ca939;
            }
        }
        .parameters {
            div.parameter {
                padding: 5px 0 5px 15px;
            }
            input {
                max-width: 80%;
            }
            label {
                padding-top: 10px;
            }
        }
        .form-group {
            padding-bottom: 15px;
        }
        .form-group.dataviews,
        .form-group.filters {
            .form-control {
                float: left;
                width: 500px;
                margin: 0 10px;
            }
            .icon.remove {
                padding-left: 5px;
                margin: 5px;
                cursor: pointer;
                font-size: 25px;
            }
            .icon.remove:hover {
                color: #4ca939;
            }
            .format {
                padding: 10px;
                input {
                    width: 60px;
                }
                span {
                    float: left;
                    margin: 20px 5px 20px 20px;
                }
            }
        }
    }
    .subtoggle {
        padding-top: 7px;
        margin-left: 45px;
        font-size: 11px;
    }
    .select2-container-multi {
        overflow: visible;
        max-height: none;

        .select2-choice,
        .select2-choices {
            border-color: rgba(51, 51, 51, 0.1) !important;

            .select2-search-choice {
                border: 0;
                background: transparent !important;
                color: #fff;
                padding: 0 !important;
                margin: 0;

                div.select-data-column {
                    color: #fff;
                    background-color: #474f5e;
                    padding: 6px 20px 6px 8px;
                    min-width: 60px !important;
                    max-width: 400px !important;
                    font-size: 11px;
                    line-height: 12px;
                    .ellipsis(108px);
                    .rounded(12px);

                    span.icon {
                        margin-right: 6px;
                        position: relative;
                    }
                }
            }

            .select2-locked {
                padding: 4px 2px 2px 5px !important;
            }
        }

        .select2-search-choice-close {
            filter: brightness(0) invert(1);
            top: 17px;
            right: 8px;

            &:hover {
                filter: brightness(0) invert(0.8);
            }
        }
    }
    .select2-container a.select2-choice span.select2-arrow {
        display: block !important;
    }
    .demo_code {
        font-size: 90%;
    }
    textarea.json_test {
        min-height: 240px;
    }
    ul.list_json_path {
        list-style-type: disc;
        margin-top: 18px;
        padding-left: 20px;
    }
    .sublabel {
        padding-top: 7px;
        font-size: 80%;
    }
    .waiting_line {
        margin-top: 4px;
        .waiting_line_text {
            padding-top: 8px;
        }
    }
    .p5px {
        padding: 5px !important;
    }
    table.geo-config-table {
        margin-top: 25px !important;
        td {
            padding: 0 rem(30px) 0 0;
            vertical-align: top;

            input {
                width: 35%;
                font-size: rem(11px);
                padding: rem(3px);
            }

            div.input-group {
                vertical-align: middle;
                margin: rem(5px);
            }

            &.data-mapping-cell,
            &.data-type-cell {
                input {
                    height: rem(28px);
                }

                div.input-group-addon {
                    height: rem(16px);
                    width: 8% !important;
                }

                span {
                    span.icon {
                        left: rem(-2px) !important;
                    }
                }
            }
        }
        th.location-type, td.location-type {
            width: 30%;
        }
        td.location-type.pin, td.delete-column.pin {
            vertical-align: middle;
        }
        th.field-name, td.field-name {
            width: 50%;

            .field-pin {
                margin: 10px 0;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        th.delete-column, td.delete-column {
            width: 20%;
            text-align: center;
        }
        select {
            width: 90%;
        }
        .show-extra-info {
            margin: 3px;

            &:not(.pin) {
                width: 200%;
            }
        }
    }
}

table.sc-main-listing-table {
    thead {
        th:first-child {
            width: 10%;
        }
    }
}
