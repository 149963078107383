//
// WIDGET LOADER
//
div.widget-loader {
    .flex;
    .flex-column;
    .flex-center;
    position: relative;
    height: 100%;

    loader {
        height: auto;
    }

    div.spin-loader {
        width: 50px;
        height: 50px;
        border-width: 5px;
    }

    i.icon {
        position: relative;
        .bigPulse(1.3, 2s, infinite);

        &[class*="icon-"] {
            line-height: 1;
        }
    }

    span {
        display: block;
        margin-top: 5px;
        .fontSmoothing();
    }
}

//
// WIDGET LOADING
//
widget-loading {
    // z-index needed to display in front of datagrid headers (with a small widget)
    z-index: 1;
}

div.widget-loaded-container {
    .flex-display();
    .flex-center;
    .absolute(0, 1px, 1px, 1px);
    .z-index();
    .rounded(0, 0, 10px, 10px);

    pointer-events: none;
    div {
        pointer-events: initial;
    }

    &.inactive {
        .z-index(2);
        cursor: not-allowed;
    }

    div.loaded-icon {
        i.icon {
            font-size: 28px;

            .export-icon {
                height: 1em;
                width: 1em;
            }
        }
    }

    div.loaded-panel {
        position: relative;
        .flex-display();
        .flex(initial);
        .flex-center;
        padding: 15px 12px;
        .rounded(4px);
        margin: 0 16px 5px 16px;
    }

    div.warning-badge {
        background-color: #ec971f;
        color: #fff;
        font-size: 9px;
        padding: 3px 8px;
        position: absolute;
        top: -18px;
        left: -2px;
        text-transform: uppercase;
        .rounded(4px, 0);
    }

    div.info-badge {
        background-color: #31b0d5;
        color: #fff;
        font-size: 9px;
        padding: 3px 8px;
        position: absolute;
        top: -18px;
        left: -2px;
        text-transform: uppercase;
        .rounded(4px, 0);
    }

    div.warning-panel {
        padding: 0 15px;
        text-align: left;

        p.text {
            font-size: 14px;
            margin-bottom: 8px;
        }
    }
}

//
// WIDGET DISPLAY
//
.widget-display {
    .flex-full;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 5px;

    &.slice-chart {
        padding: 1.5%;
    }

    &.am5-chart {
        padding: 0;
    }
}

.am5-modal {
    z-index: 999 !important;
}


//
// DESIGN WIDGET
//
div.design-widget {
    .flex-full;
    padding: 8px;

    div.widget-inner {
        .flex-full;
        .flex-column;
        width: 100%;
        min-width: 0;
        min-height: 70px;
        position: relative;
        .rounded(10px);
        .transition(opacity, 0.25s);
        border: 1px solid;

        &.animated {
            .magictime;
            .zoomInSmooth;
        }

        &.glow {
            z-index: 1;
        }

        &.faded {
            .opacity(20);
            .backface-visibility();
        }

        &.tiny-height {
            div.loaded-icon {
                display: none;
            }

            .widget-display {
                overflow: hidden;
            }

            div.warning-panel {
                p {
                    font-size: 11px;
                    margin-bottom: 0;
                }
                small {
                    display: none;
                }
            }

            probe-toggler a.btn {
                top: 10px;
                .z-index(5);
            }

            .dev-toggler {
                visibility: hidden;
            }
        }

        .action-message-placeholder-offset {
            position: absolute;
            right: 300px;
        }
    
        //
        // WIDGET ACTION MESSAGE PLACEHOLDER
        //
        div.action-message-placeholder {
            margin: 10px;
            position: absolute;
            top: 0px;
            right: 0px;
            text-align: center;
            width: 300px;
            .rounded(4px);
            .z-index(10000);
            padding: 5px;
    
            div.text-container {
                margin: 5px;
    
                span.title {
                    font-weight: bold;
                    font-size: 14px;
                }
                ul.info {
                    list-style-type: disc;
                    padding-left: 25px;
                }
            }
        }

        div.widget-header {
            height: 45px;
            width: auto;
            margin: 0 12px;
            padding: 0;
            border: 1px solid transparent;
            padding-left: 3px;

            div.widget-title-container {
                white-space: nowrap;
                display: inline-block;
                height: 45px;

                //
                // WIDGET TITLE
                //
                .widget-title {
                    white-space: normal !important;
                    margin-top: 11px;
                    margin-left: 2px;
                    .ellipsis();
                    .bold();

                    &.display {
                        margin-left: 5px;
                    }

                    &.link {
                        position: absolute;

                        a {
                            cursor: pointer;
                        }
                    }

                    &.small {
                        top: 1px;
                        font-size: 12px;
                    }

                    &.medium {
                        top: 1px;
                        font-size: 14px;
                    }

                    &.large {
                        top: 0;
                        font-size: 16px;
                    }

                    input {
                        position: absolute;
                        top: 4px;
                        margin: 2px 0;
                        font-size: 14px;
                        width: 250px;
                        height: 30px;
                        font-weight: normal;
                        .shadow(0, 0, 0, 0);

                        &.no-title {
                            border: dashed 2px;
                        }
                    }

                }

                span.widget-warning {
                    position: relative;
                    top: 3px;
                    left: 3px;
                    z-index: 1004;
                    i {
                        font-size: 14px;
                    }
                }
            }

            ul.dropdown-menu {
                .z-index(1002);
            }
        }

        div.widget-content {
            .flex-full;
            .flex-column;
            position: relative;

            div.loading-inner {
                .rounded(50%);
                height: 160px;
                width: 160px;

                span {
                    padding: 4px 10px;
                    .rounded(10px);
                }
            }
        }
    }
}