div.design-widget {

    div.alerts-toggler {
        display: inline-block;
        vertical-align: top;
        margin-top: 3px;
        margin-right: 10px;
        font-size: 18px;
    }
}

div.widget-creation-container{
    min-height: 475px;
}

div.creation-placeholder.icon-bell-o {
    font-size: 175px;
}