/******************* Report Builder **********************/
#report-container {

    #ajax_main_loading,
    #ajax_main_loading_container {
        left: 280px !important;
    }

    div#left-builder-frame {
        position: absolute;
        width: 280px;
        left: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 101;
        padding: 30px 8px 0 8px;
        border-right: 1px solid;

        a.back-action {
            position: absolute;
            display: block;
            .opacity(80);
            height: 25px;
            width: 280px;
            top: 0;
            left: 0;
            text-align: center;
            z-index: 102;

            i {
                line-height: 25px;
            }
        }

        a.back-action:hover {
            .opacity(100);
        }

        hr {
            margin: 16px 0 10px 0;
        }

        div.form-group {
            margin-bottom: 0px;

            input[type="text"] {
                margin-bottom: 5px;
            }

            select {
                font-size: 12px;
                height: 34px;
                margin-bottom: 5px;
            }

            label {
                font-weight: normal;
                margin-bottom: 0;

                input[type="checkbox"] {
                    margin-right: 4px;
                }
            }
        }

        span.label {
            margin-right: 10px;
            position: relative;
            font-size: 100%;
        }

        h5 {
            margin-bottom: 15px;
            font-size: 13px;
        }

        small.help-block {
            margin-top: -1px;
            margin-bottom: 10px;
        }

        div#update-report {
            margin-top: 10px;
            width: 100%;
        }
    }

    //This color and styles will always remain the same regardless of theme
    div#right-builder-frame {

        background-color: #fff;
        color: #1b1f27;
        position: absolute;
        z-index: 100;
        top: 0;
        bottom: 0;
        right: 0;
        left: 279px;
        overflow: auto;

        #report-header {
            div.header {
                padding: 10px 15px;
            }
        }

        #report-placeholder {
            padding: 10px 20px;

            div.jumbotron {
                margin-top: 30px;

                .export-menu-example {
                    .rounded(50%);
                    text-align: center;
                    background: #45484d;
                    color: #f2f2f2;
                    vertical-align: middle;
                    display: inline-block;
                    position: relative;
                    width: 36px;
                    height: 36px;
                    line-height: 36px;
                    font-size: 16px;
                    margin: 0 4px;
                }
            }
        }

        div.chart-container {
            margin-bottom: 10px;
        }
    }


    /* Report Content */
    div.service-container h4 span, div.category-container h4 span {
        margin-left: 12px;
        line-height: 32px;
    }

    div.service-container hr, div.category-container hr {
        margin: 0;
    }
}

#export-menu:before {
    content: "\ea40";
    font-family: 'icomoon';
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#export-menu {
    position: fixed;
    z-index: 100;
    bottom: 30px;
    right: 30px;
    display: block;
    width: 60px;
    height: 60px;
    text-align: center;
    .rounded(50%);
    background: #45484d;
    color: #f2f2f2;
    font-size: 24px;
    line-height: 60px;
    vertical-align: middle;
    cursor: pointer;

    div {
        display: none;
        position: absolute;
        left: 10px;
        bottom: 10px;
        color: #f2f2f2;
        text-align: center;

        a {
            font-size: 20px;
            color: #f2f2f2;
            text-decoration: none;
            width: 40px;
            height: 40px;
            .rounded(50%);
            background: #45484d;
            line-height: 40px;
            display: block;
        }

        i.icomoon-clock {
            font-size: 26px;
            line-height: 40px;
        }
    }
}

#export-menu li.desktop a:hover,
#export-menu li.mobile a:active {
    .opacity(80);
}

.export-duplicate {
    position: relative !important;
    bottom: 0 !important;
    right: 0 !important;
    left: 15px;
    visibility: hidden;

    div {
        display: inline-block !important;
        visibility: visible;
    }
}

@media print {

    div#export-menu {
        visibility:hidden;
    }

    div#right-builder-frame {
        left:0;
        overflow:visible;
    }
}
