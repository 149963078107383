//
// ACCOUNTMANAGER WIDGET
//
account-manager-widget {
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 0 5px;
  text-align: center;

  &.widget-display {
    .flex-center();
  }

  div.empty-widget {
    opacity: .3;
  }

  div.column-display {
    text-align: center;
    margin: 8px;

    div.accountmanager-image {
        margin: 15px 15px 15px 0px;

        i.icon {
            font-size: 100px;
        }

        img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }
    }

    div.accountmanager-text {
      .old-flex(horizontal);
      .flex(1);
      font-size: 16px;

      div.empty-text-placeholder {
        height: 100%;
        position: relative;
        width: 200px;
        left: -5px;
        border-radius: 4px;
      }

      > div {
        width: 50%;
      }

      div.title-line {
        white-space: nowrap;
        label {
          margin: 10px;
        }
      }

      div.value-line {
        white-space: nowrap;
        margin: 8px;
      }
    }
  }
}