div.slide-panel.dashboard-filter-panel {

    div.slide-panel-body {
        div.options-panel {
            margin-top: 0;
        }
    }

    div.widget-loaded-container {
        position: relative;
        top: 20px;

        div.warning-panel {
            padding: 0 2px;

            small {
                line-height: 20px;
            }
        }
    }

    @import "../item/dashboardfilterpanel.item.less";
}

@import "../header/dashboardfilterheader.less";