
.exportbuilder-item {
    .transition-group(
        background-color 0.2s ease,
        opacity 0.05s linear
    );
    position: absolute;
    cursor: pointer;
    //user-select: none;

    &.element-set-background {
        pointer-events: none;
        .ui-resizable-handle {
            display: none !important;
        }
    }

    &.focused {
        cursor: move;
        .small-pop();

        &.disable-resize {
            .ui-resizable-handle {
                display: none !important;
            }
        }
        &.multi-select-state {
            .animatable-border {
                display: initial;
                height: 100%;
                width: 100%;
                position: absolute;
                z-index: 1;
                background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
                background-size: 15px 4px, 15px 4px, 4px 15px, 4px 15px;
                background-position: left top, right bottom, left bottom, right top;
                //padding: 10px;
                animation: border-dance 100s infinite linear;
            }
            @keyframes border-dance {
                0% {
                    background-position: 0 0, 100% 100%, 0 100%, 100% 0;
                }
                100% {
                    background-position: 100% 0, 0 100%, 0 0, 100% 100%;
                }
            }
        }
        &:not(.multi-select-state) {
            .animatable-border {
                display: none;
            }
        }
    }



    &:not(.focused) {
        .ui-resizable-handle {
            display: none !important;
        }
    }

    &.show-lines {

    }

    &.ui-draggable-dragging,
    &.ui-resizable-resizing {
        .opacity(40);
    }

    .exportbuilder-item-style-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .exportbuilder-item-inner {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;

        div.report-element-overlay {
            .z-index(100);
            position: absolute;
            width: 100%;
            height: 100%;
            pointer-events: none;
            .transition-group(border 0.3s ease, background-color 0.3s ease);
        }

        & div.design-widget {
            padding: 0;
        }

        & div.design-widget div.widget-inner {
            pointer-events: none;

            // Fix the issue that wkhtmltopdf doesn't support flex syntax
            .widget-box-horizontal {
                .old-flex(horizontal);
            }

            .widget-box-vertical {
                .old-flex(vertical);
            }

            div.chart-widget-container {
                .old-flex-size(1);
                width: 100%;
                height: 100%;
            }
        }

        geochart-widget {
            .amcharts-zoom-control {
                display: none;
            }
        }

        & .widget-display {
            overflow: hidden;
            padding: 0px;
        }

        div.amcharts-legend-div {
            overflow: hidden !important;
            max-height: 50%;
        }

        div.summernote-widget-wrapper {
            .absolute();
            overflow: auto;
        }

        div.summernote-disabled-content {
            .absolute();
        }

        .btn {
            &.small-btn {
                font-size: 14px;
                position: absolute;
                bottom: 2px;

                &:first-of-type {
                    left: 2px;
                }
                &:nth-of-type(2) {
                    left: 30px;
                }
                &:nth-of-type(3) {
                    left: 60px;
                }

                i {
                    margin-right: 0px;
                }

            }
        }

        big-number-widget {
            .bignumber-margin {
                margin: auto;
            }
        }
    }

    &.is-in-special-edit-mode.focused {
        div.design-widget {
            div.widget-inner {
                pointer-events: initial;
            }

            geochart-widget {
                .amcharts-zoom-control {
                    display: initial;
                }
            }
        }
    }

    &.item-type-widget {
        .widget-header {
            .widget-title-container {
                span {
                    a.data-source {
                        i.icon, svg.export-icon {
                            &.custom-icon {
                                top: -2px;
                            }
                        }
                    }
                }
            }
        }

        table.dataTable {
            thead {

                > tr > th {
                    border: 0;
                }

                .sorting,
                .sorting_asc,
                .sorting_asc_disabled,
                .sorting_desc,
                .sorting_desc_disabled {
                    padding-right: 12px !important;

                    &:after {
                        .opacity(0);
                    }
                }
            }

        }

        table.show-gridlines {
            border-collapse: collapse;
            border: 1px solid #e5e5e5;
            th, td {
                border: 1px solid #e5e5e5 !important;
            }
        }
    }

    &.item-type-image {
        div.element-image-container {
            width: 100%;
            height: 100%;
            img {
                position: relative;
                height: 100%;
                width: 100%;
                vertical-align: middle;
            }
            &.aspect-fit {
                .flex();
                .flex-center();
                img {
                    .objectFit(contain);
                }
            }
            &.aspect-fill {
                img {
                    .objectFit(cover);
                }
            }
            &.stretch {
                img {
                    .objectFit(fill);
                }
            }
        }
    }

    &.item-type-color {

    }

    &.item-type-shape {
        &.shape-circle,
        &.shape-square {
            .exportbuilder-item-style-container {
                overflow: initial;
            }
            .exportbuilder-item-inner {
                overflow: initial;
            }
        }

        .element-shape-container {
            width: 100%;
            height: 100%;
        }
    }

    &.item-type-icon {
        .element-icon-container {
            width: 100%;
            height: 100%;
            i.element-icon {
            }
        }
    }

    &.item-type-text {
        .animation(none);

        .text-element-container {
            position: relative;
            display: flex;
            width: 100%;
            height: 100%;
            user-select: none;
            white-space: pre-wrap;

            p {
                margin: 0;
                width: 100%;

                .vertical-align {
                    top: 50%;
                    transform: translate(0, -50%);
                }
            }

            textarea {
                width: 100%;
                height: 100%;
                position: relative;
                top: 3px;
                border: 0;
                outline: 0;
            }
        }
    }
}