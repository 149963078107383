/* Hover actions */
html {
    font-size: 14px;
}

hr.selection-line {
    border-width:1px;
    margin-top:-70px;
    width: 100%;
}

div.selection-button-empty {
    width:40px;
    height:75px;
}

div.selection-button {
    position:relative;
    top:10px;
    display:inline-block;
    width:40px;
    height:40px;
    margin-bottom:20px;
    .rounded(20px);
    border:2px solid;
    font-size: 16px;
    line-height:38px;
    z-index:2;
    text-align: center;

    span.icomoon-refresh {
        position:relative;
        top:-1px;
        font-size: 14px;
        line-height:1;
        .rotate();
    }
}

div.selection-button.all {
    position:absolute;
    top: auto;
    left: -5px;
    bottom: -10px;
    font-size:14px
}

div.selection-button:hover {
    cursor:pointer;
    .transition(all);
}

div.selection-button.active {
    cursor:default;
}

div.main-widget.active,
div.dual-bar-container.active,
div.selection-button.active {
    cursor:default;
}

div.owl-item:hover {
    cursor:pointer;
}

div.owl-item:hover label {
    cursor:pointer;
}

div.selection-button.all:hover,
div.selection-button-container:hover div.selection-button {
    cursor:pointer;
}

p.temp-text {
    font-size:11px;
}

p.active {
    display:inline-block;
    .rounded(12px);
}


/* Legend */

div#period-legend {
    padding: 10px 0;
    text-align: center;

    //For affix
    position: absolute;
    left: 0;
    right: 0;
}

div#period-legend.affix {
    position:fixed;
    top: 43px;
    left: 225px;
    padding-right:15px; /* Compensate for the vertical scrollbar */
    z-index:99;
}

div.period-legend {

    span.period {
        margin-right:20px;
        margin-left:4px;
    }

    span.label {
        font-size:12px;
    }
}


/* Dashboard Top */
div#dashboard-top {
    margin-top:46px;

    div.main-widget {
        position: relative;
        padding: 15px 0;
        z-index:10;

        p.main-widget-title {
            font-size: 16px;
            margin-bottom: 10px;
            padding:2px 16px;
        }

        div.widget-container {
            position:relative;

            div.stats-container {
                text-align:left;
                display:inline-block;
                padding: 0 5px;
                margin-left: 12px;
                .rounded(6px);

                div.stats-box {
                    border-bottom: 1px solid #000;
                    padding:4px 8px;
                    margin:5px 2px;

                    span {
                        font-size:12px;
                    }

                    span.title {
                        display:block;
                        text-transform: uppercase;
                        .bold();
                        font-size:11px;
                    }
                }

                div.stats-box:last-child {
                    border-bottom:0;
                }
            }

            div.gauge-container {
                width: 100px;
                position: relative;
                left: 50%;
                margin-left: -50px;
                text-align: center;

                label {
                    position: absolute;
                    font-size: 24px;
                    display: block;
                    font-weight:normal;
                    top:50%;
                    margin-top:-25px;
                    left:-20px;
                }

                div.stat-diff {
                    position: absolute;
                    top:50%;
                    margin-top:6px;
                    left:-20px;

                    span {
                        font-size: 12px;
                    }

                    span.icon {
                        font-size: 14px;
                    }
                }

                p.gauge-plus {
                    position:absolute;
                    top:-4px;
                    font-size:20px;
                    left:-15px;
                }

                p.gauge-minus {
                    position:absolute;
                    bottom:-4px;
                    font-size:20px;
                    left:-15px;
                }

                p.gauge-zero {
                    position:absolute;
                    top: 50%;
                    margin-top:-8px;
                    right:-12px;
                    font-size:12px;
                }
            }

            div.col-xs-6 {
                position:static;
            }

            div.total-line {
                position:absolute;
                height:112%;
                width:1px;
                right:8px;
                top:-10px;
            }

        }
    }

    hr.selection-line {
        display:none;
    }
}
/* Dashboard Middle */
div#dashboard-middle {
    display: none;
    padding: 35px 20px 30px 20px;
    margin-top: -20px;

    div.main-widget-comparison {
        padding-bottom: 10px;

        span.title {
            display: block;
            font-size: 16px;
        }

        span.range {
            display: block;
            font-size: 12px;
        }

        span.value {
            display: block;
            font-size: 46px;
        }

        hr {
            margin: 10px 0;
        }

        div.comparison-total {
            font-size: 20px;
            white-space: nowrap;

            div.amount {
            }
        }
    }

    div.breakdown-container {

        div.dual-bar-info {
            margin-top:12px;
        }

        p.dual-bar-title {
            padding:2px 16px;
            font-size:14px;
        }
    }

    hr.selection-line {
        margin-top:-30px;
    }
}

/* Dashboard Bottom */
div#dashboard-bottom {
    padding: 20px 0;

    p.loaded-header {
        margin-bottom:15px;
    }
}

/* Loaded Header */

span.header-icon {
    float:left;
    font-size:22px;
}

p.loaded-header {
    font-size: 14px;
    float:left;
    margin-left:12px;
    font-weight:normal;

    label {
        border-bottom: 2px solid;
        margin-right:2px;
    }
}

.data-freshness-date-container {
    float: right;

    strong {
        color: #00adef;
    }
}

.fa-rotate-45 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}