#export-options-modal {

    div.modal-dialog {
        width: 400px;
        height: 170px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -200px;
        margin-top: -85px;
    }

    .modal-title {
        font-size: 15px;
    }

    .modal-header .close {
        font-size: 32px;
        margin-top: -8px;
    }

    div.modal-body {
        > div.inner {
            .flex();
            .justify-content(space-around);
        }

        input.switch {
            position: relative;
            left: 10px;
            top: -4px;
        }
    }

    div.export-option-container {
        width: 120px;
        height: 80px;
        text-align: center;
        border-right: 1px solid;
        cursor: pointer;
        margin-top: 5px;
        .bold();

        &:last-child {
            border: 0;
        }

        i.icon {
            margin-top: 14px;
            display: block;
            font-size: 30px;
        }

        span {
            display: block;
            margin-top: 10px;
            text-transform: uppercase;
        }
    }

    .disabled {
        pointer-events: none;
    }
}