/******************* CSS3 Animation MIXINS ********************/
@keyframes rotating {
  from {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotatingReverse {
  from {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes scaleUp {
  0% {
    -ms-filter: formatstring("progid:DXImageTransform.Microsoft.Alpha(opacity='{0}')", 0);
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
  100% {
    -ms-filter: formatstring("progid:DXImageTransform.Microsoft.Alpha(opacity='{0}')", 100);
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scaleIn {
  from {
    -webkit-transform: translateY(-50%) scale(0);
    -moz-transform: translateY(-50%) scale(0);
    -ms-transform: translateY(-50%) scale(0);
    -o-transform: translateY(-50%) scale(0);
    transform: translateY(-50%) scale(0);
  }
  to {
    -webkit-transform: translateY(-50%) scale(1);
    -moz-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    -o-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
  }
}
@keyframes scaleInSmooth {
  from {
    -webkit-transform: scale(0.9) translate3d(0, 0, 0) cubic-bezier(0, 0, 0, 1);
    -moz-transform: scale(0.9) translate3d(0, 0, 0) cubic-bezier(0, 0, 0, 1);
    -ms-transform: scale(0.9) translate3d(0, 0, 0) cubic-bezier(0, 0, 0, 1);
    -o-transform: scale(0.9) translate3d(0, 0, 0) cubic-bezier(0, 0, 0, 1);
    transform: scale(0.9) translate3d(0, 0, 0) cubic-bezier(0, 0, 0, 1);
  }
  to {
    -webkit-transform: scale(1) translate3d(0, 0, 0) cubic-bezier(0, 0, 0, 1);
    -moz-transform: scale(1) translate3d(0, 0, 0) cubic-bezier(0, 0, 0, 1);
    -ms-transform: scale(1) translate3d(0, 0, 0) cubic-bezier(0, 0, 0, 1);
    -o-transform: scale(1) translate3d(0, 0, 0) cubic-bezier(0, 0, 0, 1);
    transform: scale(1) translate3d(0, 0, 0) cubic-bezier(0, 0, 0, 1);
  }
}
@keyframes verticalFloating {
  from {
    -webkit-transform: translateY(-3px);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
    transform: translateY(-3px);
  }
  to {
    -webkit-transform: translateY(3px);
    -moz-transform: translateY(3px);
    -ms-transform: translateY(3px);
    -o-transform: translateY(3px);
    transform: translateY(3px);
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-transition: opacity 0.3s ease 0s;
  -moz-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeOut {
  -webkit-transition: opacity 0.3s ease 0s;
  -moz-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slideFadeIn-lr {
  from {
    opacity: 0;
    overflow: hidden;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    overflow: initial;
  }
}
@keyframes slideFadeIn-rl {
  from {
    opacity: 0;
    overflow: hidden;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    overflow: initial;
  }
}
@keyframes slideFadeIn-tb {
  from {
    opacity: 0;
    overflow: hidden;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    overflow: initial;
  }
}
@keyframes slideFadeOut-tb {
  from {
    opacity: 1;
    overflow: initial;
  }
  100% {
    opacity: 0;
    overflow: hidden;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
}
@keyframes slideFadeOut-bt {
  from {
    opacity: 1;
    overflow: initial;
  }
  100% {
    opacity: 0;
    overflow: hidden;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@keyframes slideFadeIn-bt {
  from {
    opacity: 0;
    overflow: hidden;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    overflow: initial;
  }
}
.zoomInSmall {
  -webkit-animation-name: zoomInSmall;
  animation-name: zoomInSmall;
}
@keyframes zoomInSmall {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
    -moz-transform: scale3d(0.8, 0.8, 0.8);
    -ms-transform: scale3d(0.8, 0.8, 0.8);
    -o-transform: scale3d(0.8, 0.8, 0.8);
    transform: scale3d(0.8, 0.8, 0.8);
  }
  50% {
    opacity: 1;
  }
}
.zoomOutSmall {
  -webkit-animation-name: zoomOutSmall;
  animation-name: zoomOutSmall;
}
@keyframes zoomOutSmall {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
    -moz-transform: scale3d(0.8, 0.8, 0.8);
    -ms-transform: scale3d(0.8, 0.8, 0.8);
    -o-transform: scale3d(0.8, 0.8, 0.8);
    transform: scale3d(0.8, 0.8, 0.8);
  }
}
.zoomInSmooth {
  -webkit-animation-fill-mode: none;
  animation-fill-mode: none;
  -webkit-animation-name: zoomInSmooth;
  animation-name: zoomInSmooth;
  -webkit-transition: transform 0.5s ease 0s;
  -moz-transition: transform 0.5s ease 0s;
  -o-transition: transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s;
}
@keyframes zoomInSmooth {
  from {
    -webkit-transform: scale(0.96) translate3d(0, 0, 0);
    -moz-transform: scale(0.96) translate3d(0, 0, 0);
    -ms-transform: scale(0.96) translate3d(0, 0, 0);
    -o-transform: scale(0.96) translate3d(0, 0, 0);
    transform: scale(0.96) translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: scale(1) translate3d(0, 0, 0);
    -moz-transform: scale(1) translate3d(0, 0, 0);
    -ms-transform: scale(1) translate3d(0, 0, 0);
    -o-transform: scale(1) translate3d(0, 0, 0);
    transform: scale(1) translate3d(0, 0, 0);
  }
}
@keyframes small-pop {
  50% {
    transform: scale(1.01);
  }
}
.animBounce {
  -webkit-animation: bounce 1s;
  -o-animation: bounce 1s;
  animation: bounce 1s;
}
.animScaleUp {
  -webkit-animation: scaleUp 1s;
  -o-animation: scaleUp 1s;
  animation: scaleUp 1s;
}
.animPulse {
  -webkit-animation: pulse 1s;
  -o-animation: pulse 1s;
  animation: pulse 1s;
}
.animZoomIn {
  -webkit-animation: zoomIn 0.6s;
  -o-animation: zoomIn 0.6s;
  animation: zoomIn 0.6s;
}
.animFadeInTop {
  -webkit-animation: fadeInDown 0.6s;
  -o-animation: fadeInDown 0.6s;
  animation: fadeInDown 0.6s;
}
/* Hover actions */
html {
  font-size: 14px;
}
hr.selection-line {
  border-width: 1px;
  margin-top: -70px;
  width: 100%;
}
div.selection-button-empty {
  width: 40px;
  height: 75px;
}
div.selection-button {
  position: relative;
  top: 10px;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  border-radius: 20px 20px 20px 20px;
  border: 2px solid;
  font-size: 16px;
  line-height: 38px;
  z-index: 2;
  text-align: center;
}
div.selection-button span.icomoon-refresh {
  position: relative;
  top: -1px;
  font-size: 14px;
  line-height: 1;
  -webkit-animation: rotating 1s linear;
  -o-animation: rotating 1s linear;
  animation: rotating 1s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  line-height: 0;
}
div.selection-button.all {
  position: absolute;
  top: auto;
  left: -5px;
  bottom: -10px;
  font-size: 14px;
}
div.selection-button:hover {
  cursor: pointer;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
div.selection-button.active {
  cursor: default;
}
div.main-widget.active,
div.dual-bar-container.active,
div.selection-button.active {
  cursor: default;
}
div.owl-item:hover {
  cursor: pointer;
}
div.owl-item:hover label {
  cursor: pointer;
}
div.selection-button.all:hover,
div.selection-button-container:hover div.selection-button {
  cursor: pointer;
}
p.temp-text {
  font-size: 11px;
}
p.active {
  display: inline-block;
  -webkit-border-radius: 12px 12px 12px 12px;
  -moz-border-radius: 12px 12px 12px 12px;
  border-radius: 12px 12px 12px 12px;
}
/* Legend */
div#period-legend {
  padding: 10px 0;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
}
div#period-legend.affix {
  position: fixed;
  top: 43px;
  left: 225px;
  padding-right: 15px;
  /* Compensate for the vertical scrollbar */
  z-index: 99;
}
div.period-legend span.period {
  margin-right: 20px;
  margin-left: 4px;
}
div.period-legend span.label {
  font-size: 12px;
}
/* Dashboard Top */
div#dashboard-top {
  margin-top: 46px;
}
div#dashboard-top div.main-widget {
  position: relative;
  padding: 15px 0;
  z-index: 10;
}
div#dashboard-top div.main-widget p.main-widget-title {
  font-size: 16px;
  margin-bottom: 10px;
  padding: 2px 16px;
}
div#dashboard-top div.main-widget div.widget-container {
  position: relative;
}
div#dashboard-top div.main-widget div.widget-container div.stats-container {
  text-align: left;
  display: inline-block;
  padding: 0 5px;
  margin-left: 12px;
  -webkit-border-radius: 6px 6px 6px 6px;
  -moz-border-radius: 6px 6px 6px 6px;
  border-radius: 6px 6px 6px 6px;
}
div#dashboard-top div.main-widget div.widget-container div.stats-container div.stats-box {
  border-bottom: 1px solid #000;
  padding: 4px 8px;
  margin: 5px 2px;
}
div#dashboard-top div.main-widget div.widget-container div.stats-container div.stats-box span {
  font-size: 12px;
}
div#dashboard-top div.main-widget div.widget-container div.stats-container div.stats-box span.title {
  display: block;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 11px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  div#dashboard-top div.main-widget div.widget-container div.stats-container div.stats-box span.title {
    font-weight: 900;
  }
}
@media print {
  div#dashboard-top div.main-widget div.widget-container div.stats-container div.stats-box span.title {
    font-weight: 900;
  }
}
div#dashboard-top div.main-widget div.widget-container div.stats-container div.stats-box:last-child {
  border-bottom: 0;
}
div#dashboard-top div.main-widget div.widget-container div.gauge-container {
  width: 100px;
  position: relative;
  left: 50%;
  margin-left: -50px;
  text-align: center;
}
div#dashboard-top div.main-widget div.widget-container div.gauge-container label {
  position: absolute;
  font-size: 24px;
  display: block;
  font-weight: normal;
  top: 50%;
  margin-top: -25px;
  left: -20px;
}
div#dashboard-top div.main-widget div.widget-container div.gauge-container div.stat-diff {
  position: absolute;
  top: 50%;
  margin-top: 6px;
  left: -20px;
}
div#dashboard-top div.main-widget div.widget-container div.gauge-container div.stat-diff span {
  font-size: 12px;
}
div#dashboard-top div.main-widget div.widget-container div.gauge-container div.stat-diff span.icon {
  font-size: 14px;
}
div#dashboard-top div.main-widget div.widget-container div.gauge-container p.gauge-plus {
  position: absolute;
  top: -4px;
  font-size: 20px;
  left: -15px;
}
div#dashboard-top div.main-widget div.widget-container div.gauge-container p.gauge-minus {
  position: absolute;
  bottom: -4px;
  font-size: 20px;
  left: -15px;
}
div#dashboard-top div.main-widget div.widget-container div.gauge-container p.gauge-zero {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: -12px;
  font-size: 12px;
}
div#dashboard-top div.main-widget div.widget-container div.col-xs-6 {
  position: static;
}
div#dashboard-top div.main-widget div.widget-container div.total-line {
  position: absolute;
  height: 112%;
  width: 1px;
  right: 8px;
  top: -10px;
}
div#dashboard-top hr.selection-line {
  display: none;
}
/* Dashboard Middle */
div#dashboard-middle {
  display: none;
  padding: 35px 20px 30px 20px;
  margin-top: -20px;
}
div#dashboard-middle div.main-widget-comparison {
  padding-bottom: 10px;
}
div#dashboard-middle div.main-widget-comparison span.title {
  display: block;
  font-size: 16px;
}
div#dashboard-middle div.main-widget-comparison span.range {
  display: block;
  font-size: 12px;
}
div#dashboard-middle div.main-widget-comparison span.value {
  display: block;
  font-size: 46px;
}
div#dashboard-middle div.main-widget-comparison hr {
  margin: 10px 0;
}
div#dashboard-middle div.main-widget-comparison div.comparison-total {
  font-size: 20px;
  white-space: nowrap;
}
div#dashboard-middle div.breakdown-container div.dual-bar-info {
  margin-top: 12px;
}
div#dashboard-middle div.breakdown-container p.dual-bar-title {
  padding: 2px 16px;
  font-size: 14px;
}
div#dashboard-middle hr.selection-line {
  margin-top: -30px;
}
/* Dashboard Bottom */
div#dashboard-bottom {
  padding: 20px 0;
}
div#dashboard-bottom p.loaded-header {
  margin-bottom: 15px;
}
/* Loaded Header */
span.header-icon {
  float: left;
  font-size: 22px;
}
p.loaded-header {
  font-size: 14px;
  float: left;
  margin-left: 12px;
  font-weight: normal;
}
p.loaded-header label {
  border-bottom: 2px solid;
  margin-right: 2px;
}
.data-freshness-date-container {
  float: right;
}
.data-freshness-date-container strong {
  color: #00adef;
}
.fa-rotate-45 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
div.only-agents,
div.only-clients,
div.only-dashboard-override {
  display: none;
}
div.empty-logo {
  border: 2px dashed;
  width: 230px;
  height: 90px;
  line-height: 90px;
  margin-bottom: 10px;
  text-align: center;
}
div.current-logo {
  padding: 15px;
  margin-bottom: 8px;
  width: 230px;
  height: 90px;
}
div.current-logo img {
  max-width: 200px;
  max-height: 60px;
}
label.remove-logo {
  margin-top: 5px;
}
label.remove-logo input[type="checkbox"] {
  margin-right: 4px;
}
span.light-label {
  margin-left: 4px;
  border: 1px solid;
}
span.dark-label {
  margin-left: 4px;
  border: 1px solid;
}
div.current-template {
  padding: 15px;
  margin-bottom: 8px;
  width: 230px;
}
/* Import wizard styles */
span.icon-empty {
  display: block;
  width: 110px;
  height: 110px;
  margin: 9px;
  border: 3px #fff dashed;
  text-align: center;
  font-size: 11px;
  color: #fff;
  line-height: 110px;
}
table.upload-data-table .text-muted {
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  max-width: 120px;
}
table.upload-data-table td.data-type-cell {
  position: relative;
  min-width: 105px;
}
table.upload-data-table td.data-type-cell .icon {
  font-size: 16px;
  position: absolute;
  left: 2px;
  top: 50%;
  margin-top: -11px;
}
table.upload-data-table td.data-type-cell span.icon {
  left: 6px;
}
table.upload-data-table th.data-mapping-cell {
  min-width: 250px;
}
table.upload-data-table td.data-mapping-cell {
  min-width: 250px;
}
table.upload-data-table td.data-mapping-cell div.input-group input.form-control {
  width: 43%;
}
table.upload-data-table td.data-mapping-cell div.input-group div.input-group-addon {
  width: 14%;
  float: left;
  top: 0;
  padding: 9px 12px;
}
table.upload-data-table div.add-row {
  display: block;
  width: 100%;
  height: 30px;
  text-align: center;
  font-size: 22px;
  line-height: 30px;
}
/* Client groups styles */
#client-group-client-table_wrapper i.icon {
  font-size: 20px;
  position: relative;
  top: 2px;
  margin-right: 5px;
  margin-left: -8px;
}
#client-group-client-table_wrapper .dataTables_header {
  height: 42px;
}
#client-group-client-table_wrapper table.dataTable thead td {
  border-bottom: 0;
}
div#campaign-stats {
  margin-top: -8px;
}
div#campaign-stats div.service-data-container {
  -webkit-border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  border-radius: 4px 4px 4px 4px;
}
div#campaign-stats div.panel-heading .panel-title {
  text-indent: 0;
}
div#campaign-stats div.panel-heading .panel-title span.icon {
  font-size: 20px;
  float: left;
  margin-right: 8px;
  margin-top: -3px;
}
div#campaign-stats div.panel-body {
  padding: 0;
}
div#campaign-stats div.service-square {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
div#campaign-stats div.service-square div.icon {
  margin-top: 0;
}
div#campaign-stats div.service-square div.serviceicon-custom-icon {
  font-size: 58px;
  margin: 20px 0;
}
div#campaign-stats div.service-square span.title {
  font-size: 14px;
  white-space: normal;
  margin-top: -2px;
  display: block;
  padding: 0 4px;
}
div#campaign-stats h4 {
  margin-bottom: 20px;
}
div#campaign-stats div.stat-detail {
  zoom: 1;
}
div#campaign-stats div.stat-detail:before,
div#campaign-stats div.stat-detail:after {
  content: "";
  display: table;
}
div#campaign-stats div.stat-detail:after {
  clear: both;
}
div#campaign-stats hr.selection-line {
  position: relative;
  top: -35px;
}
div#campaign-stats div.period-legend-holder {
  margin-left: 10px;
}
div#campaign-stats div.chart-container {
  margin-left: 6px;
}
div#service-metrics {
  min-height: 131px;
}
div.stat-item {
  display: inline-block;
  min-width: 150px;
}
div.stat-item span.value {
  font-size: 32px;
}
div.stat-item span.changes {
  font-size: 11px;
  position: relative;
  top: -7px;
  margin-left: 3px;
}
div.stat-item div.stat-diff {
  margin-bottom: 5px;
  margin-top: -3px;
}
div.stat-item span.title {
  display: block;
  font-size: 12px;
  margin-bottom: 6px;
  text-transform: uppercase;
}
div.chart-container div.chart-filters {
  visibility: hidden;
  margin: 8px 0 10px 0;
}
div.chart-container .dxc-legend {
  cursor: pointer;
}
form#breakdown-form {
  padding: 8px 0;
}
form#breakdown-form span.title {
  margin-left: 20px;
  display: inline-block;
  font-size: 14px;
  text-align: right;
}
form#breakdown-form input {
  margin: 0 6px 0 18px;
}
/* Custom steprep classes */
div#steprep-dashboard-container {
  display: none;
}
div#steprep-dashboard-container div.panel {
  position: absolute;
  top: 200px;
  bottom: 0;
  left: 30px;
  right: 30px;
}
div#steprep-dashboard-container div.panel div.panel-body {
  padding: 0;
  position: absolute;
  top: 50px;
  bottom: 10px;
  left: 10px;
  right: 10px;
}
div#steprep-dashboard-container div.panel iframe {
  height: 100%;
  border: 0;
}
div.impressions-info {
  margin-left: 15px;
  margin-top: 5px;
  display: block;
}
div.impressions-info span {
  font-weight: bold;
}
/***************** Manage Preferences **********************/
div.service-preference-input-container input.text {
  float: left;
  width: 350px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
div.service-preference-input-container input.color {
  -webkit-border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  border-radius: 4px 4px 4px 4px;
  width: 32px;
  height: 32px;
}
div.service-preference-input-container div .tap-color-picker-container {
  float: left;
  width: 32px;
  margin-right: 10px;
}
div.service-preference-input-container div.service-square {
  width: 34px;
  height: 34px;
  line-height: 34px;
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
div.service-preference-input-container div.service-reset-color {
  position: relative;
  width: 34px;
  height: 34px;
  line-height: 32px;
  float: left;
  padding: 0;
  text-align: center;
  font-size: 12px;
  -webkit-border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  border-radius: 4px 4px 4px 4px;
}
div.service-preference-input-container div.service-reset-color i.icon {
  margin: 0px;
}
/***************** Reporting Profiles **********************/
div.reporting-profile-content.tab-pane {
  padding-top: 0;
}
div.reporting-profile-content.tab-pane div.service-container,
div.reporting-profile-content.tab-pane div.category-container {
  position: relative;
  overflow-x: hidden;
  min-height: 580px;
}
div.reporting-profile-content.tab-pane div.service-tab,
div.reporting-profile-content.tab-pane div.category-tab {
  cursor: pointer;
  width: 250px;
  padding: 10px 10px 8px 15px;
  border-left: 4px solid;
}
div.reporting-profile-content.tab-pane div.service-tab div.is-connected-circle,
div.reporting-profile-content.tab-pane div.category-tab div.is-connected-circle {
  float: left;
  margin-top: 10px;
  margin-right: 12px;
}
div.reporting-profile-content.tab-pane div.service-tab div.title,
div.reporting-profile-content.tab-pane div.category-tab div.title {
  float: left;
  max-width: 195px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
div.reporting-profile-content.tab-pane div.service-tab span,
div.reporting-profile-content.tab-pane div.category-tab span {
  margin-left: 10px;
  font-size: 12px;
  line-height: 32px;
}
div.reporting-profile-content.tab-pane div.service-tab:hover,
div.reporting-profile-content.tab-pane div.category-tab:hover {
  border-left: 4px solid;
}
div.reporting-profile-content.tab-pane div.service-tab.active,
div.reporting-profile-content.tab-pane div.category-tab:hover {
  border-left: 4px solid;
}
div.reporting-profile-content.tab-pane div.service-content,
div.reporting-profile-content.tab-pane div.category-content {
  position: absolute;
  top: 0;
  left: 250px;
  right: 0;
  padding: 15px 30px;
  height: 100%;
  overflow-y: auto;
}
div.reporting-profile-content.tab-pane div.service-content div.header img,
div.reporting-profile-content.tab-pane div.category-content div.header img {
  height: 115px;
}
div.reporting-profile-content.tab-pane div.service-content div.content,
div.reporting-profile-content.tab-pane div.category-content div.content {
  margin-left: 16px;
}
div.reporting-profile-content.tab-pane div.service-content div.content p,
div.reporting-profile-content.tab-pane div.category-content div.content p {
  font-size: 18px;
  margin-bottom: 8px;
}
div.reporting-profile-content.tab-pane div.service-content div.content div.is-connected-display,
div.reporting-profile-content.tab-pane div.category-content div.content div.is-connected-display {
  margin-bottom: 10px;
}
div.reporting-profile-content.tab-pane div.service-content div.content div.is-connected-display div.is-connected-circle,
div.reporting-profile-content.tab-pane div.category-content div.content div.is-connected-display div.is-connected-circle {
  margin-top: 3px;
  margin-right: 8px;
}
div.reporting-profile-content.tab-pane div.service-content ul.nav,
div.reporting-profile-content.tab-pane div.category-content ul.nav {
  margin-top: 15px;
}
div.reporting-profile-content.tab-pane div.service-content div.tab-content div.tab-pane,
div.reporting-profile-content.tab-pane div.category-content div.tab-content div.tab-pane {
  padding: 5px 20px 10px 20px;
}
div.reporting-profile-content.tab-pane div.service-content div.tab-content div.tab-pane h4,
div.reporting-profile-content.tab-pane div.category-content div.tab-content div.tab-pane h4 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 14px;
}
div.reporting-profile-content.tab-pane div.service-content div.tab-content div.tab-pane select,
div.reporting-profile-content.tab-pane div.category-content div.tab-content div.tab-pane select {
  width: 280px;
  margin-bottom: 14px;
}
div.reporting-profile-content.tab-pane div.service-content div.metric-example,
div.reporting-profile-content.tab-pane div.category-content div.metric-example {
  float: left;
  cursor: default;
  border: 2px dashed;
  width: 200px;
  margin-top: 18px;
  margin-left: 40px;
  padding: 20px;
  text-align: center;
}
div.reporting-profile-content.tab-pane div.service-content div.metric-example div.selection-button,
div.reporting-profile-content.tab-pane div.category-content div.metric-example div.selection-button {
  margin-bottom: 10px;
}
div.reporting-profile-content.tab-pane div.service-content input[type=checkbox],
div.reporting-profile-content.tab-pane div.category-content input[type=checkbox] {
  margin-right: 7px;
  margin-bottom: 6px;
}
div.reporting-profile-content.tab-pane div.is-connected-circle {
  width: 10px;
  height: 10px;
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid;
}
/***************** Column sorting **********************/
div.columns-container h5 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
}
div.columns-container h5 span {
  margin-left: 8px;
  font-size: 12px;
  font-weight: normal;
}
div.columns-container ul {
  padding-left: 0;
}
div.columns-container ul li {
  cursor: move;
}
div.columns-container ul li span.icon {
  margin-left: -5px;
  margin-right: 10px;
  margin-bottom: -2px;
}
div.columns-container ul li span.move-column {
  cursor: pointer;
}
div.columns-container ul li.web-only {
  font-style: italic;
}
div.columns-container ul.not-sortable-columns li {
  cursor: default;
}
div.columns-container ul.not-sortable-columns li span.icon {
  display: none;
}
/***************** Generated/Scheduled Reports **********************/
form.report-form div.checkbox input {
  margin-top: 2px;
}
form.report-form div.radio input {
  margin-top: 1px;
}
form.report-form div.radio-fixed {
  padding-top: 5px;
  height: 34px;
}
form.report-form div.radio-fixed p {
  margin-top: 4px;
  display: inline-block;
}
form.report-form div.radio-fixed input {
  margin-top: 5px;
}
form.report-form div.radio-fixed-small {
  padding-top: 3px;
  height: 24px;
  display: inline-block;
  margin-right: 15px;
}
form.report-form div.radio-fixed-small p {
  margin-top: 5px;
  display: inline-block;
}
form.report-form div.radio-fixed-small input {
  margin-top: 6px;
}
form.report-form div.popover p {
  font-size: 11px;
  line-height: 20px;
}
form.report-form div.input-group {
  width: 100%;
}
form.report-form div.input-group div.input-group-addon {
  display: inline;
  position: relative;
  top: 7px;
  padding-bottom: 7px;
}
form.report-form #report-filename-container,
form.report-form #report-filename-help {
  display: none;
}
/******************* Report Builder **********************/
#report-container {
  /* Report Content */
}
#report-container #ajax_main_loading,
#report-container #ajax_main_loading_container {
  left: 280px !important;
}
#report-container div#left-builder-frame {
  position: absolute;
  width: 280px;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 101;
  padding: 30px 8px 0 8px;
  border-right: 1px solid;
}
#report-container div#left-builder-frame a.back-action {
  position: absolute;
  display: block;
  -ms-filter: formatstring("progid:DXImageTransform.Microsoft.Alpha(opacity='{0}')", 80);
  filter: alpha(opacity=80);
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  opacity: 0.8;
  height: 25px;
  width: 280px;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 102;
}
#report-container div#left-builder-frame a.back-action i {
  line-height: 25px;
}
#report-container div#left-builder-frame a.back-action:hover {
  -ms-filter: formatstring("progid:DXImageTransform.Microsoft.Alpha(opacity='{0}')", 100);
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
#report-container div#left-builder-frame hr {
  margin: 16px 0 10px 0;
}
#report-container div#left-builder-frame div.form-group {
  margin-bottom: 0px;
}
#report-container div#left-builder-frame div.form-group input[type="text"] {
  margin-bottom: 5px;
}
#report-container div#left-builder-frame div.form-group select {
  font-size: 12px;
  height: 34px;
  margin-bottom: 5px;
}
#report-container div#left-builder-frame div.form-group label {
  font-weight: normal;
  margin-bottom: 0;
}
#report-container div#left-builder-frame div.form-group label input[type="checkbox"] {
  margin-right: 4px;
}
#report-container div#left-builder-frame span.label {
  margin-right: 10px;
  position: relative;
  font-size: 100%;
}
#report-container div#left-builder-frame h5 {
  margin-bottom: 15px;
  font-size: 13px;
}
#report-container div#left-builder-frame small.help-block {
  margin-top: -1px;
  margin-bottom: 10px;
}
#report-container div#left-builder-frame div#update-report {
  margin-top: 10px;
  width: 100%;
}
#report-container div#right-builder-frame {
  background-color: #fff;
  color: #1b1f27;
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  right: 0;
  left: 279px;
  overflow: auto;
}
#report-container div#right-builder-frame #report-header div.header {
  padding: 10px 15px;
}
#report-container div#right-builder-frame #report-placeholder {
  padding: 10px 20px;
}
#report-container div#right-builder-frame #report-placeholder div.jumbotron {
  margin-top: 30px;
}
#report-container div#right-builder-frame #report-placeholder div.jumbotron .export-menu-example {
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  text-align: center;
  background: #45484d;
  color: #f2f2f2;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  margin: 0 4px;
}
#report-container div#right-builder-frame div.chart-container {
  margin-bottom: 10px;
}
#report-container div.service-container h4 span,
#report-container div.category-container h4 span {
  margin-left: 12px;
  line-height: 32px;
}
#report-container div.service-container hr,
#report-container div.category-container hr {
  margin: 0;
}
#export-menu:before {
  content: "\ea40";
  font-family: 'icomoon';
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#export-menu {
  position: fixed;
  z-index: 100;
  bottom: 30px;
  right: 30px;
  display: block;
  width: 60px;
  height: 60px;
  text-align: center;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  background: #45484d;
  color: #f2f2f2;
  font-size: 24px;
  line-height: 60px;
  vertical-align: middle;
  cursor: pointer;
}
#export-menu div {
  display: none;
  position: absolute;
  left: 10px;
  bottom: 10px;
  color: #f2f2f2;
  text-align: center;
}
#export-menu div a {
  font-size: 20px;
  color: #f2f2f2;
  text-decoration: none;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  background: #45484d;
  line-height: 40px;
  display: block;
}
#export-menu div i.icomoon-clock {
  font-size: 26px;
  line-height: 40px;
}
#export-menu li.desktop a:hover,
#export-menu li.mobile a:active {
  -ms-filter: formatstring("progid:DXImageTransform.Microsoft.Alpha(opacity='{0}')", 80);
  filter: alpha(opacity=80);
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  opacity: 0.8;
}
.export-duplicate {
  position: relative !important;
  bottom: 0 !important;
  right: 0 !important;
  left: 15px;
  visibility: hidden;
}
.export-duplicate div {
  display: inline-block !important;
  visibility: visible;
}
@media print {
  div#export-menu {
    visibility: hidden;
  }
  div#right-builder-frame {
    left: 0;
    overflow: visible;
  }
}
.calculations .operand {
  display: inline-block;
}
.calculations .calculator {
  margin: 8px 64px;
  padding: 8px 32px;
}
.calculations .calculator .calculation-input {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  height: 50px;
}
.calculations .calculator .calculation-input .operand {
  font-size: 18px;
}
.calculations .calculator .calculation-input .operand.badge {
  font-weight: normal;
}
#calculation-calcinuse-modal .calcinuse-content {
  margin: 10px 0 40px 40px;
  word-wrap: break-word;
}
#calculation-calcinuse-modal .total-diff {
  font-style: italic;
}
/** If we make the parent level unselectable, then the children inherited the unselectable style.
The following is a hack to avoid that. To avoid this hack, we could have to revise all custom template .css **/
body div.smartconnector-select2 ul.select2-results li.select2-result-with-children .select2-result-label {
  cursor: default !important;
}
body div.smartconnector-select2 ul.select2-results li.select2-disabled {
  cursor: default !important;
}
body div.smartconnector-select2 ul.select2-results li.select2-result-with-children {
  -ms-filter: formatstring("progid:DXImageTransform.Microsoft.Alpha(opacity='{0}')", 100) !important;
  filter: alpha(opacity=100) !important;
  -moz-opacity: 1 !important;
  -khtml-opacity: 1 !important;
  opacity: 1 !important;
}
body div.smartconnector-select2 .select2-results .select2-result-label label {
  background-color: #333333 !important;
}
div.import-wizard-upload-container .file-caption-main {
  width: 75%;
}
div.import-wizard-upload-container .file-caption-main .hidden-xs {
  line-height: 18px;
}
div.import-wizard-upload-container .manualUploadWarningMessage {
  margin: 0 20px;
}
span.nowrap {
  white-space: nowrap;
}
.pointer-hand {
  cursor: pointer;
}
div.import-wizard-container input::placeholder,
div.import-wizard-container textarea::placeholder {
  opacity: 0.3;
}
div.import-wizard-container .icon-sc-warning {
  font-size: 16px;
  color: orange;
  margin-top: 5px;
  margin-left: -20px;
}
div.import-wizard-container .breadcrumb-container a {
  display: inline;
}
div.import-wizard-container .mlim6 {
  margin-left: -6px !important;
}
div.import-wizard-container .input-min-width {
  min-width: 220px;
}
div.import-wizard-container .sampledata {
  max-height: 300px;
  overflow-x: scroll;
}
div.import-wizard-container .samplemappingdata {
  max-height: 200px;
  max-width: 50%;
  overflow-x: scroll;
}
div.import-wizard-container .leftinline {
  float: left;
  display: inline;
}
div.import-wizard-container label.alert {
  display: block !important;
}
div.import-wizard-container .bgconfigfieldstate1 {
  background-color: #e8ebec !important;
}
div.import-wizard-container .bgconfigfieldstate2 {
  background-color: #e8ebec !important;
}
div.import-wizard-container .badge {
  color: #fff;
  background-color: #8cc63e;
  border-color: #8cc63e;
}
div.import-wizard-container .badge0 {
  color: #fff;
  background-color: #8cc63e;
  border-color: #8cc63e;
}
div.import-wizard-container .badge1,
div.import-wizard-container .badge3 {
  color: #584f3e;
  background-color: #fcf8e3;
  border-color: #fcf8e3;
}
div.import-wizard-container .badge2 {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #f2dede;
}
div.import-wizard-container .design-modal {
  font-size: smaller;
}
div.import-wizard-container .design-modal p.title {
  cursor: pointer;
}
div.import-wizard-container .design-modal p.title a.action {
  margin-right: 30px;
  float: right;
}
div.import-wizard-container .design-modal p.title a.action .icon {
  margin-left: -17px;
}
div.import-wizard-container input[type="text"].form-control.max-width-none,
div.import-wizard-container input[type="password"].form-control.max-width-none,
div.import-wizard-container select.form-control.max-width-none {
  max-width: none;
}
div.import-wizard-container .loadsample {
  padding-left: 0;
}
div.import-wizard-container table.upload-data-table td.data-mapping-cell div.input-group select.form-control {
  width: 43%;
}
div.import-wizard-container table.upload-data-table td input[type='checkbox'] {
  width: auto !important;
}
div.import-wizard-container textarea.extractdata {
  width: 100%;
  min-height: 60px;
  resize: vertical;
}
div.import-wizard-container div.sample_div {
  max-height: 50px;
  overflow-y: auto;
}
div.import-wizard-container div.data-fields-container {
  clear: both;
  overflow-x: auto;
}
div.import-wizard-container div.data-fields-container table.table th.data-mapping-cell span {
  display: inline-block;
  width: 45%;
}
div.import-wizard-container div.data-fields-container table.table th.data-mapping-cell i {
  width: 10%;
  margin-top: 3px;
}
div.import-wizard-container div.data-fields-container table.table td.data-mapping-cell {
  min-width: 300px;
}
div.import-wizard-container .panel-heading {
  min-height: 43px;
}
div.import-wizard-container .panel-heading .panel-title p {
  float: left;
}
div.import-wizard-container .panel-heading .panel-title a.action {
  margin-top: -8px;
  font-size: 12px;
}
div.import-wizard-container .panel-preprocess_functions .function-block,
div.import-wizard-container .panel-preprocess_functions .notice-block {
  margin-bottom: 20px;
}
div.import-wizard-container .panel-preprocess_functions input {
  margin-bottom: 15px;
}
div.import-wizard-container .panel-preprocess_functions select {
  margin-left: 0 !important;
}
div.import-wizard-container .panel-preprocess_functions .button-row {
  margin-top: -10px;
}
div.import-wizard-container .panel-preprocess_functions div.loading-wheel {
  margin-left: 5px !important;
}
div.import-wizard-container .panel-preprocess_functions div.custom-fields label {
  margin-top: 3px;
  margin-left: -15px;
}
div.import-wizard-container .panel-preprocess_functions a.remove-function {
  float: right;
  margin-top: -8px;
}
div.import-wizard-container .panel-preprocess_functions .show-extra-info {
  padding: 5px 0 10px 0;
}
div.import-wizard-container .panel-preprocess_functions table {
  margin-top: 20px;
}
div.import-wizard-container .panel-preprocess_functions table th,
div.import-wizard-container .panel-preprocess_functions table td {
  padding-left: 5px;
}
div.import-wizard-container .panel-preprocess_functions .code-samples textarea.code-sample {
  resize: vertical;
  min-height: 350px;
}
div.import-wizard-container .panel-preprocess_functions .code-samples .button-row {
  margin-top: 15px;
}
div.import-wizard-container .panel-preprocess_functions .code-samples #preprocess-curl-sample {
  margin-top: 20px;
}
div.import-wizard-container .panel-preprocess_functions .code-samples #preprocess-curl-sample textarea {
  margin-top: 10px;
  height: 34px;
}
div.import-wizard-container .form-suffix {
  min-height: 27px;
  padding-top: 7px;
  margin-left: -15px;
  font-weight: bold;
}
div.import-wizard-container .with-loading .full-width {
  max-width: 100% !important;
}
div.import-wizard-container .with-loading div.loading-wheel {
  margin-left: -20px !important;
}
div.import-wizard-container .loading-ml0 .loading-wheel {
  margin-left: 0 !important;
}
div.import-wizard-container #sc_delete_details {
  margin: 0px 0 40px 20px;
}
div.import-wizard-container #sc_delete_details .loading-wheel {
  margin-left: 0px;
}
div.import-wizard-container .panel-title div.loading-wheel {
  margin-top: -5px;
  margin-left: 10px;
  width: 28px;
  height: 28px;
}
div.import-wizard-container .full-width {
  max-width: 100% !important;
}
div.import-wizard-container .no-max-width {
  max-width: none !important;
}
div.import-wizard-container ul.disc {
  list-style-type: disc;
}
div.import-wizard-container .section-border {
  border-style: none none ridge ridge;
  border-width: 0 0 1px 1px;
  margin-left: 0;
  padding-left: 4px;
}
div.import-wizard-container input[type=checkbox].switch {
  margin-top: 5px;
}
div.import-wizard-container input[type=checkbox].selector {
  margin-right: 5px;
}
div.import-wizard-container .fluid-container.loading {
  font-size: 135%;
  font-weight: bold;
  color: #4ca939;
}
div.import-wizard-container #liveconnector-panel .attributes label,
div.import-wizard-container #liveconnector-panel .metrics label {
  margin-top: -7px;
  cursor: pointer;
}
div.import-wizard-container #liveconnector-panel .attributes label:hover,
div.import-wizard-container #liveconnector-panel .metrics label:hover {
  background-color: #4ca939;
}
div.import-wizard-container #liveconnector-panel .parameters div.parameter {
  padding: 5px 0 5px 15px;
}
div.import-wizard-container #liveconnector-panel .parameters input {
  max-width: 80%;
}
div.import-wizard-container #liveconnector-panel .parameters label {
  padding-top: 10px;
}
div.import-wizard-container #liveconnector-panel .form-group {
  padding-bottom: 15px;
}
div.import-wizard-container #liveconnector-panel .form-group.dataviews .form-control,
div.import-wizard-container #liveconnector-panel .form-group.filters .form-control {
  float: left;
  width: 500px;
  margin: 0 10px;
}
div.import-wizard-container #liveconnector-panel .form-group.dataviews .icon.remove,
div.import-wizard-container #liveconnector-panel .form-group.filters .icon.remove {
  padding-left: 5px;
  margin: 5px;
  cursor: pointer;
  font-size: 25px;
}
div.import-wizard-container #liveconnector-panel .form-group.dataviews .icon.remove:hover,
div.import-wizard-container #liveconnector-panel .form-group.filters .icon.remove:hover {
  color: #4ca939;
}
div.import-wizard-container #liveconnector-panel .form-group.dataviews .format,
div.import-wizard-container #liveconnector-panel .form-group.filters .format {
  padding: 10px;
}
div.import-wizard-container #liveconnector-panel .form-group.dataviews .format input,
div.import-wizard-container #liveconnector-panel .form-group.filters .format input {
  width: 60px;
}
div.import-wizard-container #liveconnector-panel .form-group.dataviews .format span,
div.import-wizard-container #liveconnector-panel .form-group.filters .format span {
  float: left;
  margin: 20px 5px 20px 20px;
}
div.import-wizard-container .subtoggle {
  padding-top: 7px;
  margin-left: 45px;
  font-size: 11px;
}
div.import-wizard-container .select2-container-multi {
  overflow: visible;
  max-height: none;
}
div.import-wizard-container .select2-container-multi .select2-choice,
div.import-wizard-container .select2-container-multi .select2-choices {
  border-color: rgba(51, 51, 51, 0.1) !important;
}
div.import-wizard-container .select2-container-multi .select2-choice .select2-search-choice,
div.import-wizard-container .select2-container-multi .select2-choices .select2-search-choice {
  border: 0;
  background: transparent !important;
  color: #fff;
  padding: 0 !important;
  margin: 0;
}
div.import-wizard-container .select2-container-multi .select2-choice .select2-search-choice div.select-data-column,
div.import-wizard-container .select2-container-multi .select2-choices .select2-search-choice div.select-data-column {
  color: #fff;
  background-color: #474f5e;
  padding: 6px 20px 6px 8px;
  min-width: 60px !important;
  max-width: 400px !important;
  font-size: 11px;
  line-height: 12px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 108px;
  -webkit-border-radius: 12px 12px 12px 12px;
  -moz-border-radius: 12px 12px 12px 12px;
  border-radius: 12px 12px 12px 12px;
}
div.import-wizard-container .select2-container-multi .select2-choice .select2-search-choice div.select-data-column span.icon,
div.import-wizard-container .select2-container-multi .select2-choices .select2-search-choice div.select-data-column span.icon {
  margin-right: 6px;
  position: relative;
}
div.import-wizard-container .select2-container-multi .select2-choice .select2-locked,
div.import-wizard-container .select2-container-multi .select2-choices .select2-locked {
  padding: 4px 2px 2px 5px !important;
}
div.import-wizard-container .select2-container-multi .select2-search-choice-close {
  filter: brightness(0) invert(1);
  top: 17px;
  right: 8px;
}
div.import-wizard-container .select2-container-multi .select2-search-choice-close:hover {
  filter: brightness(0) invert(0.8);
}
div.import-wizard-container .select2-container a.select2-choice span.select2-arrow {
  display: block !important;
}
div.import-wizard-container .demo_code {
  font-size: 90%;
}
div.import-wizard-container textarea.json_test {
  min-height: 240px;
}
div.import-wizard-container ul.list_json_path {
  list-style-type: disc;
  margin-top: 18px;
  padding-left: 20px;
}
div.import-wizard-container .sublabel {
  padding-top: 7px;
  font-size: 80%;
}
div.import-wizard-container .waiting_line {
  margin-top: 4px;
}
div.import-wizard-container .waiting_line .waiting_line_text {
  padding-top: 8px;
}
div.import-wizard-container .p5px {
  padding: 5px !important;
}
div.import-wizard-container table.geo-config-table {
  margin-top: 25px !important;
}
div.import-wizard-container table.geo-config-table td {
  padding: 0 rem(30px) 0 0;
  vertical-align: top;
}
div.import-wizard-container table.geo-config-table td input {
  width: 35%;
  font-size: rem(11px);
  padding: rem(3px);
}
div.import-wizard-container table.geo-config-table td div.input-group {
  vertical-align: middle;
  margin: rem(5px);
}
div.import-wizard-container table.geo-config-table td.data-mapping-cell input,
div.import-wizard-container table.geo-config-table td.data-type-cell input {
  height: rem(28px);
}
div.import-wizard-container table.geo-config-table td.data-mapping-cell div.input-group-addon,
div.import-wizard-container table.geo-config-table td.data-type-cell div.input-group-addon {
  height: rem(16px);
  width: 8% !important;
}
div.import-wizard-container table.geo-config-table td.data-mapping-cell span span.icon,
div.import-wizard-container table.geo-config-table td.data-type-cell span span.icon {
  left: rem(-2px) !important;
}
div.import-wizard-container table.geo-config-table th.location-type,
div.import-wizard-container table.geo-config-table td.location-type {
  width: 30%;
}
div.import-wizard-container table.geo-config-table td.location-type.pin,
div.import-wizard-container table.geo-config-table td.delete-column.pin {
  vertical-align: middle;
}
div.import-wizard-container table.geo-config-table th.field-name,
div.import-wizard-container table.geo-config-table td.field-name {
  width: 50%;
}
div.import-wizard-container table.geo-config-table th.field-name .field-pin,
div.import-wizard-container table.geo-config-table td.field-name .field-pin {
  margin: 10px 0;
}
div.import-wizard-container table.geo-config-table th.field-name .field-pin:first-child,
div.import-wizard-container table.geo-config-table td.field-name .field-pin:first-child {
  margin-top: 0;
}
div.import-wizard-container table.geo-config-table th.field-name .field-pin:last-child,
div.import-wizard-container table.geo-config-table td.field-name .field-pin:last-child {
  margin-bottom: 0;
}
div.import-wizard-container table.geo-config-table th.delete-column,
div.import-wizard-container table.geo-config-table td.delete-column {
  width: 20%;
  text-align: center;
}
div.import-wizard-container table.geo-config-table select {
  width: 90%;
}
div.import-wizard-container table.geo-config-table .show-extra-info {
  margin: 3px;
}
div.import-wizard-container table.geo-config-table .show-extra-info:not(.pin) {
  width: 200%;
}
table.sc-main-listing-table thead th:first-child {
  width: 10%;
}
.upcoming-schedule-container {
  height: 275px;
  overflow-y: auto;
}
.upcoming-schedule-container div.dataTables_header {
  display: none;
}
.upcoming-schedule-container #upcoming-scheduled-table,
.upcoming-schedule-container #upcoming-scheduled-warning {
  display: none;
}
#custom_data_date_range .radio-fixed {
  height: 45px;
}
#upcoming-scheduled-same-day-warning {
  display: none;
}
