/***************** Manage Preferences **********************/
div.service-preference-input-container {
    input.text {
        float:left;
        width:350px;
        .rounded(4px, 0, 0, 4px);
    }

    input.color {
        .rounded(4px);
		width: 32px;
		height: 32px;
	}

    div .tap-color-picker-container {
        float: left;
        width: 32px;
        margin-right: 10px;
    }

    div.service-square {
        width:34px;
        height:34px;
        line-height:34px;
        margin-left:-1px;
        .rounded(0, 4px, 4px, 0);
    }

    div.service-reset-color {
        position: relative;
        width: 34px;
        height: 34px;
        line-height: 32px;
        float: left;
        padding: 0;
        text-align: center;
        font-size: 12px;
        .rounded(4px);

        i.icon {
            margin: 0px;
        }
    }
}

/***************** Reporting Profiles **********************/

div.reporting-profile-content.tab-pane {
    padding-top:0;

    div.service-container, div.category-container {
        position: relative;
        overflow-x:hidden;
        min-height:580px;
    }

    div.service-tab, div.category-tab {
        cursor:pointer;
        width:250px;
        padding: 10px 10px 8px 15px;
        border-left:4px solid;

        div.is-connected-circle {
            float:left;
            margin-top: 10px;
            margin-right: 12px;
        }

        div.title {
            float:left;
            max-width:195px;
            white-space: nowrap;
            overflow:hidden;
            text-overflow: ellipsis;
        }

        span {
            margin-left:10px;
            font-size:12px;
            line-height:32px;
        }
    }

    div.service-tab:hover, div.category-tab:hover {
        border-left:4px solid;
    }

    div.service-tab.active, div.category-tab:hover {
        border-left:4px solid;
    }

    div.service-content, div.category-content {
        position:absolute;
        top:0;
        left:250px;
        right:0;
        padding:15px 30px;
        height:100%;
        overflow-y:auto;

        div.header {
            img {
                height:115px;
            }
        }

        div.content {
            p {
                font-size:18px;
                margin-bottom:8px;
            }
            margin-left:16px;

            div.is-connected-display {
                margin-bottom:10px;

                div.is-connected-circle {
                    margin-top:3px;
                    margin-right:8px;
                }
            }
        }

        ul.nav {
            margin-top:15px;
        }

        div.tab-content div.tab-pane {
            padding:5px 20px 10px 20px;

            h4 {
                font-size:16px;
                font-weight:bold;
                margin-bottom:14px;
            }

            select {
                width:280px;
                margin-bottom:14px;
            }
        }

        div.metric-example {
            float:left;
            cursor:default;
            border:2px dashed;
            width: 200px;
            margin-top: 18px;
            margin-left: 40px;
            padding: 20px;
            text-align:center;

            div.selection-button {
                margin-bottom:10px;
            }
        }

        input[type=checkbox] {
            margin-right: 7px;
            margin-bottom: 6px;
        }

    }

    div.is-connected-circle {
        width:10px;
        height:10px;
        .rounded(5px);
        border:1px solid;
    }

}


/***************** Column sorting **********************/

div.columns-container {

    h5 {
        font-size:14px;
        font-weight:bold;
        margin-bottom:15px;

        span {
            margin-left:8px;
            font-size:12px;
            font-weight:normal;
        }
    }

    ul {
        padding-left:0;

        li {
            cursor:move;

            span.icon {
                margin-left:-5px;
                margin-right:10px;
                margin-bottom:-2px;
            }

            span.move-column {
                cursor:pointer;
            }
        }

        li.web-only {
            font-style:italic;
        }
    }

    ul.not-sortable-columns {

        li {
            cursor:default;

            span.icon {
                display:none;
            }
        }
    }
}


/***************** Generated/Scheduled Reports **********************/

form.report-form {

    div.checkbox {
        input {
            margin-top: 2px;
        }
    }

    div.radio {
        input {
            margin-top: 1px;
        }
    }

    div.radio-fixed {
        padding-top:5px;
        height:34px;
        p {
            margin-top:4px;
            display: inline-block;
        }
        input {
            margin-top: 5px;
        }
    }

    div.radio-fixed-small {
        padding-top:3px;
        height:24px;
        display: inline-block;
        margin-right: 15px;

        p {
            margin-top:5px;
            display: inline-block;
        }

        input {
            margin-top: 6px;
        }
    }

    div.popover {
        p {
            font-size:11px;
            line-height:20px;
        }
    }

    div.input-group {
        width: 100%;

        div.input-group-addon {
            display: inline;
            position:relative;
            top:7px;
            padding-bottom: 7px;
        }
    }

    #report-filename-container,
    #report-filename-help {
        display: none;
    }
}

