#presentation-canvas {
    .z-index();
    width: 100vw;
    height: 100vh;
}

&.presentation-mode {

    export-builder-layers-page-panel {
        display: none;
    }
    export-builder-report-header {
        display: none;
    }
    export-builder-panel {
        display: none;
    }

    div.report-studio-container {
        top: 0;
    }

    export-builder-dashboard {
        pointer-events: none;
        user-select: none;
        .transition(all, 0.3s, ease);
        top: 0;
        left: 0;
        padding-right: 0;
        max-height: 100vh;
        max-width: 100vw;

        div.exportbuilder-dashboard {
            max-height: 100vh;
            background-color: black;

            .page-background-overlay {
                .transition(all, 0.3s, ease);
                .opacity(100);
            }

            div.page-content {
                overflow: hidden;
            }

            .page-background-overlay {
                .opacity(0);
                border: 1000px solid black !important;
            }
        }

    }
}