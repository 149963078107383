div.styles-tab-content {
    select.form-control {
        .bold();
        font-size: 11px;
        background-color: transparent !important;
        height: 22px;
        width: auto;
        min-width: 40px;
        margin-top: -2px;
        padding: 0;
    }

    &.tab-content {
        div.options-panel {
            padding: 0;

            .section-header {
                margin: 0;
                height: 30px;
                line-height: 28px;
                padding: 0 16px;
                font-size: 12px;
                .bold();
            }

            div.option-panel-item {
                position: relative;
                padding: 0 15px;

                label {
                    position: absolute;
                    top: 2px;
                    right: 100px;
                }
            }
        }
    }
}