div.widget-grid-item {

    &.is-dragging,
    &.is-positioning-post-drag {
        .z-index(); /* keep dragged item on top */
    }

    &:hover {
        div.widget-handle {
            .opacity(100);
        }
    }

    div.widget-handle {
        position: absolute;
        text-align: center;
        .opacity(75);
        .z-index();

        i.icon {
            display: block;
            position: relative;
        }
    }

    div.widget-resize-handle {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-right: 40px solid transparent;
        border-top: 40px solid;
        bottom: 8px;
        right: 8px;
        .rounded(0, 0, 10px, 0);
        cursor: nwse-resize;

        i.icon {
            position: relative;
            top: -38px;
            left: 23px;
            font-size: 16px;
            .transform(rotate(45deg));
        }
    }
}

//
// RESIZE WIDGET GRID
//
resize-widget-grid {
    div.resize-grid-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        .z-index(20);

        p.title {
            position: fixed;
            top: 50%;
            left: 0;
            font-size: 20px;
            .z-index();
        }

        div.widget-dimension-selector {
            .opacity(100);

            i.icon {
                position: absolute;
                top : 5px;
                left : 4px;
                z-index: 999;
                font-size: 20px;
            }


            div.size-box-container {
                width: 100%;
                height: 100%;
                text-align: center;

                div.size-box {
                    float: left;
                    width: @item-width;
                    height: 80px;
                    cursor: nwse-resize;
                    padding: 2px;

                    div.inner {
                        width: 100%;
                        height: 100%;
                    }
                }

                div.size-box.selected {
                    .opacity(40);
                }

                div.size-box.default,
                div.size-box.active {
                    .opacity(90);
                }
            }

        }
    }
}
