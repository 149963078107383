@import '../overview/serviceoverview.less';

/* Services Defaults */
div.service-flex-square {
    display:block;
    text-align:center;
    padding: 4px;

    span.title {
        .fontSmoothing();
        width:100%;
        word-wrap: break-word;
        text-align:center;
        color:#fff;
    }

    span.flex-icon,
    div.flex-icon {
        width:100%;
        margin-top: 0px;
        text-align:center;
        color:#fff;
    }

    [class^="icon-"],
    [class*="icon-"] {
        font-size: 70px;

        &[class*="serviceicon-"] {
            font-size: 100px;
        }
    }

    [class*="alphabet-"] {
          font-size: 100px;
    }
}

span.service-square,
div.service-square {
    display:block;
    position:relative;
    float:left;
    text-align:center;

    &.disconnected {
        .opacity(20);
    }

    span.title {
        .fontSmoothing();
        width:100%;
        text-align:center;
        color:#fff;
    }

    span.icon,
    div.icon {
        width:100%;
        text-align:center;
        color:#fff;
    }
}

div.service-flex-square.inactive,
div.service-square.inactive {

    span.title {
        color:rgba(0, 0, 0, .5);
    }

    div.icon {
        color:rgba(0, 0, 0, .5);
    }
}

div.service-flex-square.actionable,
div.service-square.actionable {
    cursor:pointer;

    span.title {
        position:absolute;
        bottom:45px;
        left:0;
        font-size:14px;
        font-weight:bold;
    }

    div.icon {
        position:absolute;
        top:40px;
        font-size:125px;
    }

    div.serviceicon-custom-icon {
        font-size:90px;
        line-height:130px;
    }
}

div.service-flex-square.actionable,
div.service-square.actionable:hover {

    span.title {
        color: #fff;
        text-shadow: 0 0 12px #fff;
        .transition(text-shadow, 0.2s);
    }

    div.icon {
        color:#fff;
        text-shadow: 0 0 12px #fff;
        .transition(text-shadow, 0.2s);
    }
}

div.service-flex-square.active,
div.service-square.active {

    span.title {
        color: #fff;
    }

    div.icon {
        color: #fff;
    }
}

div.service-flex-square.static
div.service-square.static {
    float:none;
}

div.service-square-options {
    position:absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    z-index:9;

    div.block {
        span {
            color:#fff !important;
        }
    }

    a.option {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index:10;
        text-align:center;

        div.option-inner {
            position:absolute;
            bottom:12px;
            width:100%;
            z-index:11;

            span {
                color:#fff;
                font-size:12px;
                padding:4px 10px;
                .rounded(10px);
                display:inline-block;
            }
        }
    }
}

div.service-square-options:hover {
    a.option {
        span {
            .transition();
        }
    }
}

.service-flex-square(@size: 32px, @ratio: 0.75) {
  width:@size;
  height:@size;

  .icon {
    line-height: @size;
    font-size: @size * @ratio;
  }

  .serviceicon-custom-icon {
    font-size: @size * @ratio * 0.8 !important;
  }
}

.service-square(@size: 32px, @ratio: 0.75) {
    width:@size;
    height:@size;

    .icon {
        line-height: @size;
        font-size: @size * @ratio;


        &.custom-icon,
        &.icomoon-bar-chart {
            font-size: @size * @ratio * 0.8 !important;
        }
    }

    .serviceicon-custom-icon {
        font-size: @size * @ratio * 0.8 !important;
    }
}

.service-square-16 {
    .service-square(16px, 0.6);
}

.service-square-24 {
    .service-square(24px);
}

.service-square-32 {
    .service-square(32px);
}

.service-square-48 {
    .service-square(48px);
}

.service-square-64 {
    .service-square(64px);
}

.service-square-128 {
    .service-square(128px);
}

.service-square-192 {
    .service-square(192px);
}

.service-square-256 {
    .service-square(256px);
}

/* Import wizard square stuff */
div.jumbotron {

    h2 {
        margin-bottom:25px;
    }

    div.service-square {
        margin-right:16px;
        margin-top:7px;
    }

    p.upsell-text {
        margin:40px 0 30px 0;
    }

    p.contact-text {
        margin-top:30px;
        font-size:16px;
    }
}


/*************** Services Dashboard ********************/
div.services-main-content,
div#services-main-content {
    padding: 10px;

    div#overall-campaign-row {
        margin-right:0;

        div.col-md-3 {
            padding:0;
        }
        div.col-md-9 {
            padding-right:0;
        }
    }

    div.panel {
        margin:15px 5px 15px 5px;

        div.panel-heading {

            div.date-info {
                font-size:13px;
                float:right;
            }
        }
    }

    div.progress {
        margin-bottom:0;
        position:relative;
        height:30px;
        top: 20px;
        .rounded(6px);

        div.progress-bar,
        a.progress-bar {
            line-height:30px;
        }

        a.progress-bar:hover {
            .transition(background-color);
        }
    }

    div.legend-panel {

        div.panel-body {
            padding: 9px 15px 14px 15px;
        }

        div.progress {
            position:static;
            height:20px;
            margin-top:6px;

            div.progress-bar {
                line-height:20px;
            }
        }
    }

    div#services-dashboard {
        margin-top: -10px;

        div.panel-heading {
            position:relative;
        }

        div.panel-body {
            padding:0;
        }
    }

    div.panel-wrapper {
        position:relative;

        div.panel {
            .transition(margin-left);
        }

        div.sort-handle-container {
            position:absolute;
            left: 32px;
            top:0;
            text-align:center;
            display:none;

            div.sort-handle-line {
                position:absolute;
                top:50%;
                left:40px;
                margin-top:-1px;
                width:30px;
                height:2px;
            }

            div.sort-handle {
                height:40px;
                width:40px;
                .rounded(20px);
                font-size:20px;
                line-height:38px;
                border:2px solid;
            }

            div.sort-handle:hover {
                cursor:move;
            }
        }
    }

    div.service-flex-square {
        div.icon {
            font-size:100px;
        }

        div.serviceicon-custom-icon {
          font-size: 70px;
        }
    }

    div.service-square {
        width:140px;
        position:absolute;
        top:0;
        bottom:0;
        left:0;

        div.icon {
            font-size:100px;
            margin-top:-35px;
        }

        div.serviceicon-custom-icon {
            font-size:70px;
            margin-top:-50px;
        }

        div.block {
            position:absolute;
            top:48%;
            width: 100%;
            color:#fff;
            z-index: -1;

            span.text,
            span.value {
                color:#fff;
            }
        }

        div.service-square-options {
            a.option div.option-inner {
                bottom:6px;

                span {
                    font-size:10px;
                }
            }
        }

    }

    div.service-square.small {

        div.icon {
            top:37px;
            right:30px;
            font-size:60px;

            &.category-custom-icon {
                font-size: 40px;
            }
        }

        div.serviceicon-custom-icon {
            top:17px;
            font-size:50px;
        }

        div.block {
            right: 15px;
            top: 10px;
            width: auto;
            padding: 0;
            min-width: 0;

            span.value {
                font-size:22px;
            }

            span.text {
                font-size:11px;
            }
        }

        div.service-square-options {
            a.option div.option-inner {
                bottom:3px;
            }
        }

    }

    div.service-square.small.current-client {
        div.icon {
            right:auto;
        }
    }

    div.service-flex-container {
      display: -webkit-flex; /* Safari */
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: stretch;
      align-content: stretch;
    }

    div.service-flex-square {
      width:140px;
    }

    div.service-flex-metrics {
      flex:1;
    }

    div.service-data-container {
        position:relative;
        float:left;
        width:100%;
        padding-left:140px;
        padding-bottom:10px;

        div.campaign-container {
            margin:10px 0;
            width:100%;
            min-height:70px;
            .clearfix();

            .progress-bar[aria-valuenow="0"] {
                min-width:100%;
            }

            .owl-wrapper {
                padding:0 15px;
            }

            .owl-controls .owl-buttons .owl-prev {
                left:5px;
            }

            .owl-controls .owl-buttons .owl-next {
                right:5px;
            }
        }
    }

    div.service-footer {
        margin:10px 10px 10px 10px;

        div.alert {
            margin-bottom:0;
        }
    }

    div.block {
        float:left;
        padding:6px 18px;
        text-align:center;
        min-width: 120px;

        span.value {
            font-size:28px;
            .fontSmoothing();
        }

        span.text {
            display:block;
            font-size:12px;
            text-transform: uppercase;
        }
    }

    //Score circle
    div.circle {
        float:right;
        width:60px;
        height:60px;
        .rounded(30px);
        text-align:center;
        color:#fff;
        padding-top:7px;
        margin:15px 15px 0 20px;

        span.value {
            font-size:20px;
        }

        span.text {
            display:block;
            font-size:10px;
            text-transform: uppercase;
        }
    }

    hr {
        margin:0;
    }

}

/*************** Connect Page ********************/

.expand-services {
    display: none;
    position: relative;
    float: right;
    font-size: 24px;
    margin-top: 16px;
//: 10px;
}
.collapse-services {
    position: relative;
    float: right;
    font-size: 24px;
    margin-top: 16px;
}
div.iwmk-container {
    padding-bottom:55px;
    min-height: 55px;
}
div.i-container {
    min-height: 55px;
}
div.io-container {
    min-height: 55px;
}
div.service-footer{
    footer {
        height:70px;
        max-height:70px;
        p {
            line-height:64px;
            position:relative;
            top:2px;
            margin:0 5px;
            font-size:18px;
            display:inline-block;
        }

        span.icomoon-plug {
            position:relative;
            top:10px;
            font-size:32px;
            margin-right:6px;
        }

        a.btn {
            padding: 10px 30px;
        }
    }
}
div.connect-container {
    overflow-x:hidden !important;
    div.service-square-container {
        div.service-square {
            width:16.666%;
            height:250px;
            .innershadow(1px, 1px, 5px, 0.1);
            div.icon {}
        }
        div.service-square-options {
            a.is-connected-badge {
                position:absolute;
                top:6px;
                right:6px;
                .rounded(20px);
                display: inline-block;
                font-size: 24px;
                height: 40px;
                width: 40px;
                line-height: 46px;
                text-align:center;
                z-index:12;
            }

            a.is-connected-badge:not(.static):hover {
                line-height: 44px;
            }

            a.is-connected-badge.static {
                cursor:default;
            }
        }
    }

}

/* Scores */
div.entry-red-head,
div.entry-yellow-head,
div.entry-green-head {
    color:#fff;
    width:32px;
    height:32px;
    .rounded(16px);
    text-align: center;
    margin: -4px 0;

    h4 {
        line-height:32px;
        font-size:16px;
        margin: 0;
    }
}

/* Custom icon selector */

#select-icon-modal {
    div.modal-body {
        height:350px;
        overflow-y:auto;
    }

    div.icon-container {
        display: inline-block;
        width: 60px;
        height: 60px;
        line-height: 56px;
        font-size: 32px;
        text-align: center;
        border: 1px dashed;
        .rounded(5px);
        margin: 10px;
    }

    div.icon-container.selected {
        cursor:default;
    }

    div.icon-container:hover {
        cursor:pointer;
    }
}
