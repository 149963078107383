.calculations {

  .operand {
    display: inline-block;
  }

  .calculator {

    margin: 8px 64px;
    padding: 8px 32px;

    .calculation-input {
      white-space:nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      height: 50px;

      .operand {
        font-size: 18px;

        &.badge {
          font-weight: normal;
        }
      }
    }
  }
}
#calculation-calcinuse-modal {
  .calcinuse-content {
    margin: 10px 0 40px 40px;
    word-wrap: break-word;
  }
  .total-diff {
    font-style: italic;
  }
}
