@import "../notes/leads.notes.less";

leads-details {

}

.datagrid-display {
    td {
        a.note-action {
            padding-left: 5px;

            span {
                margin-left: 5px;
                font-size: 14px;
                position: relative;
                top: -1px;
            }
        }
    }
}

.tag-badge {
    padding-left: 10px;
    transition: all 0.1s ease;
    font-size: 12px;
    i {
        position: relative;
        left: -3px;
        display: initial;
    }
}

td {
    .tag-badge:not(.no-click) {
        cursor: pointer;
        &:hover {
            opacity: 0.85;
        }
    }
}

td.taggable {
    span.tag {
        padding: 2px 6px 2px 6px;
        border: dashed 1px transparent;
        border-radius: 10px;
        i.tag {
            opacity: 0;
            position: relative;
            left: -5px;
            margin-left: 5px;
        }
    }
    &:hover:not(no-click) {
        cursor: pointer;
        span.tag {
            padding: 2px 6px 2px 6px;
            border: dashed 1px;
            i.tag {
                opacity: 1;
            }
        }
    }
}

div.leads-details-footer {
    display: inline-block;
    float: right;

    .alert {
        margin: 0;
    }
}

