#layout-library-modal {

    layout-library-detailed-item {
        display: block;
        div.library-detailed-item {
            min-height: 400px;
        }
    }
}

div.manage-library-layout {
    table.dataTable {
        td {
            div.dash-thumbnail-container {
                text-align: center;
                overflow: hidden;

                div.inner {
                    width: 100px;
                    height: 125px;
                    display: inline-block;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}