div.design-widget {
    &:hover {
        span.widget-interaction-container {
            span.dev-toggler {
                visibility: visible;
            }
        }
        span.widget-option-container {
            nav.widget-options {
                > i.icon {
                    visibility: visible;
                }
            }
        }
    }

    .font-weight-400 {
        font-weight: 400;
    }

    span.widget-option-container {
        nav.widget-options {
            &.open {
                > ul.dropdown-menu {
                    position: initial;
                    margin-bottom: 150px;
                    margin-right: 22px;
                }
                i[data-toggle="dropdown"] {
                    display: none;
                }
            }
        }
    }

    span.widget-interaction-container {
        .z-index(3);
        position: absolute;
        right: 30px;
        top: 5px;

        span.widget-warning {
            position: relative;
            top: 9px;
            left: 0px;
            z-index: 1004;
            i {
                font-size: 14px;
            }
        }
    }

    span.widget-option-container {
        z-index: 1004;
        position: absolute;
        right: 2px;
        top: 5px;

        nav.widget-options {
            position: relative;
            display: inline-block;
            margin-right: 8px;
            margin-top: 7px;

            > i.icon {
                font-size: 20px;
                display: block;
                width: 20px;
                height: 20px;
                cursor: pointer;
                visibility: hidden;
            }

            ul {
                right: 0;
                left: auto;

                a {
                    padding: 5px 12px;
                }
            }

            // for nested <ul> tags
            > ul {
                ul {
                    .box-shadow(none);
                }
            }
        }
    }

    span.sample-option-container {
        position: absolute;
        display: block;
        top: 9px;
        right: 15px;

        p.title,
        span.help-tooltip {
            border: 0;
            text-indent: 0;
            display: inline-block;
            position: relative;
            top: 4px;
            font-size: 12px;
            .bold();
        }

        span.help-tooltip {
            top: 5px;
        }

        //
        // SWITCH CHECKBOX
        //
        input[type=checkbox].switch {
            width: 32px;
            height: 16px;
            .rounded(10px);
            margin-top: 5px;

            &:after {
                content: '';
                width: 12px;
                height: 12px;
                top: 1px;
                left: 1px;
            }

            &:checked:after {
                .transform(translate3d(16px, 0, 0));
            }
        }
    }
}