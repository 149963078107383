library-publish-form {
    library-publish-form-before {
        div.title {
            margin-top: 7px;
        }
    }
}

#publish-selection-modal {

    div.modal-dialog {
        width: 450px;
        height: 170px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -150px;
        margin-top: -85px;
    }

    .modal-title {
        font-size: 15px;
    }

    .modal-header .close {
        font-size: 32px;
        margin-top: -8px;
    }

    div.modal-body {
        > div.inner {
            .flex();
            .justify-content(space-around);
        }

        input.switch {
            position: relative;
            left: 10px;
            top: -4px;
        }
    }

    div.publish-option-container {
        width: 235px;
        height: 100px;
        text-align: center;
        border-right: 1px solid;
        cursor: pointer;
        .bold();

        &:last-child {
            border: 0;
        }

        i.icon {
            margin-top: 14px;
            display: block;
            font-size: 30px;
        }

        span {
            display: block;
            margin-top: 10px;
            text-transform: uppercase;
        }
    }
}