@font-face {
  font-family: 'services';
  src:  url('../fonts/services.eot?osg271');
  src:  url('../fonts/services.eot?osg271#iefix') format('embedded-opentype'),
    url('../fonts/services.ttf?osg271') format('truetype'),
    url('../fonts/services.woff?osg271') format('woff'),
    url('../fonts/services.svg?osg271#services') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="serviceicon-"], [class*=" serviceicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'services' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.serviceicon-placer:before {
  content: "\ea15";
}
.serviceicon-walmartconnectdisplay:before {
  content: "\e9d3";
}
.serviceicon-walmartconnect:before {
  content: "\ea12";
}
.serviceicon-sc_pilotx:before {
  content: "\ea13";
}
.serviceicon-sc_compulse:before {
  content: "\ea14";
}
.serviceicon-stripe:before {
  content: "\ea10";
}
.serviceicon-sc_vistar_media:before {
  content: "\e96f";
}
.serviceicon-sc_reveal:before {
  content: "\ea0a";
}
.serviceicon-sc_advanced_web_ranking:before {
  content: "\ea0b";
}
.serviceicon-sc_ad_geo:before {
  content: "\ea0c";
}
.serviceicon-taporders:before {
  content: "\ea09";
}
.serviceicon-redditads:before {
  content: "\ea08";
}
.serviceicon-sc_clickhouse:before {
  content: "\ea02";
}
.serviceicon-sc_alloydb:before {
  content: "\ea03";
}
.serviceicon-applesearchads:before {
  content: "\ea04";
}
.serviceicon-tapleads:before {
  content: "\ea01";
}
.serviceicon-klaviyonativemetrics:before {
  content: "\e9ff";
}
.serviceicon-sc_agentz:before {
  content: "\e9fb";
}
.serviceicon-sc_onspot:before {
  content: "\e9fc";
}
.serviceicon-sc_wehaa:before {
  content: "\e9fd";
}
.serviceicon-gradient:before {
  content: "\e9f7";
}
.serviceicon-gradient_fill:before {
  content: "\e9f8";
}
.serviceicon-sc_tapdirect:before {
  content: "\e9f0";
}
.serviceicon-sc_googlecloud:before {
  content: "\e9f1";
}
.serviceicon-sc_email:before {
  content: "\e9f2";
}
.serviceicon-sc_azuresql:before {
  content: "\e9f3";
}
.serviceicon-sc_azurebs:before {
  content: "\e9f4";
}
.serviceicon-sc_amazonaurora:before {
  content: "\e9f5";
}
.serviceicon-sc_amazonathena:before {
  content: "\e9f6";
}
.serviceicon-yahoojapansearch:before {
  content: "\e9e8";
}
.serviceicon-yahoojapandisplay:before {
  content: "\e9f9";
}
.serviceicon-sc_snowflake_isolated:before {
  content: "\e9e9";
}
.serviceicon-sc_sabio:before {
  content: "\e9ea";
}
.serviceicon-sc_mysql_isolated:before {
  content: "\e9eb";
}
.serviceicon-sc_madhive:before {
  content: "\e9ec";
}
.serviceicon-sc_brightedge:before {
  content: "\e9ee";
}
.serviceicon-marchexmarketingedge:before {
  content: "\e9ef";
}
.serviceicon-sc_logiqdigital:before {
  content: "\e9e7";
}
.serviceicon-sc_amazondsp:before {
  content: "\e933";
}
.serviceicon-sc_wideorbit:before {
  content: "\e95f";
}
.serviceicon-adcellerant:before {
  content: "\e9a6";
}
.serviceicon-sitescout:before {
  content: "\e9a8";
}
.serviceicon-centrobasisplatform:before {
  content: "\e9aa";
}
.serviceicon-basisbcireports:before {
  content: "\ea00";
}
.serviceicon-amazondsp:before {
  content: "\e9de";
}
.serviceicon-adobeanalytics:before {
  content: "\e9e0";
}
.serviceicon-tiktokads:before {
  content: "\e9e1";
}
.serviceicon-sc_redcanyon:before {
  content: "\e9e2";
}
.serviceicon-sc_knorex:before {
  content: "\e9e3";
}
.serviceicon-brightroll:before {
  content: "\e9e4";
}
.serviceicon-yahoogemini:before {
  content: "\e9e5";
}
.serviceicon-sc_admessenger:before {
  content: "\e9dc";
}
.serviceicon-sc_sqlapp:before {
  content: "\e9dd";
}
.serviceicon-sc_xpoapp:before {
  content: "\e9df";
}
.serviceicon-spotifyads:before {
  content: "\e99f";
}
.serviceicon-stackadapt:before {
  content: "\e9da";
}
.serviceicon-dotmailer:before {
  content: "\e97f";
}
.serviceicon-invoca:before {
  content: "\e980";
}
.serviceicon-kenshoo:before {
  content: "\e9a3";
}
.serviceicon-sc_adlib:before {
  content: "\e9d7";
}
.serviceicon-sc_gamut:before {
  content: "\e9d8";
}
.serviceicon-sc_iqm:before {
  content: "\e9d9";
}
.serviceicon-thryv:before {
  content: "\e9c8";
}
.serviceicon-sc_iovox:before {
  content: "\e9d4";
}
.serviceicon-sc_datadrive:before {
  content: "\e9ed";
}
.serviceicon-sc_appscience:before {
  content: "\ea05";
}
.serviceicon-sc_agencyplatform:before {
  content: "\ea06";
}
.serviceicon-sc_stirista:before {
  content: "\ea07";
}
.serviceicon-beeswax:before {
  content: "\e9be";
}
.serviceicon-chatmeter:before {
  content: "\e9c3";
}
.serviceicon-phonewagon:before {
  content: "\e9c4";
}
.serviceicon-getintent:before {
  content: "\e9c5";
}
.serviceicon-sc_adtheorent:before {
  content: "\e9cf";
}
.serviceicon-sc_leadsrx:before {
  content: "\e9d0";
}
.serviceicon-sc_friends2follow:before {
  content: "\e9d1";
}
.serviceicon-sc_freewheel:before {
  content: "\e9d2";
}
.serviceicon-sc_quickbooks:before {
  content: "\e9c2";
}
.serviceicon-ypcalls:before {
  content: "\e9c6";
}
.serviceicon-yptraffic:before {
  content: "\e9c7";
}
.serviceicon-zetadsp:before {
  content: "\e9c9";
}
.serviceicon-qmedia:before {
  content: "\e9ce";
}
.serviceicon-sc_adelphic:before {
  content: "\e9bf";
}
.serviceicon-adelphic:before {
  content: "\ea0d";
}
.serviceicon-sc_googlesheet:before {
  content: "\e9c0";
}
.serviceicon-brightcovevideocloud:before {
  content: "\e9c1";
}
.serviceicon-sc_geniusmonkey:before {
  content: "\e975";
}
.serviceicon-sc_a4media:before {
  content: "\e9b8";
}
.serviceicon-sc_loopme:before {
  content: "\e9b9";
}
.serviceicon-sc_gimbal:before {
  content: "\e9ba";
}
.serviceicon-sc_netsertive:before {
  content: "\e9bb";
}
.serviceicon-sc_infinity:before {
  content: "\e9bc";
}
.serviceicon-shopify:before {
  content: "\e9bd";
}
.serviceicon-sc_onedrive:before {
  content: "\e9b6";
}
.serviceicon-sc_box:before {
  content: "\e9b7";
}
.serviceicon-choozle:before {
  content: "\e9b4";
  color: #535559;
}
.serviceicon-amazonsellers:before {
  content: "\e9b5";
}
.serviceicon-vimeovideos:before {
  content: "\e9b3";
}
.serviceicon-thinknear:before {
  content: "\e9b2";
}
.serviceicon-sc_manual:before {
  content: "\e90a";
}
.serviceicon-sc_googlebq:before {
  content: "\e911";
}
.serviceicon-sc_sftp:before {
  content: "\e95c";
}
.serviceicon-sc_ftp:before {
  content: "\e979";
}
.serviceicon-sc_snowflake:before {
  content: "\e9ad";
}
.serviceicon-sc_postgresql:before {
  content: "\e9ae";
}
.serviceicon-sc_mysql:before {
  content: "\e9af";
}
.serviceicon-sc_amazonredshift:before {
  content: "\e9b0";
}
.serviceicon-sc_amazons3:before {
  content: "\e9b1";
}
.serviceicon-sc_dropbox:before {
  content: "\e9ca";
}
.serviceicon-sc_salesforce:before {
  content: "\e9cb";
}
.serviceicon-sc_drive:before {
  content: "\e9cc";
}
.serviceicon-sc_tapapi:before {
  content: "\e9cd";
}
.serviceicon-tubemogulapi:before {
  content: "\e9ac";
}
.serviceicon-amazonads:before {
  content: "\e940";
}
.serviceicon-localiq:before {
  content: "\e970";
}
.serviceicon-criteo:before {
  content: "\e99a";
}
.serviceicon-criteoretail:before {
  content: "\ea16";
}
.serviceicon-quora:before {
  content: "\e99e";
}
.serviceicon-wazecampaigns:before {
  content: "\e9a4";
}
.serviceicon-waze:before {
  content: "\e9e6";
}
.serviceicon-monosolutions:before {
  content: "\e9a5";
}
.serviceicon-aoladtech:before {
  content: "\e9a7";
}
.serviceicon-mozlocal:before {
  content: "\e9a9";
}
.serviceicon-ravensiteauditor:before {
  content: "\e9a2";
}
.serviceicon-ravenseo:before {
  content: "\e9ab";
}
.serviceicon-googlewebmastertools:before {
  content: "\e9a1";
}
.serviceicon-raventoolssiteauditor:before {
  content: "\e99d";
}
.serviceicon-newravenranktracking:before {
  content: "\ea11";
}
.serviceicon-tapclass:before {
  content: "\e998";
}
.serviceicon-bing:before {
  content: "\e99b";
}
.serviceicon-appnexus:before {
  content: "\ea0e";
}
.serviceicon-sharethrough:before {
  content: "\e996";
}
.serviceicon-adpro:before {
  content: "\e997";
}
.serviceicon-madhive:before {
  content: "\e999";
}
.serviceicon-bidtellect:before {
  content: "\e99c";
}
.serviceicon-sterling:before {
  content: "\e995";
}
.serviceicon-dbmorders:before {
  content: "\e94a";
}
.serviceicon-dbm:before {
  content: "\e991";
}
.serviceicon-dfa:before {
  content: "\e992";
}
.serviceicon-adwords:before {
  content: "\e993";
}
.serviceicon-googlelocalservices:before {
  content: "\e9fa";
}
.serviceicon-dcs:before {
  content: "\e994";
}
.serviceicon-liquidm:before {
  content: "\e925";
}
.serviceicon-whatconverts:before {
  content: "\e946";
}
.serviceicon-liveintentcampaigns:before {
  content: "\e958";
}
.serviceicon-liveintentinventory:before {
  content: "\e98b";
}
.serviceicon-visto:before {
  content: "\e986";
}
.serviceicon-tmpi:before {
  content: "\e987";
}
.serviceicon-googleanalyticsmcf:before {
  content: "\e988";
}
.serviceicon-digdevdirect:before {
  content: "\e989";
}
.serviceicon-greenbananaseo:before {
  content: "\e98a";
}
.serviceicon-measuredmarketing:before {
  content: "\e985";
}
.serviceicon-teads:before {
  content: "\e981";
}
.serviceicon-googlepagespeed:before {
  content: "\e982";
}
.serviceicon-siteimpact:before {
  content: "\e983";
}
.serviceicon-groundtruth:before {
  content: "\e984";
}
.serviceicon-vervevelocity:before {
  content: "\e97b";
}
.serviceicon-adform:before {
  content: "\e97c";
}
.serviceicon-uberall:before {
  content: "\e97d";
}
.serviceicon-matchcraft:before {
  content: "\e97e";
}
.serviceicon-convirzaforadvertisers:before {
  content: "\e972";
}
.serviceicon-verve:before {
  content: "\e973";
}
.serviceicon-shoutaboutus:before {
  content: "\e974";
}
.serviceicon-semrushdomains:before {
  content: "\e976";
}
.serviceicon-responsetap:before {
  content: "\e977";
}
.serviceicon-eqworks:before {
  content: "\e978";
}
.serviceicon-taboola:before {
  content: "\e97a";
}
.serviceicon-authoritylabs:before {
  content: "\e971";
}
.serviceicon-appnexusio:before {
  content: "\e92c";
}
.serviceicon-steprep:before {
  content: "\e91c";
}
.serviceicon-simplifi:before {
  content: "\e96d";
}
.serviceicon-dudamobile:before {
  content: "\e96c";
}
.serviceicon-dudaone:before {
  content: "\e96b";
}
.serviceicon-freespee:before {
  content: "\e96a";
}
.serviceicon-liquidus:before {
  content: "\e969";
}
.serviceicon-googleanalytics:before {
  content: "\e968";
}
.serviceicon-googleanalyticsfour:before {
  content: "\e9db";
}
.serviceicon-googleanalyticscustom:before {
  content: "\e939";
}
.serviceicon-googleanalyticssegments:before {
  content: "\e93a";
}
.serviceicon-unbounce:before {
  content: "\e967";
}
.serviceicon-aspenemail:before {
  content: "\e947";
}
.serviceicon-aspenshared:before {
  content: "\e965";
}
.serviceicon-aspenocpj:before {
  content: "\e966";
}
.serviceicon-aspendirect:before {
  content: "\e96e";
}
.serviceicon-adobe-marketing-cloud:before {
  content: "\e900";
}
.serviceicon-appannie:before {
  content: "\e901";
}
.serviceicon-arrivalist:before {
  content: "\e902";
}
.serviceicon-cbslocal:before {
  content: "\e903";
}
.serviceicon-chatter:before {
  content: "\e904";
}
.serviceicon-cheetah:before {
  content: "\e964";
}
.serviceicon-conversant:before {
  content: "\e905";
}
.serviceicon-craiglist:before {
  content: "\e906";
}
.serviceicon-display:before {
  content: "\e94b";
}
.serviceicon-espnradio:before {
  content: "\e963";
}
.serviceicon-eblast:before {
  content: "\e95b";
}
.serviceicon-email:before {
  content: "\e94c";
}
.serviceicon-emma:before {
  content: "\e907";
}
.serviceicon-engagetosell:before {
  content: "\e908";
}
.serviceicon-goodway:before {
  content: "\e94d";
}
.serviceicon-hulu:before {
  content: "\e90b";
}
.serviceicon-landing:before {
  content: "\e94e";
}
.serviceicon-linkedininmail:before {
  content: "\e90d";
}
.serviceicon-linkedingads:before {
  content: "\e90c";
}
.serviceicon-linkedinbusinessreports:before {
  content: "\ea0f";
}
.serviceicon-mixpanel:before {
  content: "\e94f";
}
.serviceicon-notifyme:before {
  content: "\e962";
}
.serviceicon-pandora:before {
  content: "\e90e";
}
.serviceicon-presslaf:before {
  content: "\e950";
}
.serviceicon-print:before {
  content: "\e951";
}
.serviceicon-promojam:before {
  content: "\e961";
}
.serviceicon-pubmatic:before {
  content: "\e952";
}
.serviceicon-quancast:before {
  content: "\e90f";
}
.serviceicon-rocketfuel:before {
  content: "\e910";
}
.serviceicon-salesforce:before {
  content: "\e95d";
}
.serviceicon-salesforceleads:before {
  content: "\e92b";
}
.serviceicon-serpbook:before {
  content: "\e960";
}
.serviceicon-snapchat:before {
  content: "\e912";
}
.serviceicon-social:before {
  content: "\e954";
}
.serviceicon-strata:before {
  content: "\e913";
}
.serviceicon-theweatherc:before {
  content: "\e914";
}
.serviceicon-tripadvisor:before {
  content: "\e915";
}
.serviceicon-typhoon:before {
  content: "\e916";
}
.serviceicon-watchme:before {
  content: "\e917";
}
.serviceicon-yellowpages:before {
  content: "\e95e";
}
.serviceicon-zapier:before {
  content: "\e909";
}
.serviceicon-adgear:before {
  content: "\e62b";
}
.serviceicon-adjuster:before {
  content: "\e93d";
}
.serviceicon-adobe:before {
  content: "\e60b";
}
.serviceicon-adroll:before {
  content: "\e62c";
}
.serviceicon-adtegrity:before {
  content: "\e92a";
}
.serviceicon-adventive:before {
  content: "\e943";
}
.serviceicon-agendize:before {
  content: "\e931";
}
.serviceicon-apexchat:before {
  content: "\e62d";
}
.serviceicon-appnexusadtaxi:before {
  content: "\e927";
}
.serviceicon-avanser:before {
  content: "\e616";
}
.serviceicon-benchmarkemail:before {
  content: "\e920";
}
.serviceicon-bingadtaxi:before {
  content: "\e91d";
}
.serviceicon-bingwebmasterstools:before {
  content: "\e949";
}
.serviceicon-birdeye:before {
  content: "\e928";
}
.serviceicon-bitly:before {
  content: "\e959";
}
.serviceicon-bmielite:before {
  content: "\e62e";
}
.serviceicon-boostability:before {
  content: "\e643";
}
.serviceicon-buffer:before {
  content: "\e923";
}
.serviceicon-callcap:before {
  content: "\e600";
}
.serviceicon-callrail:before {
  content: "\e608";
}
.serviceicon-callsource:before {
  content: "\e604";
}
.serviceicon-campaignmonitor:before {
  content: "\e932";
}
.serviceicon-cds:before {
  content: "\e61d";
}
.serviceicon-celtra:before {
  content: "\e61e";
}
.serviceicon-clipcentric:before {
  content: "\e61b";
}
.serviceicon-constantcontact:before {
  content: "\43";
}
.serviceicon-coreaudience:before {
  content: "\e929";
}
.serviceicon-ctm:before {
  content: "\e944";
}
.serviceicon-datadyn:before {
  content: "\e630";
}
.serviceicon-default:before {
  content: "\e603";
}
.serviceicon-dataxu:before {
  content: "\e606";
}
.serviceicon-delacon:before {
  content: "\e92e";
}
.serviceicon-devhub:before {
  content: "\e620";
}
.serviceicon-dfp:before {
  content: "\73";
}
.serviceicon-dialogtech:before {
  content: "\e624";
}
.serviceicon-dropbox:before {
  content: "\e800";
}
.serviceicon-ecl:before {
  content: "\e629";
}
.serviceicon-eltoro:before {
  content: "\e632";
}
.serviceicon-eyereturn:before {
  content: "\e92f";
}
.serviceicon-facebook:before {
  content: "\c2";
}
.serviceicon-facebookads:before {
  content: "\e008";
}
.serviceicon-foursquare:before {
  content: "\66";
}
.serviceicon-frontseat:before {
  content: "\e627";
}
.serviceicon-googledrive:before {
  content: "\e801";
}
.serviceicon-googlemybusiness:before {
  content: "\e957";
}
.serviceicon-googleplus:before {
  content: "\e611";
}
.serviceicon-gshift:before {
  content: "\e95a";
}
.serviceicon-hubspot:before {
  content: "\e9a0";
}
.serviceicon-hubspotanalytics:before {
  content: "\e98c";
}
.serviceicon-hubspothostedcontent:before {
  content: "\e98d";
}
.serviceicon-hubspotsocialassists:before {
  content: "\e98e";
}
.serviceicon-hubspotpages:before {
  content: "\e98f";
}
.serviceicon-hubspotforms:before {
  content: "\e990";
}
.serviceicon-icontact:before {
  content: "\e64a";
}
.serviceicon-ifbyphone:before {
  content: "\e945";
}
.serviceicon-importwizard_plus:before {
  content: "\e948";
}
.serviceicon-instagramsocial:before {
  content: "\e64b";
}
.serviceicon-ipromote:before {
  content: "\e922";
}
.serviceicon-ivinteractive:before {
  content: "\e633";
}
.serviceicon-ivinteractiveremake:before {
  content: "\e656";
}
.serviceicon-jivox:before {
  content: "\e610";
}
.serviceicon-jumptap:before {
  content: "\e617";
}
.serviceicon-lamarkmedia:before {
  content: "\e634";
}
.serviceicon-linkedin:before {
  content: "\6c";
}
.serviceicon-linkedinprofile:before {
  content: "\e655";
}
.serviceicon-litbreaker:before {
  content: "\e64c";
}
.serviceicon-mailchimp:before {
  content: "\4c";
}
.serviceicon-marchex:before {
  content: "\e612";
}
.serviceicon-marchexproxy:before {
  content: "\e921";
}
.serviceicon-marketo:before {
  content: "\e64d";
}
.serviceicon-mediamath:before {
  content: "\e635";
}
.serviceicon-mediaprowler:before {
  content: "\e636";
}
.serviceicon-megaleads:before {
  content: "\e92d";
}
.serviceicon-micrositemasters:before {
  content: "\e650";
}
.serviceicon-microstrategy:before {
  content: "\e623";
}
.serviceicon-mixpo:before {
  content: "\e619";
}
.serviceicon-moatdisplay:before {
  content: "\e652";
}
.serviceicon-moatvideo:before {
  content: "\e657";
}
.serviceicon-moatcontent:before {
  content: "\e659";
}
.serviceicon-mocentric:before {
  content: "\e637";
}
.serviceicon-mocentricagencies:before {
  content: "\e644";
}
.serviceicon-mocentricextended:before {
  content: "\e638";
}
.serviceicon-movingcompanyreviews:before {
  content: "\e642";
}
.serviceicon-nativo:before {
  content: "\e919";
}
.serviceicon-ndn:before {
  content: "\e91a";
}
.serviceicon-outbrain:before {
  content: "\e937";
}
.serviceicon-pinterest:before {
  content: "\e918";
}
.serviceicon-pinterestads:before {
  content: "\e9d5";
}
.serviceicon-pinterestaccounts:before {
  content: "\e9d6";
}
.serviceicon-platform161:before {
  content: "\e936";
}
.serviceicon-pulsepoint:before {
  content: "\e60c";
}
.serviceicon-reachlocal:before {
  content: "\e641";
}
.serviceicon-realmedia:before {
  content: "\e60f";
}
.serviceicon-recrue:before {
  content: "\e621";
}
.serviceicon-recruemobile:before {
  content: "\e934";
}
.serviceicon-recruesocial:before {
  content: "\e654";
}
.serviceicon-reviewtrackers:before {
  content: "\e651";
}
.serviceicon-revlocal:before {
  content: "\e622";
}
.serviceicon-ripper:before {
  content: "\e926";
}
.serviceicon-rtbiq:before {
  content: "\e941";
}
.serviceicon-rubicon:before {
  content: "\e60e";
}
.serviceicon-sendible:before {
  content: "\e953";
}
.serviceicon-seomoz:before {
  content: "\6f";
}
.serviceicon-shoutlet:before {
  content: "\e60d";
}
.serviceicon-silverpop:before {
  content: "\e93f";
}
.serviceicon-simplififtp:before {
  content: "\e653";
}
.serviceicon-sizmek:before {
  content: "\e639";
}
.serviceicon-sizmeksas:before {
  content: "\e63c";
}
.serviceicon-sizmekftp:before {
  content: "\e658";
}
.serviceicon-sizmekmdxnxt:before {
  content: "\e942";
}
.serviceicon-sizmeksearch:before {
  content: "\e924";
}
.serviceicon-strongemail:before {
  content: "\e91e";
}
.serviceicon-strongemail2:before {
  content: "\e93e";
}
.serviceicon-sweetiq:before {
  content: "\e955";
}
.serviceicon-tapclassifieds:before {
  content: "\e93c";
}
.serviceicon-tapdrives:before {
  content: "\e93b";
}
.serviceicon-telmetrics:before {
  content: "\e930";
}
.serviceicon-thetradedesk:before {
  content: "\e605";
}
.serviceicon-tigerpistol:before {
  content: "\e956";
}
.serviceicon-tritonaudio:before {
  content: "\e938";
}
.serviceicon-tritonnextgen:before {
  content: "\e9fe";
}
.serviceicon-tru:before {
  content: "\e60a";
}
.serviceicon-tubemogul:before {
  content: "\e614";
}
.serviceicon-twilio:before {
  content: "\e63a";
}
.serviceicon-twitter:before {
  content: "\cb";
}
.serviceicon-twitterads:before {
  content: "\cc";
}
.serviceicon-ubermedia:before {
  content: "\e935";
}
.serviceicon-v12:before {
  content: "\e618";
}
.serviceicon-vast:before {
  content: "\e63b";
}
.serviceicon-vertresp:before {
  content: "\e646";
}
.serviceicon-vertrespcampaigns:before {
  content: "\e647";
}
.serviceicon-wufoo:before {
  content: "\e648";
}
.serviceicon-yahoo:before {
  content: "\e609";
}
.serviceicon-yahooapt:before {
  content: "\e63e";
}
.serviceicon-yasabe:before {
  content: "\e63f";
}
.serviceicon-yashi:before {
  content: "\e640";
}
.serviceicon-yelp:before {
  content: "\e613";
}
.serviceicon-yelpads:before {
  content: "\e615";
}
.serviceicon-yelpapi:before {
  content: "\e91b";
}
.serviceicon-yext:before {
  content: "\e61f";
}
.serviceicon-yextlocation:before {
  content: "\e91f";
}
.serviceicon-youtube:before {
  content: "\59";
  color: #f00;
}
