nav#main-nav div.nav-item div.nav-submenu {

    li.nav-submenu-item {
        a.full-icon {

            i.icon {
                position: absolute;
                top: 0;
                left: 6px;
                height: 36px;
                width: 36px !important;
                line-height: 36px;
                font-size: 30px !important;
                text-align: center;
            }

            span.title {
                display: block;
                text-indent: 25px;
            }
        }

        i.icon.icon-home {
            font-size: 15px !important;
            margin: -3px 9px -1px 0 !important;
        }
    }
}

.navmain-section-title {
    display: block;
    margin-top: 13px;
    text-align: left !important;

    li.item {
        .bold();
        font-size: 11px;
        margin-left: 12px;
        margin-bottom: 3px;
        min-height: 0 !important;

        hr {
            margin: 3px 0 0 0;
            width: 32px;
        }
    }
}

.navmain-newapp-link {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}