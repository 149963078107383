html {
    height: 100%;
}

body {
    position: relative;
    font-size: 12px;
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    .mainfont();
}

a:focus,
a:active,
a:hover {
    outline: none;
}

.link-fade-hover {
    .transition(background-color);
    a {
        .transition(background-color);
    }
}

.hollow {
    background-color: transparent;
    border: 1px solid;
}

b, strong {
    .bold();
}

h1, h2, h3, h4, h5,
p.main-title,
p.title {
    .fontSmoothing();
}

//Chart font
svg {
    .mainfont();
}

iframe {
    border: 0;
}

/******************** Top Content *******************/

.applyTopPadding(@value, @multiplier) {
    #left-frame,
    #right-frame,
    #dash-header.fixed,
    #dash-filters,
    div.dataTables_fixedHeader.affix.dash-filter-fixed {
        margin-top: @value + (@multiplier * @value);
    }

    nav#main-nav {
        bottom: @value + (@multiplier * @value);
    }

    &.io-impersonation {
        #right-frame {
            margin-top: @value * 2 + (@multiplier * @value);
        }

        div.ioHeader {
            margin-top: @value;
        }
    }
}

body.impersonation-mode, body.user-integration-mode {
    @topSpacing: 40px;
    @topSpacingMultiplier: 0;
    .applyTopPadding(@topSpacing, @topSpacingMultiplier);
}

body.impersonation-mode.user-integration-mode {
    @topSpacing: 40px;
    @topSpacingMultiplier: 1;
    .applyTopPadding(@topSpacing, @topSpacingMultiplier);
}

#app-header-container {
    .app-header {
        font-size: 13px;
        top: 0;
        left: 0;
        right: 0;
        height: 40px;
        line-height: 40px;
    }
    .app-header();
    position: fixed;

    .app-header-inner {
        position: relative;
    }
    .app-header {
        .app-header();
    }
    
    #user-impersonation-header {
        padding: 0 20px;

        .close-header {
            cursor: pointer;
            position: absolute;
            right: -5px;
            top: 0;
            font-size: 20px;
            .opacity(80);

            &:hover {
                .opacity(100);
            }
        }
    }

    .user-integration-header-container {
        height: 40px
    }

}

#user-integration-header {
    .z-index(1);
    position: relative;
    .user-integration-header-inner {
        position: absolute;
        width: 100%;
    }
}

/******************** Left Content *******************/

div#left-frame {
    position: absolute;
    width: 225px;
    left: 0;
    top: 0;
    bottom: 0;
    height:100%;
    overflow: visible;
    z-index:102;
    border-right:1px solid;

    &.force-show {
        .z-index(12);
    }

    div.version-number {
        position: fixed;
        bottom: 0;
        left: 0;
        .z-index();
        font-size: 10px;
        width: 225px;
        text-align: center;
        padding: 2px 0;
    }
}


/* Main Logo */
div.header-logo {
    text-align: center;
    padding: 13px 0 0 0;
    margin: 0;
    height: 65px;

    img {
        max-width: 200px;
        max-height: 38px;
    }
}

/* Main Navigation */
nav#main-nav {
    overflow-y: auto;
    position: absolute;
    left:0;
    right: 0;
    top: 105px;
    bottom: 0;

    h6 {
        margin: 20px 0 4px 35px;
        text-transform: uppercase;
        float: left;
        .opacity(60);
        font-weight: bold;
        font-size: 10px;
        letter-spacing: 0.6px;
        .transform(scale(0.9, 0.9));
    }

    div.nav-item {
        position: relative;

        &.nav-item-link {
            text-align: center;
            .transition(all);
            background-color: #34465a;
            color: #fff;
            font-size: 14px;
            .fontSmoothing();

            &:hover {
                color: #fff !important;
                background-color: #40a1dd;
                .animPulse();
            }
        }

        div.nav-item-wrapper {
            line-height: 40px;
            height: 40px;
            position:relative;

            span {
                display: inline-block;
                font-size: 13px;
                font-weight: bold;
                .fontSmoothing();
            }

            > span.icon {
                position: relative;
                margin-left: 15px;
                margin-right: 11px;
            }

            span.text {
                cursor: default;
                i.icon {
                    position:absolute;
                    top:14px;
                    right:8px;
                }
            }

        }

        div.nav-submenu {
            display: none;
            font-size: 12px;
            overflow: hidden;
            padding-bottom: 15px;

            div.nav-submenu-header {
                /* Only shown and defined in responsive mode at bottom of page */
                display:none;
            }

            ul {
                padding-left: 0;
            }

            //Only show delete dashboard button when hover nav item
            #dashboard-nav-items li.nav-submenu-item:hover i.delete-dashboard-view {
                display:block;
            }

            li {
                position:relative;
                min-height: 36px;

                &.link-submenu {
                    i.icon {
                        position: relative;
                        font-size: 16px;
                        left: -2px;
                    }
                    span {
                        position: relative;
                        top: -2px;
                    }
                }

                a {
                    display:block;
                    padding: 10px 22px 10px 24px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 224px;
                    white-space: nowrap;

                    &.two-lined {
                        i.icon {
                            top: 8px;
                        }

                        label {
                            top: 18px;
                        }
                    }

                    > i.icon {
                        position: relative;
                        margin-right: 10px;
                        font-size: 12px;
                        width: 15px;
                        display: inline-block;
                    }

                    label {
                        position: absolute;
                        left: 11px;
                        top: 12px;
                    }

                    p.subtitle {
                        margin: 0;
                        font-size: 11px;
                        margin-left: 27px;
                        .opacity(80);
                    }

                    span.nav-item-bubble {
                        position: relative;
                        top: -2px;
                        left: 4px;
                        border-radius: 3px;
                        font-size: 10px;
                        padding: 1px 6px 2px;
                    }
                }

                i.delete-dashboard-view {
                    position:absolute;
                    right:0;
                    top:13px;
                    z-index:100;
                    display:none;
                }

                a.service-link {
                    display:block;
                    padding: 9px 26px 10px 46px;
                    margin:2px 0;
                }

                span.service-square {
                    position:absolute;
                    top:0;
                    left:0;
                    height:36px;
                    width:36px;

                    span.icon {
                        line-height: 36px;
                        font-size:30px;
                    }

                    span.serviceicon-custom-icon {
                        line-height: 32px;
                        font-size:22px;
                    }
                }

                span.superscript {
                    display:inline;
                    font-size:9px;
                    position:relative;
                    left:5px;
                    top:-7px;
                }
            }

            li#service-overview-nav {
                margin-bottom:15px;

                span.icon {
                    font-size:14px;
                }
            }
        }
    }

    div#onboarding-steps {
        padding:0 8px;

        h6 {
            font-size:15px;
            margin:25px 0;
            text-align:center;
            text-decoration: initial;
            text-transform: capitalize;
            float: none;
        }

        hr {
            margin:20px 10px;
        }

        p {
            cursor:default;
            padding:5px 4px;
            font-size:12px;


            span.number {
                margin-left:-8px;
                padding:3px 7px;
                .rounded(50%);
            }
        }

        p.disabled {

            span.text {
                .opacity(50);
            }
        }

        p.current {

            span.number {
                margin-right:3px;
            }
        }

        span.label {
            float:right;
        }
    }

    div#onboarding-help {
        position:absolute;
        bottom:20px;
        padding:0 20px;

        h6 {
            font-size:15px;
            margin:10px 0;
            text-align:center;
            text-decoration: initial;
            text-transform: capitalize;
            float: none;
        }

        hr {
            margin:10px 0;
        }

        p {
            padding:5px 4px;
            font-size:12px;
        }
    }

}

div.nav-footer {
    height: 40px;

    div.user-menu {
        .transition(border);
        float: left;
        padding: 5px 0 2px 5px;
        border-bottom: 3px solid;
        border-color: transparent;

        div.dropdown {
            .z-index(10);
            float: left;
            cursor: pointer;
            position: relative;

            li.title {
                margin-left: 8px;
                margin-top: 3px;
                font-size: 11px;
                text-transform: uppercase;
            }

            div.dropdown-toggler {
                .absolute();
                .z-index();
            }

            ul.dropdown-menu {
                top: 32px !important;
            }

            img.user-image {
                border-radius: 50%;
            }
        }

        i.icon, img.user-image {
            font-size: 24px;
            display: inline-block;
            margin-right: 6px;
            margin-top: 3px;
            float: left;
        }

        img.user-image {
            height: 24px;
            width: 24px;
        }

        span.icon {
            display: inline-block;
            margin-right: 9px;

            &[class*="icon-"] {
                margin-left: -3px;
            }
        }

        div.user-text {
            .flex();
            .align-items(center);
            line-height: 28px;
            float: left;
            white-space: nowrap;

            span.user-name-text {
                display: block;
                font-size: 12px;
                overflow: hidden;
                max-width: 84px;
                text-overflow: ellipsis;
            }

            i.icon {
                font-size: 13px;
                margin-left: 4px;
                position: relative;
                top: -1px;
            }
        }
    }

    div.nav-footer-option-container {
        float: right;
        margin-right: 4px;

        a.nav-footer-option {
            display: inline-block;
            height: 40px;
            line-height: 40px;
            margin: 0 4px;
            font-size: 16px;
            text-align: center;

            &#show-hide-nav {
                .transition(transform);
                position: relative;
                top: 1px;
            }
        }
    }
}

/******************* Right Content ******************/
div#right-frame {
    //min-width: 800px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index:100;
    left: 224px;
    overflow:auto;

    &.page-dash {
        overflow-y: scroll;
        overflow-x: hidden;
    }

    &:focus {
        outline-width: 0;
    }

    // page animation
    .content-container {
        .flex-full;
        .flex-column;
    }
}

div#theme-picker-container {
    display:none;
    position: fixed;
    z-index:10000;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    border-bottom:1px solid;
    line-height: 45px;
    text-align: center;

    i.close-theme {
        cursor:pointer;
        margin-right:8px;
        font-size:16px;
        position:relative;
        top: 2px;
    }
}

/* Ajax Wrapper Container */
div#ajax_main {
}

/* Loding classes */
div.loading-wheel {
    .rotate();
    position:absolute;
    display:inline;
    margin-left: -40px;
    width:32px;
    height:32px;
    background: url('../../../images/loading-wheel.svg');
}

div.input-loading {
    position: relative;
    line-height:32px;

    div.loading-wheel {
        margin-left: 0;
        width:16px;
        height:16px;
        top:8px;
    }
    span {
        margin-left:20px;
    }
}


/* Ajax loading */
#ajax_main_loading {
    position: fixed;
    bottom: 0;
    right: 0;
    .opacity(10);
    background:#000;
    z-index:1000;
}

div#ajax_main_loading_container {
    position:fixed;
    top:50%;
    right:0;
    margin-top:-60px;
    padding:20px 0 22px 0;
    z-index:1001;
    text-align:center;
    .shadow(0px, 3px, 8px, 0.1);

    .sk-three-bounce {
        display: inline-block;
        margin: 0 auto;
        padding-right: 6px;
        position: relative;
        top: 2px;
    }
}

img.ajax_loader {
}

span.ajax_loading_text {
    .fontSmoothing();
    font-size:21px;
    white-space: nowrap;
}

/* Main Content */
div.main-content {
    padding: 4px 30px;
}

div.user-image-preview {

    img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

    i.icon {
        font-size: 50px;
    }
}

div.main-content-title {
    height:52px;
    min-height:52px; // Fix for IE

    i.icon {
        text-indent:0;
        margin-right:8px;
    }

    p.main-title {
        line-height: 52px;
        float:left;
        font-size:24px;
        margin:0;
        text-indent: 20px;
    }

    p.main-title.main-title-text{
        margin-top: 10px;
        display: inline-block;
        width: 87%;
        line-height: 30px !important;
        margin-left: 15px;
        text-indent: 0 !important;
        font-size: 21px !important;
    }

    a.main-title-action {
        margin-top:12px;
    }

    a.main-title-action.before-title {
        position:relative;
        left:18px;
        margin-right:8px;
    }

    div.main-sub-title {
        float:right;
        font-size:14px;
        margin-right:30px;
        line-height:52px;

        span {
            margin:0 8px;
        }

        div.service-square {
            margin-right:-2px;
            margin-top:13px;
        }
    }

    div.inline-buttons {
        float:left;
        margin-left:12px;
        margin-top:12px;

        span.btn {
            margin-right:8px;
        }
    }

    div.form-group {
        margin-top:12px;
    }

    div#sort-buttons {
        display:none;
    }

    small.title-sublink {
        display: block;
        position: relative;
        top: -8px;
        clear: both;
        left: 20px;
    }
}

div.main-content-title.large {
    height:80px;

    a.main-title-action {
        width:60px;
        height:60px;
        line-height:58px;
        .rounded(60px);

        span {
            font-size:24px;
        }
    }
}

div.main-content-title.single-layout {
    height: 80px;

    p.main-title {
        line-height: 72px;
    }
}

div.main-content-title.main-title-header{
    height: 74px !important;
}

/* Breadcrumbs */
div.breadcrumb-container {
    max-width:50%;
    float:left;
    ol.breadcrumb {
        padding: 6px 15px;
        margin: 10px 20px 10px 15px;
        font-size:13px;
    }
}

div#dash-filters {
    position:fixed;
    top:0;
    left:225px;
    right:0;
    z-index:101;
    height: 52px;
    padding: 8px 0 10px 0;
    border-bottom:1px solid;

    div#dash-date-range-filter {
        float:left;
        margin-top:2px;

        span.date-text-container {
            cursor:pointer;
            font-size:18px;
            margin-left:15px;
            .fontSmoothing();

            span.date-text {
                padding-bottom:4px;
            }

            i.icon {
                position:relative;
                top:-1px;
                margin-left:4px;
                font-size:14px;
            }

            label {
                position:relative;
                top:-2px;
                font-size:12px;
                padding: 2px 8px;
            }
        }

        span.date-text-container:hover {
            span.date-text {
                border-bottom: 1px solid;
            }
        }

        div.btn-group {
            margin-top:-5px;
            margin-left:15px;

            .btn {
                font-size:10px;
            }
        }

        div#date-range-filter-container {
            display: none;
            margin-left: auto;
            margin-right: 0;
            padding: 5px 10px 10px 10px;
            width: 290px;
            z-index: 200;
            position: absolute;
            top: 50px;
            left:0;

            form > div.form-group {
                margin: 15px 0;
            }

            input[type=text] {
                width: 110px;
                padding: 2px 4px;
            }

            label {
                margin-left: 6px;
            }

            input[type=checkbox] {
                margin-right: 6px;
            }

            div#compare_date_range_container {
                display: none;
            }
        }
    }

    div#dash-view-as-client-filter {
        float:right;
        margin-left:12px;

        span.icon {
            position:relative;
            font-size:18px;
            vertical-align: text-bottom;
            line-height: 34px;
        }

        .inline-block {
            display: inline-block;
            vertical-align: text-bottom;
        }

        span {
            display: inline-block;
            vertical-align: text-bottom
        }

        select {
            height: 34px; // same height as the select2 widget
            padding-top: 3px;
            vertical-align: text-bottom;
        }
    }

    div#specific-client-view {
        display:none;
        padding:0 18px;
        height:40px;
        line-height:40px;
        position:relative;
        top:8px;

        span {
            font-size:14px;
        }

        b {
            margin-left:2px;
            font-weight:normal;
        }

        a {
            font-size:18px;
            position:relative;
            top:2px;
            margin-right:6px;
        }
    }

    div.date-range-picker {
        margin-left: 15px;
    }
}


div#dash-filters.has-view-specific-client {
    height: 88px;
}

.form-header {
    .clearfix();

    span {
        .bold();
        margin:5px 0;
        height:24px;
        display: inline-block;
        padding: 0 15px;
        margin-left:30px;
        font-size:11px;
        text-transform: uppercase;

        &.group-expander-icon {
            position:absolute;
            right: 5px;
        }
    }

    hr {
        margin-top:-22px;
    }
}


/* Footer */
footer {
    z-index: 100;
    min-width: 800px;
    height:60px;
    line-height:60px;
    max-height:0;
    .transition(max-height);
    position:fixed;
    bottom:0;
    left:224px;
    right: 15px;
    padding-left:20px;

    div.btn-default {
        margin-left:12px;
    }
}


/* Redirector */
div.redirector-container {
    position:fixed;
    top:50%;
    width:150px;
    height:150px;
    margin-top:-75px;
    right:-75px;
    line-height:150px;
    text-align:center;
    z-index: 10000;
    .rounded(50%);
    font-size:40px;

    a {
        display:block;
        width:100%;
        height:100%;

        span.icon {
            position:relative;
            left:-30px;
            .transition(left);
        }
    }

    a:hover {
        span.icon {
            left: -20px;
        }
    }
}



/* Miscellaneous */

div.action,
a.action {
    display:block;
    float:left;
    position:relative;
    width:30px;
    height:30px;
    border: 2px solid;
    .rounded(50%);
    font-size:16px;
    line-height:28px;
    text-align:center;
    margin:0 0 0 12px;
    z-index: 10;

    span.icon {
        display:inline-block;
        .bold();
    }
}

a.action.disabled:hover {
    cursor: not-allowed;
}

.btn:not(.btn-circle) {
    i.icon {
        margin-right:8px;
    }
}

.viewnote .anchor-group a {
    padding-right:15px;
}

.strikethrough {
    text-decoration: line-through;
}

a.category-metrics {
  line-height: 2;
}

.category-metrics .main-content span {
  font-size: 12px;
}

.category-metrics.show-disabled {
  color: hsl(0, 0%, 80%);
  opacity: 1;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}