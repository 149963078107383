.smart-campaign-icon {

    &.service-square {
        position: absolute;
        top: 20px;
        left: 60px;

        span.icon-empty {
            width: 44px;
            height: 44px;
            line-height: 15px;
            margin: 2px;
            border: 2px #fff dashed;
            padding-top: 5px;
        }

        span.icon {
            font-size: 32px;
        }
    }
}

.smart-campaign-details-modal {
    width: 100%;

    .datasource-selects-container {
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .datasource-selects {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;

        .datasource-select {
            position: relative;
            flex: 1;
            width: 250px;
            margin: 10px;
            justify-content: space-between;

            .select2-container {
                width: 100%;
            }

            .notice-block {
                margin-left: 0;
            }
        }
    }

    .configuration-container {
        @widthPadding: 10px;
        margin-top: -20px;
        border: none;
    }

    .form-container {
        .delayed-show-lr();
    }

    .preview {
        position: relative;
        min-height: 200px;
    }
}

.smart-campaign-preview-list {
    .delayed-show-lr();

    .campaigns {
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 3px;

        .campaign {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            padding-left: 10px;

            &:first-child {
                height: 35px;
            }
            &:not(:first-child) {
                cursor: pointer;
            }

            .label-container-title {
                font-weight: bold;
            }

            &:last-child {
                border-bottom: none;
            }

            height: 50px;
            .checkbox {
                cursor: pointer;
            }
            label {
                font-weight: lighter;
                font-size: 20px;
                margin-left: 10px;
                margin-bottom: 0;
            }
        }
    }

    datagrid-widget {
        border-radius: 10px;
        table {
            tr {
                transition: background-color 0.3s ease;
                cursor: pointer;
            }
        }
    }
}