a.data-source {
    cursor: pointer;
}

.data-source {
    display: inline-block;
    height: 0px;
    width: 36px;
    text-align: center;

    i.icon, svg.export-icon {
        position: relative;
        top: 5px;
        font-size: 28px;
        stroke-width: 0;
        stroke: currentColor;
        fill: currentColor;
        width: 32px;
        height: 32px;

        &[class*=icomoon-],
        &.serviceicon-custom-icon,
        &.smaller-icon {
            top: 9px;
            font-size: 18px;
            width: 21px;
            height:21px;
        }

        &.custom-icon {
            font-size: 18px;
            width: 21px;
            height:21px;
        }

    }


    div.data-source-display {
        display: inline-block;
        position: absolute;
        left: 54px;
        line-height: initial;
        text-align: left;

        span.title {
            display: block;
            margin-top: 7px;
            margin-bottom: 3px;
        }

        small {
            display: block;
            font-size: 9px;
        }

        .p-t-7 {
            padding-top: 7px;
        }
    }
}

div.datasource-config-container {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 300px;
    margin-top: -120px;
    height: 240px;
    transform: translate(-50%, 0);
}

div.datasource-select-container {
    min-height: 36px;
    .transition(transform);
    margin: 0 12px;

    .datasource-select-inner {
        &.zoom-in {
            .animation(zoomIn 0.5s);
        }
    }

    p.title {
        margin-bottom: 5px;
    }

    .custom-dataview-message {
        text-align: center;
        padding-top: 5px;
    }
}

div.datasource-config-container.done {
    .opacity(0);
    .transition(opacity 0.6s);
}
