i[class*="-animator"] {
    .transition-group(top 1s ease, left 1s ease, transform 1s ease);
    &.is-animating {
        top: 0;
        left: 0;
        .z-index(1000);
        position: absolute;
        font-size: 30px;

        .final-position {
            .bigPulse(1.3, 2s, infinite);
        }
    }

}

#report-studio-export-options-modal {
    .transition(all, 0.3s, ease);

    div.modal-dialog {
        width: 400px;
        height: 170px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -200px;
        margin-top: -85px;
    }

    .modal-title {
        font-size: 15px;
    }

    .modal-header .close {
        font-size: 32px;
        margin-top: -8px;
    }

    div.modal-body {
        > div.inner {
            .flex();
            .justify-content(space-around);
        }

        input.switch {
            position: relative;
            left: 10px;
            top: -4px;
        }
    }

    div.export-option-container {
        width: 50%;
        height: 80px;
        border-right: 1px solid;
        cursor: pointer;
        margin-top: 5px;
        .bold();
        .flex();
        align-items: center;
        flex-direction: column;

        &:last-child {
            border: 0;
        }

        i.icon {
            margin-top: 14px;
            display: block;
            font-size: 30px;
        }

        span {
            display: block;
            margin-top: 10px;
            text-transform: uppercase;
        }
    }
}