div.filter-item {
    .rounded(4px);
    padding: 8px 0 12px 0;

    input[type="number"] {
        width: 200px;
    }

    .form-group {
        margin-bottom: 0;
    }

    span.value-description {
        display: block;
        font-size: 11px;
        margin-top: 10px;
        margin-left: 15px;
    }

    div.relative-range-panel, div.custom-range-panel {
        margin: 10px 10px 0 10px;
    }

    div.filter-options {
        .flex;
        margin: 0 10px;

        div.btn-group {
            margin-top: 8px;

            div.btn {
                padding: 4px 1px;

                i.icon {
                    font-size: 14px;
                    position: relative;
                    top: 1px;
                    left: -3px;
                }
            }
        }

        div.option {
            margin: 0 10px;
        }

        input {
            position: relative;
            top: 8px;
            margin-left: 4px;
        }

        div.blocked-option {
            display: inline-block;

            input {
                pointer-events: none;
            }
        }
    }

    div.wildcard-tip {
        margin-top: 3px;
        float: right;
        margin-right: 15px;
    }
}

div.filter-connector {
    div.line {
        width: 2px;
        height: 6px;
    }
}