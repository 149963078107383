div.export-builder-panel {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 280px;
    .z-index(5);
    overflow: hidden;

    .btn-table {
        border: 0px;
        padding: 3px 8px;
        border-radius: 4px;
        margin-right: 8px;
        background: #eff5f8;
        transition: all .3s;
        display: flex;
        align-items: center;
    }

    .btn-table.remove {
        background: #ff6c6c !important;
        color: #fff;
    }

    .mr-right-2{
        margin-right: 2px;
    }

    .slide-panel-body {
        display: initial;
        flex: initial;
        overflow: hidden;
    }

    div.panel-tab-container {
        .flex-display();
        div.panel-tab {
            flex: 1;
            i.icon {
                font-size: 16px;
                line-height: 34px;
            }
        }
    }

    div.panel-content {
        padding: 0;
    }

    div.inner {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    div.data-panel,
    div.settings-panel,
    div.styles-panel {
        .delayed-show-rl(0s);

        position: absolute;
        height: 100%;
        width: 100%;
        padding-right: 17px;
        overflow-y: scroll;
        overflow-x: hidden;
        box-sizing: content-box;

        .disabled-panel {
            .absolute();
            .z-index(2000);
            cursor: not-allowed;
        }

        div.inner {
            position: absolute;
            height: 100%;
            width: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            padding: 0 8px 0 0;
            width: calc(~'100% + 14px');
        }

        hr {
            margin: 12px 20px;
        }

        p.text {
            position: relative;
            top: 2px;
            font-size: 12px;
            .bold();

            i.icomoon-help {
                position: relative;
                top: 1px;
                left: 4px;
            }
        }

        div.section-option {
            .flex();
            .flex-center();
            .justify-content(space-between);
            .align-items(center);
            height: 32px;
            width: 100%;
        }

        .section-header {
            margin: 0;
            height: 28px;
            line-height: 26px;
            padding: 0 16px;
            font-size: 12px;
            .bold();

            //&:after {
            //    font-family: 'icomoon';
            //    content: "\ea26";
            //    position: absolute;
            //    right: 28px;
            //    margin-top: 1px;
            //}
            //
            //&.collapsed {
            //    &:after {
            //        content: "\ea29";
            //    }
            //}
        }

        .section-subheader {
            height: 20px;
            line-height: 18px;
            padding: 0 12px;
            font-size: 11px;
            .bold();
        }

        .section-content {
            padding: 0 15px;

            &.section-options {

                div.content-item {
                    p.text {
                        width: 140px;
                    }
                }
            }

            div.content-item {
                .flex();
                margin: 12px 0;
                line-height: 20px;

                p.text {
                    .bold();
                    font-size: 10px;
                    width: 105px;
                    margin: 0;
                    text-align: right;
                    padding-right: 20px;
                    line-height: 1.4;

                    i.icon {
                        font-size: 12px;
                        position: relative;
                        top: 2px;
                        margin-right: 3px;
                    }
                }

                div.shadow-section {
                    .flex();
                    margin: 0 3px;

                    &.top {
                        margin-bottom: 8px;
                    }

                    &.bottom {
                        span.select-title {
                            margin-left: 20px;
                        }

                        select {
                            margin-right: 0;
                        }
                    }

                    select {
                        margin-right: 12px;
                        width: 49px;
                    }

                    tap-colors {
                        margin-left: 17px;
                    }

                    span.select-title {
                        .bold();
                        font-size: 10px;
                        margin-right: 5px;
                        display: inline-block;
                    }
                }

                input.form-control {
                    .no-shadow();
                    height: 26px;
                    font-size: 12px;
                    width: 145px;
                }

                div.color-picker-input {
                    width: 24px;
                    height: 24px;
                }

                > i.icon {
                    cursor: pointer;
                    margin: 5px 16px 0 0;
                    font-size: 14px;
                }

                div.outline {
                    .flex();
                    .flex-center();
                    .rounded(4px);
                    border: 1px dashed;
                    cursor: pointer;
                    margin-top: 3px;

                    i.icon {
                        font-size: 18px;
                        margin-right: 4px;
                    }

                    span {
                        font-size: 12px;
                    }
                }


                rzslider {
                    width: 100px;

                    .rz-pointer:after {
                        display: none;
                    }

                    .rz-bubble {
                        display: none;
                    }
                }

                span.slider-value {
                    .bold();
                    font-size: 11px;
                    display: inline-block;
                    line-height: 17px;
                    margin-left: 12px;
                    width: 25px;
                }
            }

            .report-font-sizes {
                margin-left: 30px !important;

                i.icon {
                    position: relative;
                    top: -1px;

                    &.font-small {
                        font-size: 11px;
                    }
                    &.font-medium {
                        font-size: 14px;
                    }
                    &.font-large {
                        font-size: 17px;
                    }
                }
            }

            .chart-palette-colors {
                .flex();
                .flex-wrap(wrap);
                padding: 0 30px 0 5px;

                .color-select {
                    height: 30px;
                    width: 35px;
                    margin: 2px;
                }
            }
        }
    }

    div.data-panel {
        div.inner {
            padding: 8px 40px 16px 10px
        }
        .panel-data-container {
            position: relative;
        }

        .inline-padding-height {
            height: 100px;
        }

        div[element-overlay] {
            position: initial;
            
            div.placeholder-overlay {
                .z-index(1000);
                label {
                    font-size: 12px;
                    white-space: initial;
                }
            }
        }
    }

    .report-btn {
        .bold();
        text-transform: capitalize;
        border-radius: 5px;
        .transition(all, 0.3s);
        padding: 4px 8px;
    }

    div.settings-panel,
    div.styles-panel {

        > div {
            margin-right: -17px;
            margin-top: 6px;

            &:last-child {
                margin-bottom: 10px;
            }
        }

        select.form-control {
            .bold();
            .no-shadow();
            font-size: 11px;
            background-color: transparent !important;
            height: 22px;
            width: auto;
            min-width: 40px;
        }

        div.select2-container,
        div.select2-container-multi {
            min-width: auto;

            a.select2-choice,
            ul.select2-choices {
                height: 24px;
                font-size: 11px;
                line-height: 22px;

                span.select2-chosen {
                    padding: 0 8px;
                }

                span.select2-arrow {
                    right: 3px;
                    top: -1px;
                }

                .select2-search-field {
                    input {
                        padding-left: 5px;
                    }
                }
            }
        }
    }

    div.filters-panel {
        position: relative;
        min-height: 100px;

        .inner {
            padding: 10px;
        }
        
        p.text {
            display: inline;
        }

        .report-filter-container {
            .delayed-show-lr(0s);
            margin-top: 8px;

            .option-title-container {
                div.option-title-container {
                    line-height: 36px;
                    height: 36px;
                    width: 100%;

                    span.title {
                        float: none;
                        display: inline-block;
                        font-size: 11px;
                        .bold();
                    }

                    span.badge {
                        font-size: 10px;
                        position: relative;
                        top: 6px;
                    }
                }

                div.data-source-title {
                    position: relative;
                    margin-bottom: 3px;

                    div.data-source-display {
                        left: 40px;

                        span.title {
                            margin-bottom: 0;
                            margin-top: 4px;
                            top: -2px;
                        }
                    }

                    span.title {
                        position: relative;
                        top: -3px;
                        font-size: 12px;
                    }

                    i.icon {
                        top: 0;
                        font-size: 32px;

                        &.custom-icon {
                            top: -1px;
                            font-size: 18px;
                        }
                    }
                }

                p {
                    font-weight: 600;
                    font-size: 12px;
                    position: relative;
                    top: 3px;
                    left: 2px;
                }
            }

            div.filter-header {
                align-items: center;
                width: 100%
            }
            i.icon {
                font-size: 2.3em;
            }

            span.title {
                margin-bottom: 0;
            }

            .datasource-header {
                flex-direction: column;
                padding: 0.5em;

                .title {
                    margin: 0;
                    font-size: 12px;
                }
            }

            div.widget-filters-buttons {
                i.icon {
                    font-size: 1.3em;
                    padding-top: 3px;
                }
            }
        }

        .report-btn-container {
            position: relative;
            display: inline;
            top: -8px;
            float: right;
        }

        .slide-panel-footer {
            top: calc(~'100% - 92px');
        }

        .element-overlay-container {
            top: -20px;
            height: 100px;
            width: 100%;

            .placeholder-overlay {
                .label {
                    font-size: 12px;
                }
            }
        }
    }

    export-builder-base-element-styles {
        & > div {
            margin-bottom: 65px;
        }
    }
}
