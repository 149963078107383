div.dash-date-picker {
    float: right;
    text-align: right;
    position: relative;
    top: 4px;
	margin-top: -8px;

    &.no-dash-filter {
        div.date-input-container {
            margin-right: 0;
            input.date-picker {
                margin-left: 91px;
            }
            div.btn-hover {
                right: -32px;
            }
        }
        div.comparison-container {
            right: 212px;
        }
    }

    div.date-input-container {
        margin-right: 40px;
		height: 40px;

        &.is-comparing {
            input.date-picker {
                top: 0;

                &.current-range {
                    top: 3px;
                    font-weight: 700;
                }
                &.comparison-range {
                    font-size: 13px;
                }
            }

            div.btn-hover:hover {
                i.icon:before {
                    font-family: 'icomoon';
                    font-size: 16px;
                    content: "\ea34";
                    position: relative;
                    top: 1px;
                }
            }

        }
    }

    input.date-picker {
		.fontSmoothing();
		display: block;
        margin-left: 60px;
		width: 180px;
		text-align: left;
		background: none;
		font-size: 14px;
		border: 0;
		outline: none;
		height: 20px;
        line-height: normal;
		cursor: pointer;
		border-bottom: 2px solid transparent;
		margin-top: 0;
        position: relative;
        top: 12px;
	}

    div.comparison-container {
        position: absolute;
        right: 265px;
        top: 0;

        div.btn-hover {
            .fontSmoothing();
            position: absolute;
            top: 5px;
            right: -45px;
            font-size: 14px;
        }

        input.comparison-range {
            .magictime;
            .zoomInSmooth;
            position: relative;
            margin-right: 35px;
			opacity: 0;
			&.show {
				opacity: 1;
			}
        }
    }

    ul.nav-tabs {
        border: 0;
        float: right;
        margin-top: 6px;

        li {
            font-size: 11px;
            border-width: 3px;

            a {
                background: transparent !important;
                padding: 2px 12px;
            }
        }
    }
}

.daterangepicker {

    &.openscenter {
        .ranges {
            display: none;
        }
    }

    .ranges {

        li {
            padding: 4px 7px;
        }

        .range_inputs {
            position: absolute;
            left: 11px;
            bottom: 10px;
        }
    }

    .calendar {
        td {
            width: 40px;
            height: 40px;
        }

        select {
            background: none;
        }
    }
}