@import "../../../widget/filter/base/widget.filter.column.less";
@import "../type/widget.create.type.less";

div.column-select-dropdown {
    .select2-result-label {
        padding-left: 0;
    }

    .select2-result-sub {
        .select2-result-label {
            padding-left: 22px;
            padding-right: 0px;
            position: relative;

            label {
                position: absolute;
                padding: 0;
                margin-bottom: 0;
                width: 19px;
                height: 23px;
                line-height: 23px;
                font-size: 13px;
                top: 0;
                left: -4px;
                .rounded(0);
            }
        }
    }
}

div.widget-creation-container {
    overflow: hidden;

    div.datasource-select-container {
        height: 80px;
    }

    //
    // CANVAS (includes draw options and preview section) - middle
    //
    div.widget-creation-canvas {
        .flex-full;
        .flex-column;
        position: relative;
        padding: 0;

        //
        // WIDGET CREATION HEADER (widget type list + draw options)
        //
        div.widget-creation-header {
            border-bottom: 1px solid;
        }

        //
        // WIDGET PREVIEW
        //
        div.widget-preview {
            .flex-full;
            .flex-column;
            padding: 6px;
            overflow-y: auto;

            div.widget-grid-item {
                position: relative;
                min-width: 450px;
            }

            div.widget-inner {
                // WEIRD FIX FOR FLEXBOX NOT RESPECTING PAGE WIDTH
                width: 1px;

                div.widget-header {
                    overflow: hidden;
                }
            }

            //
            // CUSTOM WIDGET DISPLAY (edit)
            //
            .datagrid-display {
                .flex-full;

                div.dataTables_wrapper {

                    div.dataTables_length {
                        display: none;
                    }
                }
            }

            div.is-sample-notifier {
                position: absolute;
                font-size: 11px;
                bottom: 8px;
                left: 8px;
                right: 8px;
                height: 20px;
                text-align: center;
                text-transform: uppercase;
                line-height: 20px;
                .rounded(0, 10px);
            }
        }

        //
        // MEDIA WIDGET
        //
        div.widget-create-media {
            div.widget-creation-header {
                .flex-full;
                .flex-row;
                flex-basis: 60px;
                max-height: 60px;

                div.media-datasource-container {
                    .flex();
                    .flex-row();
                    align-items: center;

                    div.media-datasource-select-container {
                        max-width: 362px;
                    }
                }

                div.media-is-header-container {
                    .flex();
                    .flex-row();
                    align-items: center;
                }

                .media-widget-edit-title {
                    display: block !important;
                    margin: 10px 15px;
                    width: 362px;
                }
            }
        }




        div.note-editor {
            display: flex;
            flex-flow: column;
            height: 100%;
            width: 100%;
            border: 0;
            margin-bottom: 0;
            .shadow(0, 0, 0);

            div.panel-heading.note-toolbar {
                padding: 6px 15px;
            }

            div.note-editing-area {
                flex: 1;
                overflow-y: auto;

                div.panel-body.note-editable {
                    padding: 15px;
                    height: 100% !important;
                    min-height: 400px !important;
                }
            }

            div.note-statusbar {
                display: none;
            }
        }
    }
}