@import 'layout.edit.less';

manage-layouts-panel {
    div.slide-panel {
        .z-index();

    }
    > div.slide-panel > div.inner {

        .form-control {
            width: 190px;
            display: inline-block;
            height: 26px;
            position: relative;
            top: -10px;
            left: -2px;
            font-size: 12px;
        }
    }

    div.orderable-container {
        padding-bottom: 150px;
    }

    div.tiny-layout {
        height: auto;
        margin-left: 8px;

        div.placeholder-title {
            height: auto;
            top: 6px;
            margin: 0;

            span {
                .ellipsis(200px);
            }

            input {

            }
        }
    }

    hr {
        margin: 10px -6px 8px -6px;
    }

    .slide-panel-body {
        position: relative;
    }

    .section-more-options {
        display: inline-block;
        position: relative;
        top: 6px;
    }

    .layout-datasource-select-container,
    .layout-product-select-container {
        position: relative;
        width: 260px;
        left: -6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .notice-block {
            span[tooltip] {
                border: none;
                position: relative;
                top: 4px;
                left: 3px
            }
        }
    }

	.layout-datasource-selects {
        width: 268px;
        margin: 5px;
	}

    div.data-source-section {
        display: inline-block;
        height: 25px;
        i {
            font-size: 18px
        }
        position: relative;
        top: -5px;
        right: 5px;
        cursor: initial;
        border: none;
    }

    .dropdown-menu {
        margin-bottom: 20px;
        border: none;
        span {
            text-transform: initial;
            &:hover {
                border: none;
            }
        }

    }
}

div.tiny-layout {
    width: 270px;
    max-height: 360px;
    position: relative;
    margin: 2px 8px 0 8px;

    &.idle {
        .z-index(100);

        div.placeholder-title span {
            .opacity(80);
        }

        div.tiny-layout-item {
            display: none;
        }
    }

    &.active {
        .z-index(101);

        div.placeholder-title {
            span {
                cursor: default;
            }
        }

        div.tiny-layout-item {
            .opacity(100);
        }

        label.badge {
            &:extend(.badge-info);
        }

        div.tiny-widget {

            &:hover {
                border: 1px solid;
            }
        }
    }

    &.new {
        height: 110px;

        &.idle {
            display: none;
        }

        div.tiny-layout-item {
            height: 60px;
            border: 2px dashed transparent;

            i.icon.icon-plus {
                font-size: 32px;
            }
        }
    }

    &.dropzone-active {
    }

    div.action-container {
        float: right;
        position: relative;
        top: -6px;

        div.layout-drag-handle {
            margin-left: 10px;
            position: relative;
            top: 5px;
            cursor: move;
        }

        .btn.dropdown {
            font-size: 13px;
            position: relative;
            top: -2px;
            right: -10px;
        }
    }

    div.tiny-layout-item {
        z-index: 0;
        width: 250px;
        max-height: 310px;
        overflow: hidden;
        border: 2px dashed transparent;
        margin-top: 8px;
        .shadow(2px, 2px, 6px, 0.15);
        .transform(perspective(1px) translateZ(0));
        .transition(transform, 0.3s);

        &:hover {
            overflow-y: auto;
        }

        &.selectable {
            &:hover {
                cursor: pointer;

                label.label-success {
                    .opacity(100);
                }
            }

            label.label-success {
                .center-element();
                .opacity(0);
                .z-index();
                font-size: 12px;
            }
        }

        &.active {
            .opacity(40);
        }

        &.empty {
            .flex;
            .flex-center;
            i.icon {
                font-size: 50px;
            }
        }

        &.dropzone {
        }

        &.dropzone-hover {

        }

        &.dropzone-active {
            .opacity(100);
        }
    }

    div.placeholder-title {
        margin: 0 0 6px 6px;
        position: relative;
        height: 36px;
        border: 2px dashed transparent;

        span,
        small {
            .ellipsis(240px);
            display: inline-block;
        }

        span {
            border-bottom: 3px solid transparent;
            padding-bottom: 2px;
            cursor: pointer;
        }

        small {
            display: block;
            margin-top: -7px;
        }

        label {
            position: absolute;
            right: 0;
            top: 1px;
            font-size: 10px;
            display: none;
        }
    }
}

div.tiny-widget {
    float: left;
    width: 110px;
    margin: 4px 1px 0 4px;
    padding-bottom: 5px;
    .rounded(6px);
    border: 1px solid;
    position: relative;
    cursor: move;

    &.dragged {
        //.z-index(1000);
    }

    &.zoomed {
        .transition(transform, 0.3s);
        .transform(scale(2));
    }

    p.tiny-title {
        .ellipsis(96px);
        width: 96px;
        font-size: 10px;
        border-bottom: 1px solid;
        margin: 4px 0 0 6px;
        text-indent: 2px;
        padding-bottom: 3px;
        min-height: 18px;
    }

    p.tiny-display-order {
        position: absolute;
        top: 4px;
        right: 6px;
        font-size: 10px;
        margin: 0;
    }

    > div.inner {

        i.icon {
            float: left;
            display: inline-block;
            font-size: 22px;
            width: 50%;
            text-align: center;
            line-height: 32px;

            &[class*=" serviceicon-"] {
                font-size: 32px;
            }
        }
    }
}