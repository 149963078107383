/* Collapsed left nav */
body.responsive {
  div#left-frame {
    width: 64px;
    overflow: visible;
    text-align: center;

    div.header-logo {
      height: 48px;
      padding-top: 13px;

      img {
        max-width: 52px;
        margin-left: 2px;
      }
    }

    div.version-number {
      width: 64px;
    }

    nav#main-nav {
      position: relative;
      top: 5px;
      overflow: visible;

      h6 {
        margin: 15px 0 1px 0;
        float: none;
      }
    }

    div.nav-item {
      position: relative;
      padding: 4px 0;
      text-align: center;
      .z-index(1);

      div.nav-item-wrapper {
        line-height: 50px;
        height: 40px;

        span.text {
          display: none;
        }

        span.icon {
          visibility: visible;
          .transition(font-size, 0.2s);
          font-size: 20px;
          .fontSmoothing();
        }

        @media screen and (max-height: 660px) {
          line-height: 42px;
          height: 32px;

          span.icon {
            font-size: 16px;
          }
        }
      }

      div.nav-submenu {
        position: absolute;
        .z-index(2);
        border: 0;
        top: 0;
        left: 63px;
        display: none;
        .shadow(2px, 2px, 6px, 0.2);
        padding: 0;
        overflow-y: auto;
        max-height: 320px;

        div.nav-submenu-header {
          display: block;
          font-size: 10px;
          padding: 0 16px;
          line-height: 32px;
          text-align: left;
          position: relative;
          text-transform: uppercase;

          .nav-submenu-option {
            .rounded(50%);
            width: 24px;
            height: 24px;
            line-height: 28px;
            font-size: 16px;
            position: absolute;
            top: 3px;
            right: 10px;
            text-align: center;
            padding: 0;
          }
        }

        a {
          text-align: left;
          white-space: nowrap;
          padding: 10px 20px;

          span.icon {
            width: 25px;
          }
        }

        a.service-link {
          padding-left: 46px;
          padding-right: 60px;
        }
      }

      div.nav-submenu-bottom {
        top: initial !important;
        bottom: 0;
      }
    }

    div.nav-item:hover {
      div.nav-submenu {
        display: block !important;
      }
    }

    div.nav-footer {
      padding-left: 0;
      height: auto;
      padding-bottom: 4px;

      div.user-menu {
        float: none;
        display: block;
        width: 64px;
        padding: 5px 0;

        div.dropdown {
          float: none;
        }

        i.icon,
        img.user-image {
          float: none;
          margin: 0;
        }

        div.user-text {
          display: none;
        }
      }

      div.nav-footer-option-container {
        float: none;
        clear: both;
        margin: 0;

        a.nav-footer-option {
          height: auto;
          line-height: normal;
          margin: 2px 5px;
          font-size: 16px;

          // Support button
          div[class*="custom-launcher-"].walkme-launcher-id-50250 {
            top: 0 !important;
            width: 20px !important;
            height: 20px !important;
            font-size: 14px !important;
          }
        }

        a#show-hide-nav {
          .transform(rotate(180deg));
          position: relative;
          top: 1px;
        }
      }
    }
  }

  footer,
  div#right-frame,
  div#ajax_main_loading,
  div#ajax_main_loading_container,
  div#period-legend.affix,
  div#dashboard-filter-header.fixed,
  div#dash-header,
  div#dash-filters {
    left: 64px;
  }

  div.loading-container.overlay.responsive {
    left: 64px !important;
  }

  div#main-content-top {
    div.breadcrumb-container {
      max-width: 40%;
    }
  }
}

body.frame-mode {
  div#left-frame {
    display: none !important;
  }

  div#dash-header {
    height: auto;
    min-height: 0;
    left: 0;
  }

  div#right-frame,
  div#dash-filters,
  div#ajax_main_loading,
  div#ajax_main_loading_container {
    left: 0 !important;
  }
}

body {
  // Small screens
  @media screen and (max-width: 1024px) {
    div#dash-header {
      height: auto;
      &.fixed {
        div.dash-filters {
          display: none;
        }
      }
    }

    div.dash-filters {
      float: left;
      clear: left;

      div.dash-date-picker {
        div.date-input-container {
          input.date-picker {
            margin-left: 25px;
          }
        }
      }

      ul.nav {
        display: none;
      }
    }
  }

  // Mobile + iPad devices
  @media screen and (max-width: 768px) {
    div#device-nav-toggler {
      display: block;
    }

    div#left-frame {
      display: none;

      &.force-show {
        display: block;
        .magictime;
        .slideInLeft;
        .animation-duration(0.5s);
      }
    }

    .slide-panel-active {
      .design-page {
        width: 100%;
        padding: 10px !important;
      }
      .feedback {
        display: none;
        right: 18px;
      }
      #dash-header,
      #dashboard-filter-header {
        &.fixed {
          right: 14px;
        }
      }
    }
    footer,
    div#right-frame,
    div#ajax_main_loading,
    div#ajax_main_loading_container,
    div#period-legend.affix,
    div#dashboard-filter-header.fixed,
    div#dash-header,
    div#dash-filters {
      left: 0 !important;
    }

    div#dash-header {
      height: auto !important;
      flex-wrap: wrap;
      min-height: 0;
      margin-left: 8px;
      margin-bottom: -5px;

      &.fixed {
        right: 0;

        div.dash-button-container {
          display: none;
        }

        p.dash-title {
          margin-left: 20px !important;
        }
      }

      .dash-title-container {
        flex-wrap: wrap;
      }
      div.dash-filters {
        height: auto !important;
      }

      div.dash-title-container {
        p.dash-title {
          .ellipsis(250px);
        }
      }

      div.dash-title-container {
        margin-bottom: 6px;
        margin-left: -11px;

        div.dash-button-container {
          display: none;
        }

        p.dash-title {
          margin-left: 2px;
          font-size: 20px;

          i.icon {
            top: 0;
          }
        }
      }
    }

    .panel-pushed {
      right: 0 !important;
    }

    .panel-padded-pushed {
      padding-right: 0 !important;
    }

    .daterangepicker {
      div.ranges {
        float: left;
        height: 30px;

        > ul {
          display: none;
        }
      }

      &.dropdown-menu {
        .calendar {
          float: none;
        }
      }
    }

    div.design-widget {
      padding: 6px 0;
    }

    div.design-page.services-overview-page {
      div.campaign-performance-container {
        > div.flex-full {
          .flex-column;
        }

        div.panel {
          margin: 10px 0 0 0;
        }

        div.legend-panel {
          width: 100%;
        }
      }

      div.design-widget {
        div.widget-content {
          > div.flex-full {
            .flex-column;
            margin-bottom: 5px;
          }
        }

        div.service-entity-display.has-score-display {
          div.flex-half {
            .flex-start;
          }
        }

        big-number-widget div.bignumber-widget {
          margin: 8px 15px;

          p.value-container {
            font-size: 22px !important;
          }

          span.title {
            font-size: 9px !important;
          }
        }
      }

      div.widget-loaded-container {
        position: relative;
        margin-top: 22px;
      }
    }

    #export-builder {
      div.export-builder-header-display-overlay {
        .overlay-content-shadow {
          width: 100%;
        }
      }
    }
  }
}
