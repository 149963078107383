#widget-library-modal {


    div.library-row {
        div.carousel-item {
            div.content {
                height: 275px;
            }
        }
    }

    div.widget-placeholder {

        div.header {
            font-size: 12px;
            padding: 0 8px;
            .rounded(10px, 0);
            text-overflow: clip;
            white-space: nowrap;
            overflow: hidden;

            p.text {
                margin: 5px 0;
                text-align: center;
            }

            div.data-source {
                i.icon {
                    font-size: 36px;
                    float: left;
                    position: relative;
                    top: 1px;
                    margin-right: 4px;

                    &.custom-icon {
                        top: 0;
                        font-size: 24px;
                        margin-right: 10px;
                    }

                    &.serviceicon-custom-icon {
                        font-size: 22px;
                        top: 7px;
                        margin-right: 6px;
                    }

                    &[class*=icomoon-] {
                        font-size: 20px;
                        top: 7px;
                        left: 7px;
                        margin-right: 20px;
                    }
                }
            }
        }

        div.content {
            text-align: center;
            overflow: hidden;

            div.widget-preview {
                position: relative;
                overflow: hidden;

                div.loader {
                    position: relative;
                    top: 75px;

                    i.icon {
                        display: inline-block;
                        font-size: 70px;
                        margin-top: 10px;
                        margin-bottom: 8px;
                        .opacity(80);
                    }
                }

                div.widget-grid-item {
                    pointer-events: none;
                    height: 275px;
                    width: 100%;
                }
            }

            // Back arrow
            i.icon.large {
                font-size: 140px;
                position: absolute;
                top: 52px;
                left: 52px;
                .transition(all);
                .animZoomIn;
            }

            span.widget-type {
                display: block;
                text-transform: uppercase;
                font-size: 11px;
                margin-bottom: 15px;
            }

            span.widget-title {
                display: inline-block;
                text-align: left;
                font-size: 14px;
                margin-top: 20px;
                padding: 0 14px;
                .bold();
            }

            span.widget-description {
                display: block;
                font-size: 12px;
                text-align: left;
                margin: 6px 0 10px 0;
                padding: 0 14px;
                .opacity(70);
            }
        }

        //
        // WIDGET SPECIFIC STYLES
        //
        .widget-display {
            overflow: hidden;

            // charts
            svg {
                text.amcharts-label-back {
                    display: none;
                }
            }
        }

        big-number-widget {
            div.circle-wrap {
                p.value-container {
                    width: 75px;
                    height: 75px;
                    line-height: 75px;
                }
            }
            
            div.bignumber-widget {
                p.value-container {
                    font-size: 13px !important;

                    span.value {
                        height: 40px;
                        line-height: 22px;
                    }

                    span.value.comparison {
                        line-height: 50px;
                    }
                }
            }
        }



        .datagrid-display {
            div.dataTables_wrapper table {
                th,
                td {
                    white-space: nowrap;
                }
            }
        }

        div.dataTables_paginate,
        div.dataTables_filter,
        div.dataTables_header {
            display: none;
        }

        div.dataTables_wrapper.has-header table.dataTable {
            margin-top: 0 !important;
        }
    }
}

widget-library-detailed-item {

    div.library-detailed-right {
        width: 100%;
        position: initial !important;

        div.widget-grid-item {
            width: 100%;
            height: 350px;
        }

        div.widget-inner {
            width: 0;

            .datagrid-display {
                div.dataTables_wrapper table td {
                    white-space: nowrap;
                }
            }
        }
    }
}

div.manage-library-widget {
    table.dataTable {
        td {
            div.widget-icon-container {
                width: 100px;
                height: 125px;
                font-size: 60px;
                line-height: 125px;
                text-align: center;
                overflow: hidden;
            }

            div.widget-thumbnail-container {
                text-align: center;
                overflow: hidden;

                div.inner {
                    width: 100px;
                    height: 125px;
                    display: inline-block;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}