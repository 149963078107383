.solid-border {
	border-style: solid;
	border-width: 1px;
}

table {
	span.naming-convention-preview-item {
		float: left;
		font-size: 11px;
		position: relative;
		display: inline-block;
		padding: 6px;
		margin: 5px;
		min-width: 35px;
		.rounded(3px);
		text-align: center;
		vertical-align: center;

		&.identifier {
			.solid-border()
		}
		&.delimiter, &.custom_delimiter {
			font-weight: bolder;
			.solid-border()
		}
		&.special {
			.solid-border()
		}
	}
}

#naming-convention-rule-modal {

	.naming-convention-content {
		padding: 10px;

		.edit-sample-text {
			label, input {
				margin-top: 10px;
			}
		}
	}

	.modal-body {
		min-height: 400px;
	}

	naming-convention-rule-content {
		.dragging {
			.rule-item-delete {
				display: none !important;
			}
			.dropzone {
				border-width: 1px;
				border-style: dashed;
			}
		}
	}


	div.form-group {
		label {
			text-align: right;
		}
	}

	.preview-container {
		margin-top: 10px;
	}

	span.preview-item {
		font-size: 40px;
	}

	.live-preview-container {
		margin-top: 6px;
	}
	.live-preview {
	}

	.title-header {
		padding-left: 5px;
		margin-top: 15px;
		margin-bottom: 10px;
	}

	.convention-builder-container {
		display: flex;
		padding: 2px 5px;
		min-height: 70px;
		border-style: solid;
		border-width: 1px;
		.rounded(5px);

		.naming-convention-builder {
			display: flex;
			align-items: center;
			flex: 1;
			border-style: solid;
			border-width: 1px;
			white-space: nowrap;
			overflow: auto;
			overflow-y: hidden;
		}

		.clear-all {
			margin-top: 12px;
			margin-left: 8px;
			margin-right: 8px;
			padding: 5px 10px;
			height: 40px;
		}
	}

	.ui-state-highlight {
		display: inline-block;
	}

	.rule-item-placeholder {
		margin-left: 5px;
		opacity: 0.4;
	}
	.tags-container {
		display: inline-block;

	}

	div.rule-item {
		float: left;
		position: relative;
		display: inline-block;
		min-width: 35px;
		margin: 6px;
		.rounded(3px);
		text-align: center;
		vertical-align: center;
		transition: transform .2s ease-in-out, box-shadow .2s ease-in-out;
		padding: 6px;
		font-size: 12px;
		white-space: nowrap;
		.solid-border();

		&.orderable {
			cursor: move;
			&:hover {
				transform: scale(1.03);
			}
		}

		&.add-delimiter-container {
			position: relative;
			box-sizing: border-box;
			cursor: pointer;
			width: 150px;
			padding: 6px;
			overflow: hidden;

			.add-delimiter {
			}

			.add-delimiter-input {
				position: absolute;
				width: 149px;
				top: -2px;
				left: -1px;
				box-shadow: none;

				& > input {
					border: none;
				}
			}
		}

		&.__inner {
		}

		.rule-item-delete {
			position: absolute;
			display: none;
			top: -10px;
			right: -10px;
			cursor: pointer;
			width: 21px;
			height: 21px;

			transition: all 0.2s ease-in-out;
			transform: scale(0.0);
			.rounded(50%);

			& > span {
				font-size: 20px;
				line-height: 1;
				padding-left: 1px;
			}
		}

		&:hover {
			.rule-item-delete {
				display: initial;
				transform: scale(1.0);
			}
		}

		&.hovering {

		}

		&.identifier {
		}
		&.delimiter, &.custom_delimiter {
		}
		&.special {
		}

	}

	div.convention-tags-container {
		min-height: 100px;
		display: flex;
		flex-direction: row;
		margin-top: 5px;
		.convention-tags-section {
			position: relative;

			& > span {
				position: relative;
				top: -15px;
			}

			flex-grow: 1;
			flex-basis: 0;
			margin: 15px 5px 8px;
			padding: 10px 5px 5px;

			border-style: solid;
			border-width: 1px;
			.rounded(5px);

			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}

			.rule-item-title {
				position: absolute;
				top: -12px;
				left: 20px;
				font-size: 15px;
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}

}

naming-convention-preview {
	div.preview-item-container {
		height: 82px;
		margin-bottom: 10px;
		padding: 10px;
		overflow: auto;
		overflow-y: hidden;
		white-space: nowrap;
	}
}