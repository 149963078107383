#export-builder-quickexport-modal {

    .date-container {
        .flex();
        .flex-direction(row);
        .align-items(center);
        .justify-content(center);

        .date-item {
            flex: 1;
        }
    }

    .disabled {
        pointer-events: none;
    }
}