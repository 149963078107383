#export-builder-background-mask {
    height: 100vh;
    width: 100vw;
    position: fixed;
    .z-index(1000);
    top: 0;
    left: 0;
}

.export-builder-menu {
    opacity: 0;
    .transition(top, 0.3s, ease);
}

export-builder-right-click-menu,
ul.dropdown-menu {
    .toggle-show-lr(0.25s);

    .export-builder-element-dev-tools {
        hr {
            margin-top: 5px;
            margin-bottom: 5px;
        }
        &.is-sample-data {
            li.widget-data {
                display: none;
            }
        }
    }

    .export-builder-menu {
        position: fixed;
        .z-index(1001);
    }

    .dropdown-menu {
        display: initial;
    }

    i[class*=" icomoon-"] {
        position: relative;
        top: 2px;
    }

    span.variable-text {
        position: relative;
        left: -7px;
    }

    .dropdown-submenu {
        position: relative;

        // Needed since bootstrap prepends this element in its submenu init
        > ul > li:first-of-type > a:first-child {
            display:none;
        }

        a {
            .icomoon-caret-right {
                position: relative;
                top: 3px;
                left: 8px;
                float: right;
            }
        }

        ul.dropdown-menu {
            .transition(top, 0.3s, ease);
            .rounded(4px);
            .shadow(0, 1px, 8px, .175);
            border: 0;
            width: auto;
            opacity: 0;
            display: none;
            position: absolute;
            left: 100%;
            margin-top: -30px;

            i[class*=" icomoon-"] {
                margin-left: 2px;
                margin-right: 12px;
                position: relative;
                top: 2px;
            }

            ul > li {
              .disabled {
                  pointer-events: none;
                  opacity: 0.6;
              }
            }
        }

        i.icomoon-layers,
        i.icomoon-layers-4 {
            font-size: 11px;
            top: 1px;
        }

        &:hover {
            > ul.dropdown-menu {
                display: initial;
            }
        }
    }
}