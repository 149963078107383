div.options-panel {

    div.option-panel-item {
        position: relative;

        label {
            position: absolute;
            top: 2px;
            left: 40px;
        }
    }
}