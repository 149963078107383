@primary-text-color: #2c2d30;
@border-color: darken(#fafafa, 5%);
@jira-color: #003366;
@slack-color: #2ab27b;

pre,
code {
    background: #f5f5f5 !important;
    border-color: darken(#f5f5f5, 8%) !important;
}

pre {
    .rounded(4px);
}

.jira-color {
    background: @jira-color !important;
    border-color: darken(@jira-color, 5%) !important;
}

.slack-color {
    background: @slack-color !important;
    border-color: darken(@slack-color, 5%) !important;
}


span.widget-interaction-container {

    span.dev-toggler {
        display: inline-block !important;
        visibility: hidden;
        vertical-align: top;
        margin-top: 2px;
        margin-right: 2px;
        margin-left: 2px;
        font-size: 20px;

        &.has-error {
            visibility: visible;
            a {
                color: #d9534f !important;
            }
        }
    }
}

//
// Widget options
//
div.devtools-widget-options {
    display: inline-block;
    vertical-align: top;
    margin-right: 4px;
    margin-top: -2px;
    .z-index();

    div.widget-api-option {
        display: inline-block;
        margin: 0 5px 0 3px;
        .z-index(100);
        .animation(zoomIn 0.3s);
    }

    a.label {
        color: #fff !important;
    }

    i.icon {
        position: relative;
        top: -1px;
    }

    i.icon-code {
        top: -2px;
    }

    p {
        font-size: 11px;
        word-wrap: break-word;
    }
}

#widget-sql-modal {
    div.modal-body {
        pre {
            max-height: 300px;
        }
    }
}

#widget-bug-modal {

    div.modal-body {
        pre {
            max-height: 300px;
        }
    }

    div.bug-title {
        h4 {
            position: relative;
            top: 4px;
        }

        img {
            width: 32px;
            height: 32px;
            float: left;
            margin-right: 6px;
        }
    }
}