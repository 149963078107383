
div.widget-type-list-container {
    overflow: hidden;
    display: inline-block;
    height: 56px;

    p.title {
        display: inline-block;
        text-align: left;
        position: relative;
        top: -7px;
        margin-right: 5px;
    }

    div.widget-type-container {
        margin: 5px;

        span.title {
            font-size: 12px;
            text-transform: uppercase;
        }

        &.active {
            cursor: default;
        }

        &.disabled {
            cursor: default;

            i.icon {
                .transition(none);
            }

            i.icon:hover:after {
                .animation(none);
            }
        }
    }
}

div.widget-options-container {
    position: absolute;
    .z-index(1);
    right: 12px;
    top: 12px;
}

.collapsed-button() {
    .btn-circle;

    width: 30px;
    height: 30px;
    line-height: 29px;

    i {
        margin-right: 0 !important;
    }
    span {
        display: none;
    }
}

@media screen and (max-width: 1124px) {
    div.widget-type-list-container {
        padding-top: 11px;

        i.sonar-md {
            .sonar-button(30px);
        }

        p.title {
            top: -3px;
        }
    }
}