annotation-panel {

    messenger-thread {
        annotation-thread-display {
            display: block;
            margin: 4px 0 8px 0;

            div.advanced-options-container {
                margin: 10px 0;

                div.select2-container {
                    padding-right: 13px;
                }
            }

            div.assigned-to-container {
                position: relative;
                top: 5px;
                float: right;

                label {
                    padding: 2px 3px;
                    .ellipsis(70px);
                }
            }

            div.date-display {
                font-size: 10px;
                margin-top: 4px;
                display: inline-block;
            }
        }
    }

    annotation-thread-filters {

        div.thread-advanced-options-toggle {
            width: 100%;
            cursor: pointer;
            margin: 4px 0 7px 0;

            i.icon {
                position: relative;
                top: 1px;
            }

            span.help-tooltip {
                margin-top: 3px;
            }
        }

        div.advanced-options-container {
            margin: 10px 0;
        }
    }
}

div.design-widget {

    div.annotations-toggler {
        display: inline-block;
        vertical-align: top;
        margin-top: 3px;
        margin-right: 10px;
        font-size: 19px;
    }

    g.amcharts-graph-annotation {
        cursor: pointer;
    }
}
