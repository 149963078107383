@font-face {
  font-family: 'alphabet';
  src: url('../fonts/alphabet.eot?g4l5bo');
  src: url('../fonts/alphabet.eot?g4l5bo#iefix') format('embedded-opentype'),
    url('../fonts/alphabet.ttf?g4l5bo') format('truetype'),
    url('../fonts/alphabet.woff?g4l5bo') format('woff'),
    url('../fonts/alphabet.svg?g4l5bo#alphabet') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='alphabet-'],
[class*=' alphabet-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'alphabet' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* This CSS selector is necessary for font-family precedence on custom service icons (else it will try to default to family "service") */
.serviceicon-custom-icon[class*='alphabet-'] {
  font-family: 'alphabet' !important;
}

.alphabet-l-a:before {
  content: '\e900';
}
.alphabet-l-b:before {
  content: '\e901';
}
.alphabet-l-c:before {
  content: '\e902';
}
.alphabet-l-d:before {
  content: '\e903';
}
.alphabet-l-e:before {
  content: '\e904';
}
.alphabet-l-f:before {
  content: '\e905';
}
.alphabet-l-g:before {
  content: '\e906';
}
.alphabet-l-h:before {
  content: '\e907';
}
.alphabet-l-i:before {
  content: '\e908';
}
.alphabet-l-j:before {
  content: '\e909';
}
.alphabet-l-k:before {
  content: '\e90a';
}
.alphabet-l-l:before {
  content: '\e90b';
}
.alphabet-l-m:before {
  content: '\e90c';
}
.alphabet-l-n:before {
  content: '\e90d';
}
.alphabet-l-o:before {
  content: '\e90e';
}
.alphabet-l-p:before {
  content: '\e90f';
}
.alphabet-l-q:before {
  content: '\e910';
}
.alphabet-l-r:before {
  content: '\e911';
}
.alphabet-l-s:before {
  content: '\e912';
}
.alphabet-l-t:before {
  content: '\e913';
}
.alphabet-l-u:before {
  content: '\e914';
}
.alphabet-l-v:before {
  content: '\e915';
}
.alphabet-l-w:before {
  content: '\e916';
}
.alphabet-l-x:before {
  content: '\e917';
}
.alphabet-l-y:before {
  content: '\e918';
}
.alphabet-l-z:before {
  content: '\e919';
}
.alphabet-u-a:before {
  content: '\e91a';
}
.alphabet-u-b:before {
  content: '\e91b';
}
.alphabet-u-c:before {
  content: '\e91c';
}
.alphabet-u-d:before {
  content: '\e91d';
}
.alphabet-u-e:before {
  content: '\e91e';
}
.alphabet-u-f:before {
  content: '\e91f';
}
.alphabet-u-g:before {
  content: '\e920';
}
.alphabet-u-h:before {
  content: '\e921';
}
.alphabet-u-i:before {
  content: '\e922';
}
.alphabet-u-j:before {
  content: '\e923';
}
.alphabet-u-k:before {
  content: '\e924';
}
.alphabet-u-l:before {
  content: '\e925';
}
.alphabet-u-m:before {
  content: '\e926';
}
.alphabet-u-n:before {
  content: '\e927';
}
.alphabet-u-o:before {
  content: '\e928';
}
.alphabet-u-p:before {
  content: '\e929';
}
.alphabet-u-q:before {
  content: '\e92a';
}
.alphabet-u-r:before {
  content: '\e92b';
}
.alphabet-u-s:before {
  content: '\e92c';
}
.alphabet-u-t:before {
  content: '\e92d';
}
.alphabet-u-u:before {
  content: '\e92e';
}
.alphabet-u-v:before {
  content: '\e92f';
}
.alphabet-u-w:before {
  content: '\e930';
}
.alphabet-u-x:before {
  content: '\e931';
}
.alphabet-u-y:before {
  content: '\e932';
}
.alphabet-u-z:before {
  content: '\e933';
}
