div.form-container,
div#details-modal {
  .clearfix();

  div.modal-dialog {
    .absolute(20px, 20px, 20px, 20px);
  }

  div.modal-content {
    .flex();
    .flex-column();
    min-height: 275px;
  }

  div.modal-header {
    position: relative;

    .modal-title {
      font-size: 16px;

      .icon {
        margin-right: 6px;
      }

      .label {
        position: relative;
        top: -2px;
      }
    }
  }

  div.modal-body {
    .flex();
    .flex-column();
    overflow-y: auto;
    overflow-x: hidden;
  }

  div.modal-footer {
    button.smooth-disabled {
      opacity: 0.5;
      padding: 0;
      cursor: not-allowed;
      > div {
        padding: 7px 14px;
      }
    }
  }

  form.form-details {
    width: 100%;
  }

  div.form-detail-item {
    min-height: 70px;
  }

  .form-label {
    .bold();
    font-size: 11px;
    margin-bottom: 5px;
    display: inline-block;
    position: relative;

    &.required:after {
      top: 0;
      right: -14px;
      font-size: 18px;
    }
    span {
      text-transform: capitalize;
    }
  }

  .form-field {
    &.static {
      padding: 4px 0 6px 0;
      font-size: 13px;
    }

    audio {
      position: relative;
      top: -4px;
      left: -4px;
      height: 32px !important;
    }
  }
}
