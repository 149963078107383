div.options-panel {

    div.option-panel-item {
        padding: 6px 10px !important;

        div.option-title-container {
            line-height: 36px;
            height: 36px;
            width: 100%;

            span.title {
                float: none;
                display: inline-block;
                font-size: 11px;
                .bold();
            }

            span.badge {
                font-size: 10px;
                position: relative;
                top: 6px;
            }
        }

        div.data-source-title {
            position: relative;
            margin-bottom: 3px;

            div.data-source-display {
                left: 40px;

                span.title {
                    margin-bottom: 0;
                    margin-top: 4px;
                    top: -2px;
                }
            }

            span.title {
                position: relative;
                top: -3px;
                font-size: 12px;
            }

            i.icon {
                top: 0;
                font-size: 32px;

                &.custom-icon {
                    top: -1px;
                    font-size: 18px;
                }
            }
        }

        div.or-text {
            text-align: center;
            font-size: 11px;
        }

        hr {
            margin-top: 4px;
            margin-bottom: 14px;
        }

        div.ui-select-container {
            margin-top: 4px;
        }

        div.select2-container.select2-container-multi {
            max-height: 140px;
            overflow-y: auto;
        }

        div.option-footer {
            div.btn-group-xs {
                float: left;
                margin-top: 6px;

                > .btn {
                    padding: 2px 8px;
                }

                i.icon {
                    position: relative;
                    top: 1px;
                    font-size: 12px;
                    margin-right: 0;
                }
            }

            div.btn {
                float: left;
            }

            label {
                float: right;
                position: relative;
                margin-top: 5px;
            }
        }
    }
}