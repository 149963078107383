//Only one stylesheet is required for all the admin views since it is pretty small

div.only-agents,
div.only-clients,
div.only-dashboard-override {
    display:none;
}

div.empty-logo {
    border:2px dashed;
    width:230px;
    height:90px;
    line-height:90px;
    margin-bottom:10px;
    text-align:center;
}

div.current-logo {
    padding:15px;
    margin-bottom:8px;
    width:230px;
    height:90px;

    img {
        max-width: 200px;
        max-height: 60px;
    }
}
label.remove-logo {
    margin-top:5px;

    input[type="checkbox"] {
        margin-right:4px;
    }
}

span.light-label {
    margin-left:4px;
    border:1px solid;
}

span.dark-label {
    margin-left:4px;
    border:1px solid;
}


div.current-template {
    padding:15px;
    margin-bottom:8px;
    width:230px;
}

/* Import wizard styles */
span.icon-empty {
    display:block;
    width:110px;
    height:110px;
    margin:9px;
    border:3px #fff dashed;
    text-align:center;
    font-size:11px;
    color:#fff;
    line-height:110px;
}

table.upload-data-table {
    .text-muted {
        text-overflow: ellipsis;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        max-width: 120px;
    }
    td.data-type-cell {
        position:relative;
        min-width:105px;

        .icon {
            font-size:16px;
            position:absolute;
            left:2px;
            top:50%;
            margin-top:-11px;
        }
        span.icon {
            left:6px;
        }
    }

    th.data-mapping-cell {
        min-width:250px;
    }

    td.data-mapping-cell {
        min-width:250px;
        div.input-group {
            input.form-control {
                width: 43%;
            }
            div.input-group-addon {
                width:14%;
                float:left;
                top:0;
                padding: 9px 12px;
            }
        }
    }

    div.add-row {
        display:block;
        width:100%;
        height:30px;
        text-align:center;
        font-size:22px;
        line-height:30px;
    }

}

/* Client groups styles */
#client-group-client-table_wrapper {
    i.icon {
        font-size:20px;
        position:relative;
        top:2px;
        margin-right:5px;
        margin-left:-8px;
    }

    .dataTables_header {
        height:42px;
    }

    table.dataTable thead td {
        border-bottom:0;
    }
}

