export-builder-report-header {

    div.export-builder-report-header {
        position: relative;
        width: 100%;
        height: 84px;
        .z-index();
    }

    div.builder-super-header {
        .bold();
        height: 24px;
        line-height: 24px;
        font-size: 10px;
        text-align: center;

        .header-title-display {
            padding-right: 100px;
        }
    }

    loader {
        top: 1px;
        margin-right: 5px;
    }

    .favorite-page-icon {
        color: #ffffff;
        pointer-events: all;
    }

    .not-allowed {
        cursor: not-allowed;
    }
}