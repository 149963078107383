div.design-nav-wrapper {
    position: relative;
    float: right;
    .z-index(3);
    margin: 0 10px 0 15px;
    width: 50px;
    height: 50px;
}

.design-nav {
    position: absolute;
    .z-index(3);
    width: 50px;
    height: 50px;

    .design-nav-bg {
        /* this is the stretching navigation background */
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
        -webkit-transition: height 0.2s, box-shadow 0.2s;
        -moz-transition: height 0.2s, box-shadow 0.2s;
        transition: height 0.2s, box-shadow 0.2s;
    }

    ul {
        position: relative;
        padding: 50px 0 0;
        visibility: hidden;
        .transition(visibility, 0.3s);
        text-align: right;
        z-index: 2;

        > ul {
            padding: 0;
        }
        
        li {
            position: relative;

            label {
                position: absolute;
                display: none;
                right: 60px;
                top: 16px;
                max-width: 500px;
                width: 500px;

                /* navigation item labels */
                font-weight: normal;
                .opacity(0);
                .transform(translateX(0));

                span {
                    padding: 2px 8px;
                    .rounded(15px);
                }
            }

            a {
                position: relative;
                display: block;
                height: 50px;
                line-height: 50px;
                top: -2px;
                padding: 0 50px 0 0;
                color: rgba(255, 255, 255, 0.7);
                font-size: 13px;
                .transition(color, 0.2s);

                i.icon {
                    /* navigation item icons */
                    color: #fff;
                    position: absolute;
                    font-size: 20px;
                    right: 12px;
                    top: 50%;
                    .opacity(70);
                    .transform(translateY(-50%) scale(0));
                }

                i.icon[class*="icomoon"] {
                    right: 15px;
                    .bold();
                }
            }
        }
    }

    .design-nav-trigger {
        position: absolute;
        z-index: 3;
        top: 0;
        right: 0;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        /* replace text with image */
        overflow: hidden;
        white-space: nowrap;
        color: transparent;

        span,
        span::after,
        span::before {
            /* this is the hamburger icon */
            position: absolute;
            width: 14px;
            height: 2px;
            background-color: #ffffff;
        }

        span {
            /* middle line of the hamburger icon */
            left: 50%;
            top: 50%;
            bottom: auto;
            right: auto;
            .transform(translateX(-50%) translateY(-50%));
            .transition(background-color, 0.2s);
        }

        span::after,
        span::before {
            /* top and bottom lines of the hamburger icon */
            content: '';
            top: 0;
            left: 0;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            .transition(transform, 0.2s);
        }

        span::before {
            .transform(translateY(-5px));
        }
        span::after {
            .transform(translateY(5px));
        }
    }

    .design-nav-trigger:hover ~ .design-nav-bg {
        box-shadow: 0 6px 30px rgba(0, 0, 0, 0.2);
    }
}

.design-nav.nav-is-visible {

    width: auto;
    height: auto;

    .design-nav-bg {
        height: 100%;
        box-shadow: 0 6px 30px rgba(0, 0, 0, 0.2);
    }

    ul {
        visibility: visible;

        a {
            i.icon {
                /* navigation item icons */
                .transform(translateY(-50%) scale(1));
                .scaleIn();
                .transition(opacity, 0.4s);
            }
        }

        li:first-of-type {
            a i.icon,
            label {
                .animation-delay(0.05s);
            }
        }
        li:nth-of-type(2) {
            a i.icon,
            label {
                .animation-delay(0.1s);
            }
        }
        li:nth-of-type(3) {
            a i.icon,
            label {
                .animation-delay(0.15s);
            }
        }
        li:nth-of-type(4) {
            a i.icon,
            label {
                .animation-delay(0.2s);
            }
        }
        li:nth-of-type(5) {
            a i.icon,
            label {
                .animation-delay(0.25s);
            }
        }
        li:nth-of-type(6) {
            a i.icon,
            label {
                .animation-delay(0.25s);
            }
        }
        li:nth-of-type(7) {
            a i.icon,
            label {
                .animation-delay(0.25s);
            }
        }
        li:nth-of-type(8) {
            a i.icon,
            label {
                .animation-delay(0.25s);
            }
        }
        li:nth-of-type(9) {
            a i.icon,
            label {
                .animation-delay(0.25s);
            }
        }

        li:hover {
            a {
                color: #ffffff;
                i.icon {
                    .opacity(100);
                }
            }

            label {
                display: inline-block;
                .opacity(100);
                .transition(transform, 0.2s);
                .transform(translateX(5px));
            }
        }
    }

    .design-nav-trigger {
        span {
            background-color: transparent;
        }
        span::before {
            .transform(rotate(-45deg));
        }
        span::after {
            .transform(rotate(45deg));
        }
    }
}

/* --------------------------------

edit content

-------------------------------- */

.design-nav.edit-content {
    position: absolute;
    top: 15px;
    right: 15px;

    .design-nav-bg {
        height: 30px;
        width: 30px;
        background-color: rgba(0, 0, 0, 0.4);
        box-shadow: none;
        -webkit-transition: background 0.2s, height 0.2s;
        -moz-transition: background 0.2s, height 0.2s;
        transition: background 0.2s, height 0.2s;
    }

    ul {
        padding-top: 30px;

        a {
            width: 30px;
            height: 30px;
            line-height: 30px;
            padding: 0;
        }

        span {
            position: absolute;
            top: 0;
            right: 35px;
            height: 30px;
            line-height: 30px;
            padding: 0 1em;
            .opacity(0);
            visibility: hidden;
            background-color: rgba(0, 0, 0, 0.55);
            border-radius: 2px;
            text-align: center;
            -webkit-transition: opacity 0.2s, visibility 0.2s;
            -moz-transition: opacity 0.2s, visibility 0.2s;
            transition: opacity 0.2s, visibility 0.2s;
        }

        span::after {
            /* triangle below the tooltip */
            content: '';
            position: absolute;
            left: 100%;
            top: 50%;
            bottom: auto;
            .transform(translateY(-50%));
            height: 0;
            width: 0;
            border: 4px solid transparent;
            border-left-color: rgba(0, 0, 0, 0.55);
        }
    }

    .design-nav-trigger {
        height: 30px;
        width: 30px;

        span {
            .opacity(0);
            background-color: transparent;
        }
        span::before {
            .transform(rotate(-45deg));
        }
        span::after {
            .transform(rotate(45deg));
        }
        span,
        span::before,
        span::after {
            /* used to create the 'X' icon when the navigation is open */
            width: 12px;
        }

        ::after {
            /* edit icon */
            content: '';
            position: absolute;
            height: 16px;
            width: 16px;
            left: 50%;
            top: 50%;
            bottom: auto;
            right: auto;
            .transform(translateX(-50%) translateY(-50%));
        }
        span,
        ::after {
            -webkit-transition: opacity 0.2s;
            -moz-transition: opacity 0.2s;
            transition: opacity 0.2s;
        }
    }
}

.design-nav.edit-content.nav-is-visible {
    .design-nav-bg {
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        /* reset default style */
        box-shadow: none;
    }

    .design-nav-trigger {
        span {
            opacity: 1;
        }
        span::before {
            .transform(rotate(45deg));
        }
        span::after {
            .transform(rotate(135deg));
        }
        ::after {
            /* hide edit icon */
            opacity: 0;
        }
    }

    ul span {
        /* reset default style */
        .animation(none);
    }
}

.design-nav.edit-content .design-nav-trigger:hover ~ .design-nav-bg {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.8);
}

.design-nav.edit-content.nav-is-visible ul a:hover span {
    opacity: 1;
    visibility: visible;
    /* reset default style */
    .transform(translateX(0));
}