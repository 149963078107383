// Class applied to other elements to compensate for slidepanel space used
@panel-space-base: 300px;
@panel-space-small: @panel-space-base - 100;
@panel-space-large: @panel-space-base + 100;
@scroll-bar-space: 0;
@collapsed-nav-space: 75px;

.panel-pushed-space(@space) {
    right: @space + @scroll-bar-space !important;
}

.panel-pushed {
    .panel-pushed-space(@panel-space-base);

    &.small {
        .panel-pushed-space(@panel-space-small);
    }

    &.large {
        .panel-pushed-space(@panel-space-large);
    }
}

.panel-padded-pushed-space(@space) {
    padding-right: @space + @scroll-bar-space !important;
}

.panel-padded-pushed {
    .panel-padded-pushed-space(@panel-space-base);

    &.small {
        .panel-padded-pushed-space(@panel-space-small);
    }

    &.large {
        .panel-padded-pushed-space(@panel-space-large);
    }
}

.panel-pulled-space(@space) {
    left: -@space + @collapsed-nav-space !important;
}

.panel-pulled {
    .panel-pulled-space(@panel-space-base);

    &.small {
        .panel-pulled-space(@panel-space-small);
    }

    &.large {
        .panel-pulled-space(@panel-space-large);
    }
}

div.slide-panel {
    .flex-full;
    .flex-column;
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    .z-index(70);
    width: @panel-space-base;
    display: none;

    &.active {
        display: block;
    }

    &.small {
        width: @panel-space-small;
    }

    &.large {
        width: @panel-space-large;
    }

    > div.inner {
        height: 100%;
    }

    p.title {
        text-indent: 6px;
        border-left: 4px solid;
        .bold();
        font-size: 11px;
        margin-bottom: 4px;
        text-transform: uppercase;
        margin-left: 6px;
        .fontSmoothing();
    }

    .close-panel {
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 0px;
        font-size: 20px;
        .opacity(50);

        &:hover {
            .opacity(100);
        }
    }

    div.slide-panel-header {
        border-bottom: 1px solid;
        height: 50px;
        line-height: 50px;
        text-indent: 12px;
        font-size: 14px;
        .bold();

        > span {
            margin-left: 10px;
        }

        > i.icon {
            position: relative;
            left: 5px;
            font-size: 22px;
            top: 4px;
            margin-left: -3px;
        }
    }

    div.slide-panel-body {
        .flex;
        .flex-column;
        overflow-y: auto;
        overflow-x: hidden;
        .calcHeight(85px);

        &.has-tabs {
            .calcHeight(101px);
        }

        div.panel-tab-container {
            .flex();
            .flex-space-around();
            .align-items(stretch);
            height: 40px;
            font-size: 12px;
            width: 100%;
            border-bottom: 1px solid;

            div.panel-tab {
                width: 100%;
                height: 40px;
                text-align: center;
                line-height: 40px;
                .opacity(50);

                &:first-child {
                    left: 0;
                }

                &:hover {
                    cursor: pointer;
                    border-bottom: 4px solid;
                }

                &.active {
                    .opacity(100);
                    cursor: default;
                    border-bottom: 4px solid;
                }

                span.text {
                    display: block;
                    width: 100%;
                }
            }
        }

        div.panel-content {
            .flex;
            .flex-column;
            padding: 6px;
            position: relative;
            .clearfix();
        }

        div.panel-tab-content {
            display: none;
            &.active {
                .flex;
                .flex-column;
            }
        }

        span.options-title {
            margin-top: 20px;
            margin-left: 10px;
            margin-bottom: 5px;
            display: block;
            text-transform: uppercase;

            label.active {
                .transition(background-color);
            }
        }
    }


    div.slide-panel-footer {
        .z-index(1000);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 10px 7px 8px 15px;
        min-height: 49px;
        .animation-duration(0.4s);
        border-top: 1px solid;

        div.btn {
            margin-right: 5px;
        }
    }

    //
    // SWITCH CHECKBOX
    //
    input[type=checkbox].switch {
        width: 32px;
        height: 16px;
        .rounded(10px);
        margin-top: 1px;

        &:after {
            content: '';
            width: 12px;
            height: 12px;
            top: 1px;
            left: 1px;
        }

        &:checked:after {
            .transform(translate3d(16px, 0, 0));
        }
    }

    rzslider {
        margin: 8px 0 10px 3px;

        :focus {
            outline: -webkit-focus-ring-color auto 0 !important;
        }

        .rz-bar-wrapper {
            height: 24px;
            padding-top: 0;
            margin-top: 0;

            .rz-bar {
                height: 2px;
            }
        }

        .rz-pointer {
            top: -5px;
            width: 12px;
            height: 12px;
            .rounded(50%);
            .transition(transform);

            &:active,
            &:hover {
                .transform(scale(1.25));
            }
        }
    }
}

.options-panel-header {
    margin: 0;
    height: 28px;
    line-height: 26px;
    padding: 0 16px;
    font-size: 12px;
    .bold();
}

.options-panel-subheader {
    height: 20px;
    line-height: 18px;
    padding: 0 12px;
    font-size: 11px;
    .bold();
}

.options-panel {

    div.option-panel-item {
        .clearfix();
        padding: 6px 12px 6px 16px;
        line-height: 20px;
        position: relative;

        .mr-right-10 {
            margin-right: 10px !important;
        }

        p.text {
            font-size: 12px;
            width: 160px;
            margin: 0;
            text-align: right;
            padding-right: 20px;
            line-height: 1.4;

            i.icon {
                font-size: 12px;
                position: relative;
                top: 2px;
                margin-right: 3px;
            }
        }

        span.help-tooltip {
            position: absolute;
            left: -9px;
            top: 2px;
        }

        &.indent {
            text-indent: 8px;
            position: relative;
            top: -10px;

            p.text {
                font-size: 11px;
                position: relative;
                top: 2px;
            }
        }

        div.color-picker-input {
            width: 24px;
            height: 24px;
        }

        > i.icon {
            cursor: pointer;
            margin: 5px 16px 0 0;
            font-size: 14px;
        }

        div.outline {
            .flex();
            .flex-center();
            .rounded(4px);
            border: 1px dashed;
            cursor: pointer;
            margin-top: 3px;

            i.icon {
                font-size: 18px;
                margin-right: 4px;
            }

            span {
                font-size: 12px;
            }
        }

        rzslider {
            width: 100px;

            .rz-pointer:after {
                display: none;
            }

            .rz-bubble {
                display: none;
            }
        }

        span.slider-value {
            .bold();
            font-size: 11px;
            display: inline-block;
            line-height: 17px;
            margin-left: 12px;
            width: 25px;
        }
    }
}

.slide-panel-active {
    .design-page,
    #dashboard-filter-header {
        .panel-padded-pushed-space(@panel-space-base);
    }

    #dash-header,
    footer,
    .feedback,
    #dashboard-filter-header.fixed {
        .panel-pushed-space(@panel-space-base);
    }

    &.small {
        .design-page,
        #dashboard-filter-header {
            .panel-padded-pushed-space(@panel-space-small)
        }
        #dash-header,
        footer,
        .feedback,
        #dashboard-filter-header.fixed {
            .panel-pushed-space(@panel-space-small);
        }
    }

    &.large {
        .design-page,
        #dashboard-filter-header {
            .panel-padded-pushed-space(@panel-space-large)
        }
        #dash-header,
        footer,
        .feedback,
        #dashboard-filter-header.fixed {
            .panel-pushed-space(@panel-space-large);
        }
    }
}