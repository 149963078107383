div#widget-probe {
    .absolute();
    .z-index(1);
    height: 100%;

    div.probe-content {
        height: 100%;
        .flex();
        .flex-column();
        .flex-center();
    }

    div.probe-loading {
        .flex();
        .flex-center();
        height: 100%;
        font-size: 14px;
    }

    p.no-data {
        text-align: center;
        padding: 10px 10px 0 10px;
        .magictime;
        .zoomInSmall();
    }

    label {
        font-size: 75%;
    }
}

div.design-widget {

    probe-toggler {

        a.btn {
            .animation(zoomIn 0.5s);
            position: absolute;
            .z-index(2);
            top: -10px;
            right: -10px;

            i.icon {
                position: relative;
                top: -1px;
            }

            i.rotating {
                .rotateReverse();
                left: 0;
            }
        }

        div.close-probe-button {
            .animation(zoomIn 0.5s);
            position: absolute;
            .z-index(2);
            top: -10px;
            right: -10px;

            i.icon {
                .bold();
            }
        }
    }
}