//
// Executive Summary WIDGET
//
executive-summary-widget {
    max-width: 100%;
    max-height: 100%;
    padding: 0px 5px;

    &.hide-scroll {
        overflow-y: hidden;
    }

    &.show-scroll {
        overflow-y: auto;
    }

    div.executive-summary-widget {
        padding: 5px;
        iframe.note-video-clip {
            width: 100% !important;
        }
        .executive-content-display {
            white-space: pre-line;
        }
    }

    div.loaded-panel {
        background-color: hsl(0, 0%, 98%);
        position: relative;
        .flex-display();
        .flex(initial);
        .flex-center;
        padding: 15px 12px;
        .rounded(4px);
        margin: 0 16px 5px 16px;
    }
}