@import "../grid/dashboard.grid.less";

export-builder-report-header {
	div.export-builder-report-header {
		.delayed-show-tb(0.5s);
	}
}
export-builder-layers-page-panel {
	div.export-builder-layers-page-panel {
		.delayed-show-lr(0.5s);
	}
}

export-builder-panel {
	div.export-builder-panel {
		.delayed-show-rl(0.5s);
	}
}

.export-builder-dashboard-animation-container {
	.delayed-show-bt(1.5s);
	width: 100%;
	height: 100%;
}

//OVERRIDES
.ui-resizable-handle {
	width: 10px;
	height: 10px;
	border: 1px solid;
	.rounded(50%);
}

.ui-resizable-n {
	top: -5px;
	left: calc(~'50% - 5px') !important;
}
.ui-resizable-w {
	left: -5px;
	top: calc(~'50% - 5px') !important;
}
.ui-resizable-e {
	right: -5px;
	top: calc(~'50% - 5px') !important;
}
.ui-resizable-s {
	bottom: -5px;
	left: calc(~'50% - 5px') !important;
}
.ui-resizable-nw {
	left: -5px;
	top: -5px;
}
.ui-resizable-ne {
	top: -5px;
	right: -5px;
}
.ui-resizable-sw {
	left: -5px;
	bottom: -5px;
}
.ui-resizable-se {
	bottom: -5px;
	right: -5px;

	&.ui-icon {
		background-image: none;
	}
}

div.slide-panel {
	position: absolute;
	right: 0;
	left: 0;
	display: block;
	width: 100%;

	div.slide-panel-body {

		&.has-tabs {
			height: 100%;
		}

		div.panel-content {
			height: 100%;
			padding: 0;
		}
	}
}

// END OF OVERRIDES
.report-studio-container {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 84px;
}

export-builder-dashboard {
	display: block;
	.absolute();
	.z-index(7);
	left: 250px;
	right: -17px;
	overflow-y: auto;
	overflow-x: hidden;
	padding-right: 297px;
	box-sizing: content-box;

	.page-overlay {
		pointer-events: none;
		.z-index(1000);
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.8;
		width: 100%;
		height: 100%;
		background-color: #EDF1F3;
	}

	#multi-select-elements {
		position: fixed;
		.z-index(1000);
		display: none;

		.ui-resizable-handle {
			opacity: 0 !important;
			cursor: crosshair;
		}
	}

	.exportbuilder-dashboard {
		position: relative;
		height: 100%;
		.flex();
		.flex-center();

		.transition-group(margin-top 0.3s ease, margin-bottom 0.3s ease);
		will-change: margin-top, margin-bottom;


		.page-grid-mode {
			background-color: red;
		}

		.modify-assignment-btn {
			background: none;
			color: #0D45A4;
			border: 0;
			border-bottom: 1px solid #0D45A4;
			padding: 0;
			border-radius: 0;
		}

		.mr-bottom-10 {
			margin-bottom: 10px;
		}

		.text-right {
			text-align: right;
		}

		.hidden-info {
			background: #fff;
			padding: 50px;
		}

		.page {
			@bg-border-width: 0px;
			@bg-page-width: @page-width + 2 * @bg-border-width;

			position: relative;
			transform-origin: 50% 50% 0;
			.flex();
			.flex-center();

			// item resizing-moving animation is handled here because it's all in percentages
			.transition-group(width .3s ease, height .3s ease, transform .3s ease);

			.page-background {
				// center canvas
				position: absolute;
				transform-origin: 50% 50% 0;

				.shadow(0px, 0px, 40px, 0.2);

				background-color: white;

				&.animated {
					.transition(transform, .3s);
				}
			}

			.page-background-overlay {
				.transition(all, 0.3s, ease);
				pointer-events: none;
				position: absolute;
				transform-origin: 50% 50% 0;

				.z-index(1000);
				position: absolute;
				opacity: 0.8;
				box-sizing: content-box;
			}

			&.landscape {
			}

			&.portrait {

				.page-background {
				}
			}

			.page-content {
				position: absolute;
				width: 100%;
				height: 100%;
				transform-origin: 50% 50% 0;
				.transition-group(width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.15s ease);

				// Fix the issue that wkhtmltopdf doesn't support flex syntax
				.widget-box-horizontal {
					.old-flex(horizontal);
				}

				.widget-box-vertical {
					.old-flex(vertical);
				}

				div.chart-widget-container {
					.old-flex-size(1);
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}

div[export-builder-presentation-canvas-directive] {
	width: 100vw;
	height: 100vh;

	#sketch {
		position: fixed;
		.z-index();
		width: 100vw;
		height: 100vh;
	}

	canvas {
		position: fixed;
		.z-index();
		width: 100vw;
		height: 100vh;
		cursor: url('../../../../images/pen.png'), auto;
	}
}