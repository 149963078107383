//
// SWITCH CHECKBOX
//
input[type=checkbox].switch {
    -webkit-appearance: none;
    border: 1px solid #cfd8e2;
    width: 40px;
    height: 24px;
    .rounded(12px);
    outline: none;
    position: relative;
    .transition(all, .2s, ease-in-out);
    cursor: pointer;
    margin-top: 1px;

    &[disabled] {
        cursor: not-allowed !important;
        .opacity(50);
    }

    &:hover {
        border-color: #b2c2d4;
    }

    &:after {
        content: '';
        width: 18px;
        height: 18px;
        background: #cfd8e2;
        position: absolute;
        .rounded(50%);
        top: 2px;
        left: 2px;
        .transition(all, .2s, ease-in-out);
    }

    &:hover:after {
        background: #b2c2d4;
    }

    &:checked:after {
        .transform(translate3d(16px, 0, 0));
    }
}