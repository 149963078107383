//
// DESIGN LAYOUT
//
design-layout {
    display: block;

    div.layout-inner {
        padding: 0;
        z-index: 1;
        .opacity(0);

        &.layout-freeflow {
            .opacity(100);
        }
    }

    div.layout-freeflow {
        margin: 0 -10px;

        div.design-widget {
            padding: 0;
            margin-bottom: 20px;

            div.widget-inner {
                .rounded(0);
            }
        }

        .datagrid-display {
            overflow-y: hidden;
            min-height: 500px;
        }
    }

    div.layout-overview {

        div.widget-grid-item {
            position: relative;

            div.widget-inner {
                min-height: 150px !important;
            }
        }
    }
}