end-customer-details {
    div.design-widget {
        min-height: 150px;
    }

    div.widget-loaded-container {
        display: none;
    }

    div.loading-container {
        margin-top: 80px;
    }
}