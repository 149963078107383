//------------------------ Fonts ----------------------------->
@import (less) '../../../fonts/font-awesome/css/font-awesome.css';
@import (less) '../../../fonts/services/css/style.css';
@import (less) '../../../fonts/icomoon/css/style.css';
@import (less) '../../../fonts/icomoon/css/new_style.css';
@import (less) '../../../fonts/alphabet/css/style.css';

//------------------------- Main ----------------------------->
@import "mixins.less";
@import "site.less";
@import "modals.less";
@import "helpers.less";
@import "directives.less";
@import "loaders.less";
@import "buttons.less";
@import "slidepanel.less";
@import "walkme.less";
@import "checkbox.less";
@import "owlcarousel.less";
@import "youtube.less";


//------------------------- Plugins ----------------------------->
@import (less) "../../../libs/amcharts/plugins/export/export.css";
@import (less) "../../../plugins/bootstrap-validator/bootstrapValidator.css";
@import (less) "../../../plugins/tap-colors/tapcolors.css";

//------------------------- node_modules ----------------------------->
@import (less) "../../../../../node_modules/animate.css/animate.css";
@import (less) "../../../../../node_modules/angular-loading-bar/src/loading-bar.css";
@import (less) "../../../../../node_modules/bootstrap-daterangepicker/daterangepicker.css";
@import (less) "../../../../../node_modules/bootstrap-fileinput/css/fileinput.css";
@import (less) "../../../../../node_modules/datatables.net-bs/css/dataTables.bootstrap.css";
@import (less) "../../../../../node_modules/datatables.net-responsive-bs/css/responsive.bootstrap.css";
@import (less) "../../../../../node_modules/summernote/dist/summernote.css";
@import (less) "../../../../../node_modules/select2/select2.css";
@import (less) "../../../../../node_modules/select2-bootstrap-css/select2-bootstrap.css";
@import (less) "../../../../../node_modules/switchery-latest/dist/switchery.css";
@import (less) "../../../../../node_modules/angularjs-slider/dist/rzslider.css";
@import (less) "../../../../../node_modules/magic.css/1.1.0/magic.css";
@import (less) "../../../../../node_modules/bootstrap-sweetalert/dist/sweetalert.css";
@import (less) "../../../../../node_modules/highlight.js/styles/tomorrow.css";
@import (less) "../../../../../node_modules/spectrum-colorpicker/spectrum.css";
@import (less) "../../../../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
@import "../../../../../grok/node_modules/mapbox-gl/dist/mapbox-gl.css";

//------------------------- Views ----------------------------->
@import "login.less";

// Responsive classes and overrides need to be set after the plugins/frameworks they override
@import "datatable.less";
@import "overrides.less";
@import "responsive.less";
@import "flexbox.less";

// Add module styles below (MUST USE ../../ relative paths for module styles) //
@import "../../ui/ui.less";
@import "../../nav/main/navmain.less";
@import "../../daterange/widget/widget.daterange.less";
@import "../../design/base/design.less";
@import "../../design/widget/builder/base/widget.builder.less";
@import "../../shared/styles/slidepanel.less";
@import "../../alert/base/alert.less";
@import "../../datasource/base/datasource.less";
@import "../../connect/styles/connect.less";
@import "../../feedbacks/feedbacks.less";
@import "../../service/base/services.less";
@import "../../leads/base/leads.less";
@import "../../endcustomer/base/endcustomer.less";
@import "../../endcustomer/details/endcustomer.details.less";
@import "../../export/base/export.less";
@import "../../devtools/devtools.less";
@import "../../exportbuilder/base/exportbuilder.less";
@import "tapcolors.less";
@import "../../connectionstatus/connectionstatus.less";
@import "../../tag/tag.less";
@import "../../annotation/annotation.less";
@import "../../namingconvention/rule/namingconventionrule.less";
@import "../../smartcampaign/base/smartcampaign.less";
@import "../../smartcampaign/details/smartcampaign.details.less";
@import "../../goals/base/goals.less";
@import "../../goals/details/goals.details.less";
@import "../../preferences/styles/preferences.less";
@import "../../guides/guides.less";

@import "../../messenger/messenger.less";
@import "../../history/history.less";
@import "../../library/base/library.less";
@import "../../serviceclientmapping/base/serviceclientmapping.less";
@import "../../user/image/userimage.less";
