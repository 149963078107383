.mainfont() {
    font-family: 'Lato', 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    font-weight: 400;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        font-weight: 300;
    }

    @media print {
        font-weight: 400;
    }
}

.bold() {
    font-weight: 900;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        font-weight: 900;
    }

    @media print {
        font-weight: 900;
    }
}

.codefont() {
    font-family: 'Source Code Pro', 'Consolas' !important;
    font-weight: 600;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        font-weight: 400;
    }

    @media print {
        font-weight: 600;
    }
}

.ellipsis(@max-width: auto) {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: @max-width;
}

.rounded(@radius: 1px) {
    .rounded(@radius, @radius);
}

.rounded(@radius_top, @radius_bottom) {
    .rounded(@radius_top, @radius_top, @radius_bottom, @radius_bottom);
}

.rounded(@radius_top_left, @radius_top_right, @radius_bottom_right, @radius_bottom_left) {
    -webkit-border-radius: @radius_top_left @radius_top_right @radius_bottom_right @radius_bottom_left;
    -moz-border-radius: @radius_top_left @radius_top_right @radius_bottom_right @radius_bottom_left;
    border-radius: @radius_top_left @radius_top_right @radius_bottom_right @radius_bottom_left;
}

.shadow(@shadow_x: 2px, @shadow_y: 2px, @shadow_rad: 10px, @alpha: 0.4) {
    -webkit-box-shadow: @shadow_x @shadow_y @shadow_rad rgba(100, 100, 100, @alpha);
    -moz-box-shadow: @shadow_x @shadow_y @shadow_rad rgba(100, 100, 100, @alpha);
    box-shadow: @shadow_x @shadow_y @shadow_rad rgba(100, 100, 100, @alpha);
}

.box-shadow(@shadow) {
    -webkit-box-shadow: @shadow;
    -moz-box-shadow: @shadow;
    box-shadow: @shadow;
}

.no-shadow() {
    -webkit-box-shadow: 0 0 0 rgba(100, 100, 100, 0) !important;
    -moz-box-shadow: 0 0 0 rgba(100, 100, 100, 0) !important;
    box-shadow: 0 0 0 rgba(100, 100, 100, 0) !important;
}

.innershadow(@horizontal: 0, @vertical: 1px, @blur: 2px, @alpha: 0.4) {
    -webkit-box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
    -moz-box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
    box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
}

.outerglow(@shadow_x: 2px, @shadow_y: 2px, @shadow_rad: 10px, @rgba: rgba(0, 0, 0, 1)) {
    -webkit-box-shadow: @shadow_x @shadow_y @shadow_rad @rgba;
    -moz-box-shadow: @shadow_x @shadow_y @shadow_rad @rgba;
    box-shadow: @shadow_x @shadow_y @shadow_rad @rgba;
}

.gradient(@from: #ffffff, @to: #dddddd) {
    background: @from;
    background: linear-gradient(top, @from 0%,@to 100%);
    background: -moz-linear-gradient(top, @from 0%, @to 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@from), color-stop(100%,@to));
    background: -webkit-linear-gradient(top, @from 0%, @to 100%);
    background: -o-linear-gradient(top, @from 0%,@to 100%);
    background: -ms-linear-gradient(top, @from 0%,@to 100%);
    filter: formatstring("progid:DXImageTransform.Microsoft.gradient(startColorstr='{0}', endColorstr='{1}',GradientType=0)", @from, @to);
}

.hgradient(@from: #ffffff, @to: #dddddd) {
    background: @from;
    background: linear-gradient(left, @from 0%,@to 100%);
    background: -moz-linear-gradient(left, @from 0%, @to 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,@from), color-stop(100%,@to));
    background: -webkit-linear-gradient(left, @from 0%, @to 100%);
    background: -o-linear-gradient(left, @from 0%,@to 100%);
    background: -ms-linear-gradient(left, @from 0%,@to 100%);
    filter: formatstring("progid:DXImageTransform.Microsoft.gradient(startColorstr='{0}', endColorstr='{1}',GradientType=1)", @from, @to);
}

.opacity(@op: 100) {
    -ms-filter: formatstring("progid:DXImageTransform.Microsoft.Alpha(opacity='{0}')", @op);
    filter: alpha(opacity=@op);
    -moz-opacity: (@op / 100);
    -khtml-opacity: (@op / 100);
    opacity: (@op / 100);
}

.alpha(@color: #000, @opacity: 0.5) {
    background-color: rgba(red(@color), green(@color), blue(@color), @opacity);
    filter: formatString(@msGradient, @msColor);
}

.blur(@blur: 10px) {
    -webkit-filter: blur(@blur);
    -moz-filter: blur(@blur);
    -o-filter: blur(@blur);
    -ms-filter: blur(@blur);
    filter: blur(@blur);
}

//Use for fonts over 28px - Gives a sharper result
.fontSmoothing() {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.clearfix() {
    zoom:1;
    &:before, &:after{ content:""; display:table; }
    &:after{ clear: both; }
}

.z-index(@over: 0) {
    z-index: 1000 + @over;
}

.boxSizing(@type: initial) {
    -webkit-box-sizing: @type;
    -moz-box-sizing: @type;
    box-sizing: @type;
}

.center-element(@position: absolute) {
    position: @position;
    top: 50%;
    left: 50%;
    .transform(translateX(-50%) translateY(-50%));
}

.vertical-center(@position: absolute) {
    position: @position;
    top: 50%;
    .transform(translateY(-50%));
}

.horizontal-center(@position: absolute) {
    position: @position;
    left: 50%;
    .transform(translateX(-50%));
}

.absolute(@top:0, @right:0, @bottom:0, @left:0) {
    position: absolute;
    left: @left;
    right: @right;
    bottom: @bottom;
    top: @top;
}

.fixed(@top:0, @right:0, @bottom:0, @left:0) {
    position: fixed;
    left: @left;
    right: @right;
    bottom: @bottom;
    top: @top;
}

.box-sizing(@sizing: border-box) {
    -ms-box-sizing: @sizing;
    -moz-box-sizing: @sizing;
    -webkit-box-sizing: @sizing;
    box-sizing: @sizing;
}

.calcHeight(@offset: 0px, @height: 100%) {
    height: calc(@height ~"-" @offset);
    height: -moz-calc(@height ~"-" @offset);
    height: -webkit-calc(@height ~"-" @offset);
}

.calcWidth(@offset: 0px) {
    width: calc(100% ~"-" @offset);
    width: -moz-calc(100% ~"-" @offset);
    width: -webkit-calc(100% ~"-" @offset);
}

.objectFit(@value) {
    -o-object-fit: @value;
    object-fit: @value;
}

/******************* CSS3 Animation MIXINS ********************/
.keyframes(@name; @arguments) {
    @-webkit-keyframes @name { @arguments(); }
    @keyframes @name { @arguments(); }
}
.animation(@animation) {
    -webkit-animation: @animation;
    -o-animation: @animation;
    animation: @animation;
}
.animation-name(@name) {
    -webkit-animation-name: @name;
    animation-name: @name;
}
.animation-duration(@duration) {
    -webkit-animation-duration: @duration;
    animation-duration: @duration;
}
.animation-timing-function(@timing-function) {
    -webkit-animation-timing-function: @timing-function;
    animation-timing-function: @timing-function;
}
.animation-delay(@delay) {
    -webkit-animation-delay: @delay;
    animation-delay: @delay;
}
.animation-iteration-count(@iteration-count) {
    -webkit-animation-iteration-count: @iteration-count;
    animation-iteration-count: @iteration-count;
}
.animation-direction(@direction) {
    -webkit-animation-direction: @direction;
    animation-direction: @direction;
}
.animation-fill-mode(@fill-mode) {
    -webkit-animation-fill-mode: @fill-mode;
    animation-fill-mode: @fill-mode;
}

.animate(@speed: 0.8s, @times: 1, @fill: both) {
    -webkit-animation-duration: @speed;
    -moz-animation-duration: @speed;
    -o-animation-duration: @speed;
    animation-duration: @speed;
    -moz-animation-iteration-count:@times;
    -o-animation-iteration-count:@times;
    -webkit-animation-iteration-count:@times;
    animation-iteration-count:@times;
    -webkit-animation-fill-mode: @fill;
    -o-animation-fill-mode: @fill;
    animation-fill-mode: @fill;
}

.transition(@type: all, @duration: 0.5s, @easing: ease, @delay: 0s) {
    -webkit-transition: @type @duration @easing @delay;
    -moz-transition: @type @duration @easing @delay;
    -o-transition: @type @duration @easing @delay;
    transition: @type @duration @easing @delay;
}
.transition-group(@transition1, @transition2) {
    -webkit-transition: @transition1, @transition2;
    -moz-transition: @transition1, @transition2;
    -o-transition: @transition1, @transition2;
    transition: @transition1, @transition2;
}
.transition-group(@transition1, @transition2, @transition3) {
    -webkit-transition: @transition1, @transition2, @transition3;
    -moz-transition: @transition1, @transition2, @transition3;
    -o-transition: @transition1, @transition2, @transition3;
    transition: @transition1, @transition2, @transition3;
}
.transition-group(@transition1, @transition2, @transition3, @transition4) {
    -webkit-transition: @transition1, @transition2, @transition3, @transition4;
    -moz-transition: @transition1, @transition2, @transition3, @transition4;
    -o-transition: @transition1, @transition2, @transition3, @transition4;
    transition: @transition1, @transition2, @transition3, @transition4;
}
.transition-delay(@time) {
    -moz-transition-delay: @time;
    -webkit-transition-delay: @time;
    transition-delay: @time;
}
.scale(@ratio) {
    -webkit-transform: scale(@ratio);
    -ms-transform: scale(@ratio);
    transform: scale(@ratio);
}
.scale(@ratioX, @ratioY) {
    -webkit-transform: scale(@ratioX, @ratioY);
    -ms-transform: scale(@ratioX, @ratioY);
    transform: scale(@ratioX, @ratioY);
}
.translate(@x: 0, @y: 0) {
    -webkit-transform: translate(@x, @y);
    -ms-transform: translate(@x, @y);
    transform: translate(@x, @y);
}
.transform(@transform) {
    -webkit-transform: @transform;
    -moz-transform: @transform;
    -ms-transform: @transform;
    -o-transform: @transform;
    transform: @transform;
}
.transform-origin(@origin) {
    -webkit-transform-origin: @origin;
    -moz-transform-origin: @origin;
    -ms-transform-origin: @origin; // IE9 only
    transform-origin: @origin;
}
.backface-visibility() {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

//
//A few animate mixins
//
.rotate(@speed: 1s, @times: infinite) {
    .animation(rotating @speed linear);
    .animation-iteration-count(@times);
    line-height: 0;
}
@keyframes rotating {
    from {
        .transform(rotate(0));
    }
    to {
        .transform(rotate(360deg));
    }
}


.rotateReverse(@speed: 1s, @times: infinite) {
    .animation(rotatingReverse @speed linear);
    .animation-iteration-count(@times);
    line-height:0;
}
@keyframes rotatingReverse {
    from {
        .transform(rotate(0));
    }
    to {
        .transform(rotate(-360deg));
    }
}


.bigPulse(@size: 1.5, @speed: 1s, @rep: 1) {

    @keyframes bigPulse {
        0% {
            .transform(scale3d(1, 1, 1));
        }

        50% {
            .transform(scale3d(@size, @size, @size));
        }

        100% {
            .transform(scale3d(1, 1, 1));
        }
    }

    .animation(bigPulse @speed ease-in-out @rep);
}

.scaleUp(@scale-from: 0.95, @duration: 0.3s) {
    .animation(@duration scaleUp ease-in);
    .scale(@scale-from);
}
@keyframes scaleUp {
    0% {
        .opacity(0);
    }
    100% {
        .opacity(100);
        .scale(1);
    }
}


.scaleIn() {
    .animation(scaleIn 0.15s backwards);
}
@keyframes scaleIn {
    from {
        .transform(translateY(-50%) scale(0));
    }
    to {
        .transform(translateY(-50%) scale(1));
    }
}


.scaleInSmooth() {
    .animation(scaleInSmooth 0.6s);
}
@keyframes scaleInSmooth {
    from {
        .transform(scale(0.9) translate3d(0,0,0) cubic-bezier(0,0,0,1));
    }
    to {
        .transform(scale(1) translate3d(0,0,0) cubic-bezier(0,0,0,1));
    }
}


.verticalFloating(@speed: 0.7s, @times: infinite) {
    .animation(verticalFloating @speed linear);
    .animation-direction(alternate);
    .animation-iteration-count(@times);
}
@keyframes verticalFloating {
    from {
        .transform(translateY(-3px));
    }
    to {
        .transform(translateY(3px));
    }
}


.fadeIn {
    .fadeIn();
}
.fadeIn(@duration: 0.3s) {
    .animation-name(fadeIn);
    .transition(opacity, @duration);
    .animation-fill-mode(forwards);
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


.fadeOut {
    .fadeOut();
}
.fadeOut(@duration: 0.3s, @delay: 0s) {
    .transition(opacity, @duration);
    .animation-name(fadeOut);
    .animation-delay(@delay);
    .animation-fill-mode(forwards);
}
@keyframes fadeOut {
    from {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


.slideFadeIn-lr(@translate) {
    .animation-name(slideFadeIn-lr);
    .transform(translateX(-@translate));
    .transition-group(opacity, transform);
}
@keyframes slideFadeIn-lr {
    from {
        opacity: 0;
        overflow: hidden;
    }

    100% {
        opacity: 1;
        .transform(translateX(0));
        overflow: initial;
    }
}


.slideFadeIn-rl(@translate) {
    .animation-name(slideFadeIn-rl);
    .transform(translateX(@translate));
    .transition-group(opacity, transform);
}
@keyframes slideFadeIn-rl {
    from {
        opacity: 0;
        overflow: hidden;
    }

    100% {
        opacity: 1;
        .transform(translateX(0));
        overflow: initial;
    }
}


.slideFadeIn-tb(@translate) {
    .animation-name(slideFadeIn-tb);
    .transform(translateY(-@translate));
    .transition-group(opacity, transform);
}
@keyframes slideFadeIn-tb {
    from {
        opacity: 0;
        overflow: hidden;
    }

    100% {
        opacity: 1;
        .transform(translateY(0));
        overflow: initial;
    }
}


.slideFadeOut-tb() {
    .animation-name(slideFadeOut-tb);
    .transform(translateY(0));
    .transition-group(opacity, transform);
}
@keyframes slideFadeOut-tb {
    from {
        opacity: 1;
        overflow: initial;
    }

    100% {
        opacity: 0;
        overflow: hidden;
        .transform(translateY(20px));
    }
}


.slideFadeOut-bt() {
    .animation-name(slideFadeOut-bt);
    .transform(translateY(0));
    .transition-group(opacity, transform);
}
@keyframes slideFadeOut-bt {
    from {
        opacity: 1;
        overflow: initial;
    }

    100% {
        opacity: 0;
        overflow: hidden;
        .transform(translateY(-20px));
    }
}


.slideFadeIn-bt(@translate) {
    .animation-name(slideFadeIn-bt);
    .transform(translateY(@translate));
    .transition-group(opacity, transform);
}
@keyframes slideFadeIn-bt {
    from {
        opacity: 0;
        overflow: hidden;
    }

    100% {
        opacity: 1;
        .transform(translateY(0));
        overflow: initial;
    }
}


.zoomInSmall {
    .zoomInSmall();
}
.zoomInSmall() {
    .animation-name(zoomInSmall);
}
@keyframes zoomInSmall {

    from {
        opacity: 0;
        .transform(scale3d(.8, .8, .8));
    }

    50% {
        opacity: 1;
    }
}


.zoomOutSmall {
    .zoomOutSmall();
}
.zoomOutSmall() {
    .animation-name(zoomOutSmall);
}
@keyframes zoomOutSmall {

    from {
        opacity: 1;
    }

    50% {
        opacity: 0;
        .transform(scale3d(.8, .8, .8));
    }
}


.zoomInSmooth {
    .zoomInSmooth();
}
.zoomInSmooth() {
    .animation-fill-mode(none);
    .animation-name(zoomInSmooth);
    .transition(transform);
}
@keyframes zoomInSmooth {

    from {
        .transform(scale(0.96) translate3d(0,0,0));
    }

    to {
        .transform(scale(1.0) translate3d(0,0,0));
    }
}


.small-pop() {
    .animation(small-pop 0.3s linear 1);
}
@keyframes small-pop {
    50%  {transform: scale(1.01);}
}


// Delay showing an element. Useful where elements showing have a janky apparition
.delayed-show-lr(@delay: 0.25s, @duration: 0.5s, @translate: 10px) {
    .opacity(0);
    .slideFadeIn-lr(@translate);
    .animation-duration(@duration);
    .animation-delay(@delay);
    .animation-fill-mode(forwards);
}

// Delay showing an element. Useful where elements showing have a janky apparition
.delayed-show-rl(@delay: 0.25s, @duration: 0.5s, @translate: 10px) {
    .opacity(0);
    .slideFadeIn-rl(@translate);
    .animation-duration(@duration);
    .animation-delay(@delay);
    .animation-fill-mode(forwards);
}

// Delay showing an element. Useful where elements showing have a janky apparition
.delayed-show-tb(@delay: 0.25s, @duration: 0.5s, @translate: 10px) {
    .opacity(0);
    .slideFadeIn-tb(@translate);
    .animation-duration(@duration);
    .animation-delay(@delay);
    .animation-fill-mode(forwards);
}

// Delay showing an element. Useful where elements showing have a janky apparition
.delayed-hide-tb(@delay: 0.25s, @duration: 0.5s) {
    .slideFadeOut-tb();
    .animation-duration(@duration);
    .animation-delay(@delay);
    .animation-fill-mode(forwards);
}

// Delay showing an element. Useful where elements showing have a janky apparition
.delayed-hide-bt(@delay: 0.25s, @duration: 0.5s) {
    .slideFadeOut-bt();
    .animation-duration(@duration);
    .animation-delay(@delay);
    .animation-fill-mode(forwards);
}

// Delay showing an element. Useful where elements showing have a janky apparition
.delayed-show-bt(@delay: 0.25s, @duration: 0.5s, @translate: 10px) {
    .opacity(0);
    .slideFadeIn-bt(@translate);
    .animation-duration(@duration);
    .animation-delay(@delay);
    .animation-fill-mode(forwards);
}

// Non delayed versions of delayed show
.toggle-show-lr(@duration: 0.5s, @translate: 10px) {
    .delayed-show-lr(0s, @duration, @translate);
    .animation-fill-mode(both);
}

.toggle-show-tb(@duration: 0.5s, @translate: 10px) {
    .delayed-show-tb(0s, @duration, @translate);
    .animation-fill-mode(both);
}

.toggle-hide-tb(@duration: 0.5s) {
    .delayed-hide-tb(0s, @duration);
}

.delayed-hide-bt(@duration: 0.5s) {
    .delayed-hide-bt(0s, @duration);
}

//
// Animate.css classes as functions. Avoids using "animated" class and will trigger animation on show/hide
//
.animBounce {
    .animation(bounce 1s);
}

.animScaleUp {
    .animation(scaleUp 1s);
}

.animPulse {
    .animation(pulse 1s);
}

.animZoomIn {
    .animation(zoomIn 0.6s);
}

.animFadeInTop {
    .animation(fadeInDown 0.6s);
}