// This file is used to solve https://tapclicks.atlassian.net/browse/CON-2028
// This will not be an issue in the new UI

div.service-flex-square[data-id="52"],
div[data-service-name-filter="youtube"] {
    background-color: #fff !important;

    span.title {
        color: rgba(0, 0, 0, 0.5) !important;
    }

    div.option-inner span {
        color: #000 !important;
    }
}

.flex-icon.serviceicon-youtube,
.icon.serviceicon-youtube {
    color: #FF0000 !important;
    background-color: #fff !important;
}

div.service-preference-input-container {
    .serviceicon-youtube {
        font-size: 36px !important;
    }
}

.service-square-24 {
    .serviceicon-youtube {
        font-size: 22px !important;
    }
}

.select2-container.select2-container-disabled .select2-choice div {
    border-left: unset !important;
}

.widget-header,
.nav-submenu-item.service-item {
    .service-square {
        .serviceicon-youtube {
            font-size: 36px !important;
        }
    }
}