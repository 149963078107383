owl-carousel {

    div.owl-carousel {
        clear: both;

        .owl-dots {
            text-align: center;
            margin-top: 10px;

            .owl-dot {
                width: 15px;
                height: 15px;
                border-radius: 100%;
                margin-right: 5px;
                display: inline-block; /*for making them horizontal, if you want vertical then you can use- display: block*/
            }
        }

        div.owl-height {
            .transition(none);
            max-height: 3000px;
        }
    }
}