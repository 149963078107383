div.library-tab-content {

    &.search-active {
        div.default-row {
            .zoomOutSmall();
        }
    }

    p.title {
        .bold();
        .fontSmoothing();
        font-size: 11px;
        margin-bottom: 4px;
        text-transform: uppercase;
        margin-left: 6px;
        border: 0;
        text-indent: 0;
    }

    div.search-container {
        position: relative;
        cursor: pointer;
        overflow: hidden;
        min-height: 110px;
        padding: 15px 15px 0 15px;

        > i.icon {
            .z-index();
            position: absolute;
            top: 23px;
            left: 24px;
            font-size: 16px;

            &.icomoon-cross {
                cursor: pointer;
            }
        }

        input.form-control {
            padding-left: 32px;
        }

        div.search-box-container {
            position: relative;
            margin-top: 16px;

            > i.icon {
                .z-index();
                position: absolute;
                top: 9px;
                left: 9px;
                font-size: 16px;
            }

            span.search-select2-container {

                div.select2-container {

                    ul.select2-choices {
                        padding-left: 28px;
                    }

                    li.select2-search-choice {
                        margin-left: 5px;
                        line-height: 25px;
                        padding-left: 8px;
                        padding-right: 24px;
                        font-size: 12px;

                        .icon {
                            margin-right: 7px;

                            &.icon-tag {
                                margin-right: 5px;
                            }

                            &.custom-icon {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }

            span.search-select2-container.icon-margin-fix .select2-container .select2-choices li.select2-search-choice .icon {
                margin-right: 0px;
            }
        }
    }

    div.library-rows {
        overflow: hidden;
        height: 100%;
    }

    div.library-row {
        overflow-y: auto;
        overflow-x: hidden;
        .calcHeight(110px);

        h6 {
            .bold();
            font-size: 13px;
            text-indent: 20px;
            margin: 10px 0;
        }

        div.library-item {
            .transition(all, 0.3s, ease-in-out);
            .opacity(90);
            .rounded(6px);
            display: inline-block;
            margin: 5px 3px 5px 9px;
            width: 180px;
            position: relative;
            padding: 5px;
            border: 1px solid @light-border-color;
            outline: 1px solid transparent;

            &.selected,
            &:hover {
                .opacity(100);
                .z-index(1);
                border-color: @active-icon-color;
                outline-color: @active-icon-color;
                cursor: pointer;
            }

            div.header {
                padding: 0 8px;
                white-space: nowrap;
                overflow: hidden;

                > i.icon {
                    font-size: 24px;
                    float: left;
                    margin-right: 4px;
                    position: relative;

                    &.custom-icon {
                        top: 0;
                        font-size: 16px;
                    }

                    &.serviceicon-custom-icon {
                        font-size: 16px;
                        top: 7px;
                    }

                    &[class*=icomoon-] {
                        font-size: 14px;
                        top: 3px;
                        left: -2px;
                    }
                }

                p.text {
                    float: left;
                    font-size: 10px;
                    position: relative;
                    top: 3px;
                    .ellipsis(100px);
                }

                label.badge {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    font-size: 11px;
                    padding: 2px 6px;
                    .rounded(50%);
                    .z-index(1000);
                    .opacity(80);
                }
            }

            img {
                width: 100%;
                height: 200px;
            }

            div.icon-body {
                font-size: 60px;
                line-height: 200px;
                height: 200px;
                text-align: center;
            }

            div.footer {
                padding: 3px 0;
                white-space: nowrap;
                overflow: hidden;

                i.icon {
                    font-size: 18px;
                    float: left;
                }

                span.text,
                label {
                    font-size: 10px;
                    float: left;
                    margin-top: 1px;
                    margin-left: 3px;
                }
            }
        }
    }
}