// Page level styles
@import "../page/base/design.page.less";
@import "../details/details.modal.less";
@import "stretchynav.less";
@import "design.datepicker.less";
@import "../page/dashboardfilterpanel/base/dashboardfilterpanel.less";
@import "../page/executivesummary/base/executivesummary.less";
@import "../page/executivesummary/panel/executivesummarypanel.less";
@import "../page/executivesummary/create/create.executivesummary.less";
@import "../page/library/page.library.less";
@import "../widget/products/product.widget.less";
// Layout level styles
@import "../layout/base/design.layout.less";
@import "../layout/manage/layout.manage.less";
@import "../layout/isotope/design.isotope.less";
@import "../layout/library/layout.library.less";
@import "../layout/drawoptionpanel/base/drawoptionpanel.less";
// Widget level styles
@import "../widget/base/design.modal.less";
@import "../widget/base/design.widget.less";
@import "../widget/option/base/widget.option.less";
@import "../widget/library/base/widget.library.less";
@import "../widget/create/base/widget.create.less";
@import "../widget/filter/base/widget.filter.less";
@import "../widget/resize/base/widget.resize.less";
@import "../widget/probe/base/widget.probe.less";
@import "../widget/types/media/widget.media.less";
@import "../widget/types/bignumber/widget.bignumber.less";
@import "../widget/types/datagrid/base/widget.datagrid.less";
@import "../widget/types/datagrid/tagpicker/datagrid.tagpicker.less";
@import "../widget/types/chart/widget.chart.less";
@import "../widget/types/admin/accountmanager/widget.accountmanager.less";
@import "../widget/types/chart/goal/widget.goal.less";
@import "../widget/types/chart/am5/widget.am5.less";
@import "../widget/types/executivesummary/widget.executivesummary.less";


div.loading-container {
    .flex-display();
    .flex-center;
    .absolute();
    .z-index(1000);
}

.dash-loader {

    &.pushed {
        div.loading-container {
            left: 0;
            padding-right: 300px;
        }
    }

    .icon {
        position: relative;
        top: -5px;
    }
}

div#dash-header {
    .clearfix();
    height: 65px;
    min-height: 65px;
    top: -50px;
    margin-left: 15px;

    &.page-freeflow {
        height: auto;
        min-height: inherit;
    }

    &.fixed {
        position: fixed;
        border-bottom: 1px solid;
        .transition(top);
        top: 0;
        left: 225px;
        right: 15px;
        height: 50px;
        min-height: 50px;
        line-height: 46px;
        .z-index(11000);
        margin-left: 0;

        div.dash-title-container {
            p.dash-title {
                margin-left: 15px;
                font-size: 16px;

                &.indented {
                    margin-left: 42px;
                }

                i.icon {
                    top: 7px;
                    left: 7px;
                }
            }

            a.action {
                top: 10px;
                left: 12px;
                margin-right: 0;
            }

            div.dropdown {
                top: 9px;

                > i.icon {
                    line-height: 40px;
                }
            }

            div.btn-hover {
                top: 10px;
            }

            div.dash-button-container {
                display: none;
            }

            div.dash-subtitle-container {
                margin-top: -4px;
                margin-left: 0;
                clear: none;

                span.notice-block {
                    margin-left: 7px;
                    line-height: normal;
                    margin-top: 21px;
                }
            }
        }

        div.dash-filters {
            height: 50px;

            div.dash-filter-toggler {
                top: 5px;
            }

            div.date-input-container {

                &.is-comparing {
                    input {
                        &.current-range {
                            top: 4px;
                        }

                        &.comparison-range {
                            top: 2px;
                        }
                    }
                }
            }

            div.dash-date-picker {
                height: 50px;
                margin-top: -2px;

                input {
                    &.comparison-range {
                        top: 10px;
                    }
                }
            }

            ul.nav-tabs {
                visibility: hidden;
            }
        }
    }

    &.pushed {
        right: 300px;
    }

    i.icon {
        text-indent: 0;
    }

    div.dash-title-container {
        position: relative;
        float: left;

        &.shorten-title {
            p.dash-title {
                .ellipsis(250px);
                height: 35px;
            }
        }

        p.dash-title {
            font-size: 24px;
            margin-right: 5px;
            .fontSmoothing();
            .ellipsis(600px);
            float: left;
            margin-bottom: 0;
            width: auto !important;
            height: 35px;

            &.indented {
                margin-left: 24px;
            }

            i.icon {
                position: absolute;
                left: -10px;
                top: 2px;
                font-size: 32px;
            }
        }

        div.dash-button-container {
            .flex();
            height: 30px;
            float: left;
            clear: none;
            position: relative;

            div.inner {
                .flex();

                &.disabled {
                    pointer-events: none;
                    cursor: not-allowed;
                    .opacity(25);
                }
            }

            &:hover {
                div.dash-button-notifier {
                    .opacity(75);
                    .transition(opacity, 0.5s, ease, 0.8s);
                }
            }

            div.btn-hover {
                margin: 0 -3px;

                i.icon-pencil {
                    position: relative;
                    top: -1px;
                }

                i.icomoon-add {
                    font-size: 16px;
                    position: relative;
                    top: 1px;
                }

                i.icomoon-barchart-bold {
                    margin-left: 2px;
                    margin-right: 13px;
                }
            }

            div.dash-button-notifier {
                .opacity(0);
                float: left;
                margin-left: 5px;
                margin-top: 8px;
            }
        }

        div.dash-subtitle-container {
            float: left;
            margin-top: 6px;
            margin-left: 3px;

            div.btn-hover {
                margin: 0 -2px;
            }

            span.notice-block {
                margin-left: 3px;
            }

            label {
                display: inline-block;
                position: relative;
                top: -1px;
                margin: 0 2px;
                font-size: 85%;
            }
        }

        .dropdown {
            margin: 0 3px;
            .z-index(1000);
            display: inline-block;

            &:hover {
                > i.icon.icomoon-ellipsis-v {
                    .opacity(100);
                }
            }

            > i.icon.icomoon-ellipsis-v {
                .opacity(70);
                line-height: 35px;
                font-size: 18px;
            }

            ul.dropdown-menu {
                [class*=" icon-"] {
                    margin-right: 8px;
                    margin-left: -2px;
                }

                i.icon.icomoon-report-full {
                    margin-left: 2px;
                }
            }
        }

        .favorite-page-icon {
            color: #F2A449;
        }

        .not-allowed {
            cursor: not-allowed;
        }
    }
}
@-moz-document url-prefix() {
    div#dashboard-date-range-picker {
        div.ranges {
            width: 12.6rem;
        }
    }
}

div.dash-filters {
    float: right;
    position: relative;

    div.date-input-container {
        &.is-comparing {
            div.dash-filter-toggler {
                left: 120px;
            }
        }
    }

    div.dash-filter-toggler {
        position: absolute;
        top: -1px;
        right: 7px;
    }
}

.dash-banner {
    background: #d3d3d38c;
    font-size: 14px;
    padding: 10px;
    margin: 10px;
}

@media screen {
    @media (max-width: 1238px) {
        div#dash-header {
            &.fixed {
                height: 80px;
            }
        }
    }
}
@media screen {
    @media (max-width: 1500px) {
        div#dash-header {
            div.dash-title-container {
                p.dash-title {
                    .ellipsis(350px);
                }
            }
        }
    }
    @media (max-width: 1280px) {
        div#dash-header {
            div.dash-title-container {
                p.dash-title {
                    .ellipsis(250px);
                }
            }
        }
    }
}
