div.user-avatar-bubble {

    div.default-user-bubble {
        .rounded(50%);
        .shadow(0, 1px, 2px, 0.3);
        .bold();
        background-color: #f3f3f3;
        color: #8c8c8c;
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 10px;
        text-align: center;
    }

    img.user-image {
        .rounded(50%);
        .shadow(0, 1px, 2px, 0.5);
        display: inline-block;
        margin-right: 6px;
        margin-top: 3px;
        height: 24px;
        width: 24px;
    }
}