
div.color-picker-input {
    position: relative;
    -webkit-appearance: none;
    padding: 0;
    margin: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;
    outline: none;
    transition: box-shadow 0.35s;
    .rounded(50%);
    border: solid 1px;

    &:hover {
        -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    }

    &-hidden {
        visibility: hidden;
        div {
            visibility: visible;
        }
    }

    .no-color-marker {
        width: 100%;
        height: 100%;
        text-align: center;
        position: absolute;
        color: black;
        text-shadow: 0 0 0 #ffffff;
    }

}