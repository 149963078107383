/* Date range directive */
div.date-range-picker {
    padding-left: 10px;

    select,
    input[type=text] {
        display: inline-block;
        width: auto;
    }

    input[type=text] {
        width: 220px;
        padding: 10px 5px 8px 32px;
        margin-left: -16px;
        .rounded(4px);
    }

    i.icon-calendar {
        position: relative;
        left: 12px;
        top: -1px
    }

    i.clear-button {
        position: relative;
        top: 10px;
        right: 35px;
        font-size: 10px;
        float: right;
        cursor: pointer;
    }
}

/* Relative date range select directive */
div.date-range-select {

    select {
        width: 200px;
    }

    .form-control {
        font-size: 12px;
    }
}

/* Custom widget date range selector */
#widget_custom_date_range_container {
    margin-top: 8px;
    .form-control {
       width: 43%;
    }
}

/* Overlay */
.element-overlay-container {
    position: relative;
    overflow: hidden;
    padding-top: 10px;

    div.placeholder-overlay {
        .flex;
        .flex-center;
        .absolute();
        text-align:center;
        height:100%;
        cursor: not-allowed;

        label {
            display: inline-block;
            .bold();
            .z-index(1001);
            font-size: 14px;
            padding: 10px 20px;
        }
    }
}

.element-overlay {
    .absolute();
    text-align:center;
    .z-index(1000);
    .opacity(70);
}

/* Element Placeholder */
div.element-placeholder {
    .flex;
    .flex-center;
    .flex-column;
    text-align: center;

    div.inner {
        .animZoomIn;
        margin-top: -20px;

        img {
            width: 400px;
            object-fit: contain;
        }

        i.icon {
            font-size: 150px;
            margin-bottom: 10px;
        }

        h3 {
            margin: 0;
            font-size: 32px;
            line-height: 64px;
        }

        p {
            margin: 0;
            font-size: 18px;
            line-height: 36px;
        }

        div.btn {
            margin-top: 40px;
        }
    }
}


/* Text to input directive */
.text-to-input {

    div.text-wrapper {

        span {
            .rounded(4px, 0, 0, 4px);
            padding: 2px 6px 4px 6px;
            border-right: 0;
        }

        div.btn {
            top: -2px;
            left: 1px;
        }
    }

    div.input-wrapper {
        display: none;
        position: relative;
        top: -4px;
        left: -1px;

        input.form-control {
            .rounded(4px, 0, 0, 4px);
            display: inline-block;
            width: auto;
            height: 37px;
            border-right: 0;
            min-width: 120px;
        }

        div.button-container {
            right: 0px;
            position: absolute;
        }
    }

    div.btn {
        visibility: hidden;
        .rounded(0);
        text-indent: 0;
        position: relative;
        top: 2px;
        padding-bottom: 9px;
        margin-left: -5px;

        i.icon {
            margin: 0;
            font-size: 16px;
            position: relative;
            top: 1px;
        }
    }

    div.btn:last-child {
        .rounded(0, 4px, 4px, 0);
    }

    //
    // Hover
    //
    div.text-to-input:not(.disabled) {

        &:hover {
            span {
                border: 1px solid;
                margin: -1px;
            }
            div.btn {
                visibility: visible;
            }
        }
    }

    //
    // Active
    //
    &.active {
        div.text-wrapper {
            display: none;
        }

        div.input-wrapper {
            display: block;
        }

        div.btn {
            visibility: visible;
        }
    }
}


/* Device nav toggler directive */
div#device-nav-toggler {
    display: none;
    position: absolute;
    top: 7px;
    right: 5px;
    .fontSmoothing();
    .z-index(10);
}
body.impersonation-mode div#device-nav-toggler {
    top: 42px;
    right: 2px;
}

div#device-nav-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: black;
    .opacity(0);

    &.active {
        .transition(opacity);
        .z-index(11);
        .opacity(60);
    }
}